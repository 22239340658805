const defaultOptions = [];

export function columnsAppearance(state = defaultOptions, action) {
  //reducer
  switch (action.type) {
    case "UPDATE_COLUMNS_APPEARANCE":
        let drillListDataMap = new Map(state);
      drillListDataMap.set(action.key, action.columnsAppearance);
      return drillListDataMap;
    //   return [
    //     {
    //       columnsAppearance: action.columnsAppearance,
    //     },
    //   ];
    case "GET_COLUMNS_APPEARANCE":
      return state;
    default:
      return state;
  }
}
