import React, { useEffect } from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { cleanUpTabulatorColumns } from "../../../../class/common";
import { copyObjectValues } from "../../../../class/utils";
import { FormatTypes, GL_STRINGS_REVIEW } from "../../../../class/constants";
import { formatValHTML } from "../../../../class/format";




const ExistingAssignments = (props, ref) => {

    let tabulator;
    const { data , columns ,lstTransitionColumnNames, transitionDisplayMap} = props;
    const id = "existing-assignments";
    
   
    useEffect(() => {
        if(data){
            tableWrap();
        }
    }, [data, columns]);
   

    const tableWrap = () => {
       
        const paginate = data.length > 9;
        let options = {
            data: data,
            width: "100%",
            layout: "fitColumns", //fit columns to width of table
            pagination: paginate ? "local" : false,       //paginate the data
            paginationSize: paginate ? 10 : false,
            paginationSizeSelector: paginate ? [10, 50, 100] : false,
            footerElement: paginate ? "<div class='uk-margin-xsmall-top' id='existing_footer'></div>" : false,
            placeholder: "No data available"
        }
        var tableColumns = copyObjectValues(columns);
        tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, { id: id });
        tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        tabulator = new Tabulator("#" + id, options);
        tabulator.setSort(lstTransitionColumnNames[0],"asc");
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
        });
        return columns;
	}   
    
    const getColumnFormatter=(colField)=> {
		let columnFormatter;
		switch (true) {
            case lstTransitionColumnNames.includes(colField): 
                columnFormatter = function (cell) {
                    var rowData = cell.getRow().getData();
                    var div = document.createElement("div")
                    div.style.display = "flex";
                    div.style.flexDirection = "column";
                    div.style.gap = "0.1vw";
                    var span = document.createElement("span");
                    span.innerText = cell.getValue();
                    div.append(span);
                    if(colField in transitionDisplayMap){
                        var span2 = document.createElement("span");
                        span2.innerText = rowData[transitionDisplayMap[colField]];
                        span2.classList.add("fs-10");
                        span2.style.fontFamily = "Lato";
                        div.append(span2);
                    }
                    return div;
                }
                break;
            case colField === GL_STRINGS_REVIEW.COLUMNS.FIELDS.AMOUNT:
                columnFormatter = function (cell) {
                    var span = document.createElement("span");
                    var span2 = document.createElement("span");
                    var rowData = cell.getRow().getData();
                    var icon = document.createElement("i");
                    
                    if (rowData.flipped) {
                        icon.classList.add("far", "fa-retweet","uk-margin-small-right");
                        icon.setAttribute("uk-tooltip", "Flipped");
                        span.appendChild(icon);
                    }

                    span2.innerHTML = formatValHTML(cell.getValue(), FormatTypes.AMOUNT);
                    span.classList.add("uk-flex", "align-items-center");
                    span.appendChild(span2);
                    // span.style.height = "0px" // to align content in center verticaly
                    return span;
                }
                break;
			default:
                columnFormatter = function(cell) {
                    return formatValHTML(cell.getValue(), FormatTypes.TEXT);  
                }
            break;
        }
		return columnFormatter;
    }

    return (
        <div>
            <div id={id} className="large-margin-bottom table-new" />
        </div>
    );
}

export default ExistingAssignments;