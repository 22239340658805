//ACTIONS -----------------------------------------------------
export function updateUserSettings(settings) {
  return {
    type: 'UPDATE_USERSETTINGS',
    settings,
  }
}

export function getUserSetting() {
  return {
    type: 'GET_USERSETTINGS'
  }
}


export function updateUserSections(userSections) {
  return {
    type: 'UPDATE_USERSECTIONS',
    userSections:userSections,
  }
}

export function getUserSections() {
  return {
    type: 'GET_USERSECTIONS'
  }
}