import React, { useEffect, forwardRef, useImperativeHandle } from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { cleanUpTabulatorColumns } from "../../../../class/common";
import { copyObjectValues } from "../../../../class/utils";
import { DEFINE_COST_CENTER, FormatTypes, MANAGE_COST_CENTER } from "../../../../class/constants";
import { formatValHTML, formatValString } from "../../../../class/format";
import { lang } from "../../../../language/messages_en";
import { createDropdown } from "../../../../newComponents/DropdownTabulator";

const CostCentersTable = (props, ref) => {

    let tabulator;
    
    const FIELDS = DEFINE_COST_CENTER.COLUMNS.FIELDS;
    const DROP_DOWN = DEFINE_COST_CENTER.COLUMNS.DROP_DOWN;
    const CLOSED = "closed";
    const SELLING = "selling";
    const SOURCE_SYSTEM = "use_source_system";

    const {data} = props;
    const {costCenterStates} = props;
    const {columns} = props;
    const {id} = props;

    useImperativeHandle(ref, () => ({
        getData:() => {
            return tabulator.getData();
        },
        replaceData : () =>{
            return tabulator.replaceData(tabulator.getData());
        },
        replaceDataWithParam : (data) => {
            return tabulator.replaceData(data);
        }
    }));
    useEffect(() => {
        if(data){
            tableWrap();
            if(costCenterStates?.length > 0){
                addFooterText();
            }
        }
    }, [data]);
   

    const tableWrap = () => {
        // commented to use later
        // const paginate = data.length > 49;
        const paginate = true;
        let options =  {
            data: data, //link data to table
            width: "100%",
            maxHeight: "40vh",
            layout: "fitColumns",      //fit columns to width of table
            columnHeaderSortMulti:false,
            index: FIELDS.COST_CENTER,
			pagination: paginate ? "local" : false,       //paginate the data
            paginationSize: paginate ? 50 : false,
            paginationSizeSelector:paginate ? [10,50,100] : false,
            footerElement: "<div class='uk-margin-xsmall-top' id='"+id+"_footer'></div>"
        }
        var tableColumns = copyObjectValues(columns);
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, {id: id});
		tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        tabulator = new Tabulator("#" + id, options);
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
        });
        return columns;
	}   
    
    const getColumnFormatter=(colField)=> {
		var columnFormatter;
		switch (colField) {
            case FIELDS.STATE:
                columnFormatter = function(cell){

                    const dropDown = createStateDropDown(cell);
                    return dropDown;
                }
            break;
            case FIELDS.COGS:
            case FIELDS.TRX_REVENUE:
            case FIELDS.GL_REVENUE:
            case FIELDS.OPEX:
                columnFormatter = function(cell) {
                    return formatValHTML(cell.getValue(), FormatTypes.AMOUNT);  
                };
            break;
            case FIELDS.TRX_LINE :
                columnFormatter = function(cell) {
                    return formatValHTML(cell.getValue(), FormatTypes.NUMERIC);  
                };
            break;
			default:
                columnFormatter = function(cell) {
                    return formatValHTML(cell.getValue(), FormatTypes.TEXT);  
                }
            break;
        }
		return columnFormatter;
    }

    /**
 * update cost center state and update footer values
 * @param {*} e 
 * @param {*} cell 
 */
    const setCostCenterState = (e,cell) =>{
        cell.setValue(e.target.value);
        let rowIndex = cell.getRow().getData()[FIELDS.COST_CENTER];
        tabulator.updateRow(rowIndex, cell.getRow().getData());
        addFooterText();    
    }
    const getCostCenterData = () =>{
        return tabulator.getData();
    }

    const createTag = () => {
        let tag = document.createElement("div");
        tag.classList.add('status-tag', 'status-tag-default');
        let circleIcon = document.createElement('i');
        circleIcon.className = 'far fa-question-circle';
        let textValue = document.createElement("span");
        textValue.innerText = "Existing";

        tag.append(circleIcon);
        tag.append(textValue);
        
        return tag;
    }
    
    const createStateDropDown = (cell) => {
        const dropDown = createDropdown(["form-control-d","select-width-cost-center-state"]);
        dropDown.id = "cost_center_state_" + cell.getRow().getPosition();
    
        let option = document.createElement("option");
        option.classList.add("manage-cost-center-option-style")
        option.value = lang.manage_cost_centers.revenue_generating;
        option.title = lang.manage_cost_centers.revenue_generating;
        option.innerHTML = lang.manage_cost_centers.revenue_generating;
        option.disabled = true;
        dropDown.appendChild(option); 

        costCenterStates.filter(e=>e[DROP_DOWN.IS_REVENUE_GENERATING]
            && e.name.toLowerCase() == CLOSED ||  e.name.toLowerCase() == SELLING).sort((function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })).forEach(d=> {
            option = document.createElement("option");
            option.value = d.name;
            option.title = ((d.description)? d.description: d.name);
            option.innerHTML = d.display_name;
            dropDown.appendChild(option);
        });

        costCenterStates.filter(e=>e[DROP_DOWN.IS_REVENUE_GENERATING]
                && e .name.toLowerCase() !== CLOSED && e.name.toLowerCase() !== SELLING).sort((function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })).forEach(d=> {
            option = document.createElement("option");
            option.value = d.name;
            option.title = ((d.description)? d.description: d.name);
            option.innerHTML = d.display_name;
            dropDown.appendChild(option);
        });

        option = document.createElement("option");
        option.classList.add("manage-cost-center-option-style")
        option.value = lang.manage_cost_centers.non_revenue_generating;
        option.title = lang.manage_cost_centers.non_revenue_generating;
        option.innerHTML = lang.manage_cost_centers.non_revenue_generating;
        option.disabled = true;
        dropDown.appendChild(option);

        costCenterStates.filter(e=>e.name === SOURCE_SYSTEM).forEach(d=> {
            option = document.createElement("option");
            option.value = d.name;
            option.title = ((d.description)? d.description: d.name);
            option.innerHTML = d.display_name;
            dropDown.appendChild(option);
        })

        costCenterStates.filter(e=>!e[DROP_DOWN.IS_REVENUE_GENERATING]
            && e.name !== SOURCE_SYSTEM
                ).sort((function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })).forEach(d=> {
            option = document.createElement("option");
            option.value = d.name;
            option.title = ((d.description)? d.description: d.name);
            option.innerHTML = d.display_name;
            dropDown.appendChild(option);
        });
        
        dropDown.onchange = (e) => {setCostCenterState(e,cell); dropDown.classList.remove('placeholder-text');}
        dropDown.value = cell.getValue();

        if(!cell.getValue() && !dropDown.value) {
            /* create a select placeholder */
            let placeholder = document.createElement("option");
            placeholder.hidden = true;
            placeholder.defaultSelected = true;
            placeholder.innerHTML = 'Select state';
            dropDown.appendChild(placeholder);
            dropDown.classList.add('placeholder-text');
        }
        
        return dropDown;
    }

/**
 * for footer info in definition table 
 * @param {*} propsData 
 */
    const addFooterText = () => {
        let totalsText = "";
        const revenueGeneratingStates = costCenterStates.map((e) => {if(e.is_revenue_generating) return e.name} );
        let revenue_generating = [];
        let non_revenue_generating = [];

        tabulator.getData().forEach(element => {
            if(revenueGeneratingStates.includes(element.cost_center_state)){
                revenue_generating.push(element);
            } else {
                non_revenue_generating.push(element);
            }
        });
        
        totalsText ="<span class=\"uk-margin-small-right uk-text-medium\"> Count: " + formatValString(data.length,FormatTypes.NUMERIC) + "</span>";
        totalsText += "<span class=\"uk-margin-small-right uk-text-medium\">Revenue Generating: " + formatValString(revenue_generating.length, FormatTypes.NUMERIC) + "</span>";
        totalsText += "<span class=\"uk-margin-small-right uk-text-medium\"> Non Revenue Generating: " + formatValString(non_revenue_generating.length, FormatTypes.NUMERIC) + "</span>";

        document.getElementById(id+"_footer").innerHTML ="<p class='uk-margin-small-right'>" + totalsText + "</p>";
    }

    return (
        <div>
            <div id={id} className="large-margin-bottom define_cost_center" />
        </div>
    );
}

export default forwardRef(CostCentersTable);