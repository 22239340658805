import React, { Component, createRef, useRef } from 'react';
import Tabulator from "tabulator-tables"; //import Tabulator library
import { copyObjectValues, getTranslationFile, findOptionByKey, deepCompareObjects } from '../../class/utils.js';
import {FORMAT_SETTINGS, FormatTypes, METRICS_MAPPING, INFO_MESSAGE, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE, DIALOG_SIZE} from '../../class/constants';
import { formatValHTML } from '../../class/format.js';
import { cleanUpTabulatorColumns,translateTabulatorFilter,translateTabulatorSorter} from '../../class/common.js';
import { CustomSelect } from '../../form/elements.js';
import { convertPxToViewport } from '../../class/formatting.js';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';
import DropDown from '../../newComponents/DropDown.js';

const MESSAGES = getTranslationFile();

const DETAILS_ARR = ['Number', 'Name', 'Key', 'Value', 'Lines','Revenue', 'COGS'];
const SUMMARY_ARR = ['Key', 'Key1', 'Value', 'Lines', 'Revenue', 'Total_COGS'];
class ViewMoreMetric extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            summary: []
        };
        this.onChangeTimePeriod = this.onChangeTimePeriod.bind(this);
        this.setDefaultTimePeirod = this.setDefaultTimePeirod.bind(this);
        this.formatDynamicColumns = this.formatDynamicColumns.bind(this);
        this.formatArr = this.formatArr.bind(this);

    }


    drawTable=()=>{
        let obj = this;
        if (obj.state.data && obj.state.metricDetailsColumns && obj.state.summaryColumns) {
            var tableColumns = obj.formatDynamicColumns(obj.state.metricDetailsColumns, "details");
            tableColumns = cleanUpTabulatorColumns(copyObjectValues(tableColumns), obj.state.data, obj.refreshFilterDivs, obj.table, {id: "metricsDetailstotals", headerFilters: obj.table?.modules?.filter?.headerFilters});
            tableColumns.forEach(col => {
                col.formatter = obj.getColumnFormatter(col.title, col.formatter);
            });
            tableColumns = obj.replaceVectorMachineToDisplayNameInColumnsTitle(obj.props.vectors,tableColumns,"details");
            obj.table?.setColumns(tableColumns);		//set data for view more table
            obj.table?.setData(obj.state.data);		//set data for view more table
            if(obj.table?.getSorters().length > 0) {
                obj.table?.setSort(obj.table.getSorters()[0].field,obj.table.getSorters()[0].dir);
            } else {
                obj.table?.setSort("Metric_Value","desc");
            }
            
            obj.table?.clearFilter(true);
            var tableColumnsSum = (obj.formatDynamicColumns(obj.state.summaryColumns));
            tableColumnsSum = cleanUpTabulatorColumns(copyObjectValues(tableColumnsSum), null, obj.refreshFilterDivs, obj.viewMoreSummaryMetricDetail, {id: "viewMoreSummaryMetricDetail"});
            tableColumnsSum.forEach(col => {
                col.formatter = obj.getColumnFormatter(col.title, col.formatter);
            });
            tableColumnsSum = obj.replaceVectorMachineToDisplayNameInColumnsTitle(obj.props.vectors,tableColumnsSum,"");
            obj.viewMoreSummaryMetricDetail.setHeight(convertPxToViewport(100));
            obj.viewMoreSummaryMetricDetail.setColumns(tableColumnsSum);		//set data for view more summary table
            obj.viewMoreSummaryMetricDetail.setData(obj.state.summaryData);	 //set data for view more summary table
        }
    }

    replaceVectorMachineToDisplayNameInColumnsTitle = (vectors, columns , type) => {
        for(let e in columns) {
            let field = columns[e].field;
            let vector = type === 'details' ? vectors.filter(v=> field.startsWith(v.vector_name)) : vectors.filter(v=> field.includes(v.vector_name))
            if(vector.length) {
                let vectorMachineName = vector[0].vector_name;
                let vectorDisplayName = vector[0].display_name;
                columns[e]["title"] = columns[e]["field"].replace(vectorMachineName,vectorDisplayName).replace(/_/g," ");
                columns[e].titleFormatterParams.title =  columns[e]["title"];
            }
        }
        return columns;
    }


    componentDidUpdate(prevProps) {
        let obj = this;
        if(!obj.table && $("#metricsDetailstotals").length > 0 && obj.state.openDialog) { //if dialog has been opened and tabulator is not yet defined
          var options = {
              layout: "fitData",      //fit columns to width of table
              data: [],
              // groupBy: VIEW_MORE_INVOICE_LINE_FIELDS.TYPE,
              responsiveLayout: false,  //hide columns that dont fit on the table
              tooltips: true,            //show tool tips on cells
              addRowPos: "top",          //when adding a new row, add it to the top of the table
              history: true,             //allow undo and redo actions on the table
              pagination: false,          //paginate the data
              movableColumns: false,     //allow column order to be changed
              selectable: false,
              height: "100%",
              resizableColumns: false,
              autoResize: true,
              virtualDomBuffer: 800,
              width: "100%",
              placeholder: MESSAGES.no_data_available,
              reactiveData: true,      //tabulator listens to any change in the data array and updates the table
              tooltips: function(column){
                  return column._cell.value;
              }, renderComplete: function() {
                  // toggleLoader(false, "getMetricsData");
              }
          }

          obj.table = new Tabulator("#metricsDetailstotals", options);
          obj.viewMoreSummaryMetricDetail = new Tabulator("#viewMoreSummaryMetricDetail", options);

      }
        if(!obj.table && $("#metricsDetailstotals").length > 0 && obj.state.openDialog) { //if dialog has been opened and tabulator is not yet defined
          var options = {
              layout: "fitColumns",      //fit columns to width of table
              data: [],
              // groupBy: VIEW_MORE_INVOICE_LINE_FIELDS.TYPE,
              responsiveLayout: false,  //hide columns that dont fit on the table
              tooltips: true,            //show tool tips on cells
              addRowPos: "top",          //when adding a new row, add it to the top of the table
              history: true,             //allow undo and redo actions on the table
              pagination: false,          //paginate the data
              movableColumns: false,     //allow column order to be changed
              selectable: false,
              height: "100%",
              resizableColumns: false,
              autoResize: true,
              virtualDomBuffer: 800,
              width: "100%",
              placeholder: MESSAGES.no_data_available,
              reactiveData: true,      //tabulator listens to any change in the data array and updates the table
              tooltips: function(column){
                  return column._cell.value;
              }, renderComplete: function() {
                  // toggleLoader(false, "getMetricsData");
              }
          }

          obj.table = new Tabulator("#metricsDetailstotals", options);
          obj.viewMoreSummaryMetricDetail = new Tabulator("#viewMoreSummaryMetricDetail", options);

      }
        if ((this.props.metricDetailsId && !prevProps.metricDetailsId) || (this.props.metricDetailsId !== prevProps.metricDetailsId)) {
            this.setState({
                data:this.props.data,
                summaryData: this.props.summaryData,
                metricDetailsColumns:this.props.metricDetailsColumns,
                summaryColumns:this.props.summaryColumns
            },() => {
                obj.drawTable();
            });
        }
    }

    formatDynamicColumns(data, typeCol) {
        var array = [];
        if (data && data.length > 0) {
           data.forEach((key)=>{
                var object = {
                    field: key,
                    title: key,
                    tooltip: false,
                    headerSort: true,
                    headerSort:  typeCol !== 'details' ? false : true,
                    format_type: ['Total_Revenue','Total_COGS','Total_Lines'].includes(key) ? "" : FormatTypes.TEXT,
                    dontFilter: typeCol !== "details" || ['Revenue','COGS','Lines','Metric_Value'].includes(key) ? true : false 
                };
                array.push(object);
            });
        }
        var formattedArr = [];
        if(typeCol === "details") {
            formattedArr = this.formatArr(array, "Number", formattedArr, 0, typeCol)
        } else {
            formattedArr = this.formatArr(array, "Key", formattedArr, 0, typeCol)
        }
        return formattedArr;
    }

    formatArr(array, name, final, index, type) {
        if (type === "details") {
            for (var e in array) {
                if (array[e].field.includes(name)) {
                    array[e].title = array[e].title.replace(/_/g,' ').replace('Key', " Number")
                    final.push(array[e]);
                   final = this.formatArr(array, DETAILS_ARR[index+1], final, index+1,type);
                }
            }
        }else {
            for (var i in array) {
                if (array[i].title.includes(name) && ((name === 'Key' && !array[i].title.endsWith('1')) || name !== 'Key')) {
                    if(array[i].title.includes('Key1')) {
                        array[i].title = array[i].field.replace('1','').replace('Key','');
                    }
                    array[i].title = array[i].title.replace(/_/g,' ').replace('Key','');
                    final.push(array[i]);
                    final = this.formatArr(array, SUMMARY_ARR[index+1], final, index+1);
                    break;
                }
            }
        }
        return final
    }

    onChangeTimePeriod(e) {
        var obj = this;
        this.setState({
            selectedPeriod: e.value
        }, function (){
            obj.table.clearFilter(true);
            obj.table.setSort("Metric_Value","desc");
            obj.props.getMetricsData([obj.props.clickedCell], "getMetricsDetails", e.value);
        });
    }

    getColumnFormatter(colField) {
        var columnFormatter;		
            switch(colField.replace("Total ","")) {
                case "Revenue":
                columnFormatter = function(cell, formatterParams) {
                var p = document.createElement('p');
                p.innerHTML = formatValHTML(cell.getValue(),FormatTypes.RATIO);
                return p;
                };
                break;
                case "COGS":
                    columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement('p');
                    p.innerHTML = formatValHTML(cell.getValue(),FormatTypes.RATIO);
                    return p;
                    };
                    break;
                case "Lines":
                    columnFormatter = function(cell, formatterParams) {
                    var p = document.createElement('p');
                    p.innerHTML = formatValHTML(cell.getValue(),FormatTypes.NUMERIC);
                    return p;
                    };
                    break;
                case "Total Metric Value":
                    case "Metric Value":
                        columnFormatter = function(cell, formatterParams) {
                        var p = document.createElement('p');
                        p.innerHTML = formatValHTML(cell.getValue(),FormatTypes.RATIO).replace(window._format[FORMAT_SETTINGS.CURRENCY_SIGN],'');
                        return p;
                        };
                        break;
            default:
            columnFormatter = function(cell, formatterParams) {
                var p = document.createElement('p');
                p.innerHTML = formatValHTML(cell.getValue(),FormatTypes.TEXT);
                return p;
            }
        }
		return columnFormatter;
    }

    setDefaultTimePeirod() {
        this.setState({
            selectedPeriod: "",
            data: []
        });
        this.setOpenDialog(false);
        this.props.resetmetricDetails();
    }
    
    getMetricDetails = () => {
        this.props.getMetricsData([this.props.clickedCell], "getMetricsDetails", this.state.selectedPeriod,translateTabulatorFilter(this.table.getFilters(true)),translateTabulatorSorter(this.table.getSorters()));
    }


  dialogActions = () => {
    return (
      <Button 
      id="close-btn"
      label={"Close"}
      variant={BUTTON_VARIANT.PRIMARY}
      size={SIZES.DEFAULT}
      type={BUTTON_TYPE.DEFAULT}
      onBtnClick={this.setDefaultTimePeirod}
  />
    )
  }

  setOpenDialog = (isOpen) => {
    let _this = this;

    if(!isOpen) { // when closing the view more dialog, set this.table to undefined so it recreates a table when opening view more dialog
      _this.table = undefined;
      _this.viewMoreSummaryMetricDetail = undefined
    }

    _this.setState({
      openDialog: isOpen
    })
  }

  dialogContent = () => {
    var clickedRow = this.props.clickedCell;

    return (
      <div >
        <div className="uk-background-default uk-border">
          <div className="mapping-header justify-content-between uk-padding-small-right">
            <div className="uk-display-flex align-items-center">
              <span className="fs-14 uk-text-bold">{(clickedRow ? clickedRow[METRICS_MAPPING.FIELDS.METRIC] : "") + " Details"}</span>
              <i className="fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={INFO_MESSAGE} />
            </div>

            <div className="uk-display-flex align-items-center">
              <Button
                id="apply"
                label={"Apply Filter"}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className={"uk-margin-right"}
                onBtnClick={this.getMetricDetails}
              />
              <label className="fs-14 uk-margin-default-right">Period</label>
              <DropDown
                id="select-periods"
                className="width-150 input__dropdown"
                name="periods"
                placeholder="Period"
                value={this.state.selectedPeriod ? findOptionByKey(this.props.periods || [], this.state.selectedPeriod && this.state.selectedPeriod.value ? this.state.selectedPeriod.value : this.state.selectedPeriod) :
                  findOptionByKey(this.props.periods || [], this.props.selectedPeriod && this.props.selectedPeriod.value ? this.props.selectedPeriod.value : this.props.selectedPeriod)}
                options={this.props.periods || []}
                onChange={this.onChangeTimePeriod}
                type={DROPDOWN_TYPE.INPUT}
              />
            </div>
          </div>
          <div id="metricsDetailstotals"></div>
        </div>
        <div className="uk-margin-top uk-border">
          <div className="mapping-header">
            <span className="fs-14 uk-text-bold">Totals</span>
          </div>
          <div id="viewMoreSummaryMetricDetail"></div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal
        id={"view-more-metric-details-dialog"}
        openDialog={this.state.openDialog}
        bodyContent={this.dialogContent}
        dialogActions={this.dialogActions}
        closeClick={this.setDefaultTimePeirod}
        size={DIALOG_SIZE.XLARGE}
      />
    );
  }
}

export default ViewMoreMetric;