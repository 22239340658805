// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setup-subtitle {
  color: #5455a8;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 700;
}

.main_report_container.supply-chain-setup {
  display: flex;
  flex-direction: column;
}

.tab-body {
  height: 100%;
  overflow: auto;
}

.save-btn-div {
  padding: 1.25vw;
}

.supply-chain-setup .tab-body #filterModal {
  height: 100%;
  width: 100%;
}

.supply-chain-setup .tab-body #filterModal .advanced-filter-inner-container {
  max-height: 100%;
}

.tab-body #filter_container,
.tab-body #filterDialog,
.tab-body #filter-table {
  height: 100%;
}

.tab-body .filter-separator {
  width: 96%;
}

.supply-chain-setup .vector-component .__option--is-disabled{
  height: 100%;
  align-items: center;
  padding: 0 0.42vw;
  color: black;
  font-size: 0.63vw;
  font-weight: 700;
  background-color: #F3F3F3;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/supplyChainSetup/supplyChainSetup.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;;EAGE,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".setup-subtitle {\n  color: #5455a8;\n  font-size: 0.83vw;\n  font-style: normal;\n  font-weight: 700;\n}\n\n.main_report_container.supply-chain-setup {\n  display: flex;\n  flex-direction: column;\n}\n\n.tab-body {\n  height: 100%;\n  overflow: auto;\n}\n\n.save-btn-div {\n  padding: 1.25vw;\n}\n\n.supply-chain-setup .tab-body #filterModal {\n  height: 100%;\n  width: 100%;\n}\n\n.supply-chain-setup .tab-body #filterModal .advanced-filter-inner-container {\n  max-height: 100%;\n}\n\n.tab-body #filter_container,\n.tab-body #filterDialog,\n.tab-body #filter-table {\n  height: 100%;\n}\n\n.tab-body .filter-separator {\n  width: 96%;\n}\n\n.supply-chain-setup .vector-component .__option--is-disabled{\n  height: 100%;\n  align-items: center;\n  padding: 0 0.42vw;\n  color: black;\n  font-size: 0.63vw;\n  font-weight: 700;\n  background-color: #F3F3F3;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
