import React, { useEffect, useState } from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { copyObjectValues } from "../../class/utils";
import { cleanUpTabulatorColumns } from "../../class/common";
import { BUILD_DETAILS, FormatTypes } from "../../class/constants";
import { formatValString } from "../../class/format";
function BuildDetailsTable({data}) {

    const [selectedData, setSelectedData] = useState(null);
    const [table, setTable] = useState(null);
    const [column, setColumn] = useState(null);

    const [displayRecord, setDisplayRecord] = useState(0);
    const [lastid, setLastid] = useState()

    let elem;
    let tabulator;
    let tableDataNested = copyObjectValues(data);
   
    const columns = [
        { 
            title: '', 
            field: BUILD_DETAILS.COLUMNS.FIELDS.EXPAND,
            headerSort: false,
            dontFilter: true,
            width: '3%',
            cssClass: "expand-collapse-cell",
            download: false
        },
        {
            title: BUILD_DETAILS.COLUMNS.TITLES.PERIOD,
            field: BUILD_DETAILS.COLUMNS.FIELDS.PERIOD,
        },
        {
            title: BUILD_DETAILS.COLUMNS.TITLES.SUMMARY,
            field: BUILD_DETAILS.COLUMNS.FIELDS.SUMMARY,
        },
        {
            title: BUILD_DETAILS.COLUMNS.TITLES.DESCRIPTION,
            field:  BUILD_DETAILS.COLUMNS.FIELDS.DESCRIPTION,
            width: "56%"
        }
    ]

    const tableWrap = () => {
        let options =  {
            data: tableDataNested, //link data to table
            height: "100%", // set height of table (in CSS or here), this enables the Virtual DOM and improves render speed dramatically (can be any valid css height value)
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: "hide",  //hide columns that dont fit on the table
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: true,     //allow column order to be changed
            selectable: false,
            movableRows: false,
            resizableColumns: false,
            dataTreeCollapseElement: "<div title='Collapse' class='uk-button-icon dark transparent-bg'><i class='far fa-chevron-down' /></div>",
            dataTreeExpandElement: "<div title='Expand' class='uk-button-icon dark transparent-bg'><i class='far fa-chevron-right' /></div>",
            autoResize: true,
            dataTreeStartExpanded:false,  //start with an collpased tree
			invalidOptionWarnings: false,
            placeholder: "",
            dataTreeBranchElement:false, //hide branch icon from child element
            dataTree: true,
            reactiveData: true,
            virtualDom: true,
            virtualDomBuffer:1000
        }
        var tableColumns = copyObjectValues(columns);
        tableColumns.forEach(col => {
            if(col.field === BUILD_DETAILS.COLUMNS.FIELDS.PERIOD){
                col.width = '10%';
            }else if(col.field === BUILD_DETAILS.COLUMNS.FIELDS.SUMMARY){
                col.width = '30%';
            }
        });
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, {id:"build_details"});
		tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        tabulator = new Tabulator(elem, options);
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
        });
        return columns;
	}

    useEffect(() => {
        tableWrap();
        setTable(tabulator);
    }, []);
    
    const getColumnFormatter=(colField)=> {
		var _this = this;
		var columnFormatter;
		switch (colField) {
            case BUILD_DETAILS.COLUMNS.FIELDS.PERIOD:
                columnFormatter = function(cell,formatterParams) {
                    var span = document.createElement("span");
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    var icon = document.createElement("i");
                    icon.classList.add("uk-margin-small-left", "uk-cursor-pointer");
                    if  (rowData.status == BUILD_DETAILS.STATUS.NOT_BUILT){
                        icon.classList.add("fal", "fa-clock", "text-grey");
                        icon.setAttribute("uk-tooltip", BUILD_DETAILS.STATUS.NOT_BUILT);
                    }
                    else if  (rowData.status == BUILD_DETAILS.STATUS.BUILT){
                        icon.classList.add("fas", "fa-check");
                        icon.setAttribute("uk-tooltip", BUILD_DETAILS.STATUS.BUILT);
                    }
                    else if  (rowData.status == BUILD_DETAILS.STATUS.VARIANCE){
                        icon.classList.add("fas", "fa-exclamation", "pi-text-yellow");
                        icon.setAttribute("uk-tooltip", BUILD_DETAILS.STATUS.VARIANCE);
                    }
                    
                    p.textContent = cell.getValue();
                    span.appendChild(p);
                    span.appendChild(icon);
                    return span;
                };
            break;
            case BUILD_DETAILS.COLUMNS.FIELDS.SUMMARY:
                columnFormatter = function(cell,formatterParams) {
                    var div = document.createElement("div");
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    var icon =document.createElement("i");
                    var pBold = document.createElement("p");
                    pBold.classList.add("uk-text-bold");
                    if  (rowData.status == BUILD_DETAILS.STATUS.NOT_BUILT){
                        p.textContent = BUILD_DETAILS.SUMMARY.READY_TO_BE_BUILT;
                    }
                    else if (rowData.status == BUILD_DETAILS.STATUS.BUILT){
                        p.textContent = BUILD_DETAILS.SUMMARY.BUILT_SUCCESSFULLY;
                    }
                    else if (rowData.status == BUILD_DETAILS.STATUS.VARIANCE){
                        pBold.textContent = BUILD_DETAILS.SUMMARY.ISSUES;
                        p.textContent = BUILD_DETAILS.SUMMARY.BUILT_WITH;
                        p.appendChild(pBold);
                    }else if(!rowData.period){
                        pBold.textContent =rowData.name;
                        var p2 = document.createElement("p");
                        var p3 = document.createElement("p");
                        p2.textContent="PSL ";
                        p3.textContent = " "+BUILD_DETAILS.SUMMARY.NOT_ASSIGNES;
                        p.appendChild(p2);
                        p.appendChild(pBold);
                        p.appendChild(p3);
                    }
                    div.appendChild(p);
                    div.appendChild(icon);
                    return div;
                };
            break;
            case BUILD_DETAILS.COLUMNS.FIELDS.DESCRIPTION:
                columnFormatter = function(cell,formatterParams) {
                    var div = document.createElement("div");
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    var pLink = document.createElement("a");
                    var p2 = document.createElement("p");
                    p2.textContent += " to go build";
                    pLink.classList.add("uk-link","uk-text-decoration-underline");
                    if  (rowData.status == BUILD_DETAILS.STATUS.NOT_BUILT){
                        // p.textContent = " Click ";
                        // pLink.textContent = "here";
                        // p.appendChild(pLink);
                        // p.appendChild(p2);
                    }else if (rowData.status == BUILD_DETAILS.STATUS.VARIANCE){
                        // p.textContent = BUILD_DETAILS.DESCRIPTION.NEED_TO_BUILD + " Click ";
                        // pLink.textContent = "here";
                        // p.appendChild(pLink);
                        // p.appendChild(p2);
                    }else if(!rowData.period){
                        p.textContent = "A variance of "+formatValString(rowData.variance_amount,FormatTypes.AMOUNT) 
                        +" ("+formatValString(rowData.variancePercenatage,FormatTypes.PERCENTAGE)+")"
                        +" out of " + formatValString(rowData.definedAmount,FormatTypes.AMOUNT); 
                    }
                    div.appendChild(p);
                    return div;
                };
            break;
			default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                }
            break;
        }

		return columnFormatter;
    }

    return (
        <div>
            <div id="build_details" ref={el => (elem = el)} />
        </div>
    );
}

export default BuildDetailsTable