export function updateColumnsAppearance(columnsAppearance) {
    return {
      type: 'UPDATE_COLUMNS_APPEARANCE',
      columnsAppearance: columnsAppearance,
      key: "columnsAppearance",
    }
  }
  
  export function getColumnsAppearance() {
    return {
      type: 'GET_COLUMNS_APPEARANCE'
    }
  }