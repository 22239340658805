import React, { useEffect, useState } from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { copyObjectValues } from "../../class/utils";
import { cleanUpTabulatorColumns } from "../../class/common";
import { CONFIGURE_GL_ACCOUNT_RANGES } from "../../class/constants";
import "./styles/configureTieOut.css";

function ConfigureGlAccountRangesTable({data, openDialog, prepareSelectedRow}) {

    const [tableData, setTableData] = useState(null);

    let elem;
    let tabulator;

    useEffect(() => {
        if(tableData){
            tableWrap();
        }
    }, [tableData]);

    useEffect(() => {
        if(data){
            const extractedInfo = [];
            let revenue;
            let cogs;
            let opex;
            let outOfScope;

            for (const key in data) {
                const items = {
                    display_name: data[key][0].display_name,
                    name: data[key][0].name
                };

                switch(key) { // arranging rows from ui faster than sorting on api
                    case "gl_revenue":
                        revenue = items;
                    break;
                    case "gl_cogs":
                        cogs = items;
                    break;
                    case "gl_opex":
                        opex = items;
                    break;
                    case "gl_exclusions":
                        outOfScope = items;
                    break;
                }
                    
            }
            extractedInfo.push(revenue,cogs,opex,outOfScope); // how should be displayed from requirements 
            setTableData(extractedInfo);
        }
    }, [data]);
   
    const columns = [
        { 
            title: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.TITLES.ACCOUNT_CATEGORY, 
            field: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.FIELDS.ACCOUNT_CATEGORY_DISPLAY,
            widthGrow:4,
            headerSort:false,
            headerHozAlign:"left",
        },
        {
            title: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.TITLES.ACTION, 
            field: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.FIELDS.ACCOUNT_CATEGORY,
            headerHozAlign:"center",
            hozAlign:"center",
            headerSort:false,
            cssClass: "align-items-center"
        }
    ]

    const tableWrap = () => {
        let options =  {
            data: tableData, //link data to table
            layout: "fitColumns",      //fit columns to width of table
            columnHeaderSortMulti:false
        }
        var tableColumns = copyObjectValues(columns);
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, {id:"tie_out_ranges"});
		tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        tabulator = new Tabulator(elem, options);
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
        });
        return columns;
	}

    const prepareDialog = (cell) => {
        let selected = cell.getValue();
        prepareSelectedRow(selected);
        openDialog(true);
    }

    
    
    const getColumnFormatter=(colField)=> {
		var columnFormatter;
		switch (colField) {
            case CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.FIELDS.ACCOUNT_CATEGORY_DISPLAY:
                columnFormatter = function(cell) {
                    return cell.getValue();
                };
            break;
            case CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.FIELDS.ACCOUNT_CATEGORY:
                columnFormatter = function(cell) {
                    var button = document.createElement("button");
                    button.classList.add("custom-btn","btn-default-secondary","btn-size-default", "tie_out_configure-btn");
                    button.innerHTML = "Configure";
                    button.value = cell.getValue();
                    button.onclick = () => {
                        prepareDialog(cell);
                    }
                    return button;
                };
            break;
			default:
                columnFormatter = function(cell) {
                    return cell.getValue();
                }
            break;
        }
		return columnFormatter;
    }

    return (
        <div>
            <div id="tie_out_ranges" className="large-margin-bottom" ref={el => (elem = el)}/>
        </div>
    );
}

export default ConfigureGlAccountRangesTable;