import React, { Component } from 'react';
import '../styles/dataModeling.css';
import Tabulator from "tabulator-tables";

import { getTranslationFile } from '../class/utils.js';
import { FormatTypes} from '../class/constants.js';



const lang = getTranslationFile();

var REPORT_DETAILS = [
    {
		title: "",
		field: "title",
		titleDownload:"",
        format_type:FormatTypes.TEXT
    },
	{
		title: "",
		field: "detail",
		titleDownload:"",
        format_type:FormatTypes.TEXT
    },
   
];


class ExcelDetailsTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
            tableData: [],
		};
    }

    componentDidUpdate(prevProps){
        if(prevProps.params && this.props.params && (JSON.stringify(prevProps.params)!== JSON.stringify(this.props.params) || this.tabulator.getData().length === 0)){
                this.tabulator.setData(this.props.params);
        }
    }

	componentDidMount() {
        var obj = this;
        
		var options = {
			layout: "fitColumns",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			// pagination: false, //"local",       //paginate the data
           

            movableColumns: false,     //allow column order to be changed
            autoResize: false,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			invalidOptionWarnings: false,
            
            reactiveData: true,      //tabulator listens to any change in the data array and updates the table
            virtualDomBuffer: 2000,
            columnHeaderVertAlign:"bottom",
			placeholder: lang.no_data_available,
			height: "50%",
            width: "100%",
            virtualDom:false,
            renderComplete: obj.onTabulatorRenderComplete,
            
            
        }
        obj.tabulator = new Tabulator("#excel_details_table", options);

        let tableColumns = REPORT_DETAILS;
        this.tabulator.setColumns(tableColumns);
        this.tabulator.setData([]);
        if (this.props.params) {
             this.tabulator.setData(this.props.params);  
        }

    }


	render() {
		return(
            <div id="excel_details_table" className = "vector_analysis" ref="excel_details_table"/>
		);
	}

}

export default ExcelDetailsTable;