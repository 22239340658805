// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.hover-list {
    position: absolute;
    top: -50px;
    left: 30px; 
    display: block;
    z-index: 10;
}

.rectangle-list {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    width: 110px;
    max-height: 33vh;
    overflow: scroll;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    padding: 15px 15px 10px 15px;
    flex-direction: column;
}

.rectangle-list-title {
    color: #08155E;
    font-weight: 700;
}

.rectangle-list-border {
    border-bottom: 1px solid #DFDFDF;
    margin: 14px 0;
}

.rectangle-list-border-light{
    border-bottom: 1px solid #EFEFEF;
    margin: 14px 0;
}

.rectangle-list-content {
    display: flex;
    flex-direction: column;
}

.rectangle-list-name {
    color: #333;
}


.list-left-arrow{
    position: absolute;
    top: 54px;
    left: -7px;
}`, "",{"version":3,"sources":["webpack://./src/components/monthlyBuild/Style/HoverableList.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,cAAc;IACd,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,2CAA2C;IAC3C,oBAAoB;IACpB,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,gCAAgC;IAChC,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd","sourcesContent":["\n.hover-list {\n    position: absolute;\n    top: -50px;\n    left: 30px; \n    display: block;\n    z-index: 10;\n}\n\n.rectangle-list {\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    background-color: #fff;\n    width: 110px;\n    max-height: 33vh;\n    overflow: scroll;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n    display: inline-flex;\n    padding: 15px 15px 10px 15px;\n    flex-direction: column;\n}\n\n.rectangle-list-title {\n    color: #08155E;\n    font-weight: 700;\n}\n\n.rectangle-list-border {\n    border-bottom: 1px solid #DFDFDF;\n    margin: 14px 0;\n}\n\n.rectangle-list-border-light{\n    border-bottom: 1px solid #EFEFEF;\n    margin: 14px 0;\n}\n\n.rectangle-list-content {\n    display: flex;\n    flex-direction: column;\n}\n\n.rectangle-list-name {\n    color: #333;\n}\n\n\n.list-left-arrow{\n    position: absolute;\n    top: 54px;\n    left: -7px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
