// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
  display: flex;
  flex-direction: column;
  row-gap: 0.78vw;
}

.accordion-title-container {
  display: flex;
  align-items: center;
  column-gap: 0.52vw;
}

.accordion-content-container {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s linear;
}

.accordion-content-container.active {
  grid-template-rows: 1fr;
}

.accordion-content-container .content-container {
  overflow: hidden;
}

.accordion-content-container .content-container .content{
  margin-top: 0.52vw
}

.chevron-style {
  position: absolute;
  bottom: -0.26vw;
  right: -0.26vw;
  -webkit-user-select: none;
          user-select: none;
}

.chevron-style img {
  -webkit-user-select: none;
          user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/accordion/accordion.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,0CAA0C;AAC5C;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB","sourcesContent":[".accordion {\n  display: flex;\n  flex-direction: column;\n  row-gap: 0.78vw;\n}\n\n.accordion-title-container {\n  display: flex;\n  align-items: center;\n  column-gap: 0.52vw;\n}\n\n.accordion-content-container {\n  display: grid;\n  grid-template-rows: 0fr;\n  transition: grid-template-rows 0.2s linear;\n}\n\n.accordion-content-container.active {\n  grid-template-rows: 1fr;\n}\n\n.accordion-content-container .content-container {\n  overflow: hidden;\n}\n\n.accordion-content-container .content-container .content{\n  margin-top: 0.52vw\n}\n\n.chevron-style {\n  position: absolute;\n  bottom: -0.26vw;\n  right: -0.26vw;\n  user-select: none;\n}\n\n.chevron-style img {\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
