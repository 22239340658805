// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.draggable-node{
    font-size: var(--value-14-px);
    color: #000;
    font-family: Lato;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0.3125vw 0.46875vw 0.3125vw 0.573vw;
    border-top: 1px solid #E4E4EA;
    gap: 0.35vw;
    background: #ffffff;
    line-height: 1.25vw;
}
.drag-handle-container{
    display: flex;
    flex-direction: column;
    height: 0.9375vw;
    justify-content: space-evenly;
}
.drag-handle{
    background: rgba(152, 155, 169, 1);
    height: 0.1445vw;
    width: 0.729vw;
    border-radius: 0.15625vw;
}`, "",{"version":3,"sources":["webpack://./src/components/draggable/draggable.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,4CAA4C;IAC5C,6BAA6B;IAC7B,WAAW;IACX,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,6BAA6B;AACjC;AACA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,cAAc;IACd,wBAAwB;AAC5B","sourcesContent":[".draggable-node{\n    font-size: var(--value-14-px);\n    color: #000;\n    font-family: Lato;\n    font-weight: 700;\n    display: flex;\n    align-items: center;\n    padding: 0.3125vw 0.46875vw 0.3125vw 0.573vw;\n    border-top: 1px solid #E4E4EA;\n    gap: 0.35vw;\n    background: #ffffff;\n    line-height: 1.25vw;\n}\n.drag-handle-container{\n    display: flex;\n    flex-direction: column;\n    height: 0.9375vw;\n    justify-content: space-evenly;\n}\n.drag-handle{\n    background: rgba(152, 155, 169, 1);\n    height: 0.1445vw;\n    width: 0.729vw;\n    border-radius: 0.15625vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
