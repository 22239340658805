import React, { Component } from 'react';
import Tabulator from "tabulator-tables";
import { getObjectValues } from '../class/array';
import { cleanUpSingleTabulatorColumn, cleanUpTabulatorColumns } from '../class/common.js';
import {
	ALL_WIDGETS, CROSSWALK_FIELDS, DATATYPE_ENUM, DATA_DICTIONARY_ENUM, DEFAULT_DATE_FORMAT_UPLOAD, DEFINE_CREDIT_DEBIT_ENTRY_TYPE, DEFINE_FIELDS, DEFINE_TITLES, Formats, FormatTypes, HISTORY_FIELDS, HISTORY_TITLES, PERIOD_ONWARD, RAW_FILE_VALIDATION, RAW_ITEMS, STATUS_ENUM, SUMMARY_FIELDS, SUMMARY_TITLES, UPLOAD_FIELDS,
	UPLOAD_SECTIONS, UPLOAD_TITLES, DIALOG_SIZE, BUTTON_VARIANT, SIZES, BUTTON_TYPE, MENU_ITEM
} from '../class/constants.js';
import { formatValHTML } from "../class/format";
import { convertPxToViewport } from '../class/formatting';
import { replaceSpecialCharacters } from '../class/jqueries.js';
import { capitaliseFirstLetterAfterChar, convertEasternTZToLocal, copyObjectValues, formatDate, getSectionExists, getTranslationFile } from '../class/utils.js';
import '../styles/dataModeling.css';
import { createCheckbox } from '../newComponents/CheckBoxTabulator';
import Button from '../newComponents/Button';
import Modal from '../newComponents/Modal'

const $ = require('jquery');
const CHECK_TITLE = "";
const checkboxCellObject = {
    title: CHECK_TITLE,
    visible: true,
    headerSort: false,
    width: '6%'
};

const P_AND_L = UPLOAD_SECTIONS.FIELDS.P_AND_L;
const GENERAL_LEDGER = UPLOAD_SECTIONS.FIELDS.GENERAL_LEDGER;
const TRANSACTION_DATA = UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA;
const ANCILLARY_FILES = UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES;
const MASTER_DATA = UPLOAD_SECTIONS.FIELDS.MASTER_DATA;
const CROSSWALK = UPLOAD_SECTIONS.FIELDS.CROSSWALK;
const MESSAGES = getTranslationFile();
const CURRENCY = UPLOAD_SECTIONS.FIELDS.CURRENCY;

var DEFINE_TABLE_COL_WIDTH = "110";

var periodsColForCW = {
	title: UPLOAD_TITLES.PERIODS,
	field: UPLOAD_FIELDS.PERIODS,
	sorter:"string",
	sorterParams:{alignEmptyValues:"bottom"}
}

var uploadDataColumns = [
	{
		title: UPLOAD_TITLES.DISPLAY_FILE_SUBTYPE,
		field: UPLOAD_FIELDS.DISPLAY_FILE_SUBTYPE,
		sorter:"string", 
		visible: true,
		sorterParams:{alignEmptyValues:"bottom"}, 
		width: '6%'
	},
	{
		title: UPLOAD_TITLES.FILE_NAME_TITLE,
		field: UPLOAD_FIELDS.FILE_NAME_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.FILE_NUMBER_TITLE,
		field: UPLOAD_FIELDS.FILE_NUMBER_FIELD,
		sorter:"string", 
		visible: false,
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.UPLOADED_BY_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_BY_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.UPLOADED_DATE_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_DATE_FIELD, 
		sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
			a = new Date(a);
			b = new Date(b);
			//a, b - the two values being compared
			//aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
			//column - the column component for the column being sorted
			//dir - the direction of the sort ("asc" or "desc")
			//sorterParams - sorterParams object from column definition array
			return a.valueOf() - b.valueOf(); //you must return the difference between the two values
		},
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.DELIMITER_TITLE,
		field: UPLOAD_FIELDS.DELIMITER_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"},
		tooltip: false,
		width:70
	},
	{
		title: UPLOAD_TITLES.AMENDMENT_TITLE,
		field: UPLOAD_FIELDS.AMENDMENT_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"},
		width: "12%"
	},
	{
		title: UPLOAD_TITLES.DATA_DICTIONARY_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_STATUS_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"},
		visible: false,
	},
	{
		title: UPLOAD_TITLES.RESPONSE_TITLE,
		field: UPLOAD_FIELDS.RESPONSE_FIELD,
		visible: false
	},
	{
		title: UPLOAD_TITLES.RAW_FILE_ID_TITLE,
		field: UPLOAD_FIELDS.RAW_FILE_ID_FIELD,
		visible: false
	},
	{
		title: UPLOAD_TITLES.PROGRESS_TITLE,
		field: UPLOAD_FIELDS.RESPONSE_FIELD,
		width:'9%',
		tooltip: false,
		headerSort:false,
	},
	{
		title: UPLOAD_TITLES.STATUS_TITLE,
		field: UPLOAD_TITLES.STATUS_TITLE,
		sorter:"string", 
		visible: true,
		sorterParams:{alignEmptyValues:"bottom"}, 
		width:'7%'
	},
	{
		title: UPLOAD_TITLES.ACTIONS,
		field: UPLOAD_FIELDS.ACTIONS,
		sorter:"string", 
		visible: true,
		sorterParams:{alignEmptyValues:"bottom"}, 
		width: '3%',
		headerSort: false
	},
];

var uploadDataPLColumns = [
	{
		title: UPLOAD_TITLES.DISPLAY_FILE_SUBTYPE,
		field: UPLOAD_FIELDS.DISPLAY_FILE_SUBTYPE,
		sorter:"string", 
		visible: true,
		sorterParams:{alignEmptyValues:"bottom"}, 
		width: '6%'
	},
	{
		title: UPLOAD_TITLES.FILE_NAME_TITLE,
		field: UPLOAD_FIELDS.FILE_NAME_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.FILE_NUMBER_TITLE,
		field: UPLOAD_FIELDS.FILE_NUMBER_FIELD,
		sorter:"string", 
		visible: false,
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.UPLOADED_BY_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_BY_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.UPLOADED_DATE_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_DATE_FIELD, 
		sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
			a = new Date(a);
			b = new Date(b)
			//a, b - the two values being compared
			//aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
			//column - the column component for the column being sorted
			//dir - the direction of the sort ("asc" or "desc")
			//sorterParams - sorterParams object from column definition array
			return a.valueOf() - b.valueOf(); //you must return the difference between the two values
		},
		sorterParams:{alignEmptyValues:"bottom"}
	},
	{
		title: UPLOAD_TITLES.STATUS_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_STATUS_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"},
		tooltip: false,
		visible: true
	},
	{
		title: UPLOAD_TITLES.DATA_DICTIONARY_TITLE,
		field: UPLOAD_FIELDS.UPLOADED_STATUS_FIELD,
		sorter:"string", 
		sorterParams:{alignEmptyValues:"bottom"},
		visible: false
	},
	{
		title: UPLOAD_TITLES.RESPONSE_TITLE,
		field: UPLOAD_FIELDS.RESPONSE_FIELD,
		visible: false
	},
	{
		title: UPLOAD_TITLES.RAW_FILE_ID_TITLE,
		field: UPLOAD_FIELDS.RAW_FILE_ID_FIELD,
		visible: false
	},
	{
		title: UPLOAD_TITLES.ACTIONS,
		field: UPLOAD_FIELDS.ACTIONS,
		headerSort: false
	},
];

var defineDataColumns = [
	{
		title: DEFINE_TITLES.FIELD_NAME_TITLE,
		field: DEFINE_FIELDS.FIELD_NAME_FIELD,
		format_type: FormatTypes.TEXT
	},
	{
		title: DEFINE_TITLES.DATA_TITLE,
		field: DEFINE_FIELDS.DATA_FIELD,
	},
	{
		title: DEFINE_TITLES.DATA_TYPE_TITLE,
		field: DEFINE_FIELDS.DATA_TYPE_FIELD,
	},
	{
		title: DEFINE_TITLES.DATA_TYPE_ID_TITLE,
		field: DEFINE_FIELDS.DATA_TYPE_ID_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DATE_FORMAT_TITLE,
		field: DEFINE_FIELDS.DATE_FORMAT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.FORMAT_TITLE,
		field: DEFINE_FIELDS.FORMAT_FIELD,
		headerSort: false,
	},
	{
		title: DEFINE_TITLES.DESCRIPTION_TITLE,
		field: DEFINE_FIELDS.DESCRIPTION_FIELD,
	},
	{
		title: DEFINE_TITLES.CURRENCY_CONVERSION,
		field: DEFINE_FIELDS.CURRENCY_CONVERSION,
		visible: false
	},
	{
		title: DEFINE_TITLES.DISTINCT_VALUES_TITLE,
		field: DEFINE_FIELDS.DISTINCT_VALUES_FIELD,
		sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
			a = Number(a.substring(0, a.indexOf("/")));
			b = Number(b.substring(0, b.indexOf("/")));
			//a, b - the two values being compared
			//aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
			//column - the column component for the column being sorted
			//dir - the direction of the sort ("asc" or "desc")
			//sorterParams - sorterParams object from column definition array
		
			return a.valueOf() - b.valueOf(); //you must return the difference between the two values
		}
	},
	{
		title: DEFINE_TITLES.SAMPLES_TITLE,
		field: DEFINE_FIELDS.SAMPLES_FIELD,
	},
	{
		title: UPLOAD_TITLES.RAW_FILE_ID_TITLE,
		field: UPLOAD_FIELDS.RAW_FILE_ID_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MIN_TITLE,
		field: DEFINE_FIELDS.MIN_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MAX_TITLE,
		field: DEFINE_FIELDS.MAX_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.IS_TRAILING_TITLE,
		field: DEFINE_FIELDS.IS_TRAILING_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.TRAIL_OR_LEAD_TITLE,
		field: DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.TRUE_INPUT_TITLE,
		field: DEFINE_FIELDS.TRUE_INPUT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.FALSE_INPUT_TITLE,
		field: DEFINE_FIELDS.FALSE_INPUT_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DECIMAL_TITLE,
		field: DEFINE_FIELDS.DECIMAL_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MIN_LENGTH_TITLE,
		field: DEFINE_FIELDS.MIN_LENGTH_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.MAX_LENGTH_TITLE,
		field: DEFINE_FIELDS.MAX_LENGTH_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.DECIMAL_TITLE,
		field: DEFINE_FIELDS.DECIMAL_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.ROW_NUMBER_TITLE,
		field: DEFINE_FIELDS.ROW_NUMBER_FIELD,
		visible: false,
	},
	{
		title: DEFINE_TITLES.RAW_FILE_FIELD_ID_TITLE,
		field: DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD,
		visible: false,
	}
];

var HISOTRY_TABLE_COL_WIDTH = "110";
var historyDataColumns = [
	{
		title: HISTORY_TITLES.FILE_NUMBER,
		field: HISTORY_FIELDS.FILE_NUMBER,
		sorter: "string",
		width:'20%',
		format_type: FormatTypes.TEXT
	},
	{
		title: HISTORY_TITLES.FILE_NAME,
		field: HISTORY_FIELDS.FILE_NAME,
		sorter: "string",
		width:'20%',
		format_type: FormatTypes.TEXT
	},
	{
		title: HISTORY_TITLES.USER,
		field: HISTORY_FIELDS.USER,
		sorter: "string",
		width: '20%',
		format_type: FormatTypes.TEXT
	},
	{
		title: HISTORY_TITLES.EXECUTED_DATE,
		field: HISTORY_FIELDS.EXECUTED_DATE, 
		sorter:function(a, b, aRow, bRow, column, dir, sorterParams){
			a = new Date(a);
			b = new Date(b)
			return a.valueOf() - b.valueOf();
		},
		width: '15%',
		formatter: "date"
	},
	{
		title: HISTORY_TITLES.EXECUTED_ACTION,
		field: HISTORY_FIELDS.EXECUTED_ACTION,
		sorter:"string",
		width: '10%',
		formatter: "headerselect"
	},
	{
		title: HISTORY_TITLES.STATUS,
		field: HISTORY_FIELDS.STATUS,
		sorter:"string",
		width: '7%',
		formatter: "status",
		tooltip: false
	},
	{
		title: HISTORY_TITLES.SECTION,
		field: HISTORY_FIELDS.SECTION,
		sorter: "string",
		width: '10%',
		formatter: "headerselect"
	},
	{
		title: HISTORY_TITLES.SUBSECTION,
		field: HISTORY_FIELDS.SUBSECTION,
		sorter: "string",
		width: '15%',
		formatter: "headerselect"
	},
	{
		title: HISTORY_TITLES.ERROR_MESSAGE,
		field: HISTORY_FIELDS.ERROR_MESSAGE,
		sorter: "string",
		width: '20%',
		formatter: "textarea"
	},
	{
		title: HISTORY_TITLES.REPLACED_FILES,
		field: HISTORY_FIELDS.REPLACED_FILES,
		sorter: "string",
		width: '20%',
		formatter: "files"
	},
	{
		title: UPLOAD_TITLES.RAW_FILE_ID_TITLE,
		field: UPLOAD_FIELDS.RAW_FILE_ID_FIELD,
		visible: false
	},
	{
		title: UPLOAD_TITLES.START_PERIOD,
		field: UPLOAD_FIELDS.START_PERIOD,
		sorter: "string",
		width: '10%',
		formatter: "headerselect"
	},
	{
		title: UPLOAD_TITLES.END_PERIOD,
		field: UPLOAD_FIELDS.END_PERIOD,
		sorter: "string",
		width: '10%',
		formatter: "headerselect"
	}
];

var dataSummaryColumns = [
	{
		title: SUMMARY_TITLES.PERIOD,
		field: SUMMARY_FIELDS.PERIOD,
		visible: true
	},
	{
		title: SUMMARY_TITLES.STATUS,
		field: SUMMARY_FIELDS.STATUS
	},
	{
		title: SUMMARY_TITLES.EXPECTED_FILES,
		field: SUMMARY_FIELDS.EXPECTED_FILES
	},
	{
		title: SUMMARY_TITLES.PASSED_FILES,
		field: SUMMARY_FIELDS.PASSED_FILES
	},
	{
		title: SUMMARY_TITLES.PENDING_FILES,
		field: SUMMARY_FIELDS.PENDING_FILES
	},
	{
		title: SUMMARY_TITLES.FAILED_FILES,
		field: SUMMARY_FIELDS.FAILED_FILES
	},
	{
		title: SUMMARY_TITLES.DETAILS,
		field: SUMMARY_FIELDS.DETAILS,
		headerSort: false
	},
	{
		title: SUMMARY_TITLES.PERIOD_ID,
		field: SUMMARY_FIELDS.PERIOD_ID,
		visible: false
	},
];

class UploadDataTable extends Component {
    constructor(props) {
		super(props);
		this.state = {
			tableColumns: [],
			tableData: [],
			//initialDefinition: props.initialDefinition,
			mandatorySectionFields: this.props.mandatorySectionFields,
			dataTypesFields: this.props.dataTypesFields,
		};
		
		this.getRawFileDefinition = this.getRawFileDefinition.bind(this);
		this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
		this.formModalElements = this.formModalElements.bind(this);
		this.formatDecimal = this.formatDecimal.bind(this);
		this.returnFormat = this.returnFormat.bind(this);
		this.getDataSummaryFormatter = this.getDataSummaryFormatter.bind(this);
		this.addTooltipTitleFormatter = this.addTooltipTitleFormatter.bind(this);
		this.setValue = this.setValue.bind(this);
		this.enableConfigure = this.enableConfigure.bind(this);
		this.configureModal = this.configureModal.bind(this);
	}

	getRawFileDefinition(cell) {
		var rowData = cell.getRow().getData();	//extract raw data
		var response = JSON.parse(rowData["response"]);
		var columnNames = response.headers;
		this.props.setSelectedSubSection(cell);

		//get field definitions from API, in <UploadData /> and send column names to fill them in the table
		this.props.getRawFileDefinitionList(cell.getTable().element.id, copyObjectValues(defineDataColumns), rowData[UPLOAD_FIELDS.RAW_FILE_ID_FIELD]);
	}

	getPLRawFileDefinition(cell) {
		//get field definitions from API, in <UploadData />
		this.props.getClientPeriodTieOff(true);
	}

	formModalElements(cell) {
		this.props.setFormatsElements(cell);
	}

	configureModal(row){
		this.props.configure(row);
	}

	getDataSummaryFormatter(colField) {
		var columnFormatter;
		var obj = this;
		
        switch(colField) {
			case SUMMARY_FIELDS.PERIOD:
			columnFormatter = function(cell, formatterParams) {
				var div  = document.createElement("div");
				div.classList.add("text-link", "uk-flex-center");
				if (cell.getRow().getData()["highlighted"] && cell.getRow().getData()["highlighted"] === "true") {
					$(cell.getRow().getElement()).addClass("highlighted");
				} else {
					$(cell.getRow().getElement()).removeClass("highlighted");
				}
				var data = cell.getTable().getData();
				div.innerHTML = cell.getValue();
				var option = {value:cell.getValue(), label:cell.getValue(), "data-period_id":cell.getRow().getData()[SUMMARY_FIELDS.PERIOD_ID]};
				$(div).on("click", () => {
					cell.getRow().getData()["highlighted"] = "true";
					for (var e in data) {
						if (data[e][SUMMARY_FIELDS.PERIOD_ID] === cell.getRow().getData()[SUMMARY_FIELDS.PERIOD_ID]) {
							data[e]["highlighted"]="true";
						} else{
							data[e]["highlighted"]="false";
						}
					}
					obj.tabulator.replaceData(data);
					obj.props.setChosenPeriod(option);
				});
				return div;	
			}
			break;

			case SUMMARY_FIELDS.STATUS:
			columnFormatter = function(cell, formatterParams) {
				var p = document.createElement('p');
				var icon = document.createElement('i');
				if (Number(cell.getRow().getData()[SUMMARY_FIELDS.EXPECTED_FILES]) === 0) {
					icon.classList.add('fal','fa-clock','uk-margin-small-right','uk-text-sub');
				}else if (cell.getRow().getData()[SUMMARY_FIELDS.PENDING_FILES] > 0) {
					icon.classList.add('fas','fa-times','uk-margin-small-right','uk-text-sub');
				} else {
					icon.classList.add('fas','fa-check','uk-margin-small-right','uk-text-sub');
				}
				p.appendChild(icon);
				return p;
			};
			break;

			case SUMMARY_FIELDS.DETAILS:
			columnFormatter = function(cell, formatterParams) {
				var div = document.createElement("div");
				div.classList.add("text-link", "uk-flex-center");
				div.innerHTML = "More";
				div.classList.remove("disabled");
				return div;	
			}
			break;

			default:
			columnFormatter = function(cell, formatterParams){
				return cell.getValue();
			}
			break;
		}
		
		return columnFormatter;
	}

	getUploadTableColumnFormatter(colTitle) {
		var columnFormatter;
		var obj = this;
		var isDefineFileAllowed = this.props.userAllowedSections ? getSectionExists(this.props.userAllowedSections, MENU_ITEM.FIELDS.DEFINE_FILE) : false;
		
        switch(colTitle) {
			case UPLOAD_TITLES.DISPLAY_FILE_SUBTYPE:
				columnFormatter = function(cell, formatterParams) {
					return "";
				}
				break;
			case CHECK_TITLE:
			columnFormatter = function(cell, formatterParams) {
				// cell.getColumn().getElement().classList.add("hidden-column-title"); //keep column title, just hide it from UI	//uncomment this line to hide Check col title
				var isRowEmpty = cell.getRow().getData()[UPLOAD_FIELDS.FILE_NAME_FIELD] === undefined;
				if(isRowEmpty) {	//if row is empty, do not add any elements
					return "";
				}

				var checkbox = createCheckbox();
				checkbox.name='chosenEntity';
				checkbox.id=cell.getRow().getData()[UPLOAD_FIELDS.FILE_SUBTYPE];
				checkbox.classList.add('chosenEntity');
				checkbox.onchange = () => {
					obj.props.onCheckBoxChecked(cell);
				};

				// if status is pending decyprion in P&L user is allowed to select file in order to delete it
				if(obj.props.id === P_AND_L && cell.getRow().getData()[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD].replace(/_/g,'') !== DATA_DICTIONARY_ENUM.pending_decryption.toLowerCase().replace(/ /g,'') 
					&& cell.getRow().getData()[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD].replace(/_/g,'') !== DATA_DICTIONARY_ENUM.failed_decrypt.toLowerCase().replace(/ /g,'')) {
					checkbox.disabled = true;
					checkbox.classList.add('disabled');
				}
				if (obj.props.fileIds.includes(cell.getRow().getData()[UPLOAD_FIELDS.RAW_FILE_ID_FIELD].toString())){
					$(cell.getRow().getElement()).css({"display": "none"});
				}

				if (cell.getRow().getData()[UPLOAD_FIELDS.PRIVATE]) {
					checkbox.disabled = true;
					checkbox.classList.add('disabled');
				}
				return  obj.props.id === CROSSWALK ? "" : checkbox;
			};
			break;

			case UPLOAD_TITLES.FILE_NAME_TITLE:
			columnFormatter = function(cell, formatterParams) {
				if(!isDefineFileAllowed || DATA_DICTIONARY_ENUM[cell.getRow().getData()[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]] !== DATA_DICTIONARY_ENUM.passed) {
					return cell.getValue();
				} else {
					var p = document.createElement("p");
					p.textContent = cell.getValue();
					p.style.color = "blue";

					if(obj.props.id === P_AND_L){
						$(p).on("click", () => obj.getPLRawFileDefinition(cell));		//get file definition when opening the modal
					}else{
						$(p).on("click", () => obj.getRawFileDefinition(cell));		//get file definition when opening the modal
					}
					$(p).css("cursor","pointer");
					
					return p;
				}
			}
			break;

			case UPLOAD_TITLES.PERIODS:
				columnFormatter = function(cell, formatterParams) {
					var p = document.createElement('p');
					var row = cell.getRow().getData();
					p.textContent = row[CROSSWALK_FIELDS.START_PERIOD] ? row[CROSSWALK_FIELDS.START_PERIOD] + " - " + (row[CROSSWALK_FIELDS.END_PERIOD] ? row[CROSSWALK_FIELDS.END_PERIOD] : PERIOD_ONWARD) : "";
					return p;
			}
			
			break;
			case UPLOAD_TITLES.DELIMITER_TITLE:
			columnFormatter = function(cell, formatterParams) {
				var fileDelimiterKeys = Object.keys(obj.props.fileDelimiters);
				var fileDelimiterValues = getObjectValues(obj.props.fileDelimiters);
				var valueIndex = fileDelimiterValues.indexOf(cell.getValue());

				if(valueIndex > -1) {
					return fileDelimiterKeys[valueIndex];
				} else {
					return cell.getValue();
				}
			}
			break;

			case UPLOAD_TITLES.DATA_DICTIONARY_TITLE:
			columnFormatter = function(cell, formatterParams) {
				var isRowEmpty = cell.getRow().getData()[UPLOAD_FIELDS.FILE_NAME_FIELD] === undefined;
				if(isRowEmpty) {	//if row is empty, do not add any elements
					return "";
				}
				var value = DATA_DICTIONARY_ENUM[cell.getValue()];

				var p = document.createElement("p");
				var icon = document.createElement("i");
				icon.title = value;
				if(value === DATA_DICTIONARY_ENUM.passed || value === DATA_DICTIONARY_ENUM.overwritten) {
					icon.classList.add("fas", "fa-check", "uk-margin-small-right", "fa-lg");
				} else if(value === DATA_DICTIONARY_ENUM.not_defined) {
					icon.classList.add("fas", "fa-times", "uk-margin-small-right" , "fa-lg");
				}
				p.appendChild(icon);
				var p2 = document.createElement("p");
				p2.innerText = value;
				p.appendChild(p2);

				return p;
			}
			break;

			case UPLOAD_TITLES.STATUS_TITLE:
			columnFormatter = function(cell, formatterParams) {
				if (obj.props.id === CROSSWALK) {
					let div = document.createElement('div');
					let i = document.createElement('i');
					if (cell.getRow().getData().status === 'passed') {
						i.classList.add('fas','fa-check');
						div.title =cell.getRow().getData().status;
						div.appendChild(i);
					} else {
						i.classList.add('fas','fa-times');
						div.title = cell.getRow().getData().status;
						div.appendChild(i);
					}
					return div;
				}
			};
			break;

			case UPLOAD_TITLES.UPLOADED_DATE_TITLE:
			columnFormatter = function(cell, columnFormatter) {
				if(!cell.getValue()) {
					return cell.getValue();
				}

				return formatDate(convertEasternTZToLocal(cell.getValue()));
			}
			break;

			case UPLOAD_TITLES.PROGRESS_TITLE:
			columnFormatter = function(cell, formatterParams) {
				var validations = JSON.parse(cell.getValue())["validation"];
				var totalValidations = validations.length;
				var passedValidations = 0;
			
				for (var valid in validations) {
					valid = validations[valid];
					if(STATUS_ENUM[valid[RAW_FILE_VALIDATION.VALIDATION_STATUS]] !== STATUS_ENUM.fail) {
						passedValidations++;
					}
				}
			
				var percent = isNaN(totalValidations) || totalValidations === 0 ? 0 : passedValidations / totalValidations * 100;
				var div = document.createElement('div');
				div.title = JSON.parse(cell.getValue())["status"] && JSON.parse(cell.getValue())["status"] === DATA_DICTIONARY_ENUM.pending_decryption.toLowerCase().replace(/ /g,'_') ? 
							DATA_DICTIONARY_ENUM.pending_decryption : DATA_DICTIONARY_ENUM[cell.getRow().getData()[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]];
				div.classList.add("progress");
				div.classList.add("width-90");
				div.style.backgroundColor = "#E5E5E5";
			
				var progressDiv = document.createElement("div");
				progressDiv.classList.add("progress-bar", "progress-bar-success", "progress-bar-animated");
				div.appendChild(progressDiv);
			
				div.children[0].innerHTML = formatValHTML(Math.round(percent), FormatTypes.PERCENTAGE);
				div.children[0].style.width = percent + "%";
				
				div.style.cursor = "pointer";
				div.onclick = () => obj.showUploadStatusResponse(cell, UPLOAD_FIELDS.PROGRESS_FIELD);
				return div;
			};
			break;

			case UPLOAD_TITLES.ACTIONS:
				columnFormatter = function(cell) {
					var rowData = cell.getRow().getData();
					var file_type = obj.props.id;
					var file_subType = rowData[UPLOAD_FIELDS.FILE_SUBTYPE];
					if(obj.props.id === CROSSWALK) {
						let div = document.createElement("div");
						div.style.display = "flex";
						div.style.justifyContent = "space-evenly";
	
						let deleteIcon = document.createElement("i");
						deleteIcon.classList.add("fal", "fa-trash-alt", "uk-cursor-pointer");
						deleteIcon.onclick=()=>{
							obj.props.showDeleteFilesPopup(file_subType, file_type, rowData);
						}
						div.appendChild(deleteIcon);
	
						if(DATA_DICTIONARY_ENUM[rowData[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD]] === DATA_DICTIONARY_ENUM.passed) {
							let editIcon = document.createElement("i");
							editIcon.classList.add("fal", "fa-edit", "uk-cursor-pointer");
							editIcon.onclick=()=>{
								obj.props.prepareRawFileDefinition(file_type, file_subType, rowData);
							}
							div.appendChild(editIcon);
						}
	
						return div;
					}
				
				}
			break;
		}
		
		return columnFormatter;
	}

	getDatatypeOptionsEnum() {
		return Object.freeze(DATATYPE_ENUM);
	}

	setValue() {
		this.setCellValue(this.state.e, this.state.cell, this, true)
	}
	
	setCellValue(e, cell, obj, confirmed) {
    let _this = this;
		//this function updates the value of the cell, hence updates the defineDataTable state of <UploadData />
		//because that state is passed down directly to the tabulator as data object, so by altering one, the other
		//is also changed
		if ((e && $(e.currentTarget).val()) === Formats.Boolean || this.state.value) {
			if (!confirmed) {
        _this.setConfirmBoolDialogOpen(true);
				this.setState({
					value: $(e.currentTarget).val(),
					id: e.currentTarget[e.currentTarget.selectedIndex]["data-id"],
					cell:cell
				});
				return;
			}else{	
				this.setState({
					value: undefined
				},function(){
          _this.setConfirmBoolDialogOpen(false);
          
					this.tabulator.scrollToRow(cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
					var rowData = cell.getRow().getData();
					// this.props.getBoolValues(rowData, obj);
				})
			}
		}
		if (confirmed) {
			this.tabulator.scrollToRow(cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
			cell.getRow().getData()[DEFINE_FIELDS.DATA_TYPE_FIELD] = this.state.value
		} else {
			cell.setValue($(e.currentTarget).val());
		}

		if (cell.getColumn()._column.field !== DEFINE_FIELDS.DESCRIPTION_FIELD) {
			cell.getRow().getData()[DEFINE_FIELDS.DATA_TYPE_ID_FIELD] = e ? e.currentTarget[e.currentTarget.selectedIndex]["data-id"] : this.state.id;
		}

		var format = this.returnFormat(cell);
		cell.getRow().getData()[DEFINE_FIELDS.FORMAT_FIELD] = format;

		var tableDataDefinition = cell.getTable().getData();
		var table = this.tabulator;
		table.replaceData(tableDataDefinition);

		//replacing the table data after the user changes anything
		$('#save-new-arch').removeClass('disabled');
		this.props.compareDefinition(JSON.stringify(this.props.initialDefinition), JSON.stringify(table.getData()));
	}

	formatDecimal(number, decimal) {
		var num = number.split(".");
		var appendedZeros = "";
		if (num.length > 1) {
			for (var i=0; i <Number(decimal)-Number(num[1].length); i++) {
				appendedZeros += "0";
			}
			return number+appendedZeros;
		}else {
			for(var i=0; i<Number(decimal); i++ ) {
				appendedZeros += "0"
			}
			return Number(decimal) > 0 ? number+"."+appendedZeros : number;
		}
	}

	enableConfigure(e,id,row){
		if (e.target.checked) {
			$("#"+id +"-configure").removeClass("disabled");
			if (!row[DEFINE_FIELDS.CURRENCY_FIELD_NAME]) {
				$("#"+id+"-configure").addClass("red")
			}
			this.props.clearConfiguration(row, true);
		} else {
			row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = undefined;
			row[DEFINE_FIELDS.CURRENCY_FIELD_NAME] = undefined;
			$("#"+id+"-configure").removeClass("red");
			this.props.clearConfiguration(row);
		}
	}

	getDefineTableColumnFormatter(colTitle) {
		var columnFormatter;
		var obj = this;

		switch(colTitle) {
			case DEFINE_TITLES.DATA_TITLE:
			columnFormatter = function(cell, formatterParams) {
				let rowIndex = Number(cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);   //extract the row index from the row data
				let div = document.createElement("div");
				div.classList.add("divSelectClass");

				let select = document.createElement("select");
				select.onchange = (e) => obj.props.handleRowDataChange(e, cell);
				select.style.width = "100%";
				select.title = cell.getValue();
				select.style.cursor = "pointer";
				select.classList.add("form-control", "table-form-control");

				var tableDataDefinition = cell.getTable().getData();
				
				var tableDataDefinitionNames = [];
				if(tableDataDefinition) {
					tableDataDefinition.map((item) => {
						tableDataDefinitionNames.push(item[DEFINE_FIELDS.DATA_FIELD]);
					});
				}

				var selectedSectionFields = tableDataDefinitionNames || [];
				
				for (var field in obj.state.mandatorySectionFields) {
					field = obj.state.mandatorySectionFields[field];
					
					let option = document.createElement("option");
					option.value = field.name;
				    option.title = field.description;
					option.innerHTML = field.name + (field.required === true || field.required === "t" ? " *" : "");
					let selectedOptionIndex = Number(selectedSectionFields.indexOf(field.name));
					if(selectedOptionIndex === -1 || selectedOptionIndex === rowIndex) {
						//then append it to the select element, if the option isn't 
						//selected by any other row or if selected by this exact row
						select.appendChild(option);
					}
				}

				let otherOption = document.createElement("option");	//add "Other" select option
				otherOption.value = "Other";
				otherOption.innerHTML = "Other";
				select.appendChild(otherOption);

				if(cell.getValue() === "") {
					cell.setValue("Other");	//if the cell doesn't have a value, set "Other" as a default one, this condition will evaluate to true on the first iteration only
				}

				if(obj.props.defineAsReadOnly) {	//if opening the popup as read only, only show values with inability to edit anything
					return cell.getValue();		//NB: keep this condition after cell.setValue()
				}

				// if(cell.getRow().getData()[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD] !== "" &&  obj.props.isFileDefined === false) {
				// 	return cell.getValue();	//if file is semi-defined, rows that are already defined should be read-only
				// }

				select.value = cell.getValue();		//set the cell value as the selected option in the SELECT dropdown
				div.appendChild(select);

				return div;
			};
			break;

			case DEFINE_TITLES.DATA_TYPE_TITLE:
			columnFormatter = function(cell, formatterParams) {
				if(obj.props.defineAsReadOnly) {	//if opening the popup as read only, only show values with inability to edit anything
					return cell.getValue();
				}

				let select = document.createElement("select");
				select.classList.add("form-control", "table-form-control", "uk-display-inline", "width-100");
				select.onchange = (e) => obj.setCellValue(e, cell, obj);
				var datatypeKeys = obj.state.dataTypesFields;
				var definedData = obj.props.defineData;

				for(var key in datatypeKeys) {
					let datatype = datatypeKeys[key]["datatype"];
					let option = document.createElement("option");
					option["data-id"] = datatypeKeys[key][DEFINE_FIELDS.DATA_TYPE_ID_FIELD];
					
					var changed = false;
					for(var e in definedData) {
						if(definedData[e][DEFINE_FIELDS.DATA_TYPE_ID_FIELD] === option["data-id"] && definedData[e][DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD] === cell.getRow().getData()[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD]) {
							option[DEFINE_FIELDS.MAX_FIELD] = definedData[e][DEFINE_FIELDS.MAX_FIELD];
							option[DEFINE_FIELDS.MIN_FIELD] = definedData[e][DEFINE_FIELDS.MIN_FIELD];
							option[DEFINE_FIELDS.MAX_LENGTH_FIELD] = definedData[e][DEFINE_FIELDS.MAX_LENGTH_FIELD];
							option[DEFINE_TITLES.MIN_LENGTH_TITLE] = definedData[e][DEFINE_TITLES.MIN_LENGTH_TITLE];
							option[DEFINE_FIELDS.IS_TRAILING_FIELD] = definedData[e][DEFINE_FIELDS.IS_TRAILING_FIELD];
							option[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = definedData[e][DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD];
							option[DEFINE_FIELDS.DECIMAL_FIELD] = definedData[e][DEFINE_FIELDS.DECIMAL_FIELD];
							option[DEFINE_FIELDS.TRUE_INPUT_FIELD] = definedData[e][DEFINE_FIELDS.TRUE_INPUT_FIELD];
							option[DEFINE_FIELDS.FALSE_INPUT_FIELD] = definedData[e][DEFINE_FIELDS.FALSE_INPUT_FIELD];
							option[DEFINE_FIELDS.DATE_FORMAT_FIELD] = definedData[e][DEFINE_FIELDS.DATE_FORMAT_FIELD];
							changed = true;
						}
					}
					if (!changed) {
						option[DEFINE_FIELDS.MAX_FIELD] = "";
						option[DEFINE_FIELDS.MIN_FIELD] = "";
						option[DEFINE_FIELDS.MAX_LENGTH_FIELD] = "";
						option[DEFINE_TITLES.MIN_LENGTH_TITLE] = "";
						option[DEFINE_FIELDS.IS_TRAILING_FIELD] = "";
						option[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = "";
						option[DEFINE_FIELDS.DECIMAL_FIELD] = "";
						option[DEFINE_FIELDS.TRUE_INPUT_FIELD] = "";
						option[DEFINE_FIELDS.FALSE_INPUT_FIELD] = "";
						option[DEFINE_FIELDS.DATE_FORMAT_FIELD] = "";
					}
					option.value = datatype;
					option.innerHTML = datatype;
					select.appendChild(option);
				}
				
				if(cell.getRow().getData()[DEFINE_FIELDS.DATA_FIELD] !== "Other") {	//allow the user to edit data type only when he selects 'Other' under data
					// cell.getElement().style.textAlign = "left";
					// cell.getElement().style.paddingLeft = "15px";
					return cell.getValue();		//NB: keep this condition after cell.setValue()
				}

				// if(cell.getRow().getData()[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD] !== "" &&  obj.props.isFileDefined === false) {
				// 	return cell.getValue();	//if file is semi-defined, rows that are already defined should be read-only
				// }

				select.value = cell.getValue();		//set the cell value as the selected option in the SELECT dropdown
				return select;
			}
				break;
			
			case DEFINE_TITLES.FORMAT_TITLE:
			columnFormatter = function(cell, formatterParams) {
				var div = document.createElement("div");
				div.classList.add("uk-border-rounded", "purple-color-fill","uk-width");
				var icon = document.createElement("i");

				icon.classList.add("fal","fa-edit", "uk-float-right");

				if(!obj.props.defineAsReadOnly) {
					icon.classList.add("uk-cursor-pointer");
					div.classList.add("purple-fill");
					div.classList.add("uk-cursor-pointer");
				}
				var p = document.createElement("p");
				var text = "";

				text = obj.returnFormat(cell);
				cell.getRow().getData()[DEFINE_FIELDS.FORMAT_FIELD] = text;

				p.textContent = text;
				p.title = text;
				if (!obj.props.defineAsReadOnly) {
					$(p).css("cursor","pointer");
				}
				$(p).css("overflow", "hidden");		//without overflow hidden, ellipsis doesn't work
				$(p).css("text-overflow", "ellipsis");
				$(p).css("max-width", "80%");
				p.classList.add("uk-float-left");
				
				div.onclick = () => obj.formModalElements(cell);

				div.appendChild(p);
				div.appendChild(icon);

                return div;
			}
			break;

			case DEFINE_TITLES.DESCRIPTION_TITLE:
			columnFormatter = function(cell, formatterParams) {
				if(cell.getRow().getData()[DEFINE_FIELDS.DATA_FIELD] !== "Other") {	//allow the user to edit description only when he selects 'Other' under data
					return cell.getValue();
				}
				
				if(obj.props.defineAsReadOnly) {	//if opening the popup as read only, only show values with inability to edit anything
					return cell.getValue();
				}

				// if(cell.getRow().getData()[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD] !== "" && obj.props.isFileDefined === false) {
				// 	return cell.getValue();	//if file is semi-defined, rows that are already defined should be read-only
				// }

				let input = document.createElement("input");
				input.style.width = "100%";
				input.style.height = "100%";
				input.classList.add("form-control");

				input.onblur = (e) => obj.setCellValue(e, cell, obj);
				input.value = cell.getValue() || "";

				return input;
			}
			break;

			case DEFINE_TITLES.CURRENCY_CONVERSION:
			columnFormatter = function(cell, formatterParams) {
				if(cell.getRow().getData()[DEFINE_FIELDS.DATA_TYPE_FIELD].toLowerCase() === FormatTypes.NUMERIC.toLowerCase() && ![UPLOAD_SECTIONS.FIELDS.PRELIMINARY_DATA,UPLOAD_SECTIONS.FIELDS.INVENTORY_DATA, UPLOAD_SECTIONS.FIELDS.WAREHOUSE_INVENTORY_MOVEMENT, UPLOAD_SECTIONS.FIELDS.PLANOGRAM].includes(obj.props.section)) {
					var p = document.createElement('p');
					var checkbox = createCheckbox();
                    checkbox.name='chosenEntity';
					checkbox.id = 'chosenEntity-' + cell.getRow().getData()[DEFINE_FIELDS.FIELD_NAME_FIELD];
					checkbox.onclick=(e)=>{
						obj.enableConfigure(e,cell.getRow().getData()[DEFINE_FIELDS.FIELD_NAME_FIELD], cell.getRow().getData());
					}
					checkbox.checked = cell.getRow().getData()[DEFINE_FIELDS.CURRENCY_FIELD_NAME] || cell.getRow().getData().currency_checked === true ? true : false;
					checkbox.classList.add("uk-display-inline-block");
					var configureLink = document.createElement("a");
					var link = document.createTextNode(MESSAGES.UPLOAD.configure);
					configureLink.appendChild(link);
					configureLink.id = cell.getRow().getData()[DEFINE_FIELDS.FIELD_NAME_FIELD]+"-configure";
					if (!checkbox.checked) {
						configureLink.classList.add("disabled");
					}
					configureLink.classList.add( "uk-display-inline-block", "uk-margin-small-left", "uk-text-medium", "uk-cursor-pointer");
					if (checkbox.checked && (!cell.getRow().getData()[DEFINE_FIELDS.CURRENCY_FIELD_NAME] || !cell.getRow().getData()[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID])) {
						configureLink.classList.add("red");
					}
					configureLink.onclick=()=>{
						obj.configureModal(cell.getRow().getData());
					}
					p.appendChild(checkbox);
					p.appendChild(configureLink);
					return p;
				}else {
					return "";
				}
			}
			break;
			
			default:
			columnFormatter = function(cell, formatterParams) {
				let p = document.createElement("p");
				// if(cell.getColumn().getField() === RAW_ITEMS.FIELD_NAME && cell.getValue().match(/\w+\_PI\_CW\b/)) {
				// 	p.title = cell.getValue() + MESSAGES.UPLOAD.crosswalk_column;
				// 	p.classList.add("red");
				// }
				p.innerText = cell.getValue();
				return p;
			};
			break;
		}

		return columnFormatter;
	}

	returnFormat(cell) {
		var obj = this;
		var rowData = cell.getRow().getData();
		
		var hash = "";
		var trail = "";
		var text = "";

		if (rowData[DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.Numeric) {
			for (var i=0; i< Number(rowData[DEFINE_FIELDS.DECIMAL_FIELD]); i++) {
				hash+= "#";
			}
			hash = hash !== "" ? "."+hash : "";
			text = obj.formatDecimal(rowData[DEFINE_FIELDS.MIN_FIELD],rowData[DEFINE_FIELDS.DECIMAL_FIELD]) + "<" + "#" + hash +"<" +obj.formatDecimal(rowData[DEFINE_FIELDS.MAX_FIELD],rowData[DEFINE_FIELDS.DECIMAL_FIELD]);
		} else if (rowData[DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.String) {
			if (rowData[DEFINE_FIELDS.IS_TRAILING_FIELD] === "false") {
				trail= rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]+rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]+rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]+"**";
			}else {
				trail= " **" + rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]+rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]+rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]
			}
			text = "(" + rowData[DEFINE_FIELDS.MIN_LENGTH_FIELD] + "-" + rowData[DEFINE_FIELDS.MAX_LENGTH_FIELD] + ") " + trail;
		} else if (rowData[DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.DateTime) {
			if(rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "") {
				text = DEFAULT_DATE_FORMAT_UPLOAD;
			} else {
				text = rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD];
			}
		} else if (rowData[DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.Boolean) {
			text = "T: " + rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD] //+ ", F:" + rowData[DEFINE_FIELDS.FALSE_INPUT_FIELD];
			if(rowData[DEFINE_FIELDS.DATA_FIELD] === DEFINE_CREDIT_DEBIT_ENTRY_TYPE.ENTRY_TYPE) {
				text = "C: " +rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD] //+ ", D:" + rowData[DEFINE_FIELDS.FALSE_INPUT_FIELD];
			}
		}

		return text;
	}

	addTooltipTitleFormatter(cell){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", cell.getValue());

        p.appendChild(el);
        div.appendChild(p);
        return div;
    }  
	
	getTabulatorColumns(columns) {
		columns = copyObjectValues(columns) || [];
		var obj = this;
		columns.forEach(col => {
			var colInit = {};
			if(this.props.table_type === this.props.UPLOAD_DATA) {
				col.formatter = this.getUploadTableColumnFormatter(col.title);
				if (this.props.id === P_AND_L) {
					colInit = uploadDataPLColumns.find(obj => {return obj.title === col.title})
				} else {
					colInit = uploadDataColumns.find(obj => {return obj.title === col.title})
				}
			} else if (this.props.table_type === this.props.DATA_SUMMARY) {
				if (col.field === SUMMARY_FIELDS.STATUS || col.field === SUMMARY_FIELDS.PERIOD) {
					col.titleFormatter = (cell) => this.addTooltipTitleFormatter(cell);
				}
				col.formatter = this.getDataSummaryFormatter(col.field);
			} else {
				if(this.props.table_type === this.props.DEFINE_DATA && col.format_type !== undefined) {
					col = cleanUpSingleTabulatorColumn(col, {id:this.props.id});
				}
				col.formatter = this.getDefineTableColumnFormatter(col.title);
				colInit = defineDataColumns.find(obj => {return obj.title === col.title});
				if (obj.props.section !== CURRENCY && obj.props.section !== P_AND_L && obj.props.section !== CROSSWALK && col.title === DEFINE_TITLES.CURRENCY_CONVERSION) {
					col.visible = true
				}
			}
			if(colInit && colInit.sorter) {
				col.sorter = colInit.sorter;
			}
			if(colInit && colInit.sorterParams) {
				col.sorterParams = colInit.sorterParams;
			}
		});			
		return columns;
	}

	/**
	 * This function checks the default data length value and adds empty rows
	 * to the data if its length was lower than the default value
	 * @param {*} data 
	 */
	completeDefaultDataLength(data, columns) {
		if(this.props.defaultRows > 0) {
			if (!data) {	//if data is undefined, show 5 empty rows
				data = [];
			}
			for(var i=data.length; i < this.props.defaultRows; i++) {
				var emptyRow = {};

				//extract "field" values from the columns array
				var fields = [];
				for(var col in columns) {
					col = columns[col];
					fields.push(col.field);
				}

				//fill these keys with empty values to create empty rows
				Object.keys(fields).forEach(key => {
					emptyRow[key] = "";
				});

				data.push(emptyRow);
			}
		}

		return data;
	}

	getHistoryTableColumnFormatter(colTitle) {
		var columnFormatter;

		switch(colTitle) {
			case HISTORY_TITLES.EXECUTED_DATE:
				columnFormatter = function(cell, formatterParams) {
					if(cell.getValue() === undefined) {	//if row is empty, do not add any elements
						return "";
					}

					return formatDate(convertEasternTZToLocal(cell.getValue()));
				};
				break;
			case HISTORY_TITLES.REPLACED_FILES:
				columnFormatter = function(cell, formatterParams) {
					// cell.getElement().css({"white-space":"pre-wrap"});
					var rowData = cell.getRow().getData();
			
					if(rowData[HISTORY_FIELDS.REPLACED_FILES] === undefined) {	//if row is empty, do not add any elements
						return "";
					}
					var files = JSON.parse(rowData[HISTORY_FIELDS.REPLACED_FILES]).files;
					if(files.length > 0) {
						var appendFileToReplace = files.map(function(item) { return item.value });
						return appendFileToReplace.join(", ");
					}
				}
				break;
			case HISTORY_TITLES.ERROR_MESSAGE:
				columnFormatter = function(cell, formatterParams) {
					// cell.getElement().css({"white-space":"pre-wrap"});
					return cell.getValue();
				}
				break;
		}
		
		return columnFormatter;
	}

	showUploadStatusResponse(cell, flag) {
		var cellValue = JSON.parse(cell.getValue());
		cellValue.fileStatus = cell.getData()[UPLOAD_FIELDS.UPLOADED_STATUS_FIELD];
		this.props.updateUploadStatusResponse(cellValue, flag);
	}

	shouldComponentUpdate(nextProps) {
		var shouldUpdate = false;
		
		if(!this.props.tableData && !this.tabulator.getColumns().length) {
			shouldUpdate = true;	//update to show 5 empty rows only when there is no data, when there is data, do not rely on default rows to update
			return shouldUpdate;
		}
		
		// shouldUpdate = this.props.tableData && (JSON.stringify(this.props.tableData) !== JSON.stringify(this.tabulator.getData()) || this.props.table_type === this.props.DEFINE_DATA) ? true : false;
		// shouldUpdate = this.props.tableData && (this.props.tableData !== nextProps.tableData || this.props.table_type === this.props.DEFINE_DATA) ? true : false;
		shouldUpdate = nextProps.tableData !== null && nextProps.tableData !== undefined 
					&& (JSON.stringify(this.props.tableData) !== JSON.stringify(nextProps.tableData) || this.props.table_type === this.props.DEFINE_DATA || this.props.table_type === this.props.HISTORY_DATA);

		if(!shouldUpdate && this.props.HISTORY_DATA === this.props.table_type) {
			var filterValue = $("#filter-dropdown-" + HISTORY_FIELDS.SECTION + " select").val();
			var sectionValue = capitaliseFirstLetterAfterChar(nextProps.selectedSection).replaceAll("_", " ");
			shouldUpdate = sectionValue !== null && filterValue !== sectionValue;
		}

		return shouldUpdate;
		
	}

	getAllSections(){
		var values = [];

		values.push(capitaliseFirstLetterAfterChar(TRANSACTION_DATA).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(ANCILLARY_FILES).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(GENERAL_LEDGER).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(MASTER_DATA).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(P_AND_L).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(CROSSWALK).replaceAll("_", " "));
		values.push(capitaliseFirstLetterAfterChar(CURRENCY).replaceAll("_", " "));
		return values;
	}
	
	componentDidUpdate(prevProps) {
		var tableColumns = [];
		//table data
		this.state.mandatorySectionFields = prevProps.mandatorySectionFields;
		this.state.dataTypesFields = prevProps.dataTypesFields;
		var tableData = this.props.fromDataSource ? this.props.getTableData() : this.props.tableData;
		if(prevProps.table_type === prevProps.UPLOAD_DATA) {
			tableData = this.completeDefaultDataLength(tableData, tableColumns);	//add the default 5 rows if necessary
		}
		this.tabulator.setData(tableData);
		
		if(prevProps.table_type === prevProps.HISTORY_DATA) {
			tableColumns = cleanUpTabulatorColumns(copyObjectValues(historyDataColumns), tableData, this.refreshFilterDivs, this.tabulator,{id: prevProps.id});
			tableColumns.find(e => e.field === "section").headerFilterParams.values = this.getAllSections();
		} else if (prevProps.table_type === prevProps.UPLOAD_DATA) {
			if (prevProps.id === P_AND_L) {
				tableColumns = copyObjectValues(uploadDataPLColumns);
			} else {
				tableColumns = copyObjectValues(uploadDataColumns);
			}
			
			if(prevProps.id === CROSSWALK) {
				tableColumns.splice(3, 0, periodsColForCW);
			}
			tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
		} else if(prevProps.table_type === prevProps.DEFINE_DATA) {
			tableColumns = copyObjectValues(defineDataColumns);
		} else if (prevProps.table_type === prevProps.DATA_SUMMARY) {
			tableColumns = copyObjectValues(dataSummaryColumns);
		}
		//table columns
		if(prevProps.table_type !== prevProps.HISTORY_DATA) {
			tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
		}
		this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table
		this.tabulator.clearFilter(true);   //clear all table filters when data is updating

		if(prevProps.table_type === prevProps.HISTORY_DATA) {
			$("#filter-dropdown-" + HISTORY_FIELDS.SECTION + " div[value=Clear]").trigger("click");
			var filterValue = capitaliseFirstLetterAfterChar(this.props.selectedSection).replaceAll("_", " ");
			$("#filter-dropdown-" + HISTORY_FIELDS.SECTION + " select").val(filterValue);
			$("#filter-dropdown-" + HISTORY_FIELDS.SECTION + " div[value=Apply]").trigger("click");
		}
		var sorter = [{ column: UPLOAD_FIELDS.UPLOADED_DATE_FIELD, dir: "desc" }, { column: UPLOAD_FIELDS.DISPLAY_FILE_SUBTYPE, dir: "asc" }];
		if(prevProps.table_type === prevProps.HISTORY_DATA) {
			sorter = { column: HISTORY_FIELDS.EXECUTED_DATE, dir:"desc" };
		}
		if(prevProps.table_type === prevProps.DATA_SUMMARY) {
			sorter = { column: SUMMARY_TITLES.PERIOD, dir:"desc" };
		}
		this.tabulator.setSort(sorter);	
		var options = this.tabulator.options;
		if (prevProps.groupValues !== options.groupValues && prevProps.groupValues && prevProps.groupValues.length > 0) {
			options.groupValues = [prevProps.groupValues];
			options.data = tableData;
			options.columns = tableColumns;
			this.tabulator = new Tabulator(this.refs.mainTable, options);
		}

		if(this.props.tableData && JSON.stringify(this.props.tableData) !== JSON.stringify(this.state.tableData)) {
			this.state.tableData = this.props.tableData;
		}
		
		
	}


	onTabulatorRenderComplete() {
		if(!this.tabulator)
			return;
			
		var height = 260;
		if(this.props.table_type === this.props.HISTORY_DATA) {
			height = 500;
		} else if (this.props.table_type === this.props.UPLOAD_DATA && !this.props.isSectionExpanded) {
			height = 0;
		} else if (this.props.table_type === this.props.DEFINE_DATA) {
			height = 300;
		}

		$(".tabulator").height(convertPxToViewport(height, true));
	}

	componentDidMount() {
		var obj = this;
		//setup tabulator
		var options = {
			index: this.props.DATA_SUMMARY ? SUMMARY_FIELDS.PERIOD_ID :this.props.DEFINE_DATA ? DEFINE_FIELDS.ROW_NUMBER_FIELD : "",
			layout: "fitColumns",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			pagination: false, //"local",       //paginate the data
			// paginationSize:1000,         //allow 7 rows per page of data
			movableColumns: true,     //allow column order to be changed
			// autoResize: [obj.props.DATA_SUMMARY,obj.props.UPLOAD_DATA].indexOf(obj.props.table_type) > -1,
			autoResize: false,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			invalidOptionWarnings: false,
			resizableColumns: obj.props.table_type === obj.props.UPLOAD_DATA,	//only allow resize if it "upload data" table
			// responsiveLayout:"collapse",
			// initialSort: initialSort,
			// columnHeaderSortMulti:true,
			virtualDomBuffer: 4000,
			// virtualDom: obj.props.table_type === obj.props.DEFINE_DATA ? false : true, 
			placeholder: this.props.UPLOAD_DATA? MESSAGES.no_uploaded_files : "",
			height: obj.props.table_type === obj.props.DEFINE_DATA ? convertPxToViewport(100, true) : "40%",
			width: "100%",
			renderComplete: this.onTabulatorRenderComplete,
			tooltips:function(column){
				if(column._cell.column.field === UPLOAD_FIELDS.UPLOADED_STATUS_FIELD) {
					return DATA_DICTIONARY_ENUM[column._cell.value];
				} else {
					return column._cell.value;
				} 
			}
		}

		options.groupHeader = function (groupName, count, data) {
			var subtypeSectionInfo = {expected: 0, received: 0, passed: 0, failed: 0};
			var status = "";
			var widthPL = ""; 
			var groupNameValue = "";
			var groupValueNameArr = obj.props.subSections.filter(e=>e.label === groupName);
			if (groupValueNameArr.length > 0) {
				groupNameValue = groupValueNameArr[0].value;
			} //getting subtype name as saved in db 
			if(obj.props.sectionInfo !== undefined && obj.props.sectionInfo[groupNameValue]) {
				var subtypeSectionInfo = obj.props.sectionInfo[groupNameValue];
			}
			var status = "<i class='fas fa-times uk-text-sub'/>";
			var visibleCols = obj.tabulator.getColumns().filter(
				col => col.isVisible() === true && [UPLOAD_FIELDS.ACTIONS].includes(col.getField())
			);
			var width = $(visibleCols[0].getElement()).width();
			if (obj.props.id === P_AND_L ) {
				widthPL =width/1.57;
			} else {
				widthPL = width/2;
			}
			if (count > 0) {
				if (obj.props.sectionInfo[data[0].file_subtype].status === "true") {
					status = "<i class='fas fa-check uk-text-sub'/>"
				} else {
					status = "<i class='fas fa-times uk-text-sub'/>"
				}
			
			}
			var display = "";
			if (obj.props.isDefineFileAllowed) {
				display="uk-display-none";
			} else {
				display="uk-display-inline-block";
			}
			let isDisabled = obj.props.isMigrationAccessible !== undefined && !obj.props.isMigrationAccessible ? "disabled" :"";
			var fileType = data[0] ? data[0].file_type : subtypeSectionInfo.file_type;
			var subType = data[0] ?  data[0].file_subtype : subtypeSectionInfo.file_subtype;
			var groupInfo  = obj.props.id === CROSSWALK ? "" : obj.props.getRowGroupInfoAsHTML(subtypeSectionInfo);
			var deleteDiv = obj.props.id === CROSSWALK ? "" :"<div title='Delete' class='uk-margin-xsmall-top uk-float-right uk-display-inline-block uk-cursor-pointer "+isDisabled+"' id='delete_subtype_files' subType="+subType.replace("&", "\\&")+" type="+fileType.replace("&", "\\&")+" style=margin-right:"+convertPxToViewport(width/4)+">"+ 
							"<i class='fal fa-trash-alt uk-margin-xsmall-right' id='delete_subtype_files' subType="+subType.replace("&", "\\&")+" type="+fileType.replace("&", "\\&")+"></i>"+
							"</div>";
			var statusDiv =  obj.props.id === CROSSWALK ? "" :"<div class='uk-float-right status-col' style=width:"+convertPxToViewport(widthPL)+";margin-right:"+ (window.innerWidth > 2000? convertPxToViewport(widthPL-60) : convertPxToViewport(widthPL+60))+";text-align:center>"+status+"</div>"+
							"</div>";
			var typeDiv = obj.props.id === CROSSWALK ? "" :"<div class='uk-margin-xsmall-top uk-float-right "+display+" "+isDisabled+"' id='define_group_section' type="+fileType+" subtype_define="+subType+" style=margin-right:"+convertPxToViewport(width/5)+">"+
							"<i class='fal fa-edit uk-margin-xsmall-right uk-cursor-pointer' id='define_group_section' type="+fileType+" subtype_define="+subType+"></i></div>" ;
			if (data[0]) {
				return "<div class='uk-text-medium uk-text-unbold uk-display-inline-block width-250'>"+groupName+"</div>" + groupInfo +
							typeDiv + deleteDiv + statusDiv;
							
			}else {
				widthPL += 60;
				statusDiv =  obj.props.id === CROSSWALK ? "" :"<div class='uk-float-right status-col' style=width:"+convertPxToViewport(widthPL)+";margin-right:"+ (window.innerWidth > 2000? convertPxToViewport(widthPL-75) : convertPxToViewport(widthPL+30))+";text-align:center>"+status+"</div>"+
				"</div>";
				return "<div class='uk-text-medium uk-text-unbold uk-display-inline-block width-250'>"+groupName+"</div>" + groupInfo + statusDiv;
			}
				
		}

		if(obj.props.groupBy && obj.props.groupBy !== "") {
			window._profitIsleShowUploadPopup = function(elem) {
				var h5 = $(elem).parent();
				obj.props.showUploadPopup(h5, h5.attr("table-type"), h5.attr("subsection"));
			}
			options.groupBy = obj.props.groupBy;
			options.groupStartOpen = false;
			options.groupToggleElement = "header";
			options.groupClick = function(e, group){ 
				if(e.target.id === "delete_subtype_files") {
					e.stopImmediatePropagation();            
					var file_type = e.target.attributes.type.value;
					var file_subType = e.target.attributes.subType.value;
					obj.props.showDeleteFilesPopup(file_subType, file_type);
				} else if(e.target.id === "define_group_section") {
					e.stopImmediatePropagation();            
					obj.props.prepareRawFileDefinition(e.target.attributes.type.value, e.target.attributes.subtype_define.value);
				}else {
					$(".tabulator").height(convertPxToViewport(260, true));
				}
			}
		}
		this.tabulator = new Tabulator(this.refs.mainTable, options);
	}

  setConfirmBoolDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openConfirmBoolDialog: isOpen,
    })
  }

  confirmBoolDialogContent = () => {
    return <p className="uk-text-xmedium">{MESSAGES.confirm_boolean_selection}</p>
  }

  confirmBoolDialogActions = () => {
    return (
      <>
        <Button
          id="confirm-bool-btn"
          label={"Submit"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => {this.props.setValue(); this.setConfirmBoolDialogOpen(false)}}
        />
        <Button
          id="close-btn"
          label={MESSAGES.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setConfirmBoolDialogOpen(false)}
        />
      </>
    )
  }

	render() {
		
    return (
      <>
        <Modal
          id={"confirm-bool-dialog"}
          openDialog={this.state.openConfirmBoolDialog}
          bodyContent={this.confirmBoolDialogContent}
          dialogActions={this.confirmBoolDialogActions}
          closeClick={() => this.setConfirmBoolDialogOpen(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <div id={this.props.id} className={replaceSpecialCharacters(this.props.id)} ref="mainTable" />
      </>
    );

  }

}

export default UploadDataTable;