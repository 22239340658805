// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-toast {
    position: fixed;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 6px;
    z-index: 100002 !important;
    opacity: 0; /* Start hidden */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Fade in and move up */
}

.new-toast.show-toast {
    opacity: 0.9; /* Fully visible */
    transform: translate(-50%, 0); /* Center horizontally, move up to normal position */
}

.toast-close {
    cursor: pointer;
    margin-left: 15px;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    transition: transform 0.2s ease-in-out; /* Smooth transition */
}

.toast-close:hover {
    transform: rotate(90deg); 
}
`, "",{"version":3,"sources":["webpack://./src/styles/toast.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,SAAS;IACT,2BAA2B,EAAE,wBAAwB;IACrD,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,0BAA0B;IAC1B,UAAU,EAAE,iBAAiB;IAC7B,kDAAkD,EAAE,wBAAwB;AAChF;;AAEA;IACI,YAAY,EAAE,kBAAkB;IAChC,6BAA6B,EAAE,oDAAoD;AACvF;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,sCAAsC,EAAE,sBAAsB;AAClE;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".new-toast {\n    position: fixed;\n    bottom: 4vh;\n    left: 50%;\n    transform: translateX(-50%); /* Center horizontally */\n    min-height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 10px 20px;\n    background-color: #333;\n    color: #fff;\n    border-radius: 6px;\n    z-index: 100002 !important;\n    opacity: 0; /* Start hidden */\n    transition: opacity 0.5s ease, transform 0.5s ease; /* Fade in and move up */\n}\n\n.new-toast.show-toast {\n    opacity: 0.9; /* Fully visible */\n    transform: translate(-50%, 0); /* Center horizontally, move up to normal position */\n}\n\n.toast-close {\n    cursor: pointer;\n    margin-left: 15px;\n    font-size: 20px;\n    line-height: 20px;\n    color: #fff;\n    transition: transform 0.2s ease-in-out; /* Smooth transition */\n}\n\n.toast-close:hover {\n    transform: rotate(90deg); \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
