import React, { Component } from 'react';
import shortid from 'shortid';
import Tabulator from "tabulator-tables"; //import Tabulator library

import { findIndexOfValue, getEmbeddedChild } from '../../class/array';
import { cleanUpSingleTabulatorColumn, setDraggable, setLocalStorageValueByParameter, toggleLoader } from "../../class/common";
import {
  ACCOUNT_AMOUNT_FIELDS,
  ACCOUNT_AMOUNT_TITLES,
  ACCRUALS,
  ALL_WIDGETS,
  API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CALCULATED_COLUMNS,
  CC_LABELS,
  DIALOG_SIZE,
  DROPDOWN_TYPE,
  FY_VALUES,
  FormatTypes,
  Formats,
  GLACCOUNTS_FIELDS,
  METRICS_MAPPING,
  PSL_RETURN_NAMES,
  PS_MAPPING,
  RAW_ITEMS,
  ROW_STATUS,
  SCENARIO_STATUS,
  SIZES,
  STAGE_FIELDS,
  STAGING_SECTIONS,
  STAGING_STATUS_ENUM,
  Type,
  VECTOR_MAPPING,
  costtype
} from '../../class/constants';
import { formatValHTML, formatValReact, formatValString } from "../../class/format";
import { convertPxToViewport, convertViewportToPx } from '../../class/formatting.js';
import { alertAndLogError, extractCostKeys } from '../../class/jqueries';
import { is_aN } from '../../class/number';
import { extractNumber, replaceSpecialChars } from '../../class/string';
import { capitaliseFirstLetterAfterChar, capitalizeFirstLetter, copyObjectValues, findOptionByKey, findOptionByKeyValue, generateActionToken, getTranslationFile, getTreeLeaves, logoutIfUnauthenticated, parseBoolean, tryParse } from '../../class/utils.js';
import { Loader } from '../../form/elements.js';
import { lang } from '../../language/messages_en';
import Button from '../../newComponents/Button.js';
import DropDown from '../../newComponents/DropDown.js';
import Modal from '../../newComponents/Modal.js';
import { getTableButton, getTableIcon } from '../../newComponents/tabulatorComponents.js';
import { arrangeData } from '../filter/FilterHelperFunctions';
import GLAccountList from './/GLAccountList.js';
import { getCalculatedColumns, getProfitStackLines } from './CommonRequests';
import ValueFormula from './ValueFormula';
import ViewMoreMetric from './ViewMoreMetric';
import { FETCHAPI_PARAMS,FETCH_METHOD,fetchAPI } from '../../class/networkUtils.js';

const AmCharts = require('@amcharts/amcharts3-react');

const $ = require('jquery');
const UIkit = require("uikit");


const baseUrl = process.env.REACT_APP_BASE_URL;
const path = "/DataModeling";
const MESSAGES = getTranslationFile();
const cost_center = "Cost Center";
const _calcCols = STAGING_SECTIONS.CALCULATED_COLUMNS;
const _metric = "metric";
const _metric_value = "metric_value"
const _driverType = "driver_type";


var METRICS_COLUMNS = [
    {
        title: '', 
        field: PS_MAPPING.FIELDS.ADD,
        headerSort: false,
        dontFilter: true,
        width: 150
    },
    {
        title: METRICS_MAPPING.TITLES.METRIC,
        field: METRICS_MAPPING.FIELDS.METRIC,
        sorter: "string"
    },
    {
        title: METRICS_MAPPING.TITLES.VECTOR,
        field: METRICS_MAPPING.FIELDS.VECTOR,
        sorter: "string",
        visible: false
    },
    {
        title: METRICS_MAPPING.TITLES.VECTOR,
        field: METRICS_MAPPING.FIELDS.VECTOR_DISPLAY,
        sorter: "string"
    },
    { 
        title: METRICS_MAPPING.TITLES.COST_CENTER, 
        field: METRICS_MAPPING.FIELDS.COST_CENTER,
        sorter: "string",
        dontFilter: true,
        tooltip: false,
        width: 140
    },
    {
        title: METRICS_MAPPING.TITLES.METRIC_VALUE, 
        field: METRICS_MAPPING.FIELDS.METRIC_VALUE,
        sorter: "amount",
        dontFilter: true,
        visible: true
    },
    {
        title: METRICS_MAPPING.TITLES.DETAILS, 
        field: METRICS_MAPPING.FIELDS.DETAILS,
        // headerSort: false,
        dontFilter: true,
        width: 160
    },
    { 
        title: '', 
        field: METRICS_MAPPING.FIELDS.DELETE,
        headerSort: false,
        dontFilter: true,   
        width: 30
    },
    { 
        title: '', 
        field: METRICS_MAPPING.FIELDS.FILTER,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    { 
        title: '', 
        field: METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE_ID,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    { 
        title: '', 
        field: METRICS_MAPPING.FIELDS.ROW_STATUS,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    {
        title: '', 
        field: METRICS_MAPPING.FIELDS.RULE,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    {
        title: '', 
        field: METRICS_MAPPING.FIELDS.DESCRIPTION,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
    {
        title: '', 
        field: METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION,
        headerSort: false,
        dontFilter: true,
        visible: false
    },
];


const GR = "Gross Revenue";
const COGS = "COGS";
const NR = "Net Revenue";
const CostOfSales = "Cost of Sales";
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _leadingID = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _leadingCostKey = PS_MAPPING.FIELDS.LEADING_COSTKEY;
const _file = PS_MAPPING.EXCEPTION_FIELDS.FILE;
const _name = PS_MAPPING.FIELDS.NAME;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _mapFormula = PS_MAPPING.FIELDS.MAP_FORMULA;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _parentCostKey = PS_MAPPING.FIELDS.PARENT_COST_KEY;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const _combinations = PS_MAPPING.FIELDS.FIELDS_COMBINATIONS;
const _deleted = PS_MAPPING.FIELDS.DELETED;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _costCenter = PS_MAPPING.FIELDS.COST_CENTER;
const _percentage = PS_MAPPING.EXCEPTION_FIELDS.PERCENTAGE;
const _amount = PS_MAPPING.EXCEPTION_FIELDS.AMOUNT;
const _matchedSuffix = MESSAGES.pss_map_exception.suffixes.matched;
const _unMatchedSuffix = MESSAGES.pss_map_exception.suffixes.unmatched;
const _attribute = PS_MAPPING.FIELDS.ATTRIBUTE;
const _attributeFunction = PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION;
const _calcCol = PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL;
const _attributeType = PS_MAPPING.FIELDS.ATTR_TYPE;
const _inProfitMap = PS_MAPPING.FIELDS.IN_PROFIT_MAP;
const _excludeFromPs = PS_MAPPING.FIELDS.EXCLUDE_FROM_PS;
const _includeInPMTotals = PS_MAPPING.FIELDS.INCLUDE_IN_PM_TOTALS;

const _attributeFunctions= {
    COUNT: "count",
    SUM: "sum"
}

const ACTION_TYPE = {
    SHOW_HIDE: "show/hide",
    DELETE: "delete",
    MAP: "map"
}

const NONE = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.NONE;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
const TRANSACTION = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.TRANSACTION;

var newMetric = false;
const metricChartTitles = [STAGING_STATUS_ENUM.STAGED +" Metrics", STAGING_STATUS_ENUM.INVALID+" Metrics", STAGING_STATUS_ENUM.NOT_STAGED+" Metrics" ];

var initialPeriodTotal = {}
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.ASSIGNED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.EXCLUDED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_COMBINATION] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.ASSIGNED_AMOUNT] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.EXCLUDED_AMOUNT] = 0;
initialPeriodTotal[ACCOUNT_AMOUNT_FIELDS.UNASSIGNED_AMOUNT] = 0;

const initialPSColumns = [PS_MAPPING.FIELDS.HANDLE_ROW, PS_MAPPING.FIELDS.ADD, PS_MAPPING.FIELDS.EXPAND, _name, _costType, 
    _costCenter, _amount, PS_MAPPING.FIELDS.MAPPED, PS_MAPPING.FIELDS.COST_TERM, PS_MAPPING.FIELDS.ACTION, PS_MAPPING.FIELDS.DELETE]

const _vectorName = VECTOR_MAPPING.FIELDS.VECTOR_COLUMN_NAME;
const _displayName = VECTOR_MAPPING.FIELDS.VECTOR_DISPLAY_NAME;

/**
 * Profit Stack Mapping
 * @author [Sarah Farjallah]
 */
class ProfitStackMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profitStackFields: [],
            profitStackTableOriginalFields: [],
            originalMappedLines: [],
            profitStackOriginalFields: [],
            GLAccounts:[],
            selectedOptions: [],
            mappedLines: [],
            periodName:"",
            toBeDeleted: [],
            amount: [],
            showGLAccounts: false,
            dataTotals : [],
            dataTotals_id: shortid.generate(),
            glType: '',
            metricFields: [],
            metricOriginalFields: [],
            loadingRequest: 0,
            filter: "",
            accountNumbers: [],
            accountsType: "",
            isChanged: false,
            isMappingChanged: false,
            pslLine: {},
            smallLoadingRequest: 0,
            row:{},
            exceptionMetrics: [],
            showAttributeFormula: false,
            exceptionMetricsRaw:[],
            vectorList: [],
            missingPeriodFiles: [],
            hidden: false,
            isChangedPeriod: true
        };

        this.getProfitStackFields = this.getProfitStackFields.bind(this);
        this.onTabulatorRenderComplete = this.onTabulatorRenderComplete.bind(this);
        this.generateUniqueCostKey = this.generateUniqueCostKey.bind(this);
        this.generateUniqueIdDB = this.generateUniqueIdDB.bind(this);
        this.checkCellHasChildren = this.checkCellHasChildren.bind(this);
        this.onAddChild = this.onAddChild.bind(this);
        this.showDeleteRowModal = this.showDeleteRowModal.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.updateRemovalOfMapping = this.updateRemovalOfMapping.bind(this);
        this.updateProfitStackFields = this.updateProfitStackFields.bind(this);
        this.toggleTableColumnsForCalculated = this.toggleTableColumnsForCalculated.bind(this);
        this.saveChildSettings = this.saveChildSettings.bind(this);
        this.handleSelectMultiChange = this.handleSelectMultiChange.bind(this);
        this.updateDeletedProfitStackFields = this.updateDeletedProfitStackFields.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.discardChangesAndGoBack = this.discardChangesAndGoBack.bind(this);
        this.checkScenario = this.checkScenario.bind(this);
        this.getMappedLines = this.getMappedLines.bind(this);
        this.setChosenTimePeriod = this.setChosenTimePeriod.bind(this);
        this.updatePSLFilter = this.updatePSLFilter.bind(this);
        this.removeEmptyChildrenCells = this.removeEmptyChildrenCells.bind(this);
        this.hideGLAccounts = this.hideGLAccounts.bind(this);
        this.saveMappedCalculatedPSL = this.saveMappedCalculatedPSL.bind(this);
        this.setMappedLinesData = this.setMappedLinesData.bind(this);
        this.parseAmount = this.parseAmount.bind(this);
        this.refreshAmonts = this.refreshAmonts.bind(this);
        this.showGLAccounts = this.showGLAccounts.bind(this);
        this.getTotals = this.getTotals.bind(this);
        this.showScenarioPopUp = this.showScenarioPopUp.bind(this);
        this.getMetricFields = this.getMetricFields.bind(this);
        this.setStagingReport = this.setStagingReport.bind(this);
        this.updateMappedMetric = this.updateMappedMetric.bind(this);
        this.modifyData = this.modifyData.bind(this);
        this.isGrandChild = this.isGrandChild.bind(this);
        this.isSubChild = this.isSubChild.bind(this);
        this.onAddParentRow = this.onAddParentRow.bind(this);
        this.checkExistingName = this.checkExistingName.bind(this);
        this.checkEmptyCells = this.checkEmptyCells.bind(this);
        this.addManualChild = this.addManualChild.bind(this);
        this.findUnMatched = this.findUnMatched.bind(this);
        this.sortMappedLines = this.sortMappedLines.bind(this);
        this.changeSwitch = this.changeSwitch.bind(this);
        this.renameMatched = this.renameMatched.bind(this);
        this.onMetricDelete = this.onMetricDelete.bind(this);
        this.showExclusionPopUp = this.showExclusionPopUp.bind(this);
        this.discardChangesAndGoToExclude = this.discardChangesAndGoToExclude.bind(this);
        this.getChartValues = this.getChartValues.bind(this);
        this.generateStatus = this.generateStatus.bind(this);
        this.editCheck = this.editCheck.bind(this);
        this.getSiblings = this.getSiblings.bind(this);
        this.isDisabledToggleButton = this.isDisabledToggleButton.bind(this);
        this.init = this.init.bind(this);
        this.addIndexChild = this.addIndexChild.bind(this);
        this.removeMovedChild = this.removeMovedChild.bind(this);
        this.showValueFormula = this.showValueFormula.bind(this);
        this.hideValueFormula = this.hideValueFormula.bind(this);
        this.getVectorList = this.getVectorList.bind(this);
        this.showHideAddColumn = this.showHideAddColumn.bind(this);
        this.getColumnValuesFilter = this.getColumnValuesFilter.bind(this);
        this.validateConditions = this.validateConditions.bind(this);
        this.updateFilterConditionFiles = this.updateFilterConditionFiles.bind(this);
        this.saveMetricMapping = this.saveMetricMapping.bind(this);
        this.getConditionFormula = this.getConditionFormula.bind(this);
        this.getMetricsData = this.getMetricsData.bind(this);
        this.appendToFormula = this.appendToFormula.bind(this);
        this.constructMenuDropDown = this.constructMenuDropDown.bind(this);
        this.showAttributeFormula = this.showAttributeFormula.bind(this);
        this.hideAttributeFormula = this.hideAttributeFormula.bind(this);
        this.getMetricStagedAmounts = this.getMetricStagedAmounts.bind(this);
        this.handleElementChange = this.handleElementChange.bind(this);
        this.submitAttribute = this.submitAttribute.bind(this);
        this.cancelAttribute = this.cancelAttribute.bind(this);
        this.returnMenuDropDown = this.returnMenuDropDown.bind(this);
        this.getParentAmount = this.getParentAmount.bind(this);
        this.changeVisibiltyOfLine = this.changeVisibiltyOfLine.bind(this);
        this.hideDropDown = this.hideDropDown.bind(this);
        
        this.expandedRowsCostkeys = [];
        this.redrawTable = false;
        this.isProfitStackMapping = false;
        this.isMetricMapping = false;
        this.replaceDataTimeout = "";

        this.isPSCalculated = false;    //set to true only when mapping section is opened for a calculated line
        this.isPSAttribute = false;    //set to true only when mapping section is opened for an attribute line

        
        this.hasFetchedAmount = false;

        this.controller = new AbortController()
        this.signal = this.controller.signal;
    }

    editCheck = function(cell){
        var rowData = cell.getRow().getData();
        let mappedLines = this.state.mappedLines;
        let unmatchedLine = mappedLines.filter(line => line[_costKey] === rowData[_costKey] && parseBoolean(line[_isMatched]) === false)[0];
        // do not allow edit if unmatched or VAR Revenue or VAR Cogs
        if (unmatchedLine || rowData[_name].toUpperCase() === 'VAR - REVENUE' || rowData[_name].toUpperCase() === 'VAR - COGS' ){   
            return false;
        }
        return true;
    }

    checkExistingName(name, data, costKey, oldValue) {
        for(var e in data) {
            if (data[e]){
                if (data[e][_name].toLowerCase().includes(_unMatchedSuffix) && data[e][_name].replace(_unMatchedSuffix,"")  === oldValue.replace(_matchedSuffix,"")) {
                    data[e][_name] = name.replace(_matchedSuffix,"") + _unMatchedSuffix;
                }
                if((data[e][_returnName] && data[e][_returnName].toLowerCase().replace(_matchedSuffix,"").replace(_unMatchedSuffix,"") === name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '') && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
                    return -1;
                } else if(data[e].name.toLowerCase().replace(/ /g,"" ).replace(_matchedSuffix,"") === name.toLowerCase().replace(/ /g,"" ) && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                    return 1;
                }else {
                    if(data[e][_children]) {
                        var res = this.checkExistingName(name, data[e][_children], costKey, oldValue);
                        if(res !== 1 && res !== -1) {
                            continue;
                        } else{
                            return res;
                        }
                    }
                }
            } 
        }
    }

    checkEmptyCells(data) {
        for(var e in data) {
            if(data[e][_name].replace(/ /g,"" ) === '' && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                return 1;
            } else{
                if(data[e][_children]) {
                    if(this.checkEmptyCells(data[e][_children].filter((item) => item[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) !== 1) {
                        continue;
                    } else{
                        return 1;
                    }
                }
            }
        }
    }

    getProfitStackFields() {
        var costKeys= [];
        var callback = (data) => {
            if (data) {
                var pss = tryParse(data.data);
                var _costkeys = JSON.parse(data.costkeys).costkeys;
                var ids = JSON.parse(data.costkeys).ids;
                pss.map(line => {
                    if(line[_mapFormula]) {
                        line[_mapFormula] = tryParse(line[_mapFormula], [])
                    }
                    
                    line[_attribute] = line[_attribute] ? line[_attribute] : "";
                    line[_attributeFunction] = line[_attributeFunction] ? line[_attributeFunction] : "";
                    line[_attributeType] = line[_attributeType] ? line[_attributeType] : "";

                });
                this.setState({
                    profitStackFields: pss,
                    originalData: copyObjectValues(pss),
                    profitStackTableOriginalFields: copyObjectValues(pss),
                    profitStackOriginalFields: extractCostKeys(pss, costKeys),
                    costkeys:_costkeys,
                    ids:ids
                }, function(){
                    this.tabulator.setData(pss);
                })
            }
        }

        getProfitStackLines(this.props.scenarioId,"true", callback,false, lang.observability.stage.profit_stack_mapping.key);
    }

    getCalculatedColumns() {
        var obj = this;
        var callback = (data) => {
            if (data) {
                if(data.data && data.data.length) {
                    var calculatedColumns = [];
                    for (var e in data.data) {
                        calculatedColumns.push({label:data.data[e][CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME] , value:data.data[e][CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME],
                            usedValue:data.data[e][CALCULATED_COLUMNS.FIELDS.FORMULA],[RAW_ITEMS.FIELD_DATA_TYPE] :Formats.Numeric, 
                            [RAW_ITEMS.SUBTYPE_NAME]: _calcCols, [RAW_ITEMS.SUBTYPE_NAME]: _calcCols, [RAW_ITEMS.FIELD_DATA_TYPE]: FormatTypes.NUMERIC.toUpperCase()});
                    }
                    obj.setState({
                        calculatedCols: data.data.map(cc=>{
                            cc.label = cc[CALCULATED_COLUMNS.FIELDS.DISPLAY_NAME];
                            cc.value = cc[CALCULATED_COLUMNS.FIELDS.NAME];
                            cc[RAW_ITEMS.SUBTYPE_NAME]= _calcCols;
                            cc[RAW_ITEMS.RAW_FILE_SUBTYPE_ID]= _calcCols;
                            cc[RAW_ITEMS.FIELD_DATA_TYPE]= FormatTypes.NUMERIC.toUpperCase();
                            return cc;
                        }),
                        calculatedColumns: calculatedColumns
                    }, function() {
                        if(obj.glRef && obj.glRef.exclDriverRef) {
                            obj.saveChildSettings(obj.state.cell);      //refresh unusedCalculatedCols and send them as props
                        }
                    });
                }
            }
        }

        getCalculatedColumns(this.props.scenarioId, this.props.selectedPeriod.value, true, callback, lang.observability.stage.metric_mapping.key);
    }

    getCalculatedColsFilesByPeriod() {
        toggleLoader(true, "getCalculatedColsFilesByPeriod");

        var query = {
            action: "getCalculatedColsFilesByPeriod",
            scenario_id: this.props.scenarioId,
            timePeriod: this.props.selectedPeriod.value
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if (data && data.data) {
                    this.setState({
                        missingPeriodFiles: data.data.filter(item => item.count === 0).map(item => {return item[CALCULATED_COLUMNS.FIELDS.NAME]}),
                    })
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, "getCalculatedColsFilesByPeriod");
            });
    }

    getMetricFields(queryType) {
        var obj = this;
        toggleLoader(true, "getMetricFields");

        var query = {
            action: "getMetricFields",
            scenario_id: this.props.scenarioId,
            queryType: queryType
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if (data && data.metrics) {
                    var allMetricCountData = [];
                    var titles = metricChartTitles;
                    allMetricCountData = obj.getChartValues(data.metrics);
                    var arr = data.metrics;
                    arr.map(e=> e.index = generateActionToken(5))
                    var exceptionMetrics = [];
                    for(var e in arr) {
                        exceptionMetrics.push({label:arr[e].metric , value: arr[e].metric, type:_metric, is_cost_center: arr[e][METRICS_MAPPING.FIELDS.COST_CENTER],
                            [RAW_ITEMS.SUBTYPE_NAME]: arr[e].metric, [RAW_ITEMS.SUBTYPE_ID]: arr[e].metric, [RAW_ITEMS.FIELD_NAME]: _metric_value, [PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME_DB] : _metric_value,
                        period_status:arr[e].period_status})                   
                        arr[e]["metric_configuration"] = typeof arr[e]["metric_configuration"] === "string" ? 
                                    JSON.parse(arr[e]["metric_configuration"]) : arr[e]["metric_configuration"];    
                    }
                    this.setState({
                        metricFields: arr,
                        metricOriginalFields: copyObjectValues(arr),
                        exceptionMetricsRaw: exceptionMetrics
                    }, function () {
                        if (this.isMetricMapping) {
                            this.tabulator.setData(copyObjectValues(this.state.metricFields));
                            if (this.tabulator.getData()) {
                                $(".add-row").removeClass("uk-hidden");
                            }
                            // this.getMetricsData(this.state.metricFields,'getMetrics');
                        }  else{
                            this.getMetricStagedAmounts();
                        }                    
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, "getMetricFields");
            });
    }


    getMetricStagedAmounts() {
        var obj = this;
        toggleLoader(true, "getMetricStagedAmounts");

        var query = {
            action: "getMetricStagedAmounts",
            scenario_id: this.props.scenarioId,
            timePeriod: this.props.selectedPeriod.value
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                var arr = obj.state.exceptionMetricsRaw;
                if (data && data.data) {
                    for(var i in arr) {
                        var currMetric = data.data.filter(e => e.metric === arr[i].file_type);
                        arr[i].amount = currMetric.length > 0 ? currMetric[0].amount : 0;
                    }
                }
                this.setState({
                    exceptionMetrics: arr
                });
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(() => {
                toggleLoader(false, "getMetricStagedAmounts");
            });
    }


    /**
     * called from viewMoreMetric component when closing the modal
     */
    resetmetricDetails=()=>{
        this.setState({
            metricDetails:[]
        });
    }

    getMetricsData = (data, queryType, timePeriod,moreDetailsFilter=[],moreDetailsSorter=[]) => {
        let obj = this;
        let tabulatorData = obj.tabulator.getData();
        let showLoader = false;
        if(queryType === "getMetricsDetails") {
            showLoader = true;
        } else{
            if(!obj.state.isChangedPeriod && !obj.state.isChanged){ // don't load metrics if there's no need/nochange
                return;
            }
            obj.isParsingAmount = true;
            obj.tabulator.replaceData(tabulatorData);
        }

        if(data == null){
            if(obj.state.isChangedPeriod){
                data = tabulatorData;
            } else {
                data = tabulatorData.filter(e => e[METRICS_MAPPING.FIELDS.FETCH_DATA])
            }
        }
        let period = timePeriod ? typeof timePeriod === 'object' ? timePeriod.value : timePeriod :this.props.selectedPeriod.value;
        
        let query = {
            action: "getMetricsData",
            scenario_id: this.props.scenarioId,
            data: JSON.stringify(data),
            queryType: queryType,
            timePeriod: period,
            moreDetailsFilter: JSON.stringify(moreDetailsFilter),
            moreDetailsSorter: JSON.stringify(moreDetailsSorter)
        }
    
        let onThenCallback = (data) => {
            if (data && data.data) {
                if(queryType === "getMetricsDetails") {
                    obj.setState({
                        metricDetails: data.data,
                        summary: data.summary,
                        metricDetailsId: generateActionToken(5),
                        metricDetailsColumns:data.metricDetailsColumns,
                        summaryColumns:data.summaryColumns
                    },()=>{
                        if(obj.viewMoreRef){
                            obj.viewMoreRef.table.clearFilter(true);
                        }
                    })
                } else{
                    obj.isParsingAmount = false;
                    let arr = data.data;
                    var metrics = this.tabulator.getData();
                    for (let i = 0; i < metrics.length; i++) {
                        let metricId =  metrics[i].metric_id;
                        let metricToMatch = !! metricId ? metrics[i].metric : metrics[i].metric_display_name; //If is new depend on metric_display_name else depend on metric machine name
                        
                        metrics[i][METRICS_MAPPING.FIELDS.FETCH_DATA] = false; // to not fetch data anymore for fetched values
                        const foundMetric = arr.find(dataItem => dataItem.metric === metricToMatch && dataItem.row_status === metrics[i].row_status);

                        if (foundMetric) {
                            metrics[i].metric_value = foundMetric.metric_value;
                        }
                    }
                    obj.setState({
                        metricFields: metrics,
                        isChangedPeriod: false
                    }, function () {
                        $("#small_loader").hide();
                        obj.tabulator.setData(copyObjectValues(this.state.metricFields));
                        if (obj.tabulator.getData()) {
                            $(".add-row").removeClass("uk-hidden");
                        }
                    });
                }                   
            }
    
        };
    
        let fetchOptions = {
          [FETCHAPI_PARAMS.funcName]: "getMetricsData",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
          [FETCHAPI_PARAMS.showLoader]: showLoader,
          [FETCHAPI_PARAMS.path]: path,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.signal]: this.signal,
          [FETCHAPI_PARAMS.periods]: period,
          [FETCHAPI_PARAMS.screenName]:lang.observability.stage.metric_mapping.screen_name,
          [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.metric_mapping.requests_description.get_metrics_data
    
        }
        fetchAPI(fetchOptions);
    }

    getChartValues (data, period) {
        var obj = this;
        var stagedCount = 0;
        var notStagedCount = 0;
        var invalidCount = 0;
        var timePeriod = obj.props.selectedPeriod.value;
        if (period !== undefined) {
            timePeriod = period;
        }
        for (var e in data) {
            if(data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
                Object.keys(JSON.parse("["+JSON.stringify(data[e]["period_status"])+"]")[0]).forEach(function eachKey(key){
                    if (key === timePeriod) {
                        if (JSON.parse("["+JSON.stringify(data[e]["period_status"])+"]")[0][key] === 'STAGED'){
                            stagedCount++;
                        } else if (JSON.parse("["+JSON.stringify(data[e]["period_status"])+"]")[0][key] === 'NOT_STAGED'){
                            notStagedCount++;
                        } else{
                            invalidCount++;
                        }
                    }
                });
            }  
        }
        return [stagedCount, invalidCount, notStagedCount];
    }

    getMappedLines(period, manageExclusions) {
        var obj = this;
        toggleLoader(true, "getMappedLines");

        var query = {
            action: "getMappedLines",
            scenario_id: this.props.scenarioId,
            timePeriod: period ? period :this.state.periodName,
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if (data) {
                    let parsedTotals = obj.state.periodTotals ? obj.state.periodTotals.filter(e => e.time_periods === obj.props.selectedPeriod.value) : [initialPeriodTotal];
                    let lines = obj.sortMappedLines(data.data);
                    lines.map(line => {
                        if(line[_mapFormula]) {
                            line[_mapFormula] = tryParse(line[_mapFormula], [])
                        }
                    });

                    let tempState = {
                        mappedLines: lines,
                        originalMappedLines: copyObjectValues(data.data),
                        dataTotals: parsedTotals,
                        dataTotals_id: shortid.generate(),
                    }

                    obj.setState(tempState, function(){
                        obj.parseAmount(undefined, manageExclusions);
                        obj.getTotals(undefined, false, true);
                    });
                }

            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "getMappedLines");
            });
    }


    fetchAncillaryColsAmounts(period) {
        var obj = this;
        toggleLoader(true, "fetchAncillaryColsAmounts");

        var query = {
            action: "fetchAncillaryColsAmounts",
            timePeriod: period ? period :this.state.periodName,
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if (data) {                    
                    obj.setState({
                        ancillaryColsAmounts: data
                    });
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(()=>{
                toggleLoader(false, "fetchAncillaryColsAmounts");
            });
    }

    onAddParentRow(lineType) {
        var obj = this;
        var newRow = {};
        var data =  obj.tabulator.getData();//copyObjectValues(this.state.profitStackFields);
        clearTimeout(this.replaceDataTimeout);

            if (this.isMetricMapping) {
            //open metric mapping when clicking plus
            newMetric = true;
            
            newRow = {
                [METRICS_MAPPING.FIELDS.METRIC] : "",
                [METRICS_MAPPING.FIELDS.VECTOR]: "",
                [METRICS_MAPPING.FIELDS.COST_CENTER]: "false",
                [METRICS_MAPPING.FIELDS.STATUS]: STAGING_STATUS_ENUM.VALID,
                [METRICS_MAPPING.FIELDS.ROW_STATUS] : METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NEW,
                [METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE_ID] : "",
                [METRICS_MAPPING.FIELDS.FILTER]: ""
            };
            $("#load-metric-value").addClass("disabled uk-disabled")
            obj.setLineToMap(STAGING_SECTIONS.METRICS_MAPPING, null, newRow);   //set newRow as metric to map
            obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL');
            obj.showValueFormula(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL');
        }
    }

    updateProfitStackFields(pssFields, costKey, children) {
        var obj = this;
        for(var e in pssFields) {
            if(pssFields[e][_costKey] === costKey) {
                if(pssFields[e][_children])
                    pssFields[e][_children] = pssFields[e][_children].concat(children);
                else {
                    pssFields[e][_children] = children;
                }
            }else{
                if(pssFields[e][_children] && pssFields[e][_children].length > 0) {
                    obj.updateProfitStackFields(pssFields[e][_children], costKey, children);
                }
            }
        }
        return pssFields;
    }

    updateDeletedProfitStackFields(pssFields, costKey, name, id ) {
        var obj = this;
        for(var e in pssFields) {
           
            if(pssFields[e][_costKey] === costKey) {
                pssFields.splice(e,1);
                if (pssFields[e]){
                    if(pssFields[e][_name].replace(_unMatchedSuffix,"") === name.replace(_matchedSuffix,"")) {
                        pssFields.splice(e,1);
                    }
                }
            } else {
                if(pssFields[e][_children] && pssFields[e][_children].length > 0){
                    obj.updateDeletedProfitStackFields(pssFields[e][_children], costKey, name, id);
                } else {
                    delete pssFields[e][_children];

                }
            }
            if (id && pssFields[e] && pssFields[e][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
                pssFields.splice(e,1);
            }
        }
        return pssFields;
    }

    reOrderLine(pssFields, toCostKey, line, insertBefore=false) {
        if(toCostKey === line[_costKey]) {
            return pssFields;   //if source and destination are the same, do not enter loop
        }

        var obj = this;
        var spliced = false;
        var added = false;
        var index = 0;

        while((!spliced || !added) && index < pssFields.length) {
            if(pssFields[index][_costKey] === line[_costKey]) {
                pssFields.splice(index, 1);     //when encountering this element, remove it from list
                spliced = true;
                index--; //re-adjust index after splicing
            } else if(pssFields[index][_costKey] === toCostKey) {
                if(insertBefore) {
                    pssFields.splice(Number(index), 0, line);   //when encountering the target costkey, push the element before it if insertBefore = true
                } else {
                    pssFields.splice(Number(index)+1, 0, line);   //when encountering the target costkey, push the element behind it
                }
                added = true;
                index++; //re-adjust index after splicing
            } else if(pssFields[index][_children]){
                obj.reOrderLine(pssFields[index][_children], toCostKey, line);
            }
            index++;
        }

        return pssFields;
    }

    onAddChild(data, costKey, row, level) {
        let _this = this;
        let psFields = this.tabulator.getData();
        var children = [];
        var mappedLines = this.state.mappedLines; 
        var newCostKey = this.generateUniqueCostKey(psFields);
        var newId = this.generateUniqueIdDB(psFields);
        var newRow = {
            [_name] : "",
            [_id]: newId.toString(),
            ["id"]: newId.toString(),
            [_costKey]: newCostKey+"Insert",
            [_parentCostKey]: costKey,
            "new": "1",
            level: level+1,
            isNotAC:false,
            [_returnName]: "",
            [ROW_STATUS.FIELD]:
            ROW_STATUS.VALUES.NEW,
            [_costType]: data[_costType]    //copy parent's costtype
        };
        children.push(newRow);
        psFields = this.updateProfitStackFields(psFields, costKey, children);
        mappedLines.map(function(item){
            if(item[_costKey] === costKey){
                item[_deleted] = "true";
            }
        });
        this.tabulator.replaceData(psFields);
        this.setState({
            profitStackFields: psFields,
            isChanged: true
        }, function() {
            // if(row.getData()[_parentCostKey] !== '201' ) { 
                //&&  row.getData()[_children] && row.getData()[_children].length === 0) { // second condition added for updateOfRemoval should be called on leaves only
                _this.updateRemovalOfMapping(_this.findPslRow(psFields, _costKey, costKey), false);
            // }
        });
    }

    /**
    * Recusive function that loops over nested array and remove children array if it's empty  
    * @param {*} array of profitStackFields
    */
    removeEmptyChildrenCells(pssFields) {
       var obj = this;
        for(var e in pssFields) {
            if(pssFields[e][_children] && pssFields[e][_children].length > 0){
                obj.removeEmptyChildrenCells(pssFields[e][_children]);
            }else{
                if(pssFields[e][_children] && pssFields[e][_children].length === 0) {
                   pssFields[e][_children] = null;
                }
            }
        }
    }
    
    /**
    * Recusive function that loops over nested array and collects children's costKeys  
    * @param {*} 
    */
    collectDeletedChildren(costKey, pssFields, toBeDeleted, id) {
        var obj = this;
        for (var e in pssFields) {
            if (pssFields[e][_costKey] === costKey || pssFields[e][_parentCostKey] === costKey 
                || (toBeDeleted.includes(pssFields[e][_parentCostKey]) && pssFields[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
                if(pssFields[e]["new"] === "1") {
                    pssFields.splice(e, 1);
                    if (pssFields[e] && pssFields[e][_children] && pssFields[e][_children].length === 0) {
                        delete pssFields[e][_children];
                    }
                    continue;
                } else {
                    if(!toBeDeleted.includes(pssFields[e][_costKey])) {
                        toBeDeleted.push(pssFields[e][_costKey]);
                    }
                    pssFields[e][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                    var accrual = pssFields.filter(item => item[PS_MAPPING.FIELDS.ACTUAL_ID] === id);
                    if(accrual.length > 0) {
                        if(!toBeDeleted.includes(accrual[0][_costKey])) {
                            toBeDeleted.push(accrual[0][_costKey]);
                        }
                        pssFields.filter(item => item[PS_MAPPING.FIELDS.ACTUAL_ID])[0][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                    }
                    if(pssFields[e][_name].includes(_matchedSuffix)) {
                        var name = pssFields[e][_name].replace(_matchedSuffix, "");
                        var unmatched = pssFields.filter(item => item[_name].replace(_unMatchedSuffix,"") === name);                      
                        if(unmatched.length > 0) {
                            if(!toBeDeleted.includes(unmatched[0][_costKey])) {
                                toBeDeleted.push(unmatched[0][_costKey]);
                            }
                            unmatched[0][ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
                        }
                    }
                }
            }
            if(pssFields[e][_children]  && pssFields[e][_children].length > 0) {
                obj.collectDeletedChildren(costKey, pssFields[e][_children], toBeDeleted, id);
            } else {
                delete pssFields[e][_children];
            }
        }
        return pssFields;
    }

    isDisabledToggleButton(file, item, mappedLines) {
        if(!item || (!!item && item[_mappingException] !== ANCILLARY)) {
            return false;
        }
        var ancColumns = this.props.allAncillaryColumns;
        if(!item[_isMatched]) {
            var pssSiblings = item[_leadingID] ? this.getSiblings(item[_leadingID], this.state.mappedLines).filter(e => e[_isMatched] && e[_file]) : [];      //fetching siblings of leading PS Line
            for(var e in pssSiblings) {
                var sibling = pssSiblings[e];
                if (sibling[_driverType] === _metric) {
                    var metric = this.state.exceptionMetricsRaw.filter(e=>e.value === sibling[_file]);
                    if (metric.length > 0 && metric[0].is_cost_center === 'false') {
                        return true;
                    }
                } else {
                    var columns = ancColumns.filter(el=>el.file_type === sibling[_file]);
                    var costCenterCol = columns.filter(el=>el.name === cost_center);
                    if(costCenterCol.length === 0) {
                        return true;
                    }
                }
            }
            return false;
        } else {
            var ancFile = file;
            if(!file && !!item) {
                item = this.getLeadingItem(item[_leadingID], mappedLines);
                ancFile = item[_file];
            }
    
            if (!!item && item[_driverType] === _metric) {
                var metric = this.state.exceptionMetricsRaw.filter(e=>e.value === ancFile);
                if (metric.length > 0 && metric[0].is_cost_center === 'true') {
                    return false;
                } else {
                    return true;
                }
            } else {
                var columns = ancColumns.filter(el=>el.file_type === ancFile);
                var costCenterCol = columns.filter(el=>el.name === cost_center);
                return costCenterCol.length === 0;    
            }    
        }
    }

    getLeadingItem(leadingId, mappedLines) {
        mappedLines = mappedLines ?  mappedLines.filter(el=>el[_id] === leadingId) :  this.state.mappedLines.filter(el=>el[_leadingID] === leadingId);
        for (var e in mappedLines) {
            var file = mappedLines[e][_file];
            if (file) {
                return mappedLines[e];
            }
        }
        return "";
    }

    showDeleteRowModal(row) {
        let usingLines = this.isRowUsedInCalculated(row);
        if(usingLines.length) {
            let msg = row[_name] + MESSAGES.used_ps_no_delete_1_calc;
            msg += MESSAGES.modal.variable_place_holder;
            msg += MESSAGES.used_ps_no_delete_2;
            // this.modalRef.setVariableList(usingLines);
            // this.modalRef.showModal(undefined, msg);
            return;
        }
        else{
            this.setOpenConfirmDeleteLineDialog(true)
        }
        
    }

  onDeleteRow(row) {
    this.setOpenConfirmDeleteLineDialog(false);
    if (this.isMetricMapping) {
      this.onMetricDelete(row);
    } else {
      this.updateRemovalOfMapping(row, true);
    }
  }

    isRowUsedInCalculated(row) {
        let deletedCK = row[_costKey];
        let calculatedMappedLines = this.state.mappedLines.filter(line=>line[_costType] === costtype.calculated);
        let usingLines = [];
        calculatedMappedLines.forEach(line=>{
            let formula = line[_mapFormula];
            let isUsed = formula ? formula.filter(item=>item[CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.VALUE] === deletedCK.replace("Insert","")).length > 0 : undefined;
            if(isUsed) {
                usingLines.push(line[_name]);
            }
        })
        return usingLines;
    }

    updateRemovalOfMapping(row, shouldDelete) {
        var costKey = row[_costKey];
        var name = row[_name];
        var id = row[PS_MAPPING.FIELDS.PSS_ID];
        var toBeDeleted = this.state.toBeDeleted;
        var isMapped = false;
        var unmatchedLine = "";
        var pssFields = copyObjectValues(this.tabulator.getData());
        var mappedLines = this.state.mappedLines;
        var editedLine = mappedLines.filter(el=>el[_costKey] === costKey)[0];
        var isEditingLeading = !!editedLine ? editedLine[_costKey] === editedLine[_leadingCostKey] : false;
        let lonelyUnmatched = false;
        
        if(shouldDelete) {
            var updateData = this.collectDeletedChildren(costKey, this.tabulator.getData(), toBeDeleted, id);
            this.tabulator.replaceData(updateData);
        }
        for(var e in mappedLines) {
            if(mappedLines[e][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
                mappedLines[e].deleted = "true";
            }
        }
        if(!!editedLine) {
            let leadingCK = editedLine[_leadingCostKey];
            let leadingLine = mappedLines.filter(el => el[_leadingCostKey] === leadingCK)[0];
            var ledLines = mappedLines.filter(function(el){if(el[_leadingCostKey] === leadingCK ) return el;});
            var ledLinesWithoutLeading = mappedLines.filter(function(el){if(el[_leadingCostKey] === leadingCK && el[_costKey] !== leadingCK) return el;});

            if(ledLinesWithoutLeading.length === 1 && parseBoolean(ledLinesWithoutLeading[0][_isMatched]) === false) {
                //if this mapping only has unmatched line other than the deleted leading line, delete its mapping
                //if its the same name as the leading, it will be deleted, if not, it will be kept as non mapped
                lonelyUnmatched = true; //to indicate that the unmatched line doesn't belong to any other leading line
                let unmatchedCostkey = ledLinesWithoutLeading[0][_costKey];
                let unmatchedIndex = findIndexOfValue(mappedLines, _costKey, unmatchedCostkey);
                mappedLines.splice(unmatchedIndex, 1);

                if(ledLinesWithoutLeading[0][_name].includes(_unMatchedSuffix)) {
                    pssFields = this.updateDeletedProfitStackFields(pssFields, ledLinesWithoutLeading[0][_costKey], "");
                }
            }

            if (ledLines.length > 0 && ledLinesWithoutLeading.length > 0) {
                var newLeadingCostKey = isEditingLeading ? ledLinesWithoutLeading[0][_costKey] : leadingLine[_costKey];
                var newLeadingPssId = isEditingLeading ? ledLinesWithoutLeading[0][_id] : leadingLine[_id];
                var newUmatchedName = isEditingLeading ? ledLinesWithoutLeading[0][_name] : leadingLine[_name];
                if(isEditingLeading) {
                    ledLinesWithoutLeading[0][_amount] = (parseFloat(editedLine[_amount]) + parseFloat(ledLinesWithoutLeading[0][_amount])).toString();
                    ledLinesWithoutLeading[0][_percentage] = (parseFloat(editedLine[_percentage]) + parseFloat(ledLinesWithoutLeading[0][_percentage])).toString();
                } else {
                    leadingLine[_amount] = (parseFloat(editedLine[_amount]) + parseFloat(leadingLine[_amount])).toString();
                    leadingLine[_percentage] = (parseFloat(editedLine[_percentage]) + parseFloat(leadingLine[_percentage])).toString();
                }
                ledLines.map(line=> line[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED); //update row_status for all mapped lines

                if(isEditingLeading) {  //moving combinations, file and filter from the old leading line to the new leading line
                    for( var e in ledLines) {
                        ledLines[e][_leadingCostKey] = newLeadingCostKey;
                        ledLines[e][_leadingID] = newLeadingPssId;
                        if(ledLines[e][_costKey] === newLeadingCostKey) {
                            ledLines[e][_combinations] = editedLine[_combinations];
                            ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] = !ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] ? editedLine[PS_MAPPING.EXCEPTION_FIELDS.FILE] : ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE];
                            ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] = !ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] ? editedLine[PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME] : ledLines[e][PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME];
                            ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] = !ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE] ? editedLine[PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER] :  ledLines[e][PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER];
                            ledLines[e][_driverType] = !ledLines[e][_driverType] ? editedLine[_driverType] :  ledLines[e][_driverType];
                        }

                        if(ledLines[e][_name].includes(_unMatchedSuffix)) { //this condition is for renaming previously unmatched lines edited after this change
                            ledLines[e][_name] = newUmatchedName.replace(_matchedSuffix, _unMatchedSuffix);
                            this.renameMatched(pssFields, ledLines[e][_costKey], false, newUmatchedName);
                        }

                        if(parseBoolean(ledLines[e][_isMatched]) === false && !lonelyUnmatched) {
                            //this condition is for preventing the deletion of the unmatched line if there is an alternative leading line
                            let unmatchedCostkey = ledLines[e][_costKey];   //get costkey of the saved unmatched line
                            unmatchedLine = getEmbeddedChild(this.tabulator.getData(), _children, _costKey, unmatchedCostkey);  //get unmatched line from table data
                            unmatchedLine = Object.assign({}, unmatchedLine, ledLines[e]);  //update this line from mappedLines
                            let indexOfDeletedUnmatched = !!unmatchedLine ? toBeDeleted.indexOf(unmatchedCostkey) : -1;
                            if(unmatchedLine && indexOfDeletedUnmatched > -1) {
                                toBeDeleted.splice(indexOfDeletedUnmatched, 1);     //since we're renaming unmatched line and not deleting it, do not delete it from DB
                            }
                        }
                    }
                }
            }
            this.tabulator.replaceData(pssFields);
        }

        if(shouldDelete) {
            pssFields = this.updateDeletedProfitStackFields(pssFields, costKey, name, id);
        } else {
            this.renameMatched(pssFields, costKey, false);
        }

        if(unmatchedLine && unmatchedLine[_name].includes(_unMatchedSuffix)) {
            //do not reorder unless the unmatched line was automatically created
            pssFields = this.reOrderLine(pssFields, newLeadingCostKey, unmatchedLine);
        }
        this.removeEmptyChildrenCells(pssFields);
        this.tabulator.replaceData(pssFields);          
        mappedLines.map(function(item,key){
            if((item[_name] && name) && (item[_costKey] === costKey || (item[_parentCostKey] === costKey && shouldDelete) || item[_name].replace(_unMatchedSuffix,"") === name.replace(_matchedSuffix,""))) {
                mappedLines.splice(key,1);
                isMapped = true;
            }
        });
        mappedLines = this.sortMappedLines(mappedLines);
        var originalFields = this.state.profitStackTableOriginalFields;
        var modifiedFields = this.tabulator.getData();
        var changed = this.compareFields(originalFields, modifiedFields);
        this.setState({
            profitStackFields: pssFields,
            mappedLines: mappedLines,
            isChanged: !changed ? !changed : this.state.isMappingChanged,
            toBeDeleted:  toBeDeleted
        },function(){
            // if(isMapped) {
            //     this.tabulator.setData(this.tabulator.getData());
            //     // this.getTotals(undefined, false, true);
            // }
                
            this.tabulator.scrollToRow(row[_parentCostKey]);
        });
        this.tabulator.addFilter(ROW_STATUS.FIELD, "in", [ROW_STATUS.VALUES.NEW, ROW_STATUS.VALUES.OLD, ROW_STATUS.VALUES.EDITED]);
    }

    reExpandCollapsedRows(rows, expandAll) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        allRows.forEach(function (row) {
            if (row._row.modules.dataTree) {
                let isExpanded = row._row.modules.dataTree.open;
                if ((obj.expandedRowsCostkeys.indexOf(row.getData()[_costKey]) !== -1 && (!isExpanded || obj.redrawTable)) ||
                    (row.getData()[_costKey] && !isExpanded && expandAll)) {
                    //!isExpanded - if row is already expanded, do not retry to expand
                    //if redrawTable = true because if a row has been moved, it is expanded in tabulator data, but not in the table, so re-expand anyways
                    row.treeExpand();
                    obj.reExpandCollapsedRows(row.getTreeChildren(), expandAll);
                }
            }
        })
    }

    getRandomCostkey() {
        var costkey = (Math.floor(Math.random()*1000));
        return costkey === 201 ? this.getRandomCostkey() : costkey;
    }
        
    generateUniqueCostKey() {
        var generatedId = "";
        do{
            var inPSFields = 0;
            generatedId = this.getRandomCostkey();
            for(var i = 0 ; i < this.state.costkeys.length; i++){
                if(Number(this.state.costkeys[i].costkey.replace("Insert","")) === generatedId) {
                    inPSFields = -1;
                    break;
                } 
            }
                        
            if(inPSFields === 0)
                inPSFields = 1;

        } while(inPSFields <= 0)
        this.state.costkeys.push({costkey:generatedId.toString()});
        return generatedId;
    }

    generateUniqueIdDB() {
        var generatedId = "";
        do{
            var inPSFields = 0;
            generatedId = this.getRandomCostkey();
            for(var i = 0 ; i < this.state.ids.length; i++){
                if(Number(this.state.ids[i].id) === generatedId) {
                    inPSFields = -1;
                    break;
                }
            }
                        
            if(inPSFields === 0)
                inPSFields = 1;

        } while(inPSFields <= 0)
        this.state.ids.push({id:generatedId});
        return generatedId;
    }

    compareFields(originalFields, modifiedFields) {

        // compare lengths - can save a lot of time 
        if (originalFields.length !== modifiedFields.length)
            return true;

        for (var i = 0, l = originalFields.length; i < l; i++) {
            var objectsAreDifferent = false;
            for (var propertyName in originalFields[i]) {
                // comparing objects 
                if(typeof modifiedFields[i][propertyName] === "object" && modifiedFields[i][propertyName] !== null) {
                    for (var subPropertyName in originalFields[i][propertyName]) {
                        if (originalFields[i] && modifiedFields[i]) {
                            if(originalFields[i][propertyName][subPropertyName] !== modifiedFields[i][propertyName][subPropertyName]) {
                                objectsAreDifferent = true;
                                break;
                            }
                        }
                    }
                } else if(originalFields[i][propertyName] !== modifiedFields[i][propertyName]) {
                    objectsAreDifferent = true;
                }
                if(objectsAreDifferent) 
                    break;
            }
        }    
            
        return objectsAreDifferent;         

    }
     
    saveChanges() {
        this.showScenarioPopUp();
    }

    /**
     * This function takes the list of metrics, sets the row_status of those that have it
     * 'not_saved' to 'new', since they behave differently on the UI, but same on the API
     * @param {*} metricsData 
     */
    setNotSavedMetricsToNew(metricsData) {
        metricsData.filter(metric =>( metric[METRICS_MAPPING.FIELDS.ROW_STATUS] === METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED || !metric.metric_id) && metric[METRICS_MAPPING.FIELDS.ROW_STATUS] !== METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.DELETED).forEach(function(metric) {
            metric[METRICS_MAPPING.FIELDS.ROW_STATUS] = METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NEW;
            metric[METRICS_MAPPING.FIELDS.STATUS] = METRICS_MAPPING.METRIC_STAGING_STATUS.NOT_STAGED;

            let conditions = metric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION][0].conditionFormula[0].conditions;
            let filter = metric[METRICS_MAPPING.FIELDS.FILTER] !== "" ? JSON.parse(metric[METRICS_MAPPING.FIELDS.FILTER]).filter : [];
            for (var i = 0; i < conditions.length; i++) {
                for (var elt in conditions[i].filters) {
                    delete conditions[i].filters[elt].column_options;
                    delete conditions[i].filters[elt].value_options;
                    delete conditions[i].filters[elt].function_options;
                    delete conditions[i].filters[elt].file_options
                }
            }
            for (var elt = 0; elt < filter.length; elt++) {
                delete filter[elt].column_options;
                delete filter[elt].value_options;
                delete filter[elt].function_options;
                delete filter[elt].file_options
            }    
        });

        return metricsData;
    }


    /**
     * Remove unnecessary keys from objects because payload is being too large when saving metrics preventing request to pass
     * @param {*} metrics 
     */
    formulateMetricData = (metrics) => {
        for(let e in metrics) {
            let metric = metrics[e];
            let parentMetricConfiguration = metric?.metric_configuration[0];
            if(parentMetricConfiguration) {
                let conditionFormula = parentMetricConfiguration?.conditionFormula;
                let innerMetricConfiguration = parentMetricConfiguration?.metricConfiguration
                if(conditionFormula) {
                    conditionFormula = parentMetricConfiguration?.conditionFormula[0];
                    let conditions = conditionFormula?.conditions;
                    if(conditions) {
                        for(let c in conditions) {
                            let filters =  conditions[c].filters;
                            for(let f in filters) {
                                delete filters[f]["value_options"]
                                delete filters[f]["column_options"]
                                delete filters[f]["function_options"]
                            }
                        }       
                    }
                }
                if(innerMetricConfiguration){
                    for(let m in innerMetricConfiguration){
                        delete innerMetricConfiguration[m]["stringOutput"]
                    }
                }
            }
        }
    }

    saveMetrics() {
        var obj = this;
        var metricsData = this.setNotSavedMetricsToNew(this.tabulator.getData());

        var countDeleted = metricsData.filter(e => e[METRICS_MAPPING.FIELDS.ROW_STATUS] === METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.DELETED).length;
        this.formulateMetricData(metricsData);
        var query = {
            action: "saveMetrics",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            metrics: JSON.stringify(metricsData),
            overrideScenario: this.props.scenarioStatus !== SCENARIO_STATUS.SANDBOX,
            lastRow: obj.tabulator.getData().length === countDeleted ? true : false //using this on deleting the last row
        }
    
        let onThenCallback = () => {
            obj.setState({
                isChanged: false
            });
        }

        let onCompleteCallback = () => {
            obj.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveMetrics",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.metric_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.metric_mapping.requests_description.save_metrics
        }

        fetchAPI(fetchOptions);
    }

    onBackClick = () => {
        var obj = this;
        obj.props.goBackToStaging(false, obj.state.isChanged);
    }

    discardChangesAndGoBack(industry) {
        // var originalFields = null;
        // var modifiedFields = null;
        if(industry) {
            this.setStagingReport();
            return;
        }
    }

    setStagingReport() {
        this.setOpenConfirmBackCancelDialog(false)
        if(this.state.changed){
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
        } else {
            this.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE);
        }
    }

    updateScenario(manageExclusions){
        var obj = this;
        toggleLoader(true, "updateScenario");

        var object = {
            profitStackFields:  JSON.stringify(this.tabulator.getData()),
            mappedLines:JSON.stringify(this.state.mappedLines),
            deletedItems: JSON.stringify(this.state.toBeDeleted)
        };

        var query = {
            action: "updateScenario",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            scenarioSetting: JSON.stringify(object),
            scenarioSettingName: "profitStack"
        }
            setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
      $.ajax({
            url: baseUrl+path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                if(tryParse(result).result === 'SUCCESS') {
                    obj.setState({ isChanged: false });
                    obj.setOpenWarningDialog(true, "Your scenario has been updated!"); {/* NOT USED */}
                    return;
                }else{
                    obj.setOpenWarningDialog(true, "Something went wrong! please contact your system adminstration"); {/* NOT USED */}
                    return; 
                }
                 
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                toggleLoader(false, "updateScenario");
                if (!manageExclusions) {
                    obj.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
                } else {
                    obj.getProfitStackFields();
                    obj.getMappedLines();
                    obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION,'',true)
                }
            }
        });
    }
    
    createNewScenario(){
        toggleLoader(true, "createNewScenario");
        var obj = this;
        var object = {
            profitStackFields: JSON.stringify(this.state.profitStackFields),
            mappedLines: JSON.stringify(this.state.mappedLines),
            deletedItems: JSON.stringify(this.state.toBeDeleted),
            exclusionFilter:  "{\"filter\":"+ JSON.stringify(this.state.filter) +"}",
            excludedAccounts: this.state.accountNumbers,
            accountsType: this.state.accountsType
        };
        var query = {
            action: "createNewScenario",
            scenario_id: this.props.scenarioId,
            prefix: this.props.prefix,
            scenarioSetting: object,
            selectedStagingPeriods: this.props.selectedPeriod.value,
            scenarioSettingName: "profitStack"
        }
            setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
      $.ajax({
            url: baseUrl+path, 
            async: true, 
            type: 'POST', 
            crossDomain:true, 
            xhrFields: { withCredentials: true }, 
            data:JSON.stringify(query),
            success: function (result, response, xhr) {
                obj.setOpenWarningDialog(true, "A new scenario has been added to your sandbox!"); {/* NOT USED */}
                return;
            },
            error: function(error) {
                alertAndLogError(error);
            },
            complete: function(xhr, textStatus) {
                logoutIfUnauthenticated(xhr, obj);
                obj.props.setStagingReport(ALL_WIDGETS.FIELDS.STAGE, true);
                toggleLoader(false, "createNewScenario");
            }
        });
    }

    showScenarioPopUp(filter, accountNumbers, accountsType) {
        var obj = this;
        this.state.profitStackFields = this.tabulator.getData();
        this.setState({
            profitStackFields: this.state.profitStackFields,
            filter: filter || "",
            accountNumbers: accountNumbers || [],
            accountsType: accountsType || ""
        }, function(){
            obj.props.showScenarioPopUp();
        })
    }

    checkScenario(){
            if (this.isMetricMapping) {
            this.saveMetrics();
        }
    }

    /**
     * Recusive function to check if  cell of given cosKey in the cell has children or not 
    * @param {*} cell,  costKey
    */
    checkCellHasChildren(pssField, costKey){
        var obj = this;
        if (pssField[_costKey] === costKey){
            if(pssField[_children] && pssField[_children].length > 0){
                return 1;
            } else {
                return 0;                    }
        } else {
            var hasChildren = -1;
            if(pssField[_children] && pssField[_children].length > 0){
                var children = pssField[_children];
                for(var elt in children){
                    hasChildren = obj.checkCellHasChildren(children[elt],costKey);
                    if(hasChildren === 0 || hasChildren === 1 ) {
                        return hasChildren;
                    }
                }
            } else {
                return -1;
            }
                
        }
    }

    toggleTableColumnsForCalculated(isMapping=true) {
        if(!this.isProfitStackMapping) {
            return;
        }
        let showColumns = [PS_MAPPING.FIELDS.EXPAND, _costKey, _name, _costType, _amount];
        if(isMapping && this.isPSCalculated) {
            this.tabulator.getColumns().map(col=>col.hide());
            this.tabulator.getColumns().filter(col=>showColumns.includes(col.getField())).map(col=>col.show());     //showing these 4 columns

            this.tabulator.addFilter(_costType, "!=", costtype.attribute);
            $(".costkey_"+ this.state.pslLine[_costKey]).addClass("uk-hidden");    //we have to hide the costkey this way since a table rerender is not triggered

            //stop click event on cost center events
            $('.lined-amount').removeClass('uk-text-decoration-underline');
            $('.lined-amount').addClass('uk-disabled');
            $('.lined-amount').css('cursor','default');
        } else {
            this.tabulator.getColumns().map(col=>initialPSColumns.includes(col.getField()) ? col.show() : col.hide());
            this.tabulator.removeFilter(_costType, "!=", costtype.attribute);
            $(".costkey-draggable").removeClass("uk-hidden");

            //re-add clicking event to cost center amounts
            $('.lined-amount').addClass('uk-text-decoration-underline');
            $('.lined-amount').removeClass('uk-disabled');
            $('.lined-amount').css('cursor','pointer');
        }
    }

    saveChildSettings(cell) {
        var _this = this;
        var mappedLines = this.state.mappedLines;
        var pslLine = {};
        var costCenter = "";
        var fieldsCombination = [];
        var rowData = cell.getRow().getData();
        var cost_term_id = "";
        //if the leaf has been previously mapped before, take the data from the mapped list
        mappedLines.map(function(item){
            if(item[_costKey] === rowData[_costKey]) {
               pslLine = item;
               costCenter = item[_costCenter];
               fieldsCombination = item[_combinations];
               cost_term_id =  item[PS_MAPPING.FIELDS.COST_TERM_ID];
            }
        });

        //fetching the leading PSS id
        let leadingMappedPSLine = mappedLines.filter(e=>e[_costKey] === rowData[_costKey])[0];
        var leadingPssId = undefined;
        var leadingPssCostKey = undefined;
        var leadingPsLine = undefined;
        if (leadingMappedPSLine) {
            leadingPssId = leadingMappedPSLine[_leadingID];
            leadingPssCostKey = leadingMappedPSLine[_leadingCostKey];
            leadingPsLine = leadingPssId ? mappedLines.filter(e=>e[_id] === leadingPssId)[0] : undefined;
        }

        var unusedCalculatedCols = copyObjectValues(this.state.calculatedCols);
        var pssSiblings = leadingPssId ? this.getSiblings(leadingPssId, mappedLines) : [];      //fetching siblings of leading PS Line
        let exceptionPSLOptions = [];
        let psLeaves = getTreeLeaves(this.tabulator.getData());
        psLeaves.map(line=>{
            let mappedLeaf = findOptionByKeyValue(mappedLines, _costKey, line[_costKey]);
            let isMappedForCC = false;  //this var indicates if the line was added to mapped lines only for toggling the cost center
            if(mappedLeaf && mappedLeaf[_combinations] && !mappedLeaf[_combinations].length && mappedLeaf[_mappingException] === NONE && mappedLeaf[_id] === mappedLeaf[_leadingID]) {
                isMappedForCC = true;
            }
            
            let isSibling = findOptionByKeyValue(pssSiblings, _costKey, line[_costKey]);
            let isWeird = [costtype.calculated, costtype.attribute].includes(line[_costType]);    //it's also not standard :)
            if(!isSibling && mappedLeaf && mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]) {                //if this line is mapped, check if it has a calculated col, if it does, remove it from list of unusued cols
                let tempIndex = findIndexOfValue(unusedCalculatedCols, CALCULATED_COLUMNS.FIELDS.NAME, mappedLeaf[PS_MAPPING.EXCEPTION_FIELDS.CALCULATED_COL]);
                if(tempIndex !== -1) {
                    unusedCalculatedCols.splice(tempIndex, 1);
                }
            }
            
            if((!!isSibling || !mappedLeaf || isMappedForCC) && _this.isMappable(line) && !isWeird) {
                //if it is not mapped before, or is mapped and is a sibling, add it to leaves for psl options
                line = mappedLeaf || line;   //if mapped line exists (sibling), use it
                line.value = mappedLeaf ? mappedLeaf[_returnName] : line[_returnName];
                line.label = mappedLeaf ? mappedLeaf[_name] : line[_name];
                exceptionPSLOptions.push(copyObjectValues(line));
            }
        });

        //create PS line if not found in list of mapped lines
        pslLine = Object.getOwnPropertyNames(pslLine).length > 0 ? pslLine : 
            {
                [_id]: rowData[_id],
                [_costKey]: rowData[_costKey],
                [_parentCostKey]: rowData[_parentCostKey],
                [_name]: rowData[_name],
                [_returnName]: rowData[_returnName],
                [_costType]: rowData[_costType],
                [_mapFormula]: rowData[_mapFormula],
                [_combinations]: fieldsCombination,
                [_percentage]: "",
                [PS_MAPPING.FIELDS.RAW_FILE_FIELD_NAME]: "",
                [PS_MAPPING.EXCEPTION_FIELDS.FILE]: "",
                [PS_MAPPING.EXCEPTION_FIELDS.ANCILLARY_FILTER]: "",
                [PS_MAPPING.FIELDS.COST_TERM_ID]: cost_term_id,
                [_deleted]: "false"
            };

        $("input[name=costCentertype][value='"+costCenter+"']").prop('checked', true);
        this.setState({
            profitStackFields: this.tabulator.getData(),
            accountsType: ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION,
            glType: 'ALL',
            pslLine: leadingPsLine || pslLine,  //if not found in mappedLines, take it from built object
            mappedLine: pslLine,
            pssSiblings: pssSiblings,
            leadingPssId: !leadingPssId ?  rowData[_id] : leadingPssId,
            leadingPssCostKey: !leadingPssCostKey ?  rowData[_costKey] : leadingPssCostKey,
            cell: cell,
            exceptionPSLOptions: exceptionPSLOptions,
            costTerm: leadingPsLine ? leadingPsLine[PS_MAPPING.FIELDS.COST_TERM_ID] : pslLine ? pslLine[PS_MAPPING.FIELDS.COST_TERM_ID] : "",
            unusedCalculatedCols: unusedCalculatedCols
        });
    }

    getSiblings(leadingPssId, mappedLines) {
        var siblings = [];
        mappedLines.map(function(item){
            if (item[_leadingID] === leadingPssId) {
                item[_isMatched] = parseBoolean(item[_isMatched]);
                siblings.push(item);
            }
        });
        return siblings;
    }

    handleSelectMultiChange(option) {
        this.setState({
            selectedOptions: option
        });
    }

    showEntitiesAmount(cell) {
        var obj = this;
        var costKeyClicked = obj.state.mappedLines.filter(item => item[_costKey] === cell.getRow().getData()[_costKey])[0];

        this.setState({
            amount: costKeyClicked[PS_MAPPING.FIELDS.AMOUNT_DETAILS]
        });
    }

    refreshAmonts() {
        this.parseAmount(this.props.selectedPeriod.value);
        this.getTotals(this.props.selectedPeriod.value, false, true);
    }

    parseAmount(period, manageExclusions, loader) {
        //its false to show full loader
        var obj = this;
        obj.isParsingAmount = true;
        let periods = period ? [period] : [this.props.selectedPeriod.value]
        obj.tabulator.replaceData(obj.tabulator.getData());
        var lines = this.sortMappedLines(this.state.mappedLines)
        var query = {
            action: "parseAmount",
            mappedLines: JSON.stringify(lines),
            selectedPeriods: periods,
            scenario_id: this.props.scenarioId,
            costKey: this.props.costKey,
            clientName: this.props.clientName
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    this.logout();                  
                }
                return response.json()})
            .then((data)=>{
                obj.hasFetchedAmount = true;
                if(data){
                    data = tryParse(data.data);
                    var isYearBuilt = tryParse(data.isYearBuilt);
                    var mappedLines = obj.state.mappedLines;
                    var tempData = obj.tabulator.getData();
                    for(var elt in mappedLines) {
                        let filtered = data.filter(e => e["cost_key"]?.replace('Insert', '') === mappedLines[elt][_costKey].replace('Insert', '')
                            || e["costkey"]?.replace('Insert', '') === mappedLines[elt][_costKey].replace('Insert', ''));
                        mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT] = 0;
                        if(filtered.length > 0) {
                            mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT] = filtered[0].amount;
                            mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT_DETAILS] = filtered[0][PS_MAPPING.FIELDS.AMOUNT_DETAILS];    
                        }
                        tempData = obj.updateChildAttr(tempData, mappedLines[elt][_costKey], [PS_MAPPING.FIELDS.AMOUNT], mappedLines[elt][PS_MAPPING.FIELDS.AMOUNT]);
                    }

                    obj.state.mappedLines = obj.sortMappedLines(mappedLines);
                    obj.state.isYearBuilt = parseBoolean(isYearBuilt);
                    obj.tabulator.replaceData(tempData);
                    if (manageExclusions) {
                        obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION,'',true);
                    }
                    obj.isParsingAmount = false;
                    obj.forceUpdate();
                }
            }).catch((error)=>{
                alertAndLogError(error);
            }).then(() => {
                // toggleLoader(false, "parseAmount", loader);
                obj.tabulator.replaceData(obj.tabulator.getData());
            });
    }

    sortMappedLines(data) {
        var res = [];
        var res2 =[]
        for (var e in data) {
            if (data[e]["name"].includes("matched") && !data[e]["name"].includes("unmatched")) {
                res.push(data[e]);
            } else if (!data[e]["name"].includes("matched") && !data[e]["name"].includes("unmatched")) {
                res.push(data[e]);
            } else {
                res2.push(data[e])
            }
        }
        return res.concat(res2);
    }

    /**
     * This function retrieves the costkeys of all the descendents of an element
     * @param {*} parentCostkey 
     * @param {*} data 
     */
    getChildCostKeys(parentCostkey, data) {
        data = data || this.tabulator.getData();
        var costkeys = [];

        for(var row in data) {
            row = data[row];

            if(Number(row[_costKey]) === Number(parentCostkey)) {
                for (var child in row[_children]) {
                    child = row[_children][child];
                    costkeys.push(Number(child[_costKey]));

                    if(child[_children]) {
                        costkeys = costkeys.concat(this.getChildCostKeys(child[_costKey], row[_children]));
                    }
                }
            } else if(row[_children]) {
                costkeys = costkeys.concat(this.getChildCostKeys(parentCostkey, row[_children]));
            }
        }

        return costkeys;
    }

    getRowFromKey(key, requiredValue, rows) {
        rows = rows || this.tabulator.getRows();
        var row = {};

        for(var rowIndex in rows) {
            let loopedRow = rows[rowIndex];
            if(loopedRow.getData()[key] === requiredValue) {
                row = loopedRow;
                break;
            } else if(rows[rowIndex].getTreeChildren().length) {    //if the looped row has children, check if the required row is among them
                row = this.getRowFromKey(key, requiredValue, rows[rowIndex].getTreeChildren());
                if(row._row) {
                    //if the required row was found, exit the loop
                    break;
                }
            }
        }

        return row;
    }

    //please keep the following function commented, do not delete
    // getParentIndex(parentValue, data, attributeKey) {
    //     var index = -1;

    //     for(var row in data) {
    //         if(Number(data[row][attributeKey]) === Number(parentValue)) {
    //             index = row;
    //             break;
    //         }
    //     }

    //     return Number(index);
    // }

    getRowIndex(displayedRows, costKey) {
        var index = -1;
        displayedRows.forEach(function (item, key) {
            var costKeyItem = item.getData()[_costKey].replace("Insert", "");
            var costKeyEditted = costKey.replace("Insert", ""); //removing the insert text from costkey for the newly added rows

            if(Number(costKeyItem) === Number(costKeyEditted)) {
                index = Number(key);
                return false;
            }
        });

        return index;
    }
    
    showUploadStatusResponse(cell) {
        //pass response to parent
        var rowData = cell.getRow().getData();
        var response = JSON.parse(rowData["response"]);

        this.props.updateUploadStatusResponse(response);
    }

    shouldComponentUpdate(nextProps) {
        var shouldUpdate = true;
        return shouldUpdate;
    }

    modifyData(data, amount) {
        if(amount !== undefined) {
            data[0].assigned_amount = (Number(data[0].assigned_amount) + Number(amount)).toString();
        }
        return data;
    }

    getTotals(period, combFlag, isMappingChanged) {
        var obj = this;
        if(!isMappingChanged) {
            let parsedTotals = obj.state.periodTotals && obj.state.periodTotals.length ? obj.state.periodTotals.filter(e => e.time_periods === obj.props.selectedPeriod.value) : undefined;
            obj.setState({
                dataTotals: parsedTotals,
                dataTotals_id: shortid.generate()
            });
            return;
        }

        if (!$(".loading").not(".uk-width-1-3").is(":visible")) {
            toggleLoader(true, "getTotals", true);
        }
        obj.setState({
            isParsingTotalAmount: true
        });

        var query = {
            action: "getTotals",
            timePeriod: period ? period : this.state.periodName,
            scenario_id: this.props.scenarioId,
            mappedLines: this.state.mappedLines,
            periodsData: this.props.periodList
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      
        var tempState = {};
        fetch(`${baseUrl}${path}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response)=>{    
                if(response.status === 403) {
                    obj.logout();                  
                }
                return response.json()})
            .then((data)=>{
                if(data && data.data){
                    let parsedTotals = data.data.filter(e => e.time_periods === obj.props.selectedPeriod.value); // data.totals;                    
                    tempState.periodTotals = data.data;
                    tempState.dataTotals = parsedTotals;
                    tempState.dataTotals_id = shortid.generate();
                    tempState.isMappingChanged = !obj.state.isMappingChanged ? isMappingChanged ? true : false : true;
                    tempState.isParsingTotalAmount = false;
                    if(combFlag) {
                        obj.setOpenWarningDialog(true, "Your changes have been saved.");
                        return;
                    }
                }
            }).catch((error)=>{
                alertAndLogError(error);
                tempState.isParsingTotalAmount = false;
            }).then(()=>{
                toggleLoader(false, "getTotals", true);   
                this.setState(tempState);
            });
    }

    onTabulatorRenderComplete() {
        if(!this.tabulator) {
            return;
        }
        if(this.isPSCalculated === true) {
            this.tabulator.setHeight(convertPxToViewport(810));
        }
        this.reExpandCollapsedRows();
        this.redrawTable = false;
        setDraggable("costkey-draggable");
    }

    constructMenuDropDown() {
        var options = [];
        var stdobj = {};
        stdobj.displayName = "Standard Line"
        stdobj.id = "standard-line"
        options.push(stdobj);

        var calObj = {};
        calObj.displayName = "Calculated Line";
        calObj.id = "calculated-line";
        options.push(calObj);

        var attObj = {};
        attObj.displayName = "Attribute Line"
        attObj.id = "attribute-line"
        options.push(attObj);
        return options;
    }

    getTabulatorColumns(columns) {
        var obj = this;
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.field);

            if (col.field === PS_MAPPING.FIELDS.ADD) {
                if (this.isMetricMapping) {
                    col.titleFormatter = this.addNewRowTitleFormatter;
                    col.titleFormatterParams = { comp: this, funkName: this.onAddParentRow };
                } else {
                    col.headerFilter = "menuDropDown";
                    var options = {};
                    options.fields = obj.constructMenuDropDown();
                    options.id = "add-row-top-icon";
                    col = cleanUpSingleTabulatorColumn(col, options);
                }
            }
            if (col.field === PS_MAPPING.TITLES.MAPPED) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell, MESSAGES.profit_stack_map_mapped_title);
            }
            if (col.field === _costCenter) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter("By " + this.props.costCenter, MESSAGES.profit_stack_map_cc_title);
            }
            if (col.field === METRICS_MAPPING.FIELDS.COST_CENTER) {
                col.titleFormatter = (cell) => obj.formatTitle(cell);
            }
        });
        return columns;
    }

    formatTitle(cell) {
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = typeof cell === "string" ? cell : cell.getValue().replace("CostCenter", capitaliseFirstLetterAfterChar(this.props.costCenter));

        div.appendChild(p);
        return div;
    }

    addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = typeof cell === "string" ? cell : cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);

        p.appendChild(el);
        div.appendChild(p);
        return div;
    }  

    validateMetricData(metric, attr) {
        if(attr && (!metric[attr] || !metric[attr].length)) {
            //if an attribute is provided, only validate this attribute
            return false;
        } else if(!attr && (!metric[METRICS_MAPPING.FIELDS.VECTOR] || !metric[METRICS_MAPPING.FIELDS.VECTOR].length ||
            !metric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE] || !metric[METRICS_MAPPING.FIELDS.RAW_FILE_SUBTYPE].length ||
            !metric[METRICS_MAPPING.FIELDS.METRIC] || !metric[METRICS_MAPPING.FIELDS.METRIC].length)) {
                //if vector or ancillary file are not selected, do not send request
                return false;
        }
        return true;
    }

    isSubChild(data, costKey) {
        for (var e in data) {
            if (data[e][_costKey] === costKey) {
                return 1;
            } else{
                if (data[e][_children]) {
                    if(this.isSubChild(data[e][_children], costKey) !== 1){
                       continue;
                    } else {
                        return 1;
                    }
                }
            }
        }
}

    isGrandChild(data, costKey) {
        if(data && data !== null && data[_children]) {
            return this.isSubChild(data[_children], costKey) === 1 ? true : false;
        }
        return false;
    }

    generateStatus(status) {
        var obj = {};
        var periods = this.props.periods;
        for (var e in periods) {
            var key = periods[e].value;
            obj[key] = status;
        }    
        return obj;
    }

    getVectorList(isProfitStackMapping) {
        var obj = this;
        var query = {
            action: "getVectorList",
            scenario_id: obj.props.scenarioId,
            timePeriod: obj.state.periodName,
            vectors: "",
        }
      
        let onThenCallback = (response) => {
            if (response.data) {
                var vectorList = [];
                for (var i in response.data) {
                    if(isProfitStackMapping){
                        vectorList.push({value:response.data[i][_vectorName], usedValue: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE], label:response.data[i][_displayName], 
                        [RAW_ITEMS.SUBTYPE_ID]: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID], [RAW_ITEMS.FIELD_DATA_TYPE]: Formats.String, [RAW_ITEMS.SUBTYPE_NAME]: "vectors"});
                    }
                    else {
                        vectorList.push({value:response.data[i][_vectorName]+"Key", usedValue: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_KEY_VALUE], label:response.data[i][_displayName]+"Key", 
                        [RAW_ITEMS.SUBTYPE_ID]: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_KEY_SUBTYPE_ID], [RAW_ITEMS.FIELD_DATA_TYPE]: Formats.String, [RAW_ITEMS.SUBTYPE_NAME]: "vectors"});
                        vectorList.push({value:response.data[i][_vectorName]+"Name",usedValue: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_NAME_VALUE], label:response.data[i][_displayName]+"Name", 
                        [RAW_ITEMS.SUBTYPE_ID]: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_NAME_SUBTYPE_ID], [RAW_ITEMS.FIELD_DATA_TYPE]: Formats.String, [RAW_ITEMS.SUBTYPE_NAME]: "vectors"});
                        vectorList.push({value:response.data[i][_vectorName]+"Number", usedValue: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_VALUE], label:response.data[i][_displayName]+"Number", 
                        [RAW_ITEMS.SUBTYPE_ID]: response.data[i][VECTOR_MAPPING.FIELDS.VECTOR_NUMBER_SUBTYPE_ID], [RAW_ITEMS.FIELD_DATA_TYPE]: Formats.String, [RAW_ITEMS.SUBTYPE_NAME]: "vectors"});
                    }
                }
                
                obj.setState({
                    vectors: response.data,
                    vectorList: vectorList,
                    hiddenVectors:  response.hiddenVectors ?response.hiddenVectors: [] ,
                })
            }
       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getVectorList",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.stage.metric_mapping.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.stage.metric_mapping.requests_description.get_vector_list
        }
        fetchAPI(fetchOptions);

    }

    updateMappedMetric(newMetricData) {
        $(".add-row").removeClass("uk-hidden");
        // if(!this.validateMetricData(newMetricData)) {
        //     return;
        // }
        var obj = this;
        if(newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] === METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.EDITED && newMetricData[METRICS_MAPPING.FIELDS.STATUS] === STAGING_STATUS_ENUM.STAGED) {
            var status = this.generateStatus(STAGE_FIELDS.INVALID.toUpperCase());
            newMetricData["period_status"] = status;
            newMetricData[METRICS_MAPPING.FIELDS.STATUS] = STAGING_STATUS_ENUM.INVALID;
        }

        let tempData = copyObjectValues(this.state.metricFields);
        if(newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] === METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NEW) {
            newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] = METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED; //set the metric as 'NOT_SAVED' and not 'New' anymore
            newMetricData[METRICS_MAPPING.FIELDS.STATUS] = STAGING_STATUS_ENUM.NOT_STAGED;
            var status = this.generateStatus(STAGE_FIELDS.NOT_STAGED.toUpperCase());
            newMetricData["period_status"] = status;
            tempData.unshift(newMetricData);
        } else if(newMetricData["period_status"][this.state.periodName].toUpperCase() === STAGE_FIELDS.NOT_STAGED.toUpperCase()) {
            var status = this.generateStatus(STAGE_FIELDS.NOT_STAGED.toUpperCase());
            if(newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] !== METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED) {
                newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] = METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.EDITED;
            }
            newMetricData["period_status"] = status;
            tempData[this.state.mappedMetricPosition] = newMetricData;
        }else {
            // var status = this.generateStatus('INVALID');
            newMetricData["period_status"][this.state.periodName] = STAGE_FIELDS.INVALID.toUpperCase();
            newMetricData[METRICS_MAPPING.FIELDS.ROW_STATUS] = METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.EDITED;
            tempData[this.state.mappedMetricPosition] = newMetricData;
        }

        newMetricData[METRICS_MAPPING.FIELDS.METRIC_VALUE] = 0;
        newMetricData[METRICS_MAPPING.FIELDS.FETCH_DATA] = true;

        this.setState({
            mappedMetric: newMetricData,
            metricFields: tempData,
            isChanged: true
        }, function() {
            //If record does not exist in tabulator and is new add new row else update row using index
            if(this.state.mappedMetric[METRICS_MAPPING.FIELDS.ROW_STATUS] === METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED && !this.tabulator.getData().filter(e => e.index === this.state.mappedMetric["index"]).length){
                obj.tabulator.addRow(this.state.mappedMetric,false)
            } else {
                obj.tabulator.updateRow(this.state.mappedMetric["index"],this.state.mappedMetric)
            }   
            this.abortGetMetricsDataRequest();
        });
        
    }

    setLineToMap(lineType, cell, newRow) {
        var tempState = {};

        if(lineType === STAGING_SECTIONS.PROFIT_STACK_MAPPING) {
            tempState.profitStackLine = cell.getRow().getData()[_name];
            tempState.profitStackLineToMap = cell.getRow().getData();
            tempState.onElementChange = false;
            tempState.onFunctionChange = false;
        } else if (lineType === STAGING_SECTIONS.METRICS_MAPPING) {
            if(newRow) {
                tempState.mappedMetric = newRow;
                tempState.mappedMetricPosition = 0;
                tempState.filter = "";
            } else {
                tempState.mappedMetric = cell.getRow().getData();
                tempState.mappedMetricPosition = cell.getRow().getPosition();
                tempState.filter = cell.getRow().getData()[METRICS_MAPPING.FIELDS.FILTER];  //save metric filter in state when clicking to map a metric
            }
        }

        this.setState(tempState);
    }

    isMappable(rowData) {
        if(![PSL_RETURN_NAMES.UNITS,PSL_RETURN_NAMES.LINES,PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS, PSL_RETURN_NAMES.VARCOGS,PSL_RETURN_NAMES.VARREVENUE]
            .includes(rowData[_returnName].toLowerCase()) && rowData[PS_MAPPING.FIELDS.AC_TYPE] !== PS_MAPPING.FIELDS.AC_TYPES.CALCULATED) {
                return true;
        }

        return false;
    }

    changeVisibiltyOfLine(e, rowData) {
        var obj = this;
        var data = obj.tabulator.getData();

        var inProfitMap = false;
        var excludeFromPs = true;
        var includeInPMTotals = false;

        if (e.target.id === "hidden-attribute") {
            inProfitMap = false;
            excludeFromPs = true;
            includeInPMTotals = false;
        } else {  
            inProfitMap = true;
            excludeFromPs = false;
            includeInPMTotals = true;
        }

        data.map((line) => {
            if (rowData[_costKey] === line[_costKey]) {
                line[_inProfitMap] = inProfitMap;
                line[_excludeFromPs] = excludeFromPs;
                line[_includeInPMTotals] = includeInPMTotals;  
            }
        });

        this.setState({
            profitStackFields: copyObjectValues(data)
        }, function () {
            obj.tabulator.replaceData(data);
        });

        
    }

    hideDropDown() {
        UIkit.dropdown($('.in-dropdown-container.uk-dropdown.uk-open')).hide();
    }

    returnMenuDropDown(cell, rowData, type, callback) {
        var obj = this;
        var parentDiv = document.createElement("div");
        parentDiv.classList.add("uk-inline");
        parentDiv.id = "add-child-row-"+rowData[_costKey];

        var icon = document.createElement("i");
        var iconParent = document.createElement("button");
        var dropDownDiv = document.createElement("div");
        iconParent.classList.add("uk-button-icon", "transparent-bg");
        dropDownDiv.classList.add("in-dropdown-container");
        dropDownDiv.id = "in-dropdown-container-"+rowData[_costKey];
        
        if (rowData[_costType] === costtype.attribute) {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:top-right; offset: 5');
        } else {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:bottom-right; offset: 5');
        }

        var optionChildDiv = document.createElement("div");

        if (type === "add") {
            iconParent.appendChild(icon);
            parentDiv.appendChild(iconParent);
            icon.classList.add("far", "fa-plus-circle", "fa-lg");
            if (cell.getRow().getData()[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                icon.classList.add("disabled");
                iconParent.classList.add("disabled");
            }

            var spanDiv = document.createElement("span");
            spanDiv.id = "child-standard-line";
            spanDiv.classList.add("uk-button-icon")
            spanDiv.textContent = "Add Standard Line";
            spanDiv.onclick = () => {
                callback();
            };

            optionChildDiv.append(spanDiv);
            dropDownDiv.append(optionChildDiv);
        } else if (type === "edit") {
            var dotsButton = document.createElement("button");
            dotsButton.classList.add("uk-button-icon","dots-button","transparent-bg")
            icon.classList.add("fal", "fa-2x","fa-ellipsis-v", "fa-lg");
            
            dotsButton.appendChild(icon);

            var editFunction = function () {
                obj.hideDropDown();
                obj.saveChildSettings(cell);
                obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL', false);
            }

            var deleteFunction = function () {
                obj.hideDropDown();

                obj.showDeleteRowModal(cell.getRow().getData());
                obj.setState({
                    row: cell.getRow().getData()
                });
            }

            if(rowData[_costType] === costtype.calculated) {
                editFunction = function(){
                    obj.hideDropDown();
                    obj.saveChildSettings(cell);
                    obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                    obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL', false);
                };
            } else if(rowData[_costType] === costtype.attribute) {
                editFunction = function () {
                    obj.props.disableHeaderButtons(true);  
                    obj.hideDropDown();
                    obj.setLineToMap(STAGING_SECTIONS.PROFIT_STACK_MAPPING, cell);
                    obj.showAttributeFormula();
                };
            }
            
            if (rowData[_costType] === costtype.attribute) {
                var visibilityFunction = function (e) {
                    obj.hideDropDown();
                    obj.changeVisibiltyOfLine(e, rowData);
                }

                var showHideAction = obj.returnLineAction(ACTION_TYPE.SHOW_HIDE, "Hidden Attribute", "fa-eye-slash", cell, rowData, visibilityFunction);
                optionChildDiv.append(showHideAction);
            }
            var mapAction = obj.returnLineAction(ACTION_TYPE.MAP, "Map", "fa-edit", cell, rowData, editFunction);
            var deleteAction = obj.returnLineAction(ACTION_TYPE.DELETE, "Delete", "fa-trash-alt", cell, rowData, deleteFunction);
            
            optionChildDiv.append(mapAction);
            optionChildDiv.append(deleteAction);
            dropDownDiv.append(optionChildDiv);
            parentDiv.append(dotsButton);

        }
        parentDiv.append(dropDownDiv);

        return parentDiv;
    }

    returnLineAction(type, text, iconClass, cell, rowData, callback) {
        var obj = this;
        var disableAction = false;
        var hideAction = false;
        var dotsParent = document.createElement("button");
        dotsParent.classList.add("uk-button-icon");
        
        var icon = document.createElement("i");
        var span = document.createElement("span");

        if (type === ACTION_TYPE.SHOW_HIDE) {
            span.setAttribute("uk-tooltip", MESSAGES.profit_stack_mapping_attribute_show_hide);
            dotsParent.classList.add("uk-border-bottom");

            var inProfitMap = rowData[_inProfitMap];
            var excludeFromPs = rowData[_excludeFromPs];
            var includeINPmTotals = rowData[_includeInPMTotals];

            if (inProfitMap && !excludeFromPs && includeINPmTotals) {
                text = "Hide Attribute";
                iconClass = "fa-eye-slash";
                span.id = "hidden-attribute";
            } else {
                text = "Show Attribute";
                iconClass = "fa-eye";
                span.id = "visible-attribute";
            }

        } else if (type === ACTION_TYPE.MAP) {
            if ([costtype.attribute].includes(rowData[_costType]) && parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                disableAction = true;
            } else if ([costtype.attribute, costtype.calculated].includes(rowData[_costType]) && !parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                    disableAction = true;
                }
            } else { 
                var mappedLines = obj.state.mappedLines;
                var hideMapIcon = false;
                var unmatchedLine = mappedLines.filter(item => item[_costKey] === rowData[_costKey] && parseBoolean(item[_isMatched]) === false)[0];
                if (unmatchedLine) {
                    hideMapIcon = true;
                    hideAction = true;
                }

                if (!rowData[PS_MAPPING.FIELDS.IS_NOT_AC] && !unmatchedLine) {
                    if (!hideMapIcon) {
                        var costKey = rowData[_costKey];
                        var hasChildren = -1;
                        var table = cell.getRow().getTable();
                        var pssFields = table.getData();

                        for (var e in pssFields) {
                            hasChildren = obj.checkCellHasChildren(pssFields[e], costKey);
                            if (hasChildren === 0 || hasChildren === 1)
                                break;
                        }
                        if (((hasChildren === 0 && (rowData[_returnName] === undefined || obj.isMappable(rowData))) && !hideMapIcon)) {
                            if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                                disableAction = true;
                            }
                        } else {
                            hideAction = true;
                        }
                    }
                }
            }
        } else if (type === ACTION_TYPE.DELETE) {
            // $(dotsParent).attr("data-toggle", "modal");
            // $(dotsParent).attr("data-target", "#confirmDeleteLine");

            var unmatchedLine = obj.state.mappedLines.filter(item => item[_costKey] === rowData[_costKey] && parseBoolean(item[_isMatched]) === false)[0];
            var disabledIcon = false;
            if(unmatchedLine) {
                disabledIcon = true;
            }
            if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                disabledIcon = true;
            }

            if ([GR,NR,COGS,CostOfSales].includes(rowData[_name]) 
            || [PSL_RETURN_NAMES.UNITS,PSL_RETURN_NAMES.LINES,PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS].includes(rowData[_returnName].toLowerCase())
            || rowData[PS_MAPPING.FIELDS.AC_TYPE] === PS_MAPPING.FIELDS.AC_TYPES.CALCULATED
                || disabledIcon) {
                    disableAction = true;
            }
        }

        if (disableAction) {
            dotsParent.classList.add("disabled"); 
        }

        if (hideAction) {
            dotsParent.classList.add("hidden"); 
        }

        icon.classList.add("fal", iconClass, "uk-margin-small-right");
        span.textContent = text;

        dotsParent.append(icon);
        dotsParent.append(span);
        dotsParent.onclick = (e) => {
            if (callback) {
                callback(e);
            }
        }

        return dotsParent;
    }

    getParentAmount(rowData, ck) {
        let _this = this;
        let parentAmount = 0;
        this.state.mappedLines.map((line) => {
            if(line[_costKey].toString().replace("Insert","") === ck.replace("Insert","") && line[_deleted] === 'false'){
                parentAmount = is_aN(line[PS_MAPPING.FIELDS.AMOUNT]) ? parseFloat(line[PS_MAPPING.FIELDS.AMOUNT]) : 0;
            } else if (line[_parentCostKey] === ck || _this.isGrandChild(rowData, line[_costKey])) {
                parentAmount += is_aN(line[PS_MAPPING.FIELDS.AMOUNT]) ? parseFloat(line[PS_MAPPING.FIELDS.AMOUNT]) : 0;
            }
        });

        return parentAmount;
    }

    getColumnFormatter(colField) {
        var columnFormatter;
        var obj = this;            
        switch(colField) {
            case _name:
                columnFormatter = function (cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var divParent = document.createElement("div");
                    var div = document.createElement("div");
                    var p = document.createElement("p");
                    p.textContent = cell.getValue();
                    let mappedLines = obj.state.mappedLines;
                    let unmatchedLine = mappedLines.filter(line => line[_costKey] === rowData[_costKey] && parseBoolean(line[_isMatched]) === false)[0];

                    if(!obj.hasFetchedAmount) {
                        cell.getElement().classList.add("no-events-cells");     //disabling editing before amounts are back
                    }

                    $(p).css("cursor","pointer");
                    $(p).css("font-weight","bold");

                    div.classList.add("uk-text-overflow-ellipsis");
                    p.classList.add("uk-text-overflow-ellipsis");

                    if(rowData["level"] !== 1 && rowData["level"] !== 2 && rowData["level"] !== 3) {
                        var pixels = (rowData["level"]-1)*5;
                        $(p).css("padding-left", convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) {
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                    } else if (rowData["level"] === 2){
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color":"#cacaca"});
                    }
                    if (rowData && unmatchedLine){
                        $(p).css("cursor", "not-allowed");
                    }
                    div.appendChild(p);
        
                    if (rowData["new"] && rowData["new"] === "1") {
                        var label = document.createElement('label');
                        label.textContent = "new";
                        label.classList.add("red", "uk-padding-remove-left", "uk-margin-small-left");
                        div.classList.add("uk-responsive-medium-width");
                        divParent.classList.add("uk-display-inline-flex");

                        divParent.appendChild(div);
                        divParent.appendChild(label);
    
                        return divParent;
                    }
                    return div;
                }
                break;
            case METRICS_MAPPING.FIELDS.VECTOR_DISPLAY:
            case METRICS_MAPPING.FIELDS.METRIC:
                columnFormatter = function (cell) {
                    var div = document.createElement("div");
                    var p = document.createElement("p");
                    p.innerHTML = typeof cell === "string" ? cell : cell.getValue();
            
                    var el = document.createElement("i");
                    p.setAttribute("uk-tooltip",cell.getRow().getData()[METRICS_MAPPING.FIELDS.DESCRIPTION] && cell.getRow().getData()[METRICS_MAPPING.FIELDS.DESCRIPTION] !== ""?
                      "title: Name: " +cell.getValue()+" <br> Description: "+cell.getRow().getData()[METRICS_MAPPING.FIELDS.DESCRIPTION]+";pos:bottom" : "title: Name: " +cell.getValue());
                    p.classList.add("underline-onhover");
                    p.appendChild(el);
                    div.appendChild(p);
                    div.classList.add("uk-text-overflow-ellipsis")
                    return div
                }
            break;
            case PS_MAPPING.FIELDS.ADD:
                columnFormatter = function (cell) {
                    if (obj.isMetricMapping) {
                        var div = document.createElement("div");
                        var el = getTableIcon(["fal", "fa-sliders-h", "fa-lg"])
                        el.title = "Map";
                        // if (cell.getRow().getData()[METRICS_MAPPING.FIELDS.RULE] && cell.getRow().getData()[METRICS_MAPPING.FIELDS.RULE] !== '') {
                        //     el.classList.add("disabled");
                        //     div.title = MESSAGES.mapped_metric_cant_be_edited.replace('{x}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.METRIC]).replace('{y}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.COST_KEY]);
                        // }
                     
                        var rowDataCC = cell.getRow().getData().is_cost_center;
                        this.rowDataCC = rowDataCC
                        el.onclick = () => {
                            $("#load-metric-value").addClass("disabled uk-disabled")
                            obj.setLineToMap(STAGING_SECTIONS.METRICS_MAPPING, cell);
                            obj.showGLAccounts(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL','',rowDataCC);
                            obj.showValueFormula(ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION, 'ALL');
                            if(obj.glRef.state.mappedMetric && (!obj.glRef.state.mappedMetric.rule || obj.glRef.state.mappedMetric.rule === '')){ //Make rule validation take precedence over cost center validation
                                obj.glRef.checkForCostCenter();
                            }
                            newMetric = false;
                        }
                        div.appendChild(el);
                        return div;
                    } else {
                        var rowData = cell.getRow().getData();

                        var isRowEmpty = rowData[_costKey] === undefined;
                        if(isRowEmpty) {	//if row is empty, do not add any elements
                            return "";
                        }

                        this.rowData = rowData;
                        this.row = cell.getRow();
                        let mappedLines = obj.state.mappedLines;
                        let unmatchedLine = mappedLines.filter(line => line[_costKey] === rowData[_costKey] && parseBoolean(line[_isMatched]) === false)[0];
                       
                        if(rowData[_returnName] === undefined || 
                            ([PSL_RETURN_NAMES.UNITS,PSL_RETURN_NAMES.LINES,PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS,PSL_RETURN_NAMES.VARCOGS,PSL_RETURN_NAMES.VARREVENUE].indexOf(cell.getRow().getData()[_returnName].toLowerCase()) === -1
                            && !unmatchedLine
                            && ![costtype.calculated, costtype.attribute].includes(rowData[_costType]))
                        ) {

                            var parentDiv = obj.returnMenuDropDown(cell, rowData, "add", function () {
                                obj.onAddChild(rowData, rowData[_costKey], cell.getRow(), rowData["level"]);
                            });
                            
                            return parentDiv;
                        }  
                    }

                };
                break;
            case PS_MAPPING.FIELDS.ACTION:
                columnFormatter = function (cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var parentDiv = obj.returnMenuDropDown(cell, rowData, "edit");
                    var originalKey = parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY]);

                    if([costtype.attribute].includes(rowData[_costType]) && originalKey) {
                        var mappedLines = obj.state.mappedLines;
                        var hideMapIcon = false;
                        var unmatchedLine = mappedLines.filter(item => item[_costKey] === rowData[_costKey] && parseBoolean(item[_isMatched]) === false)[0];
                        if (unmatchedLine) {
                            hideMapIcon = true;
                        }

                        if (rowData[_name] !== undefined && !unmatchedLine
                            && unmatchedLine && !hideMapIcon) {
                            var countBtn = document.createElement("button");
                            countBtn.textContent = "Count";
                            countBtn.classList.add("pssMappingButton");
                            if (rowData[_returnName] !== undefined && !rowData[_returnName].toLowerCase().includes("units")) {
                                countBtn.classList.add("uk-disabled");
                            }
                            if (rowData[PS_MAPPING.FIELDS.NAME_IN_FACT]!==undefined && rowData[PS_MAPPING.FIELDS.NAME_IN_FACT].toUpperCase().includes('COUNT')) {
                                countBtn.classList.add("checked");
                            }
                            var sumBtn = document.createElement("button");
                            sumBtn.textContent = "Sum";
                            sumBtn.classList.add("pssMappingButton");
                            if (rowData[_returnName] !== undefined && !rowData[_returnName].toLowerCase().includes("units")) {
                                sumBtn.classList.add("uk-disabled");
                            }
                            if (rowData[PS_MAPPING.FIELDS.NAME_IN_FACT]!==undefined && rowData[PS_MAPPING.FIELDS.NAME_IN_FACT].toUpperCase().includes('SUM')) {
                                sumBtn.classList.add("checked");
                            }
                        
                            countBtn.onclick = () => {
                                var regEx = new RegExp("SUM", "ig");
                                cell.getRow().getData()[PS_MAPPING.FIELDS.NAME_IN_FACT] = rowData[PS_MAPPING.FIELDS.NAME_IN_FACT].replace(regEx, "COUNT");
                                sumBtn.classList.remove('checked');
                                countBtn.classList.add('checked');
                            };
                            sumBtn.onclick = () => {
                                var regEx = new RegExp("COUNT", "ig");
                                cell.getRow().getData()[PS_MAPPING.FIELDS.NAME_IN_FACT] = rowData[PS_MAPPING.FIELDS.NAME_IN_FACT].replace(regEx, "SUM");
                                countBtn.classList.remove('checked');
                                sumBtn.classList.add('checked');
                            };
                            var actionsContainer = document.createElement("div");
                            actionsContainer.appendChild(countBtn);
                            actionsContainer.appendChild(sumBtn);
                            
                            var totalDiv = document.createElement("div");
                            totalDiv.classList.add("uk-display-inline-flex", "uk-flex-middle");
                            parentDiv.classList.add("uk-margin-small-left");

                            totalDiv.append(actionsContainer);
                            totalDiv.append(parentDiv);

                            return totalDiv;
                        }
                    } else {
                        return parentDiv;
                    }             
                }
            break;
            
            case PS_MAPPING.FIELDS.FILTER:
            columnFormatter = function(cell, formatterParams) {
                var costKey = cell.getRow().getData()[_costKey];
                var mappedLines = obj.state.mappedLines;
                var filter = "";
                mappedLines.map(function(item){
                    if(item[_costKey] === costKey && item[_deleted] === "false"){
                        if(item["filter"]) {
                            var tempFilter= [];
                            var values = [];
                            var parsedFilter =  JSON.parse(item["filter"]).filter;
                            for(var e in parsedFilter) {
                                var values = [];
                                if (typeof parsedFilter[e].value === "object") {
                                    parsedFilter[e].value.forEach(function(val, key){
                                        values.push(val.value.toLowerCase());    //setting everything to lower case so that the filter becomes case insensitive
                                    });
                                    tempFilter.push(parsedFilter[e].field + " " + parsedFilter[e].operator + " " + ( values.length > 0 ? values.join(",") : "''"));
                                }
                            }
                            filter = tempFilter.join(", ");
                        }
                    }
                });
                var p = document.createElement("p");
                p.textContent = filter;
                p.title = filter;
                $(p).css("font-weight","bold")
                return p;
            }
            break;

            case _costCenter:
            columnFormatter = function(cell) {
                var rowData = cell.getRow().getData();
                var costKey = rowData[_costKey];
                var costCenter = "";
                var file = "";
                var mappingException = "";
                let line = obj.state.mappedLines.filter(item=>item[_costKey] === costKey && (item[_deleted] === 'false' || item[_deleted] === undefined))[0];

                if(!!line) {
                    line[_costCenter] = line[_mappingException] === TRANSACTION ? 'ALL' : line[_costCenter];
                    costCenter = line[_mappingException] === TRANSACTION ? 'ALL' : line[_costCenter];
                    file = line[PS_MAPPING.EXCEPTION_FIELDS.FILE];
                    mappingException = line[_mappingException];
                }

                var result = obj.isDisabledToggleButton(file, line);
                //show toggle for AC type = assignable and children that don't have acType, no parents, no unmatched and no acType in (calculated, attribute)
                if([PS_MAPPING.FIELDS.AC_TYPES.ASSIGNABLE, undefined].includes(rowData[PS_MAPPING.FIELDS.AC_TYPE])
                // && !rowData[_name].toLowerCase().includes("unmatched")
                && ![costtype.calculated, costtype.attribute].includes(rowData[_costType])
                && (!rowData[_children] || !rowData[_children].length)) {
                    var p = document.createElement("p");
                    var input = document.createElement("input");
                    var p2 = document.createElement("p");

                    input.type = "checkbox";
                    input.name="FY";
                    input.id = "toggle_button"+ rowData[_costKey]
                    input.value = costCenter !== "ALL" && costCenter !== ""  ? FY_VALUES.ON : FY_VALUES.OFF ;
                    input.checked = costCenter !== "ALL" && costCenter !== "" ? true : false;
                    input.onchange = ()=>{obj.changeSwitch(cell)};
                    input.style.display = 'none';

                    var span = document.createElement("span");
                    span.classList.add('sliderPSS', 'round');
                    span.onclick = ()=>{obj.changeSwitch(cell)};
                    if(result) {
                        p2.title = MESSAGES.profit_stack_no_file_cost_center.replace(new RegExp('\\[Branch]', 'g'), obj.props.costCenter);
                        span.onclick = null;
                        span.classList.add("disabled");
                    }
                    if(mappingException === TRANSACTION) {
                        span.classList.add("disabled");
                        p2.title = MESSAGES.transaction_no_cost_center;
                    }
                    if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                        span.classList.add("disabled");
                    }
                    
                    $(p).css("fs-12","x-small");
                    p.classList.add("uk-margin-right");
                    p2.appendChild(p);
                    p2.append(input);
                    p2.append(span);
                    return p2;
                } else {
                    return "";
                }
            }
            break;

            case PS_MAPPING.TITLES.MAPPED:
                columnFormatter = function(cell) {
                    var costKey = cell.getRow().getData()[_costKey];
                    var exception = "";
                    var mappedLines = obj.state.mappedLines;
                    mappedLines.map(function(item){
                        if(item[_costKey] === costKey) {
                            exception = item[_mappingException];
                        }
                    });
                    var p = document.createElement("p");
                    p.textContent = !exception || exception === NONE ? "" : exception.charAt(0).toUpperCase();
                    return p;
                }
                break;

                case PS_MAPPING.FIELDS.COST_TERM:
                columnFormatter = function(cell, formatterParams) {
                    var costKey = cell.getRow().getData()[_costKey];
                    var costTerm = "";
                    var mappedLines = obj.state.mappedLines;
                    mappedLines.map(function(item){
                        if(item[_costKey] === costKey){
                            costTerm = item[PS_MAPPING.FIELDS.COST_TERM_ID];
                        }
                    });
                    var label = " ", tooltip = "Undefined";
                    if (costTerm && costTerm !== "") {
                        var options = obj.props.clientCostTerms;
                        label = options.filter(e=>e.cost_term_id.toString() === costTerm.toString())[0].psLabel;
                        tooltip = options.filter(e=>e.cost_term_id.toString() === costTerm.toString())[0].cost_term_value;
                    }
                    var p = document.createElement("p");
                    p.textContent = label; 
                    p.setAttribute("uk-tooltip", "title:" + tooltip);
                    return p;
                }
                break;

            case METRICS_MAPPING.FIELDS.COST_CENTER:
            columnFormatter = function(cell, formatterParams) {
                var p = document.createElement("p");
                p.textContent = parseBoolean(cell.getValue()) ? CC_LABELS.YES : CC_LABELS.NO;
                return p;
            }
            break;

            case METRICS_MAPPING.FIELDS.STATUS:
                columnFormatter = function(cell, formatterParams) {
                    if(STAGING_STATUS_ENUM[cell.getValue()])
                        return STAGING_STATUS_ENUM[cell.getValue()];
                    else return cell.getValue();
                }
                break;
            case METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION:
                columnFormatter = function(cell, formatterParams) {
                  return cell.getRow().getData()[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION];
                }
                break;
            case METRICS_MAPPING.FIELDS.DELETE:
                columnFormatter = function (cell, formatterParams) {
                    var el = getTableIcon(["fal","fa-trash-alt", "fa-lg"]);
                    var div = document.createElement("div");
                    if(cell.getRow().getData()[METRICS_MAPPING.FIELDS.ROW_STATUS] === ROW_STATUS.VALUES.DELETED){
                        cell.getRow().getElement().style.display = "none";
                    }
                    if (cell.getRow().getData()[METRICS_MAPPING.FIELDS.RULE] && cell.getRow().getData()[METRICS_MAPPING.FIELDS.RULE] !== '') {
                        el.classList.add("disabled");
                        div.title = MESSAGES.mapped_metric_cant_be_deleted.replace('{x}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.METRIC]).replace('{y}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.COST_KEY]);
                    }
                    if (cell.getRow().getData()[METRICS_MAPPING.FIELDS.LINE]){
                        el.classList.add("disabled");
                        div.title = MESSAGES.mapped_metric_pss_cant_be_deleted.replace('{x}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.METRIC]).replace('{y}',cell.getRow().getData()[METRICS_MAPPING.FIELDS.LINE]) + "\n" + MESSAGES.used_ps_no_delete_2;
                    }
                    el.title = "Delete";
                    el.onclick = () => {
                        obj.setState({
                            row: cell.getRow().getData()
                        });
                        obj.setOpenConfirmDeleteLineDialog(true)
                    };
                    div.appendChild(el);
                    return div;
            }
            break;
            case METRICS_MAPPING.FIELDS.DETAILS:
                columnFormatter = function(cell) {
                    var div = document.createElement("div");
                    var p = document.createElement("p");
                    p.textContent = "More";
                    p.classList.add("uk-text-decoration-underline","uk-cursor-pointer");
                    var row = cell.getRow().getData();
                    p.onclick=() =>{
                        obj.setState({
                            showDetails: true,
                            clickedCell:row
                        },function(){
                            obj.getMetricsData([row], "getMetricsDetails");
                            obj.viewMoreRef.setOpenDialog(true)
                        })
                    }
                    div.appendChild(p);
                    return div;
                }
                break;
            case PS_MAPPING.FIELDS.AMOUNT:
            columnFormatter = function(cell) {
                var rowData = cell.getRow().getData();
                var costKey = rowData[_costKey];
                var mappedLines = obj.state.mappedLines;
                var amount = obj.getParentAmount(rowData, costKey);
                var costCenter = "";
                var driverType = ""; 
                var file = "";
                let amountDetails = [];
                let line = mappedLines.filter(item => item[_costKey].toString().replace("Insert","") === costKey.replace("Insert","") && item[_deleted] === 'false')[0];
                let isUnmatched = false;
                if(!!line) {
                    costCenter = line[_costCenter];
                    file = line[_file];
                    driverType = line[_driverType];
                    amountDetails = line[PS_MAPPING.FIELDS.AMOUNT_DETAILS];
                    isUnmatched = parseBoolean(line[_isMatched]) === false;
                }
                
                if([PSL_RETURN_NAMES.GROSS_PROFIT, PSL_RETURN_NAMES.NET_PROFIT].indexOf(rowData[_returnName]) === -1){
                    var p = document.createElement("p");
                    if(obj.isParsingAmount) {
                        let img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width=convertPxToViewport(15);
                        img.style.height=convertPxToViewport(15);
                        p.appendChild(img);
                    } else {
                        amount = amount === 0 ? "" : amount.toString();
                        p.innerHTML = formatValHTML(amount, FormatTypes.AMOUNT);
                        p.title = formatValString(amount, FormatTypes.AMOUNT);
                        if(Number(amount)< 0) {
                            p.classList.add("red");
                        }

                        let leadingLine = line;
                        if(isUnmatched && line[PS_MAPPING.FIELDS.ACCRUAL_STATUS] !== ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL) {
                            leadingLine = mappedLines.filter(item => item[_id] === leadingLine[_leadingID])[0];
                            file = leadingLine[_file];
                            driverType = leadingLine[_driverType];
                        }
                        let hasMissingFile = !!leadingLine && leadingLine[_mappingException] === TRANSACTION && obj.state.missingPeriodFiles.includes(leadingLine[_calcCol]);
                        var unstagedMetric = driverType === _metric && obj.state.exceptionMetrics.filter(r=>r.value === file).length > 0
                                && obj.state.exceptionMetrics.filter(r=>r.value === file)[0].period_status[obj.props.selectedPeriod.value].toUpperCase() !== METRICS_MAPPING.METRIC_STAGING_STATUS.STAGED;
                        if (unstagedMetric || hasMissingFile) {
                            p.innerHTML = "";
                            var icon = document.createElement("i");
                            icon.classList.add("fas","fa-exclamation", "pi-text-yellow","fa-lg");
                            p.setAttribute("uk-tooltip", hasMissingFile ? MESSAGES.calc_col_file_missing_in_period + obj.props.selectedPeriod.value : MESSAGES.not_staged_metric);
                            p.appendChild(icon);
                        }
                        if (rowData[PS_MAPPING.FIELDS.ACCRUAL_STATUS] === ACCRUALS.FIELDS.STATUS_VALUES.ACCRUAL && !obj.state.isYearBuilt) {
                            p.innerHTML = formatValHTML("N/A", FormatTypes.TEXT);
                        } else {
                            if (costCenter && costCenter !== "" && costCenter !== Type.ALL) {
                                $(p).addClass("lined-amount");
                                if(amountDetails && amountDetails.length > 0 && !unstagedMetric) {
                                    if(obj.isPSCalculated) {
                                        $(p).addClass('uk-disabled');
                                        $(p).css('cursor','default');
                                    } else {
                                        $(p).addClass("uk-text-decoration-underline");
                                        $(p).css("cursor","pointer");
                                    }
                                    p.onclick = () => obj.showEntitiesAmount(cell);
                                    $(p).attr("data-toggle", "modal");
                                    $(p).attr("data-target", "#amountModal");
                                } else {
                                    p.title = MESSAGES.profit_stack_refresh_amounts;
                                }
                            }
                        }
                    }
                }
                return p;
            }
            break;
            case METRICS_MAPPING.FIELDS.METRIC_VALUE:
                columnFormatter = function(cell) {
                    let rowData = cell.getRow().getData();
                    var amount = rowData[METRICS_MAPPING.FIELDS.METRIC_VALUE] || "";
                    var fetchData = rowData[METRICS_MAPPING.FIELDS.FETCH_DATA] || "";
                    if(obj.isParsingAmount && (fetchData || obj.state.isChangedPeriod)) {
                        var p = document.createElement("p");
                        let img = document.createElement("i");
                        img = document.createElement("img");
                        img.src = '/images/FhHRx.gif';
                        img.style.width = convertPxToViewport(15);
                        img.style.height = convertPxToViewport(15);
                        img.id = "small_loader";
                        p.appendChild(img);
                    } else {
                        var p = document.createElement("p");
                        amount = amount === 0 ? "" : amount.toString();
                        p.innerHTML = formatValHTML(amount, FormatTypes.NUMERIC);
                        p.title = formatValString(amount, FormatTypes.NUMERIC);
                        
                        if(Number(amount)< 0) {
                            p.classList.add("red");
                        }
    
                    }
                    
                    return p;
                }
                break;
            case PS_MAPPING.FIELDS.HANDLE_ROW:
            columnFormatter = function(cell) {
                if([PSL_RETURN_NAMES.GROSS_PROFIT, PSL_RETURN_NAMES.NET_PROFIT].includes(cell.getRow().getData()[_returnName])) {
                    cell.getElement().style.pointerEvents = "none";     //disable drag
                } else {
                    var i = document.createElement("i");
                    i.classList.add("fas","fa-grip-vertical");
                    i.style.width = convertPxToViewport(16);
                    i.style.cursor = "grab";
                    return i;
                }
            }
            break;

            case _costKey:
                columnFormatter = function(cell) {
                    let ck = extractNumber(cell.getValue());
                    let circle = document.createElement("div");
                    circle.classList.add("psl-border-square", "uk-grab", "costkey-draggable", "costkey_"+cell.getValue());
                    if(cell.getValue() === obj.state.pslLine[_costKey] && obj.isPSCalculated) {
                        //if parseAmount request is received after the mapping window is opened, hide the draggable circle
                        circle.classList.add("uk-hidden")
                    }
                    circle.setAttribute("node_"+_costKey, ck);
                    circle.setAttribute("node_class", "psl-border-square uk-margin-xsmall-right-left");    //classes to be added when dropped into formula box
                    circle.innerText = ck;
                    return circle;
                } 
            break;

            case _costType:
                columnFormatter = function(cell) {
                    return cell.getValue() ? cell.getValue() === costtype.invoicelinetype ? capitalizeFirstLetter(costtype.standard) : capitalizeFirstLetter(cell.getValue()) : "";
                } 
            break;

            default:
            columnFormatter = function(cell) {
                return cell.getValue();
            } 
            break;
        }
        return columnFormatter;  
    }

    showHideAddColumn() {
        var metricsNotDeleted = this.state.metricFields ? this.state.metricFields.filter(e => e[ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) : [];
        if(metricsNotDeleted.length === 0) {
            $("#add-row-top-icon").css("display", "none");
        } else {
            $("#add-row-top-icon").css("display", "block");
        }
    }

    onMetricDelete(row) {
        row[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.DELETED;
        this.abortGetMetricsDataRequest();
        this.setState({
            isChanged: true
        });
        this.setState({
            metricFields: this.tabulator.getData()
        }, function(){
            let tableData = this.tabulator.getData()
            this.tabulator.setFilter(ROW_STATUS.FIELD, "in", [METRICS_MAPPING.FIELDS.ROW_STATUS_VALUES.NOT_SAVED, ROW_STATUS.VALUES.OLD, ROW_STATUS.VALUES.EDITED]);
            var data = this.getChartValues(tableData, this.state.periodName);
            this.tabulator.setData(copyObjectValues(tableData));
        });
       
    }

    setChosenTimePeriod(e){
        var _this = this;
        if (_this.glRef) {
            this.setState({
                isChangedPeriod: true
            },()=>{
                _this.glRef.setChosenTimePeriod(e);
                _this.resetMetricValue();
            })
            return;
        }
        if(e !== null){
            this.abortGetMetricsDataRequest();
            this.setState({
                periodName: e.value,
                isChangedPeriod: true
            }, function () {
                if (_this.isMetricMapping) {
                    _this.resetMetricValue();
                }
            })
        }
    }

    updatePSLFilter(filter) {
        this.setState({
            chosenPSLFilter: filter,
        });
    }

    updateMessage(data,row, message){
        for (var e in data) {
            if(data[e][PS_MAPPING.FIELDS.ACTUAL_ID] === row[PS_MAPPING.FIELDS.PSS_ID]) {
                message.push("This change affects these profit stack lines: " + data[e][PS_MAPPING.FIELDS.NAME].replace('Accrual','Actual') +" and " + data[e][PS_MAPPING.FIELDS.NAME]);
            } else if (data[e].children && data[e].children.length > 0) {
               this.updateMessage(data[e].children, row, message);
            }
        }

    }

    changeSwitch(cell) {
        var obj = this;
        var found = false;
        var tempData = obj.tabulator.getData();
        var message = [];
        this.updateMessage(tempData,cell.getRow().getData(), message)
        if (message.length > 0) {
            obj.setOpenWarningDialog(true, message[0]); {/* NOT USED */}
        }
        obj.state.mappedLines.map(function(item){
            if (item[_costKey] === cell.getRow().getData()[_costKey]) {
                item.costCenter = item.costCenter !== 'ALL' ? 'ALL' : obj.props.costCenter;
                tempData = obj.updateChildAttr(tempData, item[_costKey], [_costCenter], item.costCenter);
                found = true;
            }
            if (item[PS_MAPPING.FIELDS.ACTUAL_ID] === cell.getRow().getData()[_id]) {
                item.costCenter = item.costCenter !== 'ALL' ? 'ALL' : obj.props.costCenter;
                tempData = obj.updateChildAttr(tempData, item[_costKey], [_costCenter], item.costCenter);
            }
        });
        if(!found) {
            var newRow = {
                [_id] : cell.getRow().getData()[_id],
                [_leadingID] : cell.getRow().getData()[_id],
                [_leadingCostKey] : cell.getRow().getData()[_costKey],  //if not previously mapped, set leading id and costkey as its own
                [_name] : cell.getRow().getData()[_name],
                [_costKey]: cell.getRow().getData()[_costKey],
                [_parentCostKey]: cell.getRow().getData()[_parentCostKey],
                [PS_MAPPING.FIELDS.FILTER]: "",
                [_combinations]: [],
                stagingQuery: "",
                [_mappingException]: NONE,
                fileName: "",
                [_costCenter]: obj.props.costCenter,
                [_deleted]: "false",
                [_returnName]: cell.getRow().getData()[_returnName],
                [ROW_STATUS.FIELD]:ROW_STATUS.VALUES.NEW
            };
            obj.state.mappedLines.push(newRow);
            tempData = obj.updateChildAttr(tempData, newRow[_costKey], [_costCenter], newRow.costCenter);
        }

        obj.tabulator.replaceData(tempData);
        obj.forceUpdate();

    }


    addManualChild(costKey, data, name, childCostKey, newRow) {
        for (var e in data) {
             if (data[e][_costKey] === costKey) {
                 var dataChildren = data[e][_children];
                 var index = "";
                for(var elt in dataChildren){
                     if(!dataChildren[elt][_name].includes("unmatched") && dataChildren[elt][_name].includes(name.replace(_matchedSuffix,"").replace(_unMatchedSuffix,''))) {
                         index =  elt;
                         break;
                     }
                 }
                data[e][_children].splice(Number(index)+1, 0, newRow);
            } 
            if (data[e][_children]) {
                this.addManualChild(costKey, data[e][_children], name, childCostKey, newRow);
            }
            
        }
    }

    renameMatched(data, childCostKey, flag, name) {
        for (var e in data) {
            if (data[e] && data[e][_costKey] === childCostKey) {
                if (flag) {
                    data[e][_name] = !data[e][_name].includes(_matchedSuffix) ? data[e][_name] + _matchedSuffix : data[e][_name];
                } else if(!name){
                    data[e][_name] = data[e][_name].replace(_matchedSuffix,"").replace(_unMatchedSuffix, "");
                } else {
                    data[e][_name] = name.replace(_matchedSuffix, "").replace(_unMatchedSuffix, "") + _unMatchedSuffix;
                }
            } else if (data[e] && data[e][_children]) {
                this.renameMatched(data[e][_children], childCostKey, flag, name);
            }
        }
    }

    updateChildAttr(data, costkey, attr, value) {

        for(var row in data) {
            row = data[row];

            if(Number(row[_costKey]) === Number(costkey)) {
                row[attr[0]] = value.toString();
            } else if(row[_children]){
                row[_children] = this.updateChildAttr(row[_children], costkey, attr, value);
            }
        }

        return data;
    }

    findUnMatched(data, parentCostKey, name, costKey ) {
        for (var e in data) {
            if (data[e][_costKey] === parentCostKey) {
                for (var elt in data[e][_children]) {
                    if (data[e][_children][elt][_name] === name && data[e][_children][elt][_costKey] === costKey) {
                        return true;
                    }
                }
            } else if (data[e][_children]) {
                if (this.findUnMatched(data[e][_children], parentCostKey, name, costKey) === true ) {
                    return true;
                } else {
                    continue;
                }
            }
        }
        return false;
    }

    findPslRow(data, attr, value) {
        var found = null;
        for (var e in data) {
            if (data[e][attr].toLowerCase() === (value+"").toLowerCase()) {
                found = data[e];
                break;
            } else if (data[e][_children]) {
                found = this.findPslRow(data[e][_children], attr, value);
                if(found) {
                    break;
                }
            }
        }
        return found;
    }
    
    saveMappedCalculatedPSL(mappedLine) {
        var _this = this;
        
        let mappedCK = mappedLine[_costKey];
        let mappedLines = copyObjectValues(this.state.mappedLines);
        let mapIndex = findIndexOfValue(mappedLines, _costKey, mappedCK);

        if(mapIndex > -1) {
            //already mapped before, update the line in mappedLines array
            mappedLines[mapIndex] = Object.assign({}, mappedLines[mapIndex], mappedLine);
        } else {
            //not mapped before, add it to the mappedLines array
            mappedLines.push(mappedLine);
        }

        this.setState({
            isChanged: true,
            mappedLines: this.sortMappedLines(mappedLines)
        }, ()=>{
            _this.tabulator.redraw();
        });
        
        this.hideGLAccounts();
        $("#submit_ps").show();
        $("#selectPeriodDiv").show();
    }

    setMappedLinesData(mappedLines, periodName, newLeadingPssId, newLeadingCostKey, removedIds) {
        var _this = this;
        if(this.isPSCalculated) {
            this.saveMappedCalculatedPSL(mappedLines);
            return;
        }
        var obj = this;
        var data = this.tabulator.getData();
        var unmatchedFound = true;
        var oldLeadingCostKey = obj.state.pslLine[_costKey];
        if (newLeadingPssId) {      // leading has been removed
            if (this.state.mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === oldLeadingCostKey && e[_deleted] ==="false" ).length === 0) { // if it has been mapped before then name should stay as is  
                this.renameMatched(data, oldLeadingCostKey, false);       //renames line mapped to ancillary to name 
            }
        } else {
            newLeadingPssId = obj.state.leadingPssId;
            newLeadingCostKey = obj.state.leadingPssCostKey;
        }

        //renaming deleted lines
        if(removedIds && removedIds.length) {
            for (var e in mappedLines) {
                if (removedIds.includes(mappedLines[e][_id])) {
                    this.renameMatched(data, mappedLines[e][_costKey], false);
                }
            }
        }

        // if matched line doesn't have an unmatched line; an unmatched line should be added
        var unmatchedline = mappedLines.filter(e=>parseBoolean(e[_isMatched]) === false && e[_leadingID] && e[_leadingID] === newLeadingPssId && e[_deleted] === "false")[0];
        if (unmatchedline && unmatchedline[_name].toLowerCase().includes(_unMatchedSuffix)) {
            unmatchedFound = getEmbeddedChild(data, _children, _costKey, unmatchedline[_costKey]);
        }
        if (!unmatchedFound) {
            var newCostKey = this.generateUniqueCostKey(this.state.profitStackFields);
            var newId = this.generateUniqueIdDB(this.state.profitStackFields);
            var name = unmatchedline[_name].includes(_unMatchedSuffix) ? unmatchedline[_name] : unmatchedline[_name] + _unMatchedSuffix;
            var newRow = {
                [_name] : name,
                [_costKey]: newCostKey+"Insert" ,
                [_parentCostKey]: unmatchedline[_parentCostKey], //obj.state.pslLine[_parentCostKey],
                "new": "1",
                level: this.state.cell.getRow().getData()["level"],
                [PS_MAPPING.FIELDS.FILTER]: "",
                [_combinations]:[],
                stagingQuery:"",
                [_mappingException]: NONE,
                fileName: "",
                [_costCenter]: 'ALL',
                isNotAC: false,
                [_deleted]: "false",
                [_returnName]: this.state.pslLine[_returnName] + replaceSpecialChars(_unMatchedSuffix),
                [_id]: newId.toString(),
                [ROW_STATUS.FIELD]:ROW_STATUS.VALUES.NEW,
                [_leadingID]: newLeadingPssId,
                [_leadingCostKey]: newLeadingCostKey,
                [_costType]: costtype.standard
            };
            
            if (obj.state.pslLine[_parentCostKey] === '201' || unmatchedline[_parentCostKey]) {
                data.push(newRow);
                data = this.reOrderLine(data, newLeadingCostKey, newRow);
            } else {
                this.addManualChild(unmatchedline.parentCostKey, data, unmatchedline[_name], newLeadingCostKey, newRow); // adds unmatched line to pss
            }
            mappedLines.map(function(item){
                if (item[_name] === unmatchedline[_name] && item[_deleted] === "false" ) {
                    item[_costKey] = newCostKey.toString()+"Insert";
                    item[_id] = newId.toString();
                    item.fieldsCombination= [];
                }
            })
        }
        //should add mappedSiblings = mappedLines.filter(line => line[_leadingID] === newLeadingPssId) -- and use it instead, not done now bc of patch
        mappedLines.filter(l => l[_leadingID] === newLeadingPssId).forEach(line =>{
            let shouldRename = line[_isMatched] && line[_mappingException] !== NONE && (_this.state.mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === line[_costKey] && e[_deleted] ==="false" ).length === 0) 
                ;
            let shouldAddSuffix = line[_mappingException] === NONE ? false : true;    //if none, remove suffix if already there, else do not add it
            
            if(shouldRename) {
                this.renameMatched(data, line[_costKey], shouldAddSuffix); // renames line mapped to ancillary to name + (matched)
            } else if(line[_mappingException] === NONE) {
                this.renameMatched(data, line[_costKey], false); // remove the suffixes from the line's name
            }

            if (line[_deleted] === "true" && (line[_name].includes(_unMatchedSuffix) || line[PS_MAPPING.FIELDS.ACTUAL_ID] && line[PS_MAPPING.FIELDS.ACTUAL_ID] !== "")) {
                // if line is not matched and doesn't contain unmatched then we shouldn't be deleting it from ps table
                data = this.updateDeletedProfitStackFields(data, line[_costKey], "");
                this.state.toBeDeleted.push(line[_costKey]);
            }

            // if(line[_costKey] === oldLeadingCostKey && paramsLeadingPssId && line[_isMatched] !== false) {
            //     line[_leadingID] = line[_id];
            //     line[_leadingCostKey] = line[_costKey];
            //     line[_name] = line[_name].replace(_matchedSuffix,"");
            // }
        });
        //deleting accrual line
        for(var leaf in mappedLines) {
            var accrual = mappedLines.filter(l => l[_leadingID] === newLeadingPssId && l[PS_MAPPING.FIELDS.PSS_ID] === mappedLines[leaf][PS_MAPPING.FIELDS.ACTUAL_ID]);
            if (mappedLines[leaf].deleted === "true" && accrual && accrual.length > 0) {
                data = this.updateDeletedProfitStackFields(data, mappedLines[leaf][_costKey], "");
                this.state.toBeDeleted.push(mappedLines[leaf][_costKey]);
            }
        }

        // if matched line has been mapped to non ancillary; unmatched line should be deleted and matched line should be renamed
        var line = mappedLines.filter(e=>e[_costKey]  === newLeadingCostKey)[0];
        var unMatchedCostKey = "";
        if(line && (line[_name].includes(_matchedSuffix) || line[_isMatched]) && line[_deleted] === "true" ) {
            mappedLines.map(function(item){ // matched line no longer mapped 
                if (item[_costKey] === line[_costKey]) {
                    item[_name] = item[_name].replace(_matchedSuffix, "");
                    item[_leadingID] = item[_id];
                    item[_leadingCostKey] = item[_costKey];
                }
                if (item[_name].replace(_unMatchedSuffix,"") === line[_name].replace(_matchedSuffix,"") && (line[_leadingID] !== line[_id] || line[_name].includes(_unMatchedSuffix))) { // remove unmatched
                    unMatchedCostKey = item[_costKey];
                    item[_deleted] = "true";
                }
                if (item[_leadingID] && item[_leadingID] === newLeadingPssId && parseBoolean(item[_isMatched]) === false) {
                    unMatchedCostKey = item[_costKey];
                    item[_deleted] = "true";
                }
            });
            
            data = this.updateDeletedProfitStackFields(data, unMatchedCostKey, "");
            this.state.toBeDeleted.push(unMatchedCostKey);
        }

        //set row_status as 'edited' in data, for all mapped lines that have row_status = 'edited'
        let editedMappedLinesReturnNames = mappedLines.filter(line=>line[ROW_STATUS.FIELD] === ROW_STATUS.VALUES.EDITED && line[_deleted] === "false").map(line=>line[_returnName]);
        for(var retName in editedMappedLinesReturnNames) {
            let editedLine = this.findPslRow(data, _returnName, editedMappedLinesReturnNames[retName]);
            if(editedLine !== null){
                editedLine[ROW_STATUS.FIELD] = ROW_STATUS.VALUES.EDITED;
            }
        }

        var res = "";
        for (var e in mappedLines) {    //updating cost center and amounts
            if(mappedLines[e][_mappingException] === ANCILLARY) {
                res = this.isDisabledToggleButton(mappedLines[e][PS_MAPPING.EXCEPTION_FIELDS.FILE], mappedLines[e], mappedLines);
                mappedLines[e][_costCenter] = res === true ? 'ALL' : mappedLines[e][_costCenter];    
            }
            
            let amount = mappedLines[e][_amount] ? mappedLines[e][_amount].toString() : "0";
            data = this.updateChildAttr(data, mappedLines[e][_costKey], [PS_MAPPING.FIELDS.AMOUNT], amount);
        }

        mappedLines = mappedLines.filter(function(elt){if (elt[_deleted] === "false") return elt;})
        
        this.state.periodName = periodName;     //state will be updated in hideGLAccounts()
        this.state.isChanged = true;
        this.state.mappedLines = this.sortMappedLines(mappedLines);
        this.forceUpdate();
        obj.tabulator.replaceData(data);
        
        this.hideGLAccounts();
        $("#submit_ps").show();
        $("#selectPeriodDiv").show();
    }

    showExclusionPopUp() {
        var obj = this;
        if (obj.state.isChanged) {
            window._profitIsleOpenModal('confirmUpdateModal');
        } else {
            this.showGLAccounts(ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION,'',true);
        }
    }

    discardChangesAndGoToExclude(){
        this.tabulator.replaceData(this.state.profitStackTableOriginalFields);
        this.setState({
            profitStackFields:this.state.profitStackTableOriginalFields,
            isChanged: false 
        },function(){
            this.getMappedLines(undefined, true);
        });
    }

    showValueFormula(accountsType, glType, isManageExclusions) {
        var obj = this;
      
        //hide the Modal first and then reshow it in case it was opened for another PS line first
        this.hideValueFormula(function() {
            obj.setState({
                accountsType: accountsType,
                showGLAccounts: true,
                showValueFormula: true,
                glType: glType,
                isManageExclusions: isManageExclusions
            }, function(){
                $("#submit_ps").hide();
                $('#mainTable').hide();
                $('#metricValueFormula').show('slide', { direction: 'right' }, 500);
                // if(typeof obj.props.setConfirmationRequirement === "function" && obj.isProfitStackMapping) {
                //     obj.props.setConfirmationRequirement(HEADER_ELEMENT.PERIOD, true, lang.ps_mapping_change_period);
                // }
            });
        });
    }

    submitAttribute() {
        var obj = this;
        var data = copyObjectValues(this.state.profitStackFields);
        var row = this.state.profitStackLineToMap;

        var attributerow = data.filter(e=> e.costKey === row.costKey)[0];
        var objMapped = this.state.mappedLines.filter(e => obj.state.profitStackLineToMap && e[_costKey] === obj.state.profitStackLineToMap[_costKey]);

        attributerow[_attribute] = obj.state.attributeValue && obj.state.attributeValue !== "" ? obj.state.attributeValue  :  objMapped && objMapped[0] && objMapped[0][_attribute] && objMapped[0][_attribute] !== "" ?
        objMapped[0][_attribute] : ""
        attributerow[_attributeFunction] = obj.state.attributeChosen && obj.state.attributeChosen["file_type"] === "vectors" ? _attributeFunctions.COUNT : obj.state.attributeFunction ? obj.state.attributeFunction : _attributeFunctions.COUNT;
        attributerow[_attributeType] = obj.state.attributeType ? obj.state.attributeType : "vector";
        attributerow[_deleted] = "false";
        attributerow[PS_MAPPING.FIELDS.DESCRIPTION] = $("#metric_description").val();
        let mappedCK = attributerow[_costKey];
        let mappedLines = copyObjectValues(this.state.mappedLines);
        let mapIndex = findIndexOfValue(mappedLines, _costKey, mappedCK);

        if(mapIndex > -1) {
            //already mapped before, update the line in mappedLines array
            mappedLines[mapIndex] = Object.assign({}, mappedLines[mapIndex], attributerow);
        } else {
            //not mapped before, add it to the mappedLines array
            mappedLines.push(attributerow);
        }

        this.setState({
            isChanged: true,
            mappedLines: this.sortMappedLines(mappedLines),
            profitStackLineToMap: row,
            onElementChange: false,
            onFunctionChange: false
        }, ()=>{
            obj.tabulator.redraw();
            obj.hideAttributeFormula();
            obj.props.disableHeaderButtons(false);
        });
    }

    cancelAttribute() {
        var obj = this;
        var data = copyObjectValues(obj.state.profitStackFieldsBeforeEditAttribute);
        this.setState({
            profitStackFields: data,
            attributeValue: "",
            attributeFunction: "",
            onElementChange: false,
            onFunctionChange: false
        }, function () {
            obj.tabulator.replaceData(data);
            obj.hideAttributeFormula();
            obj.props.disableHeaderButtons(false);
        });
    }

    hideAttributeFormula(callback, doNotShowChart) {
        var obj = this;
        $('#attributeFormulaModal').hide('slide', { direction: 'right'}, 500, function () {
            obj.setState({
                profitStackFields: obj.tabulator ? obj.tabulator.getData() : [],
                isManageExclusions: false,
                showAttributeFormula: false
            }, function() {
                    obj.toggleTableColumnsForCalculated(false);
                    if(callback) {
                        callback();
                    }
            });
            $("#submit_ps").show();
            $("#selectPeriodDiv").show();
            $("#period_label").removeClass("uk-hidden");
            $("#PeriodRange").removeClass("uk-hidden");
        });
    }

    showAttributeFormula() {
        var obj = this;
        //hide the Modal first and then reshow it in case it was opened for another PS line first
        this.hideAttributeFormula(function() {
        $("#metric_description").val("");

            obj.setState({
                showAttributeFormula: true,
                profitStackFieldsBeforeEditAttribute: copyObjectValues(obj.state.profitStackFields)
            }, function(){
                $("#submit_ps").hide();
                $("#selectPeriodDiv").hide();

                $('#GLAccountsModal').hide();
                $("#accountsChart").hide();
                $('#attributeFormulaModal').show('slide', { direction: 'right' }, 500);
                var selectedCostKey = obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.COST_KEY];
                var mappedLine = obj.state.mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === selectedCostKey);
                if (mappedLine.length > 0) {
                    $("#metric_description").val(mappedLine[0][PS_MAPPING.FIELDS.DESCRIPTION]);
                }else{
                    $("#metric_description").val(obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.DESCRIPTION]);  
                }
            });
        });
    }

    showGLAccounts(accountsType, glType, isManageExclusions,rowDataCC) {
        var obj = this;
        var direction = '';
        if (obj.isMetricMapping) {
            direction = 'right';
        } else {
            direction = 'left';
        }

        //hide the Modal first and then reshow it in case it was opened for another PS line first
        this.hideGLAccounts(function() {
            $("#metric_description").val("");
            obj.setState({
                accountsType: accountsType,
                showGLAccounts: true,
                glType: glType,
                isManageExclusions: isManageExclusions,
                rowDataCC:rowDataCC
            }, function(){
                    $("#submit_ps").hide();
                    $("#selectPeriodDiv").hide();
                    if (obj.isMetricMapping) {
                        $('#GLAccountsModal').show('slide', { direction: direction }, 500);
                    } else {
                        $('#GLAccountsModal').show('slide', { direction: 'right' }, 500);
                    }
                    // if(typeof obj.props.setConfirmationRequirement === "function" && obj.isProfitStackMapping) {
                    //     obj.props.setConfirmationRequirement(HEADER_ELEMENT.PERIOD, true, lang.ps_mapping_change_period);
                    // }
                    obj.isPSCalculated = obj.isProfitStackMapping && obj.state.pslLine[_costType] === costtype.calculated && !isManageExclusions;
                    obj.isPSAttribute = obj.isProfitStackMapping && obj.state.pslLine[_costType] === costtype.attribute && !isManageExclusions;
                    obj.toggleTableColumnsForCalculated();
                    var selectedCostKey = obj.state.profitStackLineToMap  ? obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.COST_KEY] : "";
                    var mappedLine = obj.state.mappedLines.filter(e=>e[PS_MAPPING.FIELDS.COST_KEY] === selectedCostKey);
                    if(obj.isProfitStackMapping && !isManageExclusions) {
                        if (mappedLine.length > 0) {
                            $("#metric_description").val(mappedLine[0][PS_MAPPING.FIELDS.DESCRIPTION]);
                        } else {
                            $("#metric_description").val(obj.state.profitStackLineToMap[PS_MAPPING.FIELDS.DESCRIPTION]);
                        }
                    }                  
            });
        });

        if (obj.state.showAttributeFormula) {
            obj.hideAttributeFormula(undefined, true);
        }
    }

    hideValueFormula(callback) {
        var obj = this;
        $('#metricValueFormula').hide(function () {
            obj.setState({
                showGLAccounts: true,
                showValueFormula: false,
                isManageExclusions: false,
            }, function () {
                $('#mainTable').show();

                if(callback) {
                    callback();
                }
            });
            $("#submit_ps").show();
            $('#GLAccountsModal').show();
        });
    }

    hideGLAccounts(callback) {
        var obj = this;
        $('#GLAccountsModal').hide('slide', { direction: 'right'}, 500, function () {
            obj.setState({
                profitStackFields: obj.tabulator ? obj.tabulator.getData() : [],
                showGLAccounts: false,
                isManageExclusions: false,
            }, function() {
                obj.isPSCalculated = false;
                obj.isPSAttribute = false;
                obj.toggleTableColumnsForCalculated(false);
                if(callback) {
                    callback();
                }
                
                // if(typeof obj.props.setConfirmationRequirement === "function") {
                //     obj.props.setConfirmationRequirement(HEADER_ELEMENT.PERIOD, false);
                // }
            });
            $("#submit_ps").show();
            $("#selectPeriodDiv").show();
            $('#mainTable').show();
            $("#period_label").removeClass("uk-hidden");
            $("#PeriodRange").removeClass("uk-hidden");
        });

        $('#metricValueFormula').hide('slide', { direction: 'right' }, 500);
    }
        
    static getDerivedStateFromProps(nextprops, state) {
        if(state.periods) {
            return {
                periods : state.periods,
                periodName: state.periods.length ? state.periods[0].value : ""
            }
        }
        return null;
    }

    setMappingReport(report){
        if(report === STAGING_SECTIONS.METRICS_MAPPING) {
            this.isMetricMapping = true
        } else {
            this.isMetricMapping = false;
        }
        // this.isProfitStackMapping = !this.isMetricMapping;
    }

    removeMovedChild(data, costkey, parentCostKey) {
        if (parentCostKey === '201') {
            var curIndex = data.findIndex(el => el[_costKey] === costkey)
            data.splice(curIndex, 1);
            return;
        }
        for (var e in data) {
            if(data[e][_costKey] === parentCostKey) {
                if(data[e][_children]) {
                    var index = data[e][_children].findIndex(el => el[_costKey] === costkey);
                    data[e][_children].splice(index,1);   
                }
            } else if (data[e][_children]){
                this.removeMovedChild(data[e][_children], costkey, parentCostKey )
            }
        }
    }

    addIndexChild(data, parentCostKey, costkey, item, pushLast) {
        for(var e in data) {
            if (data[e][_costKey] === parentCostKey) {
                if(data[e][_children]) {
                    var children = [];
                    for(var elt in data[e][_children]) {
                        item[_parentCostKey] = parentCostKey;
                       
                        if (data[e][_children][elt][_costKey] === costkey) {
                            if (pushLast) {
                                children.push(data[e][_children][elt]);
                                children.push(item);
                            } else {
                                children.push(item);
                                children.push(data[e][_children][elt]);
                            }
                        } else {
                            children.push(data[e][_children][elt]);
                        }
                        
                }
                data[e][_children] = children;
            }
            } else if(data[e][_children]){
                this.addIndexChild(data[e][_children], parentCostKey, costkey, item);
            }
        }
    }

    appendToFormula(value, type, className, file, column, colIndex) {
        if (this.vfRef) 
            this.vfRef.appendToFormula(value, type, className, file, column, colIndex);
    }

    updateFilterConditionFiles(files) {
        this.setState({
            chosenFiles: files
        }, function(){
            if (this.vfRef) 
                this.vfRef.setFiles(files);
        })
    }

    componentDidUpdate(prevProps) {
        this.setMappingReport(prevProps.stagingReport);
    }

    init() {
            if(this.isMetricMapping){
            this.getMetricFields();
        }
    }
    
    componentDidMount() {
        this.setMappingReport(this.props.stagingReport);
        $("#header-next").unbind('click');
        $("#header-back").unbind('click');
        if (this.isMetricMapping) {
            this.getVectorList();
            this.getCalculatedColumns();
        }

            var obj = this;
            //hiding the period drop down list in header
            // $("#periodList-header").addClass("uk-hidden");

            //adding the back and apply buttons to header
            // $("#header-buttons").removeClass("uk-hidden");


            var options = {
                //"index" sets the main column of the table to be used as reference when using updateData
                index: "index",
                layout: "fitColumns",      //fit columns to width of table
                responsiveLayout: false,  //hide columns that dont fit on the table
                tooltips: true,            //show tool tips on cells
                addRowPos: "top",          //when adding a new row, add it to the top of the table
                history: true,             //allow undo and redo actions on the table
                pagination: false,          //paginate the data
                movableColumns: false,     //allow column order to be changed
                selectable: false,
                movableRows: /*this.isProfitStackMapping ? true :*/ false,
                resizableColumns: false,
                autoResize: true,
                dataTree: false,
                dataTreeChildField: false,
                dataTreeStartExpanded: false,
                dataTreeElementColumn: this.isProfitStackMapping ? PS_MAPPING.FIELDS.EXPAND : false,
                dataTreeCollapseElement: "<div class='uk-cursor-pointer' title='Collapse'><i class='far fa-chevron-down fs-14' /></div>",
                dataTreeExpandElement: "<div class='uk-cursor-pointer' title='Expand'><i class='far fa-chevron-right fs-14' /></div>",
                dataTreeChildIndent: 15,
                dataTreeBranchElement: false, //hide branch element
                virtualDomBuffer: 10000,
                placeholder: MESSAGES.no_data_available,
                width: "100%",
                height: this.isMetricMapping ?  "100%" : "calc(100vh - 16.35vw)",
                renderComplete: this.onTabulatorRenderComplete,
                dataTree: true,
                reactiveData:true,  
                tooltips: function(column){
                    return column._cell.value;
                },
                keybindings:{
                    "navNext" : false, //disable navNext keybinding
                },
                rowMoved: function (row, index, index2) {
                    var tabulator = obj.tabulator;
                    var data = copyObjectValues(tabulator.getData());
                    var movedRowCostKey = row.getData()[_costKey];
                    var movedRowParentCostKey = row.getData()[_parentCostKey];
                    var displayedRows = tabulator.rowManager.displayRows[1];    //an array in tabulator that contains all displayed rows in order
                    let displayedRowIndex = obj.getRowIndex(displayedRows, movedRowCostKey);
                    let previousItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex - 1]);
                    let nextItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex + 1]);
                    let currentItem = row.getData();
                    var movedRowLineType = currentItem[_costType];
                    var allowMove = true;

                    //dragging to top or bottom of fields
                    if (previousItem === null || nextItem === null) {
                        obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                        currentItem[_parentCostKey] = '201';
                        obj.state.mappedLines.map(function (item) {
                            if (item[_costKey] === movedRowCostKey) {
                                item[_parentCostKey] = '201';
                            }
                        });
                        if (previousItem) {
                            data.push(currentItem);
                            obj.tabulator.replaceData(data);
                        } else {
                            data.unshift(currentItem);
                            obj.tabulator.replaceData(data);
                        }
                        return;
                    }

                    if (obj.isProfitStackMapping && ((movedRowLineType === costtype.attribute && nextItem && nextItem.getData()[_costType] !== costtype.attribute)
                        || (movedRowLineType === costtype.calculated && ((previousItem && previousItem.getData()[_costType] === costtype.attribute) || nextItem.getData()["level"] > 1)))) {
                        obj.tabulator.replaceData(obj.state.originalData); // this to prevent dragging attributes between other type lines or calculated between attribute lines or between children
                        allowMove = false;
                    } else if (previousItem && nextItem && previousItem.getData()[_costKey] === nextItem.getData()[_parentCostKey]) {
                        if (previousItem.getData().level === 2
                            && (movedRowLineType === costtype.attribute || movedRowLineType === costtype.calculated)) {
                            obj.tabulator.replaceData(data);
                            return;
                        } else {
                            // splice from original data
                            obj.state.mappedLines.map(function (item) {
                                if (item[_costKey] === movedRowCostKey) {
                                    item[_parentCostKey] = previousItem.getData()[_costKey];
                                }
                            });
                            obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                            // add to new parent children array
                            obj.addIndexChild(data, previousItem.getData()[_costKey], nextItem.getData()[_costKey], currentItem);
                            obj.tabulator.replaceData(data);
                        }
                        return;
                    } else if (previousItem && nextItem && (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey] ||
                            currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey])) {
                            //draggin to a same level 
                            if (previousItem.getData()["level"] === 2
                                && [costtype.calculated, costtype.attribute].includes(movedRowLineType)) {
                                obj.tabulator.replaceData(data);
                                allowMove = false;
                            } else {
                                obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                                currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
                                obj.state.mappedLines.map(function (item) {
                                    if (item[_costKey] === movedRowCostKey) {
                                        item[_parentCostKey] = previousItem.getData()[_parentCostKey];
                                    }
                                });

                                if (previousItem.getData()[_parentCostKey] === '201') {
                                    var currIndex = data.findIndex(el => el[_costKey] === nextItem.getData()[_costKey]);
                                    data.splice(currIndex, 0, currentItem);
                                } else {
                                    if (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey]) {
                                        obj.addIndexChild(data, previousItem.getData()[_parentCostKey], nextItem.getData()[_costKey], currentItem);
                                    } else if (currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey] && nextItem.getData()[_parentCostKey] === '201'
                                        && currentItem[_costType] === costtype.standard) {
                                        obj.addIndexChild(data, currentItem[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
                                    }
                                }
                                obj.tabulator.replaceData(data);
                            }
                            return;
                        }
                        // return;
                    // }

                    //draggin a row to be a child of a parent 
                    // if (obj.isProfitStackMapping && ((movedRowLineType === costtype.attribute && nextItem && nextItem.getData()[_costType] !== costtype.attribute)
                    //     || (movedRowLineType === costtype.calculated && ((previousItem && previousItem.getData()[_costType] === costtype.attribute) || nextItem.getData()["level"] > 1)))) {
                    //         obj.tabulator.replaceData(obj.state.originalData); // this to prevent dragging attributes between other type lines or calculated between attribute lines or between children
                    //         allowMove = false;
                    // } else {

                    // }

                    // else dragging a row under a child of a parent 
                    if (row && currentItem && previousItem && previousItem.getData()) {
                        currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
                    }
                    obj.state.mappedLines.map(function(item){
                        if(item[_costKey] === movedRowCostKey) {
                            item[_parentCostKey] = previousItem.getData()[_parentCostKey];
                        }
                    });
                    if (allowMove) {
                        obj.removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                        obj.setState({
                            originalData: copyObjectValues(data)
                        })
                    }

                    if (nextItem.getData()[_parentCostKey] === '201' && currentItem[_costType] === costtype.standard) { 
                        obj.addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
                    } else {
                        obj.addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem);  
                    }

                    if (allowMove) 
                        obj.tabulator.replaceData(data);
                    obj.profitStackFields = data;
                    obj.forceUpdate();

                    // if((previousItem && movedRowParentCostKey !== previousItem.getData()[_costKey] && 
                    // movedRowParentCostKey !== previousItem.getData()[_parentCostKey]) || (row.getTreeParent() !== false && !previousItem)) {

                        //if moving a row to another parent
                        // obj.redrawTable = true;
                        // obj.reExpandCollapsedRows();
                        // obj.tabulator.redraw(true);
                        // return;
                        // row.getData()[_parentCostKey] = previousItem.getData()[_costKey];
                    // }
                    // for (var rowIndex in displayedRows) {
                    //     rowIndex = Number(rowIndex);
                    //     let item = displayedRows[rowIndex];
                    //     let loopedItemCostKey = item.getData()[_costKey];
    
                    //     if(loopedItemCostKey === movedRowParentCostKey) {
                    //         //catch the parent of the moved row
                    //         var parentRow = obj.getRowFromKey(_costKey, movedRowParentCostKey);
                    //         var childrenCostkeys = obj.getChildCostKeys(movedRowParentCostKey, tabulator.getData());
    
                    //         var newChildrenData = [];
                    //         var newChildrenRows = [];
                    //         var parentRowData = parentRow.getData();
                    //         for(let rowIndex2 in displayedRows) {
                    //             //because we want to continue looping from the same index reached in the first loop instead of starting over,
                    //             //and +1 because rowIndex is the index of the current row (parent), and we want the children
                    //             rowIndex2 = Number(rowIndex2) + rowIndex + 1;
                    //             let child = displayedRows[rowIndex2];
                    //             let childCostKey = child.getData()[_costKey];
                                
                    //             if(childrenCostkeys.indexOf(Number(childCostKey)) === -1) {
                    //                 break;
                    //             }
    
                    //             if(child.getData()[_parentCostKey] === movedRowParentCostKey) {
                    //                 newChildrenData.push(child.getData());
                    //                 newChildrenRows.push(obj.getRowFromKey(_costKey, childCostKey));
                    //             }
                    //         }
    
                    //         parentRowData[CHILDREN] = newChildrenData;
                    //     }
                    // }
                    
               
    
                    // obj.state.isChanged = true;
                    // obj.redrawTable = true;
                    // obj.reExpandCollapsedRows();
                    // obj.tabulator.redraw(true);
                },
                dataTreeRowExpanded: function(row, level){
                    let costKey = row.getData()[_costKey];
                    if(obj.expandedRowsCostkeys.indexOf(costKey) === -1) {
                        //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                        obj.expandedRowsCostkeys.push(costKey);
                    }
                    setDraggable("costkey-draggable");
                },
                dataTreeRowCollapsed: function(row, level){
                    let costKey = row.getData()[_costKey];
                    let index = obj.expandedRowsCostkeys.indexOf(costKey);
                    if(index !== -1) {
                        //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                        obj.expandedRowsCostkeys.splice(index, 1);
                    }
                    setDraggable("costkey-draggable");
                },
                cellEdited: function(cell) {
                    var rowData = cell.getRow().getData();
                    let name = rowData[_name];
                    let firstChar = name[0];
                    if(!isNaN(Number(firstChar))) {
                        obj.setOpenWarningDialog(true, MESSAGES.name_starting_number)
                        rowData[_name] = "";
                    }
    
                    let nameExists = obj.checkExistingName(name, obj.state.profitStackFields, rowData[_costKey], cell.getOldValue());
                    if (nameExists === 1){
                        rowData[_name] = "";
                        obj.setOpenWarningDialog(true, MESSAGES.name_used);
                    } else if (nameExists === -1){
                        obj.setOpenWarningDialog(true, MESSAGES.name_reserved);
                        rowData[_name] = "";
                    }
                    
                    let isChanged = obj.state.isChanged;
                    if (nameExists !== 1 && nameExists !== -1) {
                        if (obj.isProfitStackMapping && rowData[_costType] === costtype.attribute && !parseBoolean(rowData[PS_MAPPING.FIELDS.ORIGINAL_KEY])) {
                            rowData[_returnName] = name;
                        }
                        isChanged = true

                        var mappedLines = obj.state.mappedLines;
                        for (var e in mappedLines) {
                            if (mappedLines[e][_costKey] === rowData[_costKey]) {
                                mappedLines[e][_name] = rowData[_name];
                                mappedLines[e][_returnName] = rowData[_name];
                                if (mappedLines[e]["row_status"] !== "new_row" && mappedLines[e][_costType] === "attribute") {
                                    mappedLines[e]["row_status"] = 'edited';
                                }
                            }
                        }
                        
                    }
                    var tempData = obj.updateChildAttr(cell.getTable().getData(), rowData[_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                    obj.setState({
                        isChanged: isChanged,
                        warningMessage: warningMessage,
                        originalData: copyObjectValues(tempData),
                        profitStackFields: tempData
                    })

                    if (tempData) {
                        obj.tabulator.replaceData(tempData); 
                    } 

                    cell.getColumn().getElement().removeEventListener('keypress', function (e) {
                        if (e.which === 13) {
                            e.stopImmediatePropagation();
                        }
                    }, false);
                },
                cellEditing: function(cell) {
                    cell.getColumn().getElement().addEventListener('keydown', function (e) {
                        if (e.which === 13) {
                            e.stopImmediatePropagation();
                        }
                    }, false);
                }
            }
            
            //table columns
            var tableColumns = null;
                if(this.isMetricMapping) {
                tableColumns = copyObjectValues(METRICS_COLUMNS);
            }
            tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
            options.columns = tableColumns;		//set the columns as the actual columns of the table
            if (this.isMetricMapping) {
                options.placeholder = '<div class="uk-text-center"><i class="far fa-plus-circle uk-heading-primary uk-add add-metric" id="metric_lines_add_row"></i><h4 class="uk-padding-xsmall-top">Add Metric</h4></div>';
               
            }
            this.tabulator = new Tabulator(this.refs.mainTable, options);
            if(this.props.periods) {
                this.setState({
                    periods: this.props.periods,
                    periodName: this.props.selectedPeriod ? this.props.selectedPeriod.value :"",
                    periodTotals: this.props.totals ? this.props.totals : []
                }, function() {   
                    this.init();
                });
            }
        // }
        $(".add-metric").click(function(){
            obj.onAddParentRow();
        });

    }

    componentWillUnmount() {
        $("#header-next").unbind('click');
        $("#header-back").unbind('click');
        // $("#header-buttons").addClass("uk-hidden");    
    }

    addNewRowTitleFormatter(cell, params) {
        let btn = getTableButton(
            params.comp.isMetricMapping ? "Add metric" : "",
            ["uk-text-normal", "uk-margin-small-left"],
            ["uk-button-icon", "transparent-bg"],
            [
              "far",
              "fa-plus-circle",
              "fa-lg",
              "add-row"
            ],
            "left",
        );

        btn.setAttribute("id", "add-row-top-icon");

        if (!params.comp.isMetricMapping) {
            btn.classList.remove("uk-button-icon", "transparent-bg");
            btn.classList.add("d-inline-grid", "uk-cursor-pointer");
        }

        btn.onclick = params.funkName;
        return btn;
    }

    getColumnValuesFilter(rawFileSubtypeId, fieldDataType, column, rowIndex, _function, index ,flag, callback, type,vectorId,filterAttribute){
        if(this.glRef) {
            this.glRef.getColumnValues(rawFileSubtypeId, fieldDataType, column, rowIndex, _function, index, flag, callback, type,vectorId,filterAttribute);
        }
    }

    validateConditions(formula) {
        var _this = this;
        var validated = true;
        var conditions = formula.conditions;
        var results = [formula.result];

        conditions.forEach(cond=>{
            results.push(cond.result);
        });

        results.forEach(res=>{
            if(validated) {
                validated = _this.vfRef.formulaDRef.validateFormulaResult(res);
            }
        });

        return validated;
    }

    saveMetricMapping() {
        var mappedMetric = this.glRef.state.mappedMetric;
        var metricConfigurationRows = this.glRef.metricConfRef.state.metricConfigurationRows;
        var conditionFormula = this.vfRef.finalFormula;
        var validated = this.validateConditions(conditionFormula)
        if(!validated) {
            this.setOpenWarningDialog(true, MESSAGES.calc_cols.fill_condition_correctly_err)
            return;
        }

        var rows = [];
        for (var e in metricConfigurationRows) {
            if(metricConfigurationRows[e].current){
                rows.push(metricConfigurationRows[e].current.state.metricConfigurationObject);
            }        
        }
        mappedMetric[METRICS_MAPPING.FIELDS.METRIC_CONFIGURATION] = [{metricConfiguration: rows, conditionFormula: [conditionFormula]}]
        this.glRef.saveMapping(mappedMetric);
        $("#load-metric-value").removeClass("disabled uk-disabled");
    }

    getConditionFormula() {
        return this.vfRef.finalFormula;
    }

    /**
     * This function updates result's colIndex in final formula to be correct based on metric configuration row number (PI-26636)
     * @param {*} metricConfigurationRows 
     */

    updateConditionFormula = (metricConfigurationRows) => {        
        for(let index in metricConfigurationRows) {
            let metricConfigurationObject = metricConfigurationRows[index]?.current?.state?.metricConfigurationObject;
            
            let fileType = metricConfigurationObject?.file_type;
            let rawFieldName = metricConfigurationObject?.raw_field_name;
            
            let rowNumber = Number(index) + 1;
            if(this.vfRef && this.vfRef !== null){
                
                this.vfRef.finalFormula.result
                .filter(result => result?.value === rawFieldName && result?.file_type === fileType && result?.type === "column")
                .forEach((result)=>{
                    result.colIndex = rowNumber
                })

                let conditions = this.vfRef.finalFormula?.conditions;
                for(let index in conditions){
                    let result = conditions[index]?.result;
                    result.filter(result => result?.value === rawFieldName && result?.file_type === fileType && result?.type === "column")
                    .forEach((result)=>{
                        result.colIndex = rowNumber
                    })
                }
            }
        }
        this.forceUpdate();
    }

    handleElementChange(type, e) {
        let newValue = e.currentTarget instanceof Node ? $(e.currentTarget).val() : e.value;

        if (type === "attribute-select") {
            this.setState({
                attributeChosen: e,
                attributeValue: newValue,
                attributeType: e.file_type && e.file_type.indexOf(PS_MAPPING.ATTR_TYPE_VALUES.VEC) > -1 ? PS_MAPPING.ATTR_TYPE_VALUES.VEC : e.file_type && e.file_type.indexOf(PS_MAPPING.ATTR_TYPE_VALUES.CALC) > -1 ? PS_MAPPING.ATTR_TYPE_VALUES.CALC : "",
                onElementChange: true
            })
        } else if ("attribute-function") {
            if (e === _attributeFunctions.COUNT) {
                $("#attribute-sum").removeClass('checked');
                $("#attribute-count").addClass('checked');
            } else if (e === _attributeFunctions.SUM) {
                $("#attribute-sum").addClass('checked');
                $("#attribute-count").removeClass('checked');
            }
            this.setState({
                attributeFunction: e,
                onFunctionChange: true
            })
        }

    }

    setOpenConfirmDeleteLineDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openConfirmDeleteLineDialog: isOpen
      })
    }

  confirmDeleteLineDialogActions = () => {
    return (
      <>
        <Button
          label={"Delete"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.onDeleteRow(this.state.row)}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenConfirmDeleteLineDialog(false)}
        />
      </>
    )
  }
  setOpenConfirmBackCancelDialog = (isOpen) => {
      let _this = this;
      _this.setState({
        openConfirmBackCancelDialog: isOpen
      })
    }

  confirmBackCancelDialogActions = () => {
    return (
      <>
        <Button
          label={"Yes"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.setStagingReport}
        />
        <Button
          label={MESSAGES.modal.buttons.no}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setOpenConfirmBackCancelDialog(false)}
        />
      </>
    )
  }

  setOpenWarningDialog = (isOpen, msg) => {
    let _this = this;
    _this.setState({
      openWarningDialog: isOpen,
      warningMessage: msg
    })
  }

  warningDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setOpenWarningDialog(false, "")}
      />
    )
  }

    resetMetricValue = () => {
        let data = this.tabulator.getData();
        data.forEach(item => {
            item[METRICS_MAPPING.FIELDS.METRIC_VALUE] = 0;
            item[METRICS_MAPPING.FIELDS.FETCH_DATA] = true;
        })
        this.tabulator.replaceData(data);
    }

    abortGetMetricsDataRequest = () => {
        if(this.isParsingAmount){
            this.isParsingAmount = false;
            //cancel request
            this.controller.abort()
            //Re initialize controller after canceling
            this.controller = new AbortController()
            this.signal = this.controller.signal;
            if(this.state.isChangedPeriod){
                this.resetMetricValue();
            }
        }
    }

    render() {
        var obj = this;
        this.showHideAddColumn();
        var attributeFields =  this.state.calculatedColumns && this.state.calculatedColumns.length > 0 ? this.state.vectorList.concat(this.state.calculatedColumns) : this.state.vectorList;
        attributeFields = attributeFields.length > 0 ? arrangeData(attributeFields) : [];

        var objMapped = this.state.mappedLines.filter(e => obj.state.profitStackLineToMap && e[_costKey] === obj.state.profitStackLineToMap[_costKey]);
        var attrValue = this.state.onElementChange && this.state.attributeValue ? findOptionByKey(attributeFields, this.state.attributeValue) : objMapped && objMapped[0] && objMapped[0][_attribute] && objMapped[0][_attribute] !== "" ? findOptionByKey(attributeFields, objMapped[0][_attribute]) : "";
        var attrFunction = this.state.onFunctionChange ? this.state.attributeFunction : objMapped && objMapped[0] && objMapped[0][_attributeFunction] && objMapped[0][_attributeFunction] !== "" ?
            objMapped[0][_attributeFunction] : "";    
        
        const optionLabel = ({ value, label, isDisabled }) => (
            <div title={label} className={(isDisabled ? "option-group-header" : "")+ " option-padding"}>{label}</div>
        );

        const { amount } = this.state;
        return (
            <div style={{height:"100%"}}>
                <div id="amountModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="uk-flex justify-content-between uk-padding-large-top uk-padding-large-right uk-padding-large-left">
                                <h4 className="uk-margin-remove" >Detailed Amount</h4>
                                <Button 
                                    variant={BUTTON_VARIANT.TERTIARY}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    className={"close-button uk-margin-remove"}
                                    data-dismiss="modal" 
                                    aria-label="Close"
                                    leftIcon={<i className="fal fa-times" />}
                                />
                            </div>
                            <div className = "amount-table uk-padding-large">
                            </div>
                            <div className="uk-flex justify-content-end uk-padding-large-right uk-padding-small-bottom">
                                <button className="red-btn btn_primary" data-dismiss="modal" >Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="redirectModal" className="modal fade" role="dialog"> {/* NOT USED */}
                    <div className="modal-dialog modal-md">
                        <div className="modal-content uk-padding-xmedium">
                            {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button> */}
                            <h5 className="uk-margin-small-bottom">Please define industry in settings screen then come back</h5>
                            <button className="btn uk-align-right uk-margin-small-left uk-margin-top" data-dismiss="modal" onClick={()=>this.discardChangesAndGoBack(true)}>Okay</button>
                        </div>
                    </div>
                </div>      
                <div id="confirmUpdateModal" className="modal fade" role="dialog"> {/* NOT USED */}
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="mrgb40">
                                <button type="button" className="close-button" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="fal fa-times"></i></span>
                                </button>
                            </div>
                            <h5 className="padding-left-15px">Do you want to save changes made on profit stack?</h5>
                            <div className="modal-body uk-flex justify-content-between uk-margin-default-right-left">
                                <Button  
                                    label={lang.modal.buttons.cancel}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-dismiss="modal"
                                    onBtnClick={()=>{this.hideGLAccounts}}
                                />
                                <Button  
                                    label={"Discard and continue"}
                                    variant={BUTTON_VARIANT.SECONDARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-dismiss="modal"
                                    onBtnClick={this.discardChangesAndGoToExclude}
                                />
                                <Button 
                                    label={"Save and continue"}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}  
                                    data-dismiss="modal"
                                    onBtnClick={()=>{this.updateScenario(true)}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
               
                    <div>
                        <ViewMoreMetric ref={r => this.viewMoreRef = r} clickedCell={this.state.clickedCell}
                            resetmetricDetails={this.resetmetricDetails} data={this.state.metricDetails} getMetricsData={this.getMetricsData} 
                            periods={this.props.periods} summaryData={this.state.summary} selectedPeriod={this.props.selectedPeriod} 
                            metricDetailsId= {this.state.metricDetailsId} metricDetailsColumns={this.state.metricDetailsColumns}
                            summaryColumns={this.state.summaryColumns} vectors = {this.state.vectors}
                        />
                    </div>             
                <div id="profitStackMapping" className="old-psl psm-container uk-display-inline-flex uk-width-1-1">
                    {this.isMetricMapping ?
                        <React.Fragment>
                            <div id="GLAccountsModal" className="GL-accounts-modal profit-stack-mapping" style={{height:"100%"}}>
                                <Loader fullContainer={false} />
                                {this.state.showGLAccounts ?
                                    <GLAccountList ref={el => this.glRef = el} rowDataCC={this.state.rowDataCC} callShowScenarioPopUp={this.showScenarioPopUp} callTotals={this.getTotals}
                                        accountsType={this.state.accountsType} periods={this.state.periods}  periodDetails={this.props.periodDetails} periodList={this.props.periodList}
                                        profitStackLine={this.state.profitStackLine} mappedLines={this.state.mappedLines} scenarioId={this.props.scenarioId} hideGLAccounts={this.hideGLAccounts}
                                        costKey={this.state.costKey} parentCostKey={this.state.parentCostKey} setMappedLines={this.setMappedLinesData} pslLine={this.state.pslLine}
                                        ASSIGNED={ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION} EXCLUDED={ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION} glType={this.state.glType}
                                        periodName={this.props.selectedPeriod.value} stagingReport={this.props.stagingReport} mappedMetric={this.state.mappedMetric}
                                        isManageExclusions={this.state.isManageExclusions} updateMappedMetric={this.updateMappedMetric} scenarioStatus={this.props.scenarioStatus}
                                         validateMetricData={this.validateMetricData} newMetric={newMetric} filter={this.state.filter} 
                                        costCenter={this.props.costCenter} setChosenTimePeriod={this.props.setChosenTimeperiod} isChanged={this.state.isChanged}
                                        pssFields={this.tabulator.getData()} pssSiblings={this.state.pssSiblings} ancillaryColsAmounts={this.state.ancillaryColsAmounts}
                                        exceptionPSLOptions={this.state.exceptionPSLOptions} leadingPssId={this.state.leadingPssId} leadingPssCostKey={this.state.leadingPssCostKey}
                                        allAncillaryColumns={this.props.allAncillaryColumns} mappedLine={this.state.mappedLine} clientCostTerms={this.props.clientCostTerms}
                                        costTerm={this.state.costTerm} userAllowedSections={this.props.userAllowedSections} calculatedCols={this.state.calculatedCols || []}
                                        metricFields={this.state.metricFields} metricsData={this.state.metricsData} vectors={this.state.vectors}
                                        allTypeColumns={this.props.allTypeColumns} vectorList={this.state.vectorList} calculatedColumns={this.state.calculatedColumns} 
                                        updateFilterConditionFiles={this.updateFilterConditionFiles} getConditionFormula={this.getConditionFormula} updateConditionFormula={this.updateConditionFormula}
                                        appendToFormula={this.appendToFormula} saveMetricMapping={this.saveMetricMapping}
                                        profitStackLineToMap={this.state.profitStackLineToMap} vectorOptions={this.props.vectorOptions} hiddenVectors = {this.state.hiddenVectors}
                                        scenarioType={this.props.scenarioType} setOpenWarningDialog={this.setOpenWarningDialog}
                                        disableHeaderButtons={this.props.disableHeaderButtons}
                                    />
                                : ""}
                            </div>
                            <div id="metricValueFormula" className="GL-accounts-modal" style={{height:"100%"}}>
                                <div className="right-section-container">
                                    <div className="mapping-header justify-content-between">
                                        <div className="uk-display-inline-flex align-items-center">
                                                <div className="uk-display-inline-flex align-items-center">
                                                <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{MESSAGES.metrics_metric_value_formula_title}</span>
                                                <i className="fs-12 fal fa-info-circle uk-xmargin-small-left" uk-tooltip={"TBD"} />
                                            </div>
                                        </div>
                                    </div>
                                    { this.state.showValueFormula ?
                                        <React.Fragment>
                                            <div className="value-formula-padding uk-overflow-auto">
                                                <ValueFormula ref={el=> this.vfRef = el} mappedMetric={this.state.mappedMetric} allTypeColumns={this.props.allTypeColumns} vectors={this.state.vectorList} calculatedCols={this.state.calculatedColumns} getColumnValuesFilter={this.getColumnValuesFilter} chosenFiles={this.state.chosenFiles} vectorOptions={this.props.vectorOptions}  periodName={this.state.periodName} hiddenVectors = {this.state.hiddenVectors}/>
                                            </div>
                                            <div id="submit_GL" className="uk-position-none gl-mapping-footer uk-display-flex uk-margin-remove">
                                                <Button 
                                                    label={"Submit"}
                                                    variant={BUTTON_VARIANT.PRIMARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT} 
                                                    onBtnClick={this.saveMetricMapping}
                                                />
                                                <Button  
                                                    label={lang.modal.buttons.cancel}
                                                    variant={BUTTON_VARIANT.SECONDARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT}
                                                    className="uk-margin-small-left"
                                                    onBtnClick={()=>{
                                                        $("#load-metric-value").removeClass("disabled uk-disabled");
                                                        this.vfRef.resetFormula();
                                                        this.glRef.hideGLAccount()
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :"" }
                                </div>
                            </div>
                        </React.Fragment>
                    : ""}
                    <div id="mainTable" style={{width:"100%"}}>
                        <div className={`${(this.isPSCalculated || this.isMetricMapping ? "metrics-mapping" : "")} uk-border uk-background-default`}>
                            <div className="mapping-header justify-content-between">
                                <div className="uk-display-inline-flex align-items-center">
                                    <div className="uk-display-inline-flex align-items-center">
                                        <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{this.isPSCalculated ? MESSAGES.ps_mapping.left_section.title : capitaliseFirstLetterAfterChar(this.props.stagingReport).replaceAll("_", " ")}</span>
                                        <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={this.isProfitStackMapping ? this.isPSCalculated ? "title: " + MESSAGES.ps_mapping.title_tooltip.calculated +"; pos: bottom-right" : "title: " + MESSAGES.pss_mapping_title +"; pos: bottom-right" : this.isMetricMapping ? MESSAGES.metrics_mapping_title : ""} />
                                    </div>
                                    {this.state.isChanged && !this.isPSCalculated ?
                                        <span id="changes_made" style={{display: "flex"}}>Changes not saved</span>
                                        :""
                                    }
                                </div>
                                { this.isMetricMapping ? 
                                    <div>
                                        <p className="uk-text-normal text-grey no-cursor fs-14 uk-margin-medium-right">{MESSAGES.metrics_mapping_header_period_description + this.props.selectedPeriod.value}</p>
                                    </div>
                                : ""}
                            </div>
                            <div className="profitStackMapping metrics_mapping_table">
                                <div id="profitStackMapping" ref="mainTable"/>
                            </div>
                        </div>
                    </div>
                    { !this.isMetricMapping ?
                        <React.Fragment>
                            <div id="GLAccountsModal" className="GL-accounts-modal profit-stack-mapping height-75vh">
                            <Loader fullContainer={false} />
                            {this.state.showGLAccounts ?
                                <div className={`${(this.isPSCalculated ? "right-section-container" : "map-exception-container")}`}>
                                    <GLAccountList ref={el => this.glRef = el} callShowScenarioPopUp={this.showScenarioPopUp} callTotals={this.getTotals} accountsType={this.state.accountsType}
                                        periods={this.state.periods} periodDetails={this.props.periodDetails} periodList={this.props.periodList} profitStackLine={this.state.profitStackLine}
                                        mappedLines={this.state.mappedLines} scenarioId={this.props.scenarioId} hideGLAccounts={this.hideGLAccounts} costKey={this.state.costKey}
                                        parentCostKey={this.state.parentCostKey} setMappedLines={this.setMappedLinesData} isParsingAmount={this.isParsingAmount}
                                        pslLine={this.state.pslLine} ASSIGNED={ACCOUNT_AMOUNT_TITLES.ASSIGNED_COMBINATION} EXCLUDED={ACCOUNT_AMOUNT_TITLES.EXCLUDED_COMBINATION}
                                        glType={this.state.glType} periodName={this.props.selectedPeriod.value} stagingReport={this.props.stagingReport} mappedMetric={this.state.mappedMetric}
                                        isManageExclusions={this.state.isManageExclusions} updateMappedMetric={this.updateMappedMetric} scenarioStatus={this.props.scenarioStatus}
                                         validateMetricData={this.validateMetricData} newMetric={newMetric} filter={this.state.filter}
                                        metricFields={this.state.metricFields} costCenter={this.props.costCenter} setChosenTimePeriod={this.props.setChosenTimeperiod}
                                        isChanged={this.state.isChanged} pssFields={this.tabulator.getData()} pssSiblings={this.state.pssSiblings} leadingPssId={this.state.leadingPssId}
                                        leadingPssCostKey={this.state.leadingPssCostKey} ancillaryColsAmounts={this.state.ancillaryColsAmounts} mappedLine={this.state.mappedLine}
                                        exceptionPSLOptions={this.state.exceptionPSLOptions} allAncillaryColumns={this.props.allAncillaryColumns} clientCostTerms={this.props.clientCostTerms}
                                        costTerm={this.state.costTerm} userAllowedSections={this.props.userAllowedSections} calculatedCols={this.state.unusedCalculatedCols}
                                        metricsData={this.state.metricsData} exceptionMetrics={this.state.exceptionMetrics}
                                        getParentAmount={this.getParentAmount} profitStackLineToMap={this.state.profitStackLineToMap}
                                    />
                                </div>
                            : ""}
                            </div>
                            <div id="attributeFormulaModal" className="GL-accounts-modal attribute-formula-modal">
                                <div className="right-section-container">
                                    <div className="mapping-header justify-content-between">
                                        <div className="uk-display-inline-flex align-items-center">
                                                <div className="uk-display-inline-flex align-items-center">
                                                <span className="uk-text-bold uk-text-xmedium uk-display-flex uk-flex-middle">{MESSAGES.profit_stack_mapping_attribute_title + " - " + this.state.profitStackLine}</span>
                                                <i className="fs-12 fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.profit_stack_mapping_attribute_info} />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.showAttributeFormula ?
                                        <React.Fragment>
                                            <div className="uk-margin-medium-left uk-margin-medium-top uk-height-fit">
                                                <span className="fs-14">Description</span>
                                                <textarea id="metric_description" className="form-control uk-textarea input-default" placeholder={MESSAGES.psl_placeholders.attribute_line_description}>
                                                </textarea>
                                                <div className="width-250 uk-display-inline-block">
                                                    <label className="uk-text-xmedium uk-margin-xsmall-bottom" htmlFor="select-set-attribute">{MESSAGES.profit_stack_mapping_attribute_select_title}</label>
                                                    <DropDown
                                                        id="select-set-attribute"
                                                        className="width-250 uk-cursor-pointer input__dropdown"
                                                        name={"attribute-select"}
                                                        value={attrValue}
                                                        onChange={(e) => this.handleElementChange("attribute-select", e)}
                                                        options={attributeFields}
                                                        placeholder="Select a vector or a calculated column"
                                                        formatOptionLabel={optionLabel}
                                                        type={DROPDOWN_TYPE.INPUT}
                                                        />
                                                </div>
                                                <div className="mrgl50 uk-display-inline-flex" style={{marginTop: convertPxToViewport(43)}}>
                                                    <p>
                                                        <button id="attribute-count" className={(attrValue[RAW_ITEMS.SUBTYPE_NAME] === "vectors" ? " checked disabled " : attrFunction !== _attributeFunctions.SUM ? " checked " : " ") + " pssMappingButton"} onClick={()=>this.handleElementChange("attribute-function", "count")}>Count</button>
                                                        <button id="attribute-sum" className={(attrValue[RAW_ITEMS.SUBTYPE_NAME] === "vectors" ? "uk-disabled" : attrFunction === _attributeFunctions.SUM ? "checked " : " ") + " pssMappingButton"} onClick={()=>this.handleElementChange("attribute-function", "sum")}>Sum</button>
                                                    </p>
                                                </div>
                                            </div>
                                            <div id="submit_GL" className="uk-position-none gl-mapping-footer uk-display-flex justify-content-end uk-margin-remove">
                                                <Button 
                                                    label={lang.modal.buttons.submit}
                                                    variant={BUTTON_VARIANT.PRIMARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT}
                                                    disabled={attrValue === ""}
                                                    onBtnClick={this.submitAttribute}
                                                />
                                                <Button  
                                                    label={lang.modal.buttons.cancel}
                                                    variant={BUTTON_VARIANT.SECONDARY}
                                                    size={SIZES.DEFAULT}
                                                    type={BUTTON_TYPE.DEFAULT}
                                                    className="uk-margin-small-left"
                                                    onBtnClick={()=>{this.cancelAttribute()}}
                                                />
                                            </div>
                                        </React.Fragment>
                                    :"" }
                                </div>
                            </div>
                        </React.Fragment>
                    : ""}

                </div>
                <Modal
                  id={"confirm-delete-line-dialog"}
                  openDialog={this.state.openConfirmDeleteLineDialog}
                  bodyContent={() => <h4>{lang.calc_cols.delete_confirmation + (Object.getOwnPropertyNames(this.state.row).length > 0 && this.isMetricMapping ? this.state.row[METRICS_MAPPING.FIELDS.METRIC] : "") + "?"}</h4>}
                  dialogActions={this.confirmDeleteLineDialogActions}
                  closeClick={() => this.setOpenConfirmDeleteLineDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"confirm-back-cancel-dialog"}
                  openDialog={this.state.openConfirmBackCancelDialog}
                  bodyContent={() => <h4>{MESSAGES.discard_changes_confirmation}</h4>}
                  dialogActions={this.confirmBackCancelDialogActions}
                  closeClick={() => this.setOpenConfirmBackCancelDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"warning-dialog"}
                  openDialog={this.state.openWarningDialog}
                  bodyContent={() => <h4>{this.state.warningMessage}</h4>}
                  dialogActions={this.warningDialogActions}
                  closeClick={() => this.setOpenWarningDialog(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
            </div>
            
        );
    }
}

export default ProfitStackMapping;