import React, { Component } from "react";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, CLIENT_ID_STORAGE, COLUMN_PROFILE, ORDER_VALUES, PROFILE_COLUMN, SIZES } from "../class/constants";
import { setTypePassword } from "../class/jqueries";
import { concatenateStrings, findOptionByKey, getSectionId, parseBoolean, removeAttributes } from "../class/utils";
import { lang } from "../language/messages_en";
import { linearizeHierarchy } from "../class/array";

import { getLocalStorageValueByParameter } from "../class/common";
import Button from "../newComponents/Button";
/**
 * Dynamic component to be used to authenticate before exporting to csv with all fields sent as props
 * @author [Mostafa Haydar]
 */
const $ = require('jquery');
const RECORDS_1M = 1000000;


class ConfirmPasswordModal extends Component {
    constructor(props) {
        super(props);
        this.state={
            userAuthenticated_exportAll: false,
            dataLength: ""
        }
    }

    getViews=()=>{
        let _this = this;
        let pss_lines = _this.props.pssLines? _this.props.pssLines : _this.props.pageComponent && _this.props.pageComponent.manageColumnsDrillRef ?
        _this.props.pageComponent.manageColumnsDrillRef.state.profitStackLineColumns:  _this.props.pageComponent && _this.props.pageComponent?.props?.manageColumnsDrillRef ? _this.props.pageComponent?.props?.manageColumnsDrillRef?.state?.profitStackLineColumns :"";
        let lines = pss_lines ? linearizeHierarchy(pss_lines[0][PROFILE_COLUMN.CHILDREN],PROFILE_COLUMN.CHILDREN):"";
        lines = lines? lines.filter(em=>!["column_group_percentages","column_group_attributes","column_group_medians","column_group_averages"].includes(em[PROFILE_COLUMN.RETURN_NAME])):"";
        let checkedLines = removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] || this.props.manageColumnsProfile["originalColumns"]);
        let checkedLinesAppended = [];
        for (var e in lines) {
            if (checkedLines.filter(elt=>elt[PROFILE_COLUMN.RETURN_NAME] === lines[e][PROFILE_COLUMN.RETURN_NAME] && elt[PROFILE_COLUMN.MACHINE_NAME] === "value").length === 0) {
                let row = {};
                row[PROFILE_COLUMN.RETURN_NAME] = lines[e][PROFILE_COLUMN.RETURN_NAME];
                row[PROFILE_COLUMN.MACHINE_NAME] = "value";
                row[PROFILE_COLUMN.TYPE] = "psl";
                row[PROFILE_COLUMN.COLUMN_ORDER] = Number(lines[e].rank)*1000;
                checkedLinesAppended.push(row);
            }
        }
        checkedLines = checkedLines.concat(checkedLinesAppended);
        return JSON.stringify(checkedLines);
    }
    
    render() {
        let dataLength = this.props.isDrilling && this.state.dataLength ? this.state.dataLength : this.props.dataLength;
        let largeResultNote = (typeof(dataLength) === 'number'? dataLength : parseFloat(dataLength.replace(/,/g, ""))) > RECORDS_1M ?
            <h5 className={"uk-margin-large-left uk-margin-small-bottom"}><span className={"uk-text-bold"}>Note:</span> The result you are trying to export
                is larger than one million rows. Only one million rows will be exported.</h5>
            : "";
        var disabled = /*!this.props.userAuthenticated_exportAll &&*/ !this.state.userAuthenticated_exportAll;
        var disabledClass = disabled ? " uk-button-disabled" : "";
        var userEmail = this.props.user ? this.props.user.email : "";
        let exportValue = this.props.getExportValue ? this.props.getExportValue() : this.props.export_all;
        let columnsViews = this.props.manageColumnsProfile ? exportValue ? this.getViews() : 
          JSON.stringify(removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] || this.props.manageColumnsProfile["originalColumns"])) || [] : []; 
        // let columnsViews = this.props.manageColumnsProfile ? JSON.stringify(removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS])) || [] : [];
        let periodsObject = this.props.getPeriodsObject(this.props.periodsStatusState, this.props.clientPeriodsState, this.props.datasetState);
        let quarter  = periodsObject.quarter;
        let months = periodsObject.months;
        let periods = periodsObject.periods;
        let tier = "";
        let tierName = "";
        if(this.props.isDrilling && this.props.pageComponent){
            tier = this.props.pageComponent.state.drillTier;
            tierName = findOptionByKey(this.props.vectorOptions, this.props.isDrilling? this.props.pageComponent.state.drillTier : this.props.vectors[0]).label;
        } else if (this.props.dataTier){
            tier = this.props.dataTier;
            tierName = findOptionByKey(this.props.vectorOptions,this.props.dataTier).label;
        } else {
            tier = this.props.vectors[0];
            tierName = findOptionByKey(this.props.vectorOptions, this.props.isDrilling? this.props.pageComponent.state.drillTier : this.props.vectors[0]).label;
        }
        // let filterQuery = "";
        // if(this.props.filterDialRef){
        //     filterQuery =  this.props.filterDialRef.state.exportQueryFilter;
        // }
        // if(this.props.dimensionFilterDialRef){
        //     filterQuery += " " + this.props.dimensionFilterDialRef.state.exportQueryFilter;
        // }
        let exportQueryFilter = typeof this.props.exportQueryFilter === "function"  ? this.props.exportQueryFilter() : this.props.exportQueryFilter;
        
        let drillParams = [];
        if(this.props.pageComponent?.state?.cardsData) {
          this.props.pageComponent?.state?.cardsData.forEach((card, index) => {
              let vectors = [];
              vectors.push(Array.isArray(card.data[0].tier)?card.data[0].tier[0]:card.data[0].tier);
              if(card.otherSelectedVectorsData) {
                if (Array.isArray(card.otherSelectedVectorsData[0])) {// if multiple entities are selected
                    vectors = vectors.concat([...new Set(card.otherSelectedVectorsData[0].map(m => m?.label).filter(e=>e))]);
                }else{
                    vectors = vectors.concat(card.otherSelectedVectorsData.map(m => m.label));
                }
              }
              drillParams.push("{index:'" + index + "', params:'" + concatenateStrings(vectors) + "'}");
          });
          drillParams = "[" + drillParams.join(",") + "]";
        }

        return (
            <div id="confirmPasswordModal" className="modal fade" role="dialog">
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <button type="button" className="close-button uk-width-full uk-text-right uk-padding-large-top uk-padding-large-right uk-margin-remove uk-float-unset" data-dismiss="modal" aria-label="Close" onClick={this.cancel}>
                                    <span aria-hidden="true"><i className="fal fa-times" /></span>
                                </button>
                                <div className="modal-body uk-padding-remove-top">
                                    {largeResultNote}
                                    <h5 className="uk-margin-large-left uk-margin-xmedium-bottom uk-margin-remove-top">{lang.header.custom_text.reauthenticate_modal_message}</h5>
                                    <form>
                                        <div className="uk-display-flex uk-flex-column uk-flex-middle uk-width-full">
                                            <div className="uk-margin-bottom uk-display-flex uk-flex-column">
                                                <label className="fs-14 uk-margin-xsmall-bottom" htmlFor="email">Email</label>
                                                <input type="text" className="inputConfirm" disabled
                                                    value={userEmail}/>
                                            </div>
                                    {this.props.isClientSSO && !parseBoolean(this.props.user.is_system) ?
                                        "" :
                                        <div className="uk-margin-bottom uk-display-flex uk-flex-column">
                                            <label className="fs-14 uk-margin-xsmall-bottom" htmlFor="password">Password</label>
                                            <input id="passwordConfirm" className="inputConfirm" type="text"
                                                placeholder="Please type your password..."
                                                onFocus={setTypePassword} />
                                        </div>}
                                        </div>
                                    </form>
                                    
                                    <form id="export-all-form" name="exportAllForm" target="frame"
                                        action={process.env.REACT_APP_BASE_URL + API_URL.PROFIT_LANDSCAPE_EXPORT_ALL} method="POST"
                                        encType="application/json">
                                        {/* <input type="hidden" name="action" value={this.state.exportAll_query}/> */}
                                        <input type="hidden" name="action" value="exportAll"/>
                                        <input type="hidden" name="tier" value={tier}/>
                                        <input type="hidden" name="tierName" value={tierName}/>
                                        <input type="hidden" name="profitFormat" value={this.props.profitFormat}/>
                                        <input type="hidden" name="parentKey" value="201"/>
                                        <input type="hidden" name="quarter" value={quarter}/>
                                        <input type="hidden" name="fy" value={months}/>
                                        <input type="hidden" name="columns" value={this.props.isDrilling && this.props.pageComponent && this.props.pageComponent!== null && this.props.pageComponent?.manageColumnsDrillRef ? this.props.pageComponent?.manageColumnsDrillRef.getProfileColumns() : this.props?.manageColumnsRef && this.props?.manageColumnsRef !== null ? this.props?.manageColumnsRef?.getProfileColumns() : this.props.secondaryHeader !== null && this.props.secondaryHeader?.manageColumnsRef ? this.props.secondaryHeader?.manageColumnsRef?.getProfileColumns() || "" : ""}/>
                                        <input type="hidden" name="order" value={ORDER_VALUES.DESC}/>
                                        <input type="hidden" name="filter" value={"{'filter':" + ((this.props.isDrilling && this.props.drillFilter || encodeURIComponent(this.props.filterFinal)) === "" ? "[]" : (this.props.isDrilling && this.props.drillFilter ? typeof this.props.drillFilter === 'object' ? JSON.stringify(this.props.drillFilter) : this.props.drillFilter :  encodeURIComponent(this.props.filterFinal))) + "}"}/>
                                        <input type="hidden" name="section_id" value={getSectionId("PM", this.props.match.params.sectionId).toString()}/>
                                        <input type="hidden" name="url" value={window.location.href.split("#")[0]}/>
                                        <input type="hidden" name="client_id" value={getLocalStorageValueByParameter(CLIENT_ID_STORAGE)}/>
                                        <input type="hidden" name="isTempScenario" value={this.props.isTempScenario}/>
                                        <input type="hidden" name="scenario_id" value={this.props.scenarios[0]}/>
                                        <input type="hidden" name="exportQueryFilter" value={exportQueryFilter || ""}/>
                                        <input type="hidden" name="tracking_data" value={this.props.trackingData}/>
                                        <input type="hidden" name="columnsViews" value={columnsViews}/>
                                        <input type="hidden" name="selectedPeriods" value={periods}/>
                                        <input type="hidden" name="rollingSegment" value={this.props.rollingSegment || ""}/>
                                        <input type="hidden" name="rollingPeriod" value={periodsObject.segmentPeriod || ""}/>
                                        <input type="hidden" name="export_all_psl" value={this.props.exportRef?.current?.state?.export_all_psl || ""}/>
                                        <input type="hidden" name="isDrilling" value={this.props.isDrilling}/>
                                        <input type="hidden" name="drillFilter" value={drillParams || ""} />
                                    </form>
                                    <div id="submitButtons"
                                        className="uk-display-flex justify-content-between uk-margin-default-right-left uk-padding-large-top">
                                        <Button 
                                            id="cancel-pass-btn"
                                            label={"Cancel"}
                                            variant={BUTTON_VARIANT.SECONDARY}
                                            size={SIZES.DEFAULT}
                                            type={BUTTON_TYPE.DEFAULT}
                                            data-dismiss="modal"
                                            onBtnClick={this.props.cancel}
                                        />
                                        <div>
                                            <Button 
                                                id="confirm-pass-btn"
                                                label={"Reauthenticate"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                className={"uk-margin-default-right"}
                                                onBtnClick={this.props.reauthenticateWithCredential}
                                            />
                                            <Button 
                                                id="export-all-download-btn"
                                                label={"Download"}
                                                variant={BUTTON_VARIANT.PRIMARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                onBtnClick={this.props.exportAll}
                                                disabled={disabled}
                                                data-dismiss="modal"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
        );
    }
}

export default ConfirmPasswordModal;
