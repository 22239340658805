import { SPAN } from "../class/constants";
import tracer from "./tracing";
import {SpanStatusCode, SpanKind} from "@opentelemetry/api";
import { logUIInfo } from '../class/networkUtils';

const KIND = 'kind';

export class Span {
  constructor(spanLabel, parent) {
    if (!!parent) {
        this.span = tracer.startSpan(spanLabel, {links: [{context: parent}]}); // linking parent span to another span for better debugging
        this.setParentId(parent.spanId);
    } else {
        this.span = tracer.startSpan(spanLabel);
        this.span.kind = SpanKind.CLIENT;  
    }
    
  }

  setParentId(parentId) {
    this.span.parentSpanId = parentId;
  }

  getParentId() {
    return this.span.parentSpanId;
  }

  getSpanId() {
    return this.span.spanContext().spanId;
  }

  getTraceId() {
    return this.span.spanContext().traceId;
  }


  getSpan() {
    return this.span;
  }

  setAttribute(attributeLabel, attributeValue) {
    this.span.setAttributes({[attributeLabel]: attributeValue});
  }

  setEvent(eventDescription) {
    this.span.addEvent(eventDescription);
  }

  setStatus(status, errorMessage) {
    if ([SpanStatusCode.ERROR, SpanStatusCode.OK].includes(status)) {
        this.span.setStatus({code: status, message: errorMessage || ""});
    } else {
        this.span.setStatus(SpanStatusCode.ERROR, "Couldn't set status for span "+ this.span.getSpanId());
    }
  }

  getContext(){
    return this.span.spanContext();
  }

  setSpanKind() {
    this.span.setAttribute(KIND, SpanKind.CLIENT);  
  }

  endSpan() {
    this.span.addEvent("Span ended...");
    this.span.end();
  }


}
