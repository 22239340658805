// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configureQuadrantsContainer {
    padding-left: 1.61vw;
    padding-right: var(--value-400-px);
    width:70%
}

.configureQuadrantsBorder {
    border-color:#DCDCDC;
    border-style: solid;
    border-width: var(--value-2-px);
  }


.saveConfigureQuadrants { 
    float:right;
    margin-top: var(--value-10-px);
    margin-right: var(--value-20-px);
}



.dropDownsDiv {
    position: relative;
    margin: 5%;
    /* left: var(--value-500-px); */
    /* bottom: var(--value-245-px); */
}

.revenueDiv {
    width:var(--value-215-px);
    padding-bottom: 0.79vw;
}

.select-width {
    width:var(--value-215-px) !important;
} 

.padding-bottom-label {
    padding-bottom:var(--value-6-px)
}`, "",{"version":3,"sources":["webpack://./src/styles/configureQuadrants.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kCAAkC;IAClC;AACJ;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,+BAA+B;EACjC;;;AAGF;IACI,WAAW;IACX,8BAA8B;IAC9B,gCAAgC;AACpC;;;;AAIA;IACI,kBAAkB;IAClB,UAAU;IACV,+BAA+B;IAC/B,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI;AACJ","sourcesContent":[".configureQuadrantsContainer {\n    padding-left: 1.61vw;\n    padding-right: var(--value-400-px);\n    width:70%\n}\n\n.configureQuadrantsBorder {\n    border-color:#DCDCDC;\n    border-style: solid;\n    border-width: var(--value-2-px);\n  }\n\n\n.saveConfigureQuadrants { \n    float:right;\n    margin-top: var(--value-10-px);\n    margin-right: var(--value-20-px);\n}\n\n\n\n.dropDownsDiv {\n    position: relative;\n    margin: 5%;\n    /* left: var(--value-500-px); */\n    /* bottom: var(--value-245-px); */\n}\n\n.revenueDiv {\n    width:var(--value-215-px);\n    padding-bottom: 0.79vw;\n}\n\n.select-width {\n    width:var(--value-215-px) !important;\n} \n\n.padding-bottom-label {\n    padding-bottom:var(--value-6-px)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
