import React, { Component } from 'react';
import Tabulator from "tabulator-tables";
import { cleanUpTabulatorColumns, logout, toggleLoader, setLocalStorageValueByParameter, getLocalStorageValueByParameter } from '../../class/common.js';
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, CACHE_MANAGER, CLIENT_ID_STORAGE, DEFAULT_DATETIME_FORMAT, DIALOG_SIZE, SIZES } from '../../class/constants.js';
import { convertPxToViewport } from '../../class/formatting';
import { alertAndLogError } from '../../class/jqueries.js';
import { convertEasternTZToLocal, copyObjectValues, formatDate, getTranslationFile, getSectionId } from '../../class/utils.js';
import '../../styles/common.css';
import '../../styles/header.css';

import SessionTimeout from '../../SessionTimeout';
import Button from '../../newComponents/Button.js';
import Modal from '../../newComponents/Modal.js';


const baseUrl = process.env.REACT_APP_BASE_URL;
const defaultPath = API_URL.CACHE;

const $ = require("jquery");
const UIKit = require("uikit");
const MESSAGES = getTranslationFile();


const columns = [
    {
        title: CACHE_MANAGER.TITLES.IDENTIFIER,
        field: CACHE_MANAGER.FIELDS.IDENTIFIER,
        visible: false
    },
    {
        title: CACHE_MANAGER.TITLES.KEY_NAME,
        field: CACHE_MANAGER.FIELDS.KEY_NAME,
        formatter: "plaintext",
        formatterParams: {defaultValue:""},
        widthGrow: 2,
        hozAlign:"left"
    },
    // {
    //     title: CACHE_MANAGER.TITLES.VALUE,
    //     field: CACHE_MANAGER.FIELDS.VALUE,
    //     widthGrow: 2,
    // },
    {
        title: CACHE_MANAGER.TITLES.CREATED_DATE,
        field: CACHE_MANAGER.FIELDS.CREATED_DATE,
        widthGrow: 1
    },
    {
        title: CACHE_MANAGER.TITLES.HIT_DATE,
        field: CACHE_MANAGER.FIELDS.HIT_DATE,
        widthGrow: 1,
    },
    {
        title: CACHE_MANAGER.TITLES.ACTION,
        field: CACHE_MANAGER.FIELDS.ACTION,
        widthGrow: 1,
        headerSort: false
    }
]

var obj = "";

class CacheManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
            title: ALL_WIDGETS.TITLES.SYSTEM.CACHE_MANAGER
        }

        this.initializeTabulator = this.initializeTabulator.bind(this);
        this.toggleLoader = toggleLoader.bind(this);
        this.flushCache = this.flushCache.bind(this);
        this.logout = logout.bind(this);

        obj = this;
        obj.flushPreMessage = "";
    }

    getCacheKeyList() {
        this.toggleLoader(true);
        var query = {
            action: "getCacheKeyList",
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${defaultPath}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
            .then((response) => {
                if(response.status === 403) {
                    this.logout();               
				}
            return response.json();
        })
        .then((data)=>{
            if(data.data) {
                obj.tabulator.setData(data.data);
            }
        })
        .catch((error)=>{
            alertAndLogError(error);
        })
        .then(()=>{
            this.toggleLoader(false);
        });
    }

    flushCache() {
        var obj = this;
        obj.setOpenConfirmFlushDialog(false, "");
        this.toggleLoader(true);
        var query = {
            action: "flushCache",
            identifier: this.identifier,
            key: this.key
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      fetch(`${baseUrl}${defaultPath}`, {mode:'cors', credentials:'include', method: "POST", body: JSON.stringify(query)})
        .then((response)=>{
            return response.json();
        })
        .then((data)=>{
            if (data.result === true) {
                obj.getCacheKeyList();
                obj.setOpenAfterFlushDialog(true, obj.flushPreMessage + MESSAGES.flushed_message_confirmation)
            }
        })
        .catch((error)=>{
            alertAndLogError(error);
        })
        .then(()=>{
            this.toggleLoader(false);
        });
    }

    getColumnFormatter(columnField) {
        var obj = this;
        var columnFormatter;
        switch(columnField) {
            case CACHE_MANAGER.FIELDS.IDENTIFIER:
                columnFormatter = function(cell, formatterParams) {
                    return "";
                }
                break;

            case CACHE_MANAGER.FIELDS.ACTION:
                columnFormatter = function (cell, formatterParams) {
                    var a = document.createElement("a");
                    a.textContent = CACHE_MANAGER.FLUSH_ACTION;
                    a.classList.add("uk-cursor-pointer");
                    a.onclick = () => {
                        obj.key = cell.getRow().getData()[CACHE_MANAGER.FIELDS.KEY_NAME];
                        obj.identifier = cell.getRow().getData()[CACHE_MANAGER.FIELDS.IDENTIFIER];
                        obj.flushPreMessage = "Key " + obj.key + " ";
                        obj.setOpenConfirmFlushDialog(true, obj.flushPreMessage + MESSAGES.flush_message_confirmation);
                    }
                    return a;
                }
            break;
            // case CACHE_MANAGER.FIELDS.VALUE:
            //     columnFormatter = function (cell, formatterParams) {
            //         let p = document.createElement("p");
            //         p.style.position = "relative";
            //         p.style.display = "flex";
            //         p.style.overflow = "hidden";
            //         p.style.width = "100%";
            //         p.style.height = "fit-content";
            //         p.textContent = cell.getValue();
            //         return p;
            //     }
            // break;

            case CACHE_MANAGER.FIELDS.CREATED_DATE:
            case CACHE_MANAGER.FIELDS.HIT_DATE:
            columnFormatter = function(cell) {
                var p = document.createElement("p");
                p.textContent = formatDate(convertEasternTZToLocal(cell.getValue()), DEFAULT_DATETIME_FORMAT, false, 3);
                return p;
            }
            break;
        }

        return columnFormatter;
    }

    getUserSettings=(idToken)=> {
        var sectionId = getSectionId("data", "1");

        let query = {
            action: "getUserSettings",
            idToken: idToken,
            section: sectionId,
            url: window.location.href,
            client_id: getLocalStorageValueByParameter(CLIENT_ID_STORAGE)
        }

        setLocalStorageValueByParameter(window.location.host + "_" + "lastRequestSentTime", new Date());
        fetch(`${baseUrl}${API_URL.USER_SETTINGS}`, { mode: 'cors', credentials: 'include', method: "POST", body: JSON.stringify(query) })
            .then((response) => {
                if (response.status === 403) {
                    this.logout();
                }

                return response.json()
            })
            .then((settingsData) => {
                let data = settingsData.settings;

                this.setState({
                    user: data.user,
    				dbDataSet: data.dbDataSet,
    				tablePrefix: data.tablePrefix,
                    machineName: data.machineName,
                    project_id: data.projectId,
                    clientId: data.clientId,
                    clientName: data.name,
                    costCenter: data.costCenter,
                    session_timeout_ui: data.sessionTimeoutUI,
                    session_timeout_api: data.sessionTimeoutAPI,
                });

    		}).catch((error)=>{
    			alertAndLogError(error);
    		});
    }
    
    componentDidMount() {
        this.getUserSettings();
        this.initializeTabulator();
        this.getCacheKeyList();
    }

    initializeTabulator() {
        var obj = this;
        var tabulatorOptions = {
            layout: "fitColumns",      //fit columns to width of table
			tooltips: true,            //show tool tips on cells
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
			pagination: false,         //paginate the data
			movableColumns: true,     //allow column order to be changed
			autoResize: true,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
            resizableColumns: false,
            headerSort: this.props.headerSort,
			virtualDomBuffer: 800,
            placeholder: "",
            height: "100%",
            width: "100%",
			groupBy: CACHE_MANAGER.FIELDS.IDENTIFIER,
			groupStartOpen: false,
            groupToggleElement: "header",
            // renderComplete: onRenderComplete,
            // groupClick: onRenderComplete
            groupClick:function(e, group){
                //e - the click event object
                //group - group component
                if(e.target.id === "flushbyGroup") {
                    obj.key = "";
                    obj.identifier = group.getKey();
                    obj.flushPreMessage = "Scenario " + group.getKey() + " ("+group.getRows().length+" total keys) ";
                    obj.setOpenConfirmFlushDialog(true, obj.flushPreMessage + MESSAGES.flush_message_confirmation);

                    e.preventDefault();
                }
            }
        }

        tabulatorOptions.groupHeader = function (groupName, count, data) {
            var visibleCols = obj.tabulator.getColumns().filter(col => col.isVisible() === true);
            var col0Width = $(visibleCols[0].getElement()).width() - 50;
            var col1Width = $(visibleCols[1].getElement()).width();
            var col2Width = $(visibleCols[2].getElement()).width();
            var col3Width = $(visibleCols[3].getElement()).width();
            return "<div class='uk-display-inline-block group_1' style='width:"+convertPxToViewport(col0Width)+"'>Scenario: "+groupName+"<div class='uk-display-inline-block uk-text-unbold uk-margin-left'> (Keys Count = " + count + ")</div></div>"
            + "<div class='uk-text-medium uk-text-unbold uk-display-inline-block uk-text-center group_2' style='width:"+convertPxToViewport(col1Width)+"'></div>"
            + "<div class='uk-text-medium uk-text-unbold uk-display-inline-block uk-text-center group_3' style='width:"+convertPxToViewport(col2Width)+"'></div>"
            + "<div class='uk-text-medium uk-text-unbold uk-display-inline-block uk-text-center group_4' style='width:"+convertPxToViewport(col3Width)+"'><a id='flushbyGroup' value="+groupName+">"+ CACHE_MANAGER.FLUSH_ACTION +"</a></div>";
        };

        /**
         * This function fixes the CSS of the group header after rendering the table
         */
        function onRenderComplete() {
            setTimeout(function(){      //setting timeout cz else the table would not be drawn correctly
                $(".group_4").css('visibility','hidden');
                var arrowLeftMrg = $(".tabulator-row .tabulator-arrow").css("margin-right");    //get the space used by the left arrow to be deducted later
                arrowLeftMrg = arrowLeftMrg ? arrowLeftMrg.replace("px","") : "0";
                arrowLeftMrg = Number(arrowLeftMrg);
                var visibleCols = obj.tabulator.getColumns().filter(col => col.isVisible() === true);
                $(".group_1").width($(visibleCols[0].getElement()).width() - arrowLeftMrg);
                $(".group_2").width($(visibleCols[1].getElement()).width());
                $(".group_3").width($(visibleCols[2].getElement()).width());
                $(".group_4").width($(visibleCols[3].getElement()).width()-50);
            }, 100)
        }

        this.tabulator = new Tabulator(this.refs.mainTable, tabulatorOptions);
        var tableColumns = cleanUpTabulatorColumns(copyObjectValues(columns), [], this.refreshFilterDivs, this.tabulator, {id: this.refs.mainTable.id});
        tableColumns.forEach(col => {
            if(!col.formatter) {
                col.formatter = obj.getColumnFormatter(col.field);
            } 
        });
        this.tabulator.setColumns(tableColumns);
    }

    setOpenConfirmFlushDialog = (isOpen, msg) => {
        let _this = this;
        _this.setState({
            openConfirmFlushDialog: isOpen,
            dialogMsg: msg
        })

    }

    confirmFlushDialogActions = () => {
        return (
            <>
                <Button 
                    label={MESSAGES.modal.buttons.confirm}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.flushCache()}
                />
                <Button 
                    label={MESSAGES.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => this.setOpenConfirmFlushDialog(false)}
                />
            </>
        )
    }

    setOpenAfterFlushDialog = (isOpen, msg) => {
        let _this = this;
        _this.setState({
            openAfterFlushDialog: isOpen,
            dialogMsg: msg
        })

    }

    afterFlushDialogActions = () => {
        return (
            <Button 
                id="after-flush-close-btn"
                label={MESSAGES.modal.buttons.ok}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => this.setOpenAfterFlushDialog(false)}
            />
        )
    }

    render() {

        return (
            <div style={{height:"100%"}}>
                 {this.state.session_timeout_ui ? 
                    <SessionTimeout isAuthenticated={this.state.user && this.state.machine_name !== ""} logout={this.logout} session_timeout_ui={this.state.session_timeout_ui} session_timeout_api={this.state.session_timeout_api}/>
                :""}
                <div ref="mainTable" id="cache_manager"/>   
                <Modal
                    id={"confirm-flush-cache-dialog"}
                    openDialog={this.state.openConfirmFlushDialog}
                    bodyContent={() => <h4 style={{overflowWrap: "break-word"}}>{this.state.dialogMsg}<br />{MESSAGES.are_you_sure_to_continue}</h4>}
                    dialogActions={this.confirmFlushDialogActions}
                    closeClick={() => this.setOpenConfirmFlushDialog(false, "")}     
                    size={DIALOG_SIZE.LARGE}
                />
                <Modal
                    id={"after-flush-cache-dialog"}
                    openDialog={this.state.openAfterFlushDialog}
                    bodyContent={() => <h4 style={{overflowWrap: "break-word"}}>{this.state.dialogMsg}</h4>}
                    dialogActions={this.afterFlushDialogActions}
                    closeClick={() => this.setOpenAfterFlushDialog(false, "")}     
                    size={DIALOG_SIZE.LARGE}
                />
            </div>
        )
    }
}

export default CacheManager;