import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Tabulator from "tabulator-tables"; //import Tabulator library
import { copyObjectValues } from "../../class/utils";
import { cleanUpTabulatorColumns } from "../../class/common";
import { CONFIGURE_GL_ACCOUNT_RANGES } from "../../class/constants";
import { exportToExcel } from "../../class/tabulatorExport";

const ConfigureGlAccountsExport = forwardRef((props,ref) => {
    
    const[tabulator, setTabulator] = useState(null);

    
    useImperativeHandle(ref, () => ({
        exportTableToExcel: () => {
            prepareExportTableToExcel();
        }
    }));

    useEffect(() => {
        if(tabulator){
            exportTableToExcel();
        }
    }, [tabulator]);

    const columns = [
        {
            title: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.TITLES.ACCOUNTS_LIST,
            field: CONFIGURE_GL_ACCOUNT_RANGES.COLUMNS.FIELDS.ACCOUNTS_LIST
        }
    ]

    const tableWrap = (tableData) => {
        let options =  {
            data: tableData, //link data to table
        }
        var tableColumns = copyObjectValues(columns);
		tableColumns = cleanUpTabulatorColumns(tableColumns, null, "", tabulator, {id:"tie_out_export_ranges"});
		tableColumns = getTabulatorColumns(tableColumns);
        options.columns = tableColumns;
        var table = new Tabulator("#tie_out_export_ranges", options);
        setTabulator(table);
    }

    const getTabulatorColumns=(columns)=> {
		columns = columns || [];
		columns.forEach(col => {
			col.formatter = getColumnFormatter(col.field);
        });
        return columns;
	}
    
    const getColumnFormatter=(colField)=> {
		var columnFormatter;
		switch (colField) {
			default:
                columnFormatter = function(cell) {
                    return cell.getValue();
                }
            break;
        }
		return columnFormatter;
    }

    const prepareExportTableToExcel = () => {
        tableWrap(props.data);
    }

    const exportTableToExcel = () => {
        var tempSheetName = "Undefined Accounts";
		var mainSheet = tempSheetName;
		var sheets = {};
		sheets[mainSheet] = true;
        var data = tabulator.getData();
        var options = {};
        options.data = data;
        options.sheets = sheets;
        options.fileName =  "Undefined Accounts"
        exportToExcel(options, tabulator);
    }

    return (
        <div>
            <div id="tie_out_export_ranges" style={{"display": "none"}}/>
        </div>
    );
});

export default ConfigureGlAccountsExport;