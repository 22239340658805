// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.default-line {
  background: #eaeaea;
  border-radius: var(--value-10-px);
  height: var(--value-13-px);
  margin: var(--value-10-px) 0;
  overflow: hidden;
  position: relative;
}

.short-line {
    width: calc(100% - 40%);
}

.medium-line {
  width: calc(100% - 15%);
}

.default-line::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(to right, #eaeaea 0%, rgba(0,0,0,0.05) 20%, #eaeaea 40%, #eaeaea 100%);
  background-repeat: no-repeat;
  background-size:  var(--value-450-px) var(--value-400-px);
  animation: shimmer 1s linear infinite;
}

@keyframes shimmer {
  0%{
    background-position: calc(var(--value-450-px) * -1) 0;
  }
  100%{
    background-position:  var(--value-450-px) 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/skeletonLoader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;EACE,mBAAmB;EACnB,iCAAiC;EACjC,0BAA0B;EAC1B,4BAA4B;EAC5B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,wGAAwG;EACxG,4BAA4B;EAC5B,yDAAyD;EACzD,qCAAqC;AACvC;;AAEA;EACE;IACE,qDAAqD;EACvD;EACA;IACE,2CAA2C;EAC7C;AACF","sourcesContent":[".skeleton-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 100%;\n}\n\n.default-line {\n  background: #eaeaea;\n  border-radius: var(--value-10-px);\n  height: var(--value-13-px);\n  margin: var(--value-10-px) 0;\n  overflow: hidden;\n  position: relative;\n}\n\n.short-line {\n    width: calc(100% - 40%);\n}\n\n.medium-line {\n  width: calc(100% - 15%);\n}\n\n.default-line::before {\n  position: absolute;\n  content: \"\";\n  height: 100%;\n  width: 100%;\n  background-image: linear-gradient(to right, #eaeaea 0%, rgba(0,0,0,0.05) 20%, #eaeaea 40%, #eaeaea 100%);\n  background-repeat: no-repeat;\n  background-size:  var(--value-450-px) var(--value-400-px);\n  animation: shimmer 1s linear infinite;\n}\n\n@keyframes shimmer {\n  0%{\n    background-position: calc(var(--value-450-px) * -1) 0;\n  }\n  100%{\n    background-position:  var(--value-450-px) 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
