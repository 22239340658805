import React, { Component } from 'react';
import Tabulator from "tabulator-tables";
import { cleanUpTabulatorColumns } from '../class/common.js';
import { DROP_DOWN_ELEMENT, FormatTypes, MANAGE_COST_CENTER } from '../class/constants.js';
import { formatValHTML, formatValString } from '../class/format.js';
import { convertViewportToPx } from '../class/formatting.js';
import { capitalizeFirstLetter, copyObjectValues, parseBoolean, tryParse } from '../class/utils.js';
import { lang } from '../language/messages_en.js';
import { createCheckbox } from '../newComponents/CheckBoxTabulator.js';
import { getTableButton, getTableIconButton } from '../newComponents/tabulatorComponents.js';
import { createDropdown } from '../newComponents/DropdownTabulator.js';
const $ = require('jquery');
const _sourceSystem = "use_source_system";
const _closed = "closed";
const _selling = "selling";

// * @author [Sarah Farjallah] cost center table 
class CostCenterTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChanged: false
        };
    }
/**
 * update cost center state and update footer values
 * @param {*} e 
 * @param {*} cell 
 */
    setCostCenterState=(e,cell)=>{
        let _this = this;
        cell.setValue(e.target.value);
        if(!this.props.isBulkdefine && !this.props.isCCDefinition){
            cell.getRow().getData()[MANAGE_COST_CENTER.FIELDS.ALERT] = !cell.getRow().getData()[MANAGE_COST_CENTER.FIELDS.ALERT];
        }
        let rowIndex = cell.getRow().getData()[this.props.isCCDefinition ? MANAGE_COST_CENTER.FIELDS.PERIOD : MANAGE_COST_CENTER.FIELDS.COST_CENTER];
        _this.tabulator.updateRow(rowIndex, cell.getRow().getData());
        _this.addFooterText(_this.tabulator.getData());
        _this.setState({
            isChanged:true,
        })
    }

    /**
     * enables add state butoon and disabled all states button
     */
    onCheckBoxChecked=(e, cell, all)=>{
        let _this = this;
        let checkedItems = [];
        let data = [];
        if (all) {
            data = _this.tabulator.getData(); 
            data.map(function(item){item[MANAGE_COST_CENTER.FIELDS.CHECK] = e.target.checked;
                item.disabled =  e.target.checked});
            checkedItems= data.filter(e=>e[MANAGE_COST_CENTER.FIELDS.CHECK]);
            _this.tabulator.replaceData(data);

        }else{
            cell.getData()[MANAGE_COST_CENTER.FIELDS.CHECK] = e.target.checked;
            cell.getData().disabled = e.target.checked;
            checkedItems= cell.getTable().getData().filter(e => e[MANAGE_COST_CENTER.FIELDS.CHECK]);
            // data = cell.getTable().getData();
            // data.map(item => {item.disabled = checkedItems.length > 0;});
            $(".dropdown-state").prop("disabled", checkedItems.length > 0);
        }
        if (!all) {
            if (checkedItems.length === cell.getTable().getData().length) {
                $("#check_all").prop('checked',true);
                $("#check_all").prop('indeterminate',false);
            }else if (checkedItems.length > 0){
                $("#check_all").prop('checked',false);
                $("#check_all").prop('indeterminate',true);
            }else{
                $("#check_all").prop('indeterminate',false);
                $("#check_all").prop('checked',false);
            }
        }
        _this.props.enableSelectState(checkedItems.length > 0);
        // _this.tabulator.replaceData(data);
        // if (cell) {
        //     _this.tabulator.scrollToRow(cell.getRow().getData()[MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
        // }
    }
    /**
     * updating cost center state to all checked cost centers
     * @param {*} costCenterState 
     */
    selectState=(costCenterState)=>{
        let _this = this;
        let data = _this.tabulator.getData();
        data.map(function(item){ 
            if (item[MANAGE_COST_CENTER.FIELDS.CHECK]) {
                item[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE] = costCenterState.value;
                item[MANAGE_COST_CENTER.FIELDS.CHECK] = false;
            }
            item.disabled = false;
        });
        $("#check_all").prop('indeterminate',false);
        $("#check_all").prop('checked',false);
        _this.props.enableSelectState(false);
        _this.tabulator.replaceData(data);
        _this.addFooterText(_this.tabulator.getData());
    }

    getColumnFormatter(colField, format_type) {
        let columnFormatter;
        var obj = this;
        let isPeriodView = obj.props.index === MANAGE_COST_CENTER.FIELDS.PERIOD;
        switch(colField) {
            case MANAGE_COST_CENTER.FIELDS.ACTIONS:
                columnFormatter = function(cell){
                    var rowData = cell.getRow().getData();
                    if(isPeriodView && (!rowData[MANAGE_COST_CENTER.FIELDS.VALID_GL] || !rowData[MANAGE_COST_CENTER.FIELDS.VALID_GL])) {
                        return "";
                    }                       
                    var dropdownContainer = document.createElement("div");
                    dropdownContainer.setAttribute("uk-dropdown", "mode: click; boundary: .tabulator");
                    // three dotted button
                    var dotsButtonContainer = document.createElement("div");
                    var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "dots-button"]);
                    dotsButtonContainer.classList.add("uk-inline");

                    if(obj.props.index === MANAGE_COST_CENTER.FIELDS.PERIOD && !parseBoolean(rowData[MANAGE_COST_CENTER.FIELDS.VALID_GL])){
                        dotsButtonContainer.setAttribute("uk-tooltip", lang.manage_cost_centers.missing_gl);
                        dotsButtonContainer.classList.add("disabled-with-events");
                    } else {
                        var defineContainer = getTableButton(
                            DROP_DOWN_ELEMENT.define,
                            [],
                            ["justify-content-start"],
                            ["fal", "fa-edit", "fa-lg", "uk-margin-small-right"],
                            "left",
                        );
                        defineContainer.classList.add("uk-button-icon");
                        defineContainer.onclick = () => {
                            obj.props.define(rowData, isPeriodView)
                        }
                        dropdownContainer.appendChild(defineContainer);
                    }
                   
                    dotsButtonContainer.appendChild(dotsButton);
                    dotsButtonContainer.appendChild(dropdownContainer);
                    return dotsButtonContainer;
                }
            break;
            case MANAGE_COST_CENTER.FIELDS.STATUS:
                columnFormatter = function(cell){
                    var div = document.createElement('div');
                    var icon = document.createElement("i");
                    div.classList.add("uk-flex-middle");
                    var rowData = cell.getRow().getData();
                    if (rowData[MANAGE_COST_CENTER.FIELDS.STATUS] !== MANAGE_COST_CENTER.STATUS.DEFINED
                        || !parseBoolean(rowData[MANAGE_COST_CENTER.FIELDS.VALID_GL])) {
                        icon.classList.add("fal", "fa-times", "fa-lg", "uk-margin-small-right");
                        if (isPeriodView && !parseBoolean(rowData[MANAGE_COST_CENTER.FIELDS.VALID_GL])) {
                            icon.classList.add("uk-cursor-pointer");
                            icon.setAttribute("uk-tooltip", lang.manage_cost_centers.missing_gl);
                        }
                    }else{
                        icon.classList.add("fal", "fa-check", "fa-lg", "uk-margin-small-right");
                    }
                    div.appendChild(icon);
                    return div;
                }
            break;
            case MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE:
                columnFormatter = function(cell){
                    var rowData = cell.getRow().getData();
                    let count = obj.props.countGeneratingPerPeriod(true, rowData[MANAGE_COST_CENTER.FIELDS.PERIOD]);
                    rowData[MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE] = count;
                    var div = document.createElement('div');
                    div.classList.add("uk-flex-middle");
                    div.innerHTML = formatValHTML(count, FormatTypes.NUMERIC);
                    return div;
                }
            break;
            case MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE:
            columnFormatter = function(cell){
                var rowData = cell.getRow().getData();
                let count = obj.props.countGeneratingPerPeriod(false, rowData[MANAGE_COST_CENTER.FIELDS.PERIOD])
                rowData[MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE] = count;
                var div = document.createElement('div');
                div.classList.add("uk-flex-middle");
                div.innerHTML = formatValHTML(count, FormatTypes.NUMERIC);
                return div;
            }
            break;
            case MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE:
            case MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY:
                columnFormatter = function(cell){
                    if (parseBoolean(cell.getRow().getData()[MANAGE_COST_CENTER.FIELDS.VALID_GL])) {
                        return cell.getValue();
                    }
                    return "";
                }
            break;
            case MANAGE_COST_CENTER.FIELDS.CHECK:
            columnFormatter = function(cell){
                let rowNumber = cell.getRow().getPosition();
                var checkbox = createCheckbox();
                checkbox.name='chosenEntity';
                checkbox.id = 'checkbox_icon_'+rowNumber;
                checkbox.classList.add('chosenEntity');
                checkbox.checked = cell.getRow().getData()[MANAGE_COST_CENTER.FIELDS.CHECK];
                checkbox.onchange =(e)=> {
                    obj.onCheckBoxChecked(e,cell);
                };
                return checkbox;
            }
            break;
            case MANAGE_COST_CENTER.FIELDS.ALERT:
                columnFormatter = function(cell){
                    let rowData = cell.getRow().getData();
                    var div = document.createElement('div');
                    if(rowData[MANAGE_COST_CENTER.FIELDS.ALERT] === 2){
                        let span = document.createElement('span');
                        span.classList.add("uk-cursor-pointer","red");
                        span.innerHTML = formatValHTML(lang.manage_cost_centers.new, format_type);
                        span.setAttribute('uk-tooltip', lang.manage_cost_centers.new_label);
                        div.appendChild(span);
                    } else if(rowData[MANAGE_COST_CENTER.FIELDS.ALERT] === 3) {
                        let isCCStateDiff = obj.props.isCostCenterStateDifferentThanPrevious(rowData, obj.props.period) || "";
                        let icon = document.createElement('i');
                        icon.setAttribute('uk-tooltip', lang.manage_cost_centers.warning_state.replace('[x]', capitalizeFirstLetter(isCCStateDiff).fontcolor("orange")))
                        icon.classList.add("fas", "fa-exclamation", "pi-text-yellow","uk-cursor-pointer","uk-margin-small-top");
                        div.appendChild(icon);
                    }
                  return div;
                }
            break;
            case MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE:
                columnFormatter = function(cell){
                    const dropDown = createDropdown(["dropdown-state","form-control-d","select-width-cost-center-state"]);
                    dropDown.id = "state_"+cell.getRow().getPosition();
                    // dropDown.classList.add("dropdown-state","form-control-d","select-width-cost-center-state");
                    let rowData = cell.getRow().getData();
                    let isCostCenterNew = obj.props.isCostCenterNew(obj.props.period, rowData[MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
                    let isCCStateDiff = ""
                    if (!obj.props.isBulkdefine && !obj.props.isCCDefinition) {
                        isCCStateDiff = obj.props.isCostCenterStateDifferentThanPrevious(rowData, obj.props.period);
                        if (isCostCenterNew) {
                            if (!cell.getValue()) {
                                // dropDown.classList.add("redBorder");
                            }
                        } else {
                            if (isCCStateDiff && cell.getValue()) {
                                // dropDown.classList.add("yellowBorder");
                            }
                        }
                    }
                    if (rowData.disabled) {
                        dropDown.classList.add("disabled");
                    }

                    let option = document.createElement("option");
                    option.classList.add("manage-cost-center-option-style")
                    option.value = lang.manage_cost_centers.revenue_generating;
                    option.title = lang.manage_cost_centers.revenue_generating;
                    option.innerHTML = lang.manage_cost_centers.revenue_generating;
                    option.disabled = true;
                    dropDown.appendChild(option);
                    obj.props.costCenterStates.filter(e=>e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
                        && e.name.toLowerCase() == _closed ||  e.name.toLowerCase() == _selling).sort((function(a, b){
                       if(a.name < b.name) { return -1; }
                       if(a.name > b.name) { return 1; }
                       return 0;
                   })).forEach(d=> {
                       option = document.createElement("option");
                       option.value = d.name;
                       option.title = ((d.description)? d.description: d.name);
                       option.innerHTML = d.display_name;
                       dropDown.appendChild(option);
                   });
                    obj.props.costCenterStates.filter(e=>e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
                         && e .name.toLowerCase() !== _closed && e.name.toLowerCase() !== _selling).sort((function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })).forEach(d=> {
                        option = document.createElement("option");
                        option.value = d.name;
                        option.title = ((d.description)? d.description: d.name);
                        option.innerHTML = d.display_name;
                        dropDown.appendChild(option);
                    });
                    option = document.createElement("option");
                    option.classList.add("manage-cost-center-option-style")
                    option.value = lang.manage_cost_centers.non_revenue_generating;
                    option.title = lang.manage_cost_centers.non_revenue_generating;
                    option.innerHTML = lang.manage_cost_centers.non_revenue_generating;
                    option.disabled = true;
                    dropDown.appendChild(option);
                    obj.props.costCenterStates.filter(e=>e.name === _sourceSystem).forEach(d=> {
                        option = document.createElement("option");
                        option.value = d.name;
                        option.title = ((d.description)? d.description: d.name);
                        option.innerHTML = d.display_name;
                        dropDown.appendChild(option);
                    })
                    obj.props.costCenterStates.filter(e=>!e[MANAGE_COST_CENTER.FIELDS.IS_REVENUE_GENERATING]
                        && e.name !== _sourceSystem
                         ).sort((function(a, b){
                        if(a.name < b.name) { return -1; }
                        if(a.name > b.name) { return 1; }
                        return 0;
                    })).forEach(d=> {
                        option = document.createElement("option");
                        option.value = d.name;
                        option.title = ((d.description)? d.description: d.name);
                        option.innerHTML = d.display_name;
                        dropDown.appendChild(option);
                    });
                    
                    dropDown.onchange = (e) => {obj.setCostCenterState(e,cell); dropDown.classList.remove('placeholder-text');}
                    dropDown.value = !isCostCenterNew && !cell.getValue() ?  obj.props.pullPreviousState(rowData?.period, rowData) : cell.getValue();
                    if (!rowData[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]) {
                        rowData[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE] = dropDown.value;
                    }

                    if(!cell.getValue() && !dropDown.value) {
                        /* create a select placeholder */
                        let placeholder = document.createElement("option");
                        placeholder.hidden = true;
                        placeholder.defaultSelected = true;
                        placeholder.innerHTML = 'Select state';
                        dropDown.appendChild(placeholder);
                        dropDown.classList.add('placeholder-text');
                    }
                  
                    return dropDown;
                }
            break;
            default:
            columnFormatter = function(cell, formatterParams) {
                var div = document.createElement('div');
                div.classList.add("uk-flex-middle");
                div.innerHTML = formatValHTML(cell.getValue(), format_type);
                return div;
            }
        }

        return columnFormatter;
    }

    titleTooltipFormatter = (cell, tooltip) => {
        var div = document.createElement("div");

        if (cell !== undefined) {
            var b = document.createElement("b");
            b.innerHTML = cell.getValue();
            div.appendChild(b);
        }

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltip);

        var innerDiv = document.createElement("div");
        innerDiv.appendChild(el);

        div.appendChild(innerDiv);
        return div;
    }

    getTabulatorColumns(columns){
        let _this = this;
        let defineCCTab = _this.props.isCCDefinition
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.align = false;
            col.bold = true;
            if (col.field === MANAGE_COST_CENTER.FIELDS.COST_CENTER) {
                col.sorter = "string";
            }
            if ([MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE, MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE].includes(col.field)) {
                col.format_type = FormatTypes.NUMERIC;
            }
        });

        columns = cleanUpTabulatorColumns(columns, null, _this.refreshFilterDivs, _this.tabulator, { id: _this.props.index });
        columns.forEach(col => {
            col.formatter = _this.getColumnFormatter(col.field, col.format_type);
            if (col.field === MANAGE_COST_CENTER.FIELDS.STATUS) {
                col.titleFormatter = (cell) => {
                    let tooltip = this.props.index === MANAGE_COST_CENTER.FIELDS.PERIOD ? lang.manage_cost_centers.status_tooltip : lang.manage_cost_centers.cost_center_status_tooltip;
                    return this.titleTooltipFormatter(cell, tooltip);
                };
            }
            if (col.field === MANAGE_COST_CENTER.FIELDS.CHECK) {
                col.titleFormatter = function () {
                    let div = document.createElement("div");
                    let checkbox = createCheckbox();
                    checkbox.checked = _this.state.checked > 0 ? true : false;
                    checkbox.indeterminate = _this.state.checked > 0 && _this.state.checked < _this.tabulator.getData('active').length ? true: false;
                    checkbox.id = 'check_all';
                    checkbox.onclick = function (e) {
                        _this.onCheckBoxChecked(e,undefined,true);
                    };
                    div.appendChild(checkbox);
                    let span = document.createElement("span");
                    span.classList.add("uk-text-bold", "uk-margin-small-left");
                    span.innerHTML = col.title;
                    if(span.innerHTML){
                        div.appendChild(span);
                        return div;
                    } 
                    return checkbox;
                }
                col.cssClass = "check-cell";
                col.download = false;
                col.minWidth = convertViewportToPx(30);
                col.width = convertViewportToPx(30);
            }
            if (MANAGE_COST_CENTER.FIELDS.COST_CENTER === col.field) {
                col.width = convertViewportToPx(145);
            }
            if (MANAGE_COST_CENTER.FIELDS.COST_CENTER_NAME === col.field) {
                col.width = convertViewportToPx(150);
            }
            if (defineCCTab && MANAGE_COST_CENTER.FIELDS.PERIOD === col.field) {
                col.width = convertViewportToPx(175);
            }
            if(defineCCTab &&MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE === col.field){
                col.width = convertViewportToPx(200)
            }
            if (!defineCCTab && MANAGE_COST_CENTER.FIELDS.PERIOD === col.field) {
                col.width = convertViewportToPx(297);
            }
            if ([MANAGE_COST_CENTER.FIELDS.STATUS, MANAGE_COST_CENTER.FIELDS.GENERATING_REVENUE, MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_BY,
                 MANAGE_COST_CENTER.FIELDS.NON_GENERATING_REVENUE, MANAGE_COST_CENTER.FIELDS.LAST_MODIFIED_DATE].includes(col.field)) {
                col.width = convertViewportToPx(297);
            }
            if(_this.props.isBulkdefine){
                if([MANAGE_COST_CENTER.FIELDS.GL_AMOUNT, MANAGE_COST_CENTER.FIELDS.LINE_COUNT].includes(col.field)) {
                    col.width = convertViewportToPx(130);
                }
                if(MANAGE_COST_CENTER.FIELDS.REVENUE === col.field) {
                    col.width = convertViewportToPx(135);
                }
            }else if(defineCCTab){
                if([MANAGE_COST_CENTER.FIELDS.GL_AMOUNT, MANAGE_COST_CENTER.FIELDS.LINE_COUNT].includes(col.field)) {
                    col.width = convertViewportToPx(165);
                }
                if(MANAGE_COST_CENTER.FIELDS.REVENUE === col.field) {
                    col.width = convertViewportToPx(165);
                }
            }else{
                if(MANAGE_COST_CENTER.FIELDS.REVENUE === col.field) {
                    col.width = convertViewportToPx(120);
                }
                if([MANAGE_COST_CENTER.FIELDS.GL_AMOUNT, MANAGE_COST_CENTER.FIELDS.LINE_COUNT].includes(col.field)) {
                        col.width = convertViewportToPx(105);
                }
            }
            if(MANAGE_COST_CENTER.FIELDS.LINE_COUNT === col.field && (this.props.isBulkdefine || this.props.isCCDefinition)) {
                col.cssClass = "revenue-column";
            }
        });
        return columns;     
    }

    /**
     * setting data and columns and setting sorter 
     * @param {*} columns 
     * @param {*} data 
     */
    formatTable=(columns , data)=> {
        let _this = this;
        _this.tabulator.setColumns(_this.getTabulatorColumns(columns));
        for(let e in data){
            data[e][MANAGE_COST_CENTER.FIELDS.ALERT] = 1;
            let isNew = _this.props.isCostCenterNew(_this.props.period, data[e][MANAGE_COST_CENTER.FIELDS.COST_CENTER]);
            if(isNew){
                if (!data[e][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]) {
                    data[e][MANAGE_COST_CENTER.FIELDS.ALERT] = 2;
                }
            } else {
                let isCCStateDiff = _this.props.isCostCenterStateDifferentThanPrevious(data[e], _this.props.period);
                if (isCCStateDiff && data[e][MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]) {
                    data[e][MANAGE_COST_CENTER.FIELDS.ALERT] = 3;
                }
            }
        }
        _this.tabulator.replaceData(data);
        if (_this.props.index === MANAGE_COST_CENTER.FIELDS.PERIOD || _this.props.isCCDefinition) {
            _this.tabulator.setSort(MANAGE_COST_CENTER.FIELDS.PERIOD, "desc");
        } else{
            _this.tabulator.setSort(MANAGE_COST_CENTER.FIELDS.COST_CENTER,"asc");
        }
        _this.addFooterText(_this.tabulator.getData());
    }

    /**
     * for footer info in definition table 
     * @param {*} propsData 
     */
    addFooterText=(propsData, statesMapOrg)=>{
        let _this = this;
        let data = propsData || [];
        let totalsText = "";
        let statesMap = statesMapOrg || _this.props.statesMap;
        if (statesMap && data) {
            let revenue_generating = data.filter(e=>statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]] !== undefined 
                && statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]]).length;
            let non_revenue_generating = data.filter(e=>statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]] !== undefined
                && !statesMap[e[MANAGE_COST_CENTER.FIELDS.COST_CENTER_STATE]]).length;
            if(data && data.length > 0){
                totalsText ="<span class=\"uk-margin-small-right uk-text-medium\"> Count: " + formatValString(data.length,FormatTypes.NUMERIC) + "</span>";
                totalsText += "<span class=\"uk-margin-small-right uk-text-medium\">Revenue Generating: " + formatValString(revenue_generating, FormatTypes.NUMERIC) + "</span>";
                totalsText += "<span class=\"uk-margin-small-right uk-text-medium\"> Non Revenue Generating: " + formatValString(non_revenue_generating, FormatTypes.NUMERIC) + "</span>";
    
            }
            document.getElementById(_this.props.index+"_footer").innerHTML ="<p class='uk-margin-small-right'>" + totalsText + "</p>";
        }
    }

    onTabulatorRenderComplete(test) {
		if(!this.tabulator)
            return;
    }

    componentDidUpdate(prevProps) {
        if(this.props.index !== prevProps.index) {
            this.formatTable(this.props.columns, this.props.data);
        }
       
        if(this.props.data && this.props.pagination){ // to fill the element footer in the footer of the combinations tabulator before checking any checkbox
            if(this.tabulator.getFilters().length > 0){
                this.addFooterText(this.tabulator.getData());
            } else if(prevProps.id !== this.props.id){
                this.addFooterText(this.tabulator.getData());
            }
        }
    }
    componentDidMount() {
        this.initTabulator();
    }

    prepareIndex (index, view) {
        if(index === MANAGE_COST_CENTER.FIELDS.PERIOD) {
            return MANAGE_COST_CENTER.FIELDS.PERIOD;
        } else if( index === MANAGE_COST_CENTER.FIELDS.COST_CENTER){
            return MANAGE_COST_CENTER.FIELDS.COST_CENTER;
        } else if( index === "definition"){
            if(view === "periods") {  // if you're defining a period tabulator index should be cost_center else it should be period
                return MANAGE_COST_CENTER.FIELDS.COST_CENTER;
            } else {
                return MANAGE_COST_CENTER.FIELDS.PERIOD;
            }
        }
    }

    initTabulator = () => {
        let _this = this;
        var options = {
            layout: "fitData",      //fit columns to width of table
			responsiveLayout: false,  //hide columns that dont fit on the table
			addRowPos: "top",          //when adding a new row, add it to the top of the table
			history: true,             //allow undo and redo actions on the table
            index: _this.prepareIndex(_this.props.index,_this.props.view),   // We cannot rely on this.props.pagination because if you are defining a cost center it will crash (index was wrong)
			pagination: _this.props.pagination ? "local" :  false,       //paginate the data
            paginationSize:  _this.props.pagination ? 50 :  false,
            paginationSizeSelector: _this.props.pagination ? [10,50,100] : false,
			movableColumns: false,     //allow column order to be changed
			autoResize: true,
			resizableRows: false,       //allow row order to be changed
			selectable: false,
			resizableColumns: false,
            reactiveData:true,
			virtualDom: false,
			renderComplete:_this.onTabulatorRenderComplete,
            invalidOptionWarnings: false,
            height: "100%" ,
            footerElement: _this.props.pagination ? "<div class='uk-margin-xsmall-top' id='"+_this.props.index+"_footer'></div>" : false,
        }
        // options.dataFiltered = function(filters, rows) {
        //     if (obj.props.pagination && obj.tabulator) {
        //         obj.addFooterText(obj.tabulator.getData('active'));
        //     }
        // };
        _this.tabulator = new Tabulator(_this.refs.mainTable, options);
        _this.tabulator.setColumns(_this.getTabulatorColumns(_this.props.columns));
        _this.tabulator.setData(tryParse(_this.props.data))
    }

    render() {
        return (
            <div id={this.props.index} className={this.props.className} ref="mainTable"/>
        );
    }
}

export default CostCenterTable;