import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import Tabulator from "tabulator-tables";
import { cleanUpTabulatorColumns } from '../../class/common';
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, CALCULATED_COLUMNS, DIALOG_SIZE, MANAGE_STACKS, PROFILE_COLUMN, PSL_RETURN_NAMES, PS_MAPPING, ROW_STATUS, SIZES, VECTOR_MAPPING, costtype } from '../../class/constants';
import { copyObjectValues, generateUniqueCostKey, generateUniqueIdDB, getAttributeFromCostKey, getChildCostKeys, getTranslationFile, isRedDot, tryParse, updateChildAttr } from '../../class/utils';
import '../../styles/common.css';
import '../../styles/manageStacks.css';

import { DialogContentText } from '@mui/material';
import { addLevelForAll, getEmbeddedChild, linearizeHierarchy } from '../../class/array';
import { convertPxToViewport } from '../../class/formatting';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { FormComponent } from '../../form/FormElements';
import Button from '../../newComponents/Button';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator';
import Modal from '../../newComponents/Modal';
import { getExpandCollapseButtons, getTableButton, getTableIconButton } from '../../newComponents/tabulatorComponents';
import CalculatedProfitStackLine from '../manageColumns/CalculatedProfitStackLine';
import Backdrop from '../sidePanelNew/Backdrop';
import SidePanelNew from '../sidePanelNew/SidePanelNew';

const $ = require('jquery');
const lang = getTranslationFile();
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _name = PS_MAPPING.FIELDS.NAME;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _id = PS_MAPPING.FIELDS.PSS_ID;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _mapFormula = PS_MAPPING.FIELDS.MAP_FORMULA;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _parentCostKey = PS_MAPPING.FIELDS.PARENT_COST_KEY;
const _deleted = PS_MAPPING.FIELDS.DELETED;
const _attribute = PS_MAPPING.FIELDS.ATTRIBUTE;
const _attributeFunction = PS_MAPPING.FIELDS.ATTRIBUTE_FUNCTION;
const _attributeType = PS_MAPPING.FIELDS.ATTR_TYPE;
const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;
const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE; // has children or not
const showExpandFlag = "isExpandable"; // detector for tabulator to show expand/collapse icon
// is_expandable value from database that takes into consideration if has hidden children or not
const _nameInFact = PS_MAPPING.FIELDS.NAME_IN_FACT;
const _indentationPX = 30;
const checkboxCellObject = {
    field: MANAGE_STACKS.FIELDS.CHECKBOX,
    visible: true,
    headerSort: false,
    width: 40,
    titleDownload:"\n"
}
// const dataPropsAreEqual=(prevProps, props) => {
//     return JSON.stringify(prevProps.data) === JSON.stringify(props.data) && (props.checkedItems && prevProps.checkedItems && prevProps.checkedItems.length === props.checkedItems.length);
// }
const StackTabulator = forwardRef((props,ref) => {
   
    const { data, columns, id, addCheckboxes, title, checkedItems, cell, deleteRow, toggleSaveButton, setRedDot, deletedCell, currentManageStack, isnameValid, getNewStackData, setStackIsChanged, updateRedDotState, onChangeDiscard } = props;
    let tabulator = useRef(null);  // tabulator access in functional component
    let prevData = usePrevious(data);
    let prevCheckedItems = usePrevious(checkedItems);
    let checkedItemsRef = useRef(null);
    const prevProps = usePrevious({ id });
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    let isAllExpandedRef= useRef(false);
    const [openSidePanel, setOpenSidePanel] = useState(false);
    const [profitStackFields, setProfitStackFields] = useState("");
    const [costKey, setCostKey] = useState(""); 
    const [newName, setNewName] = useState(""); 
    const [oldName, setOldName] = useState(""); 
    const [openWarning, setOpenWarning] = useState(false); 
    const [openValidationWarning, setOpenValidationWarning] = useState(false); 
    const [warning, setWarning] = useState("");
    const [originalData,setOriginalData] = useState(props.data);
    const [openInvalidFormulaDialog, setOpenInvalidFormulaDialog] = useState(false);
    const [openDeletePSLRowDialog, setOpenDeletePSLRowDialog] = useState(false);
    const [selectedPSLData, setSelectedPSLData] = useState([]);

    let expandedRowsCostkeys = useRef([]);
    let previousCostKey = useRef(null);
    let profitStackFieldsRef = useRef(null);
    let tempCalculatedLineConf = useRef({});
    let calculatedLineConf = useRef({});
    let linearisedPublishedData = useRef(null);
    let isChecking = useRef(false);
    let calcPSLineRef = useRef({});
    let globaltop = 0;
    useImperativeHandle(ref, () => ({
        getTabulatorColumns: () => {
         return getTabulatorColumns(columns);
        },
        expandCollapseAll:()=>{
            return expandCollapseAll();
        },
        getIsAllExpanded:()=>{
            return isAllExpandedRef.current;
        },
        clearFilter:()=>{
            return clearFilter()
        }
      }));
  
    /**
     * function returns value of current component
     * @param value 
     * @returns 
     */
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    
    const getDifference=(array1, array2) => {
        return array1.filter(object1 => {
          return !array2.some(object2 => {
            return object1.costKey === object2.costKey;
          });
        });
    }

    useEffect(() => {
        if(id === "newStack" && tabulator.current !== null && deletedCell && prevData && data && JSON.stringify(prevData) === JSON.stringify(data)){
            props.setDeletedCellFromChild(undefined);
            props.setData(tabulator.current.getData());
            return () => {
                prevData = tabulator.current.getData();
            }
        }
        // if(id === "publishedStack" && tabulator.current !== null && prevData && data && JSON.stringify(prevData) === JSON.stringify(data)){
        //     tabulator.current.setData(props.data);
        //     return () => {
        //         prevData = tabulator.current.getData();
        //     }
        // }
        if(tabulator.current !== null && prevData && data && getDifference(data, prevData).length !== 0){
              if(id === "newStack") {//When clicking on +
                    let movedPSL = getDifference(data,prevData)
                    if(movedPSL.length === 0){
                        movedPSL = data;
                    }
                    if(!!Object.keys(cell).length){
                        let row = cell.getRow();
                        //Get data for the parent row so we can update it's _children array
                        let tempParentRowData = row.getData();
                        //Add new row to children array
                        if(!tempParentRowData.children){
                            tempParentRowData.children = [];
                        }
                        let children = copyObjectValues(tempParentRowData.children);
                        for(let e in movedPSL){
                            movedPSL[e][_parentCostKey] = row.getData()[_costKey];
                        }
                        children.push(...movedPSL);
                        
                        //Update data table row with new children array
                        row.update({[showExpandFlag]:true,
                            is_column_expandable:true,
                            is_expandable:true,
                            children:children});
                        row.treeExpand();
                        // row.scrollTo();
                        props.setCellFromChild({})
                    
                } else {
                    for(let e in movedPSL){
                        movedPSL[e][_parentCostKey] = "201";
                        if(movedPSL[e][_costType] === costtype.attribute){
                            movedPSL[e][_nameInFact] =  movedPSL[e][_nameInFact].replaceAll("f.",""); //Remove f. from name_in_fact for attributes 
                        }
                    }
                    let difference  = getDifference(movedPSL,tabulator.current.getData());
                    if(difference.length!==0)
                        tabulator.current.addData(movedPSL,false);  
                }
            } 
            return () => { 
                prevData = data;
            };
        }
        if(checkedItems && prevCheckedItems && JSON.stringify(checkedItems) !== JSON.stringify(prevCheckedItems)){
            checkedItemsRef.current = checkedItems;
            if(id === "newStack"){
                if(tabulator.current.getFilters().length){
                    let filters = tabulator.current.getFilters();// clear the filter and reset it after
                    tabulator.current.clearFilter();
                    // tabulator.current.setColumns(getTabulatorColumns(columns)); //when something is checked re render the other +
                    tabulator.current.setFilter(filters);
                }
                // else{ 
                //     // tabulator.current.setColumns(getTabulatorColumns(columns)); //when something is checked re render the other +
                // }
                let haveAttributeChecked = checkedItemsRef.current.filter(e=>e.costtype === costtype.attribute).length>0;
                $(".moveLineFirstLevel").css("display", checkedItemsRef.current.length > 0 ? "block" : "none");
                $(".moveLine").css("display", !haveAttributeChecked && checkedItemsRef.current.length > 0 ? "block" : "none");
                $(".addGroupButton").css("display", checkedItemsRef.current.length === 0 ? "block" : "none");
            }
        }
    }, [data,checkedItems,cell,deletedCell])

    /**
     * checks if a name already exists based on the returnname
     * @param {*} name 
     * @param {*} data 
     * @param {*} costKey 
     * @returns 
     */
    function checkExistingName(name, data, costKey) {
        let filteredData = data.filter(elt => elt[_returnName].endsWith(PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX));
        for (var e in filteredData) {
            if (filteredData[e] && filteredData[e][_name]) {
                if (filteredData[e][_returnName] && filteredData[e][_returnName].toLowerCase() === name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '').replace(/ /g, '') + PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX
                    && filteredData[e][_costKey] !== costKey) {
                    return -1;
                } else if (filteredData[e].name.toLowerCase().replace(/ /g, "") === name.toLowerCase().replace(/ /g, "")
                    && filteredData[e][_costKey] !== costKey) {
                    return 1;
                }
            }
        }
        return 0;
    }

    /**
     * checks occurance of name
     */
    function checkNameOcc(data, name, costKey) {
        let count = 1;
        let filteredData = data.filter(elt => elt[_returnName].endsWith(PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX));
        name = name.toLowerCase().replaceAll(" ", "");
        let counts = [];
        for (var e in filteredData) {
            if (filteredData[e]) {
                if (filteredData[e][_costKey] !== costKey && ((filteredData[e][_name].toLowerCase().replaceAll(" ", "") === name)
                    || filteredData[e][_name].toLowerCase().replaceAll(" ", "").startsWith(name + "(") && filteredData[e][_name].toLowerCase().replaceAll(" ", "").endsWith(")"))) {
                    count++;
                }
                if ((filteredData[e][_costKey] !== costKey &&  filteredData[e][_name].toLowerCase().replaceAll(" ", "").startsWith(name +"(") && filteredData[e][_name].toLowerCase().replaceAll(" ", "").endsWith(")"))) {
                    counts.push(parseInt(filteredData[e][_name].split(name)[1].replace("(","").replace(")","").replace(/ /g,'')));
                }
            }
        }
        return counts.length > 0 ? Math.max(...counts) + 1 : count;
    } 

    useEffect(() => {
        const options = {
            //"index" sets the main column of the table to be used as reference when using updateData
            index: _costKey,
            layout: "fitColumns",      //fit columns to width of table
            responsiveLayout: false,  //hide columns that dont fit on the table
            addRowPos: "top",          //when adding a new row, add it to the top of the table
            history: true,             //allow undo and redo actions on the table
            pagination: false,          //paginate the data
            movableColumns: false,     //allow column order to be changed
            selectable: false,
            movableRows: props.movableRows,
            resizableColumns: false,
            autoResize: true,
            dataTreeChildIndent: _indentationPX,
            dataTreeChildField: "children",
            // dataTreeStartExpanded: false,
            dataTreeElementColumn: PS_MAPPING.FIELDS.EXPAND,
            dataTreeCollapseElement: getExpandCollapseButtons(false, ["collapse-button"]),
            dataTreeExpandElement: getExpandCollapseButtons(true, ["expand-button"]),
            dataTreeBranchElement: false, //hide branch element
            invalidOptionWarnings: false,
            placeholder: "",
            width: "100%",
            height: "100%",
            virtualDom: true,
            virtualDomBuffer:6000,
            // renderComplete: this.onTabulatorRenderComplete,
            dataTree: true,
            reactiveData: true,
            scrollToRowPosition: "center", //position row in the center of the table when scrolled to
            scrollToRowIfVisible: false, //prevent scrolling to a row if it is visible
            
            dataTreeRowExpanded: function (row, level) {
                let costKey = row.getData()[_costKey];
                if (expandedRowsCostkeys.current.indexOf(costKey) === -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    expandedRowsCostkeys.current = [...expandedRowsCostkeys.current,costKey];
                    props.setExpandedRowsCostkeys(id,expandedRowsCostkeys.current);
                }
                // let rowIndex = row.getIndex();
                // tabulator.current.scrollToRow(rowIndex, "center", false);
                if (id === "newStack") {
                    $('#newStack .tabulator-tableHolder').animate({
                        scrollTop:  globaltop
                    },100)
                }else{
                    $('#publishedStack .tabulator-tableHolder').animate({
                        scrollTop:  globaltop
                    },100)
                }
                // row.scrollTo();
                
            },
            dataTreeRowCollapsed: function (row, level) {
                let costKey = row.getData()[_costKey];
                let index = expandedRowsCostkeys.current.indexOf(costKey);
                if (index !== -1) {
                    //if not already added, add costkey to state. but when programmatically re-expanded, don't add it a second time
                    expandedRowsCostkeys.current.splice(index, 1);
                    props.setExpandedRowsCostkeys(id,expandedRowsCostkeys.current)
                }
                // let rowIndex = row.getIndex();
                // tabulator.current.scrollToRow(rowIndex, "center", false);
                if (id === "newStack") {
                    $('#newStack .tabulator-tableHolder').animate({
                        scrollTop:  globaltop
                    },100)
                }else{
                    $('#publishedStack .tabulator-tableHolder').animate({
                        scrollTop:  globaltop
                    },100)
                }
                // row.scrollTo();

            },
            cellEdited: function (cell) {
                var rowData = cell.getRow().getData();
                let name = rowData[_name];
                let firstChar = name[0];
                let nameNew = "";
                let nameExists = checkExistingName(name, linearizeHierarchy(cell.getTable().getData(), _children), rowData[_costKey]);
                if (nameExists === -1 || (nameExists === 1 && name !== "")){
                    
                    let len = checkNameOcc(linearizeHierarchy(cell.getTable().getData()), name, rowData[_costKey]);
                    if(len >0){
                        nameNew = name.trim() + " (" + Number(len) + ")";
                    }else {
                        nameNew = name;
                    }
                    let warningMessage = lang.rename_line.replace("[EXISTING_NAME]",nameNew);
                    setWarning(warningMessage);
                    setNewName(nameNew);
                    setOpenWarning(true);
                    setOldName(cell.getOldValue());
                    setCostKey(rowData[_costKey]);
                    return;
                }
                if (nameExists !== 1 && nameExists !== -1) {
                    rowData[_returnName] = name;
                }
                rowData[_name] = name;
                rowData[_returnName] = name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, "").replace(/ /g, '') + PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX;
                let lineObj = tempCalculatedLineConf.current;
                lineObj[PS_MAPPING.FIELDS.NAME]= name;
                tempCalculatedLineConf.current= lineObj;
                calculatedLineConf.current[rowData[_costKey]] = lineObj;
                var tempData = updateChildAttr(cell.getTable().getData(), rowData[_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                tempData = updateChildAttr(tempData, rowData[_costKey], [_name], name);
                profitStackFieldsRef.current = tempData;
                if (tempData) {
                    tabulator.current.updateRow(rowData[_costKey],rowData);
                    tabulator.current.replaceData(tabulator.current.getData());
                    props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
                }
                cell.getColumn().getElement().removeEventListener('keypress', function (e) {
                    if (e.which === 13) {
                        e.stopImmediatePropagation();
                    }
                }, false);
                setStackIsChanged(true);
                onChangeDiscard(true)
                updateRedDotState(cell.getTable().getData());
                toggleSaveButton();
            },
            cellEditing: function (cell) {
                cell.getColumn().getElement().addEventListener('keydown', function (e) {
                    if (e.which === 13) {
                        e.stopImmediatePropagation();
                    }
                }, false);
            },
            rowMouseOver:function(e,row){
                let index = row.getData()[_costKey];
                if(props.id=== 'newStack'){
                    if(checkedItemsRef.current && checkedItemsRef.current.length > 0){
                        $('#handle_row_'+index).css("display","none");
                        ///////
                    } else {
                        $('#handle_row_'+index).css("display","block");
                        $('#add_button_'+index).css("display","block");
                    }
                }
            },
            rowMouseOut:function(e,row){
                let index = row.getData()[_costKey];
                if(props.id=== 'newStack'){
                    $('#handle_row_'+index).css("display","none");
                }
                if(!$('#in-dropdown-container-'+index).hasClass('uk-open')){
                    $('#add_button_'+index).css("display","none");
                }
            },
            rowMouseEnter: function (e,row){
                if(e.buttons === 0){// if no buttons are clicked (we are draging)
                    if(row.getPrevRow()){
                        previousCostKey.current = row.getPrevRow().getData().costKey;// save the costKey of the previous line
                    }else{
                        previousCostKey.current = 0;//first line in the table don't have parent
                    }
                }
            },
            rowMoved: function (row, index, index2) {
                let data = copyObjectValues(tabulator.current.getData());
                executeMoveRow(row, data);
                profitStackFieldsRef.current = data;
               // props.setProfitStackFields(profitStackFieldsRef.current);
               setStackIsChanged(true);
               onChangeDiscard(true);
               updateRedDotState(tabulator.current.getData());
               toggleSaveButton();
               props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
            },
            scrollVertical:function(top){
                globaltop = top;
            },
        }
        
        tabulator.current = new Tabulator("#"+id,options);
        tabulator.current.setColumns(getTabulatorColumns(columns));
        tabulator.current.replaceData(data);
        props.setTabulator(tabulator.current,id);
        props.setRef(ref,id);
       
    }, [])
    const getRowIndex = (displayedRows, costKey) => {
        var index = -1;
        displayedRows.forEach(function (item, key) {
            var costKeyItem = item.getData()[_costKey].replace("Insert", "");
            var costKeyEditted = costKey.replace("Insert", ""); //removing the insert text from costkey for the newly added rows

            if (Number(costKeyItem) === Number(costKeyEditted)) {
                index = Number(key);
                return false;
            }
        });

        return index;
    }
    const removeMovedChild = (data, costkey, parentCostKey) => {
        if (parentCostKey === '201') {
            var curIndex = data.findIndex(el => el[_costKey] === costkey)
            data.splice(curIndex, 1);
            return;
        }
        for (var e in data) {
            if (data[e][_costKey] === parentCostKey) {
                if (data[e][_children]) {
                    var index = data[e][_children].findIndex(el => el[_costKey] === costkey);
                    data[e][_children].splice(index, 1);
                }
            } else if (data[e][_children]) {
                removeMovedChild(data[e][_children], costkey, parentCostKey)
            }
        }
    }
    const addIndexChild=(data, parentCostKey, costkey, item, pushLast)=> {
        for(var e in data) {
            if (data[e][_costKey] === parentCostKey) {
                if(data[e][_children]) {
                    var children = [];
                    for(var elt in data[e][_children]) {
                        item[_parentCostKey] = parentCostKey;
                       
                        if (data[e][_children][elt][_costKey] === costkey) {
                            if (pushLast) {
                                children.push(data[e][_children][elt]);
                                children.push(item);
                            } else {
                                children.push(item);
                                children.push(data[e][_children][elt]);
                            }
                        } else {
                            children.push(data[e][_children][elt]);
                        }
                        
                }
                data[e][_children] = children;
            }
            } else if(data[e][_children]){
                addIndexChild(data[e][_children], parentCostKey, costkey, item);
            }
        }
    }
    const executeMoveRow = (row, data)=>{
        var movedRowCostKey = row.getData()[_costKey];
        var movedRowParentCostKey = row.getData()[_parentCostKey];
        var displayedRows = tabulator.current.rowManager.displayRows[1];    //an array in tabulator that contains all displayed rows in order
        let displayedRowIndex = getRowIndex(displayedRows, movedRowCostKey);
        let previousItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex - 1]);
        let nextItem = displayedRowIndex === 0 ? null : (displayedRows[displayedRowIndex + 1]);
        let currentItem = row.getData();
        var movedRowLineType = currentItem[_costType];
        var allowMove = true;
        if(previousItem && previousItem.getData()[_parentCostKey] === movedRowCostKey) {
            data = removeChildrenAttribute(data);
            tabulator.current.replaceData(data).then(()=>{
                // row.scrollTo();
            });
            return;
        }
        if([costtype.attribute,costtype.calculated].includes(movedRowLineType) && ((previousItem && previousItem.getData()[_parentCostKey] !== '201') || (nextItem && nextItem.getData()[_parentCostKey] !== '201'))){
            let parent = "";
            if(previousItem){
                parent = getEmbeddedChild(data,_children,_costKey,previousItem.getData()[_parentCostKey]);
            } else {
                parent = getEmbeddedChild(data,_children,_costKey,nextItem.getData()[_parentCostKey]);
            }
            if(parent !== null){
                parent.children = parent.children.filter(e=>e[_costType] !== costtype.attribute || e[_costType] !== costtype.attribute); // remove calculated or attribute under parent;
            }
            data = removeChildrenAttribute(data);
            tabulator.current.replaceData(data).then(()=>{
                // row.scrollTo();
            });
            return;
        }
        //dragging to top or bottom of fields
        if (previousItem === null || nextItem === null) {
            removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
            data = removeChildrenAttribute(data);
            currentItem[_parentCostKey] = '201';
            if (previousItem) {
                data.push(currentItem);
                tabulator.current.replaceData(data);
            } else {
                data.unshift(currentItem);
                tabulator.current.replaceData(data);
            }
            return;
        }

        if ((movedRowLineType === costtype.attribute && nextItem && nextItem.getData()[_costType] !== costtype.attribute
            || (movedRowLineType === costtype.calculated && ((previousItem && previousItem.getData()[_costType] === costtype.attribute) || nextItem.getData()["level"] > 1)))) {
            data = removeChildrenAttribute(data);
            tabulator.current.replaceData(data); // this to prevent dragging attributes between other type lines or calculated between attribute lines or between children
            allowMove = false;
        } else if (previousItem && nextItem && previousItem.getData()[_costKey] === nextItem.getData()[_parentCostKey]) {
            if (previousItem.getData().level === 2
                && (movedRowLineType === costtype.attribute || movedRowLineType === costtype.calculated)) {
                    data = removeChildrenAttribute(data);
                    tabulator.current.replaceData(data).then(()=>{
                        // obj.props.setReorderedTreeStructure(data);
                        // row.scrollTo();
                    });
                return;
            } else {
                removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                // add to new parent children array
                addIndexChild(data, previousItem.getData()[_costKey], nextItem.getData()[_costKey], currentItem);
                addlevelOnMove(data, row);
                data = removeChildrenAttribute(data);
                tabulator.current.replaceData(data).then(()=>{
                    // obj.props.setReorderedTreeStructure(data);
                    // row.scrollTo();
                });
            }
            return;
        } else if (previousItem && nextItem && (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey] ||
            currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey])) {
            //draggin to a same level
            if (previousItem.getData()["level"] === 2
                && [costtype.calculated, costtype.attribute].includes(movedRowLineType)) {
                tabulator.current.replaceData(originalData);
                allowMove = false;
            } else {
                removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
                if(previousItem.getData()[_children] && previousItem.getData()[_children].length > 0 && expandedRowsCostkeys.current.includes(previousItem.getData()[_costKey])){
                    currentItem[_parentCostKey] = previousItem.getData()[_costKey];
                    parent = getEmbeddedChild(data,_children,_costKey,previousItem.getData()[_costKey]);
                    parent.children  = [currentItem,...parent.children];
                } else {
                    currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
                    if (previousItem.getData()[_parentCostKey] === '201') {
                        var currIndex = data.findIndex(el => el[_costKey] === nextItem.getData()[_costKey]);
                        data.splice(currIndex, 0, currentItem);
                    } else {
                        if (previousItem.getData()[_parentCostKey] === nextItem.getData()[_parentCostKey]) {
                            addIndexChild(data, previousItem.getData()[_parentCostKey], nextItem.getData()[_costKey], currentItem);
                            addlevelOnMove(data, row);
                        } else if (currentItem[_parentCostKey] === previousItem.getData()[_parentCostKey] && nextItem.getData()[_parentCostKey] === '201'
                            && currentItem[_costType] === costtype.standard) {
                            addIndexChild(data, currentItem[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
                            addlevelOnMove(data, row);
                        } else if(previousItem.getData()[_parentCostKey] !== nextItem.getData()[_parentCostKey]){
                            let parent = getEmbeddedChild(data,_children,_costKey,previousItem.getData()[_parentCostKey]);
                            if(parent !== null){
                                parent.children = [...parent.children,currentItem]
                            }
                        }
                    }
                }
                data = removeChildrenAttribute(data);
                tabulator.current.replaceData(data).then(()=>{
                    // obj.props.setReorderedTreeStructure(data);
                    // row.scrollTo();
                });
                
            }
            return;
        }
        // return;
        // else dragging a row under a child of a parent 
        if (row && currentItem && nextItem && previousItem && previousItem.getData()) {
            currentItem[_parentCostKey] = previousItem.getData()[_parentCostKey];
        }
      
        if(allowMove) {
            removeMovedChild(data, movedRowCostKey, movedRowParentCostKey);
            setOriginalData(data);
        }
        if(!nextItem && previousItem){
            data = [...data,currentItem];
            currentItem[_parentCostKey] = "201";
        } else if(nextItem && nextItem.getData()[_parentCostKey] === '201' && currentItem[_costType] === costtype.standard) {
            addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem, true);
        } else {
            addIndexChild(data, previousItem.getData()[_parentCostKey], previousItem.getData()[_costKey], currentItem);
        }
        if(allowMove){
            tabulator.current.replaceData(data).then(()=> { 
                // row.scrollTo();
            });
        }
        profitStackFieldsRef.current = data;
    }

    const addYellowDotToParent = (rowData) => {
        if (linearizeHierarchy([rowData], _children).filter(e => (e.incompatibleLine)).length > 0) {
            return true;
        } else {
            return false;
        }
    }
    
    /**
     * when moving a standard group, change his level
     * @param {*} data 
     * @param {*} row 
     */
    function addlevelOnMove(data,row){
        let parentElement = getAttributeFromCostKey(data, Number(row.getData().parentCostKey.replace("Insert","")), "column_level","");
        let newLevel = Number(parentElement -row.getData().column_level +1);
        addLevelForAll([row.getData()],_children,"column_level",newLevel);
        addLevelForAll([row.getData()],_children,"level",newLevel);
    }

    function customTreeHeaderFilter(searchVal, rowValue, rowData, filterParams) {
        if (rowValue.toLowerCase().replaceAll(" ", "").indexOf(searchVal.val.toLowerCase().replaceAll(" ", "")) >= 0) {
            return true;
        }
        if (rowData[filterParams.childrenKey] && rowData[filterParams.childrenKey].length > 0) {
            for (var index in rowData[filterParams.childrenKey]) {
                //let parent = rowData[filterParams.fieldKey][index];
                let child = rowData[filterParams.childrenKey][index];
                if (child[filterParams.fieldKey].toLowerCase().replaceAll(" ", "").indexOf(searchVal.val.toLowerCase().replaceAll(" ", "")) >= 0) {
                    return true;
                }
                if (child[filterParams.childrenKey] && child[filterParams.childrenKey].length > 0) {
                    let found = customTreeHeaderFilter(searchVal, rowValue, child, filterParams);
                    if (found) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    let isEditable = function(cell){
        let data = cell.getData();
        return data.name === "" || data[_returnName].endsWith(PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX);
    }

    const removeChildrenAttribute = (data) =>{
        for(let e in data){
            if(data[e][showExpandFlag] && data[e].children && data[e].children.length ===0){
                data[e][showExpandFlag] = false;
                delete data[e].children
            }
            else if(data[e][showExpandFlag] && data[e].children){
                removeChildrenAttribute(data[e].children);
            } else {
                continue;
            }
        }
        return data;
    }

    function getTabulatorColumns(columns) {
        if(props.id === "newStack" && !linearisedPublishedData.current){
            linearisedPublishedData.current = props.getPublishedStackTabulator(true);
        }
        columns = copyObjectValues(columns) || [];

        columns.forEach(col => {
            if ([MANAGE_STACKS.FIELDS.EXPAND, MANAGE_STACKS.FIELDS.EXPAND, PS_MAPPING.FIELDS.ADD].includes(col.field)) {
                col.dontFilter = true;
                col.headerSort = false;
            }
        });
        columns = cleanUpTabulatorColumns(columns, null, null, tabulator.current, { id: id });
        if (addCheckboxes) {
            columns.unshift(checkboxCellObject);
        }
       
        columns.forEach(col => {
            col.formatter = getColumnFormatter(col.field);
            if (col.field === PS_MAPPING.FIELDS.NAME) {
                col.width = "50%";
                col.headerFilterFunc = customTreeHeaderFilter;
                col.headerFilterFuncParams = { childrenKey: _children, fieldKey: _name };
                col.editable = isEditable;
            }

            if (col.field === PS_MAPPING.FIELDS.HANDLE_ROW) {
                col.cssClass = "psl-handle-row min-width-20 width-20";
            }
            if (col.field === PS_MAPPING.FIELDS.ACTION) {
                col.cssClass = "psl-handle-row min-width-20 width-20";
            }
          
            if (col.field === PS_MAPPING.FIELDS.ADD) {
                col.titleFormatter = addNewRowTitleFormatter;
                col.cssClass = "psl-handle-row min-width-20";
                col.width = 40;
            }
            if (col.field === MANAGE_STACKS.FIELDS.PLAN_TERM) {
                col.width = "50%";
                col.cssClass = "plan-term-cell";
            }
        });
        return columns;
    }

    function hideDropDown() {
        UIkit.dropdown($('.in-dropdown-container.uk-dropdown')).hide();
    }

    // function checkNameOcc(data, name, costKey) {
    //     for(var e in data) {
    //         if (data[e]){
    //             if (data[e][_costKey] !== costKey && ((data[e][_name] === name) || (data[e][_name].startsWith(name +" (") && data[e][_name].endsWith(")") && !data[e][_name].endsWith("(matched)") && !data[e][_name].endsWith("(unmatched)")))) {
    //                 this.count++;
    //             }
    //             if (data[e][_children]) {
    //                 checkNameOcc(data[e][_children], name, costKey);
    //             }
    //         }
    //     }
    //     return this.count;
    // } 

    function onAddParentRow(lineType) {
        let newRow = {};
        let data = linearizeHierarchy(tabulator.current.getData(),_children);//copyObjectValues(this.state.profitStackFields);
        let newStackCalcLines = data.filter(e=>e.returnName.endsWith("_ms") || e.costtype === costtype.calculated);
        let publishedData = linearizeHierarchy(props.getPublishedStackTabulator(),_children);
        let finalData = newStackCalcLines.concat(publishedData);
        var costKey = generateUniqueCostKey(finalData || []);
        setCostKey(costKey);
        var newId = generateUniqueIdDB(finalData || []);
        newRow = {
            [_name]: "",
            [_costKey]: costKey+PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX + "Insert",
            [_id]: newId.toString(),
            [VECTOR_MAPPING.ROW_STATUS_VALUES.NEW]: "1",
            returnName: "",
            [ROW_STATUS.FIELD]: ROW_STATUS.VALUES.NEW,
            [_costType]: lineType,
            [_attribute]: "",
            [_attributeFunction]: "",
            [_attributeType]: "",
            [_deleted]: "false",
            [_parentCostKey]:"201",
            [_isInSearchResult]: true,
            [_isExpandable]: lineType === costtype.standard, 
            [showExpandFlag]:lineType === costtype.standard,
            is_expandable:true,
            column_level: 0,
            level:0,
        };

        data.push(newRow);
        profitStackFieldsRef.current = copyObjectValues(data);
        setTimeout(function () {
            //replace data after user hasn't clicked on add for 300ms
            tabulator.current.addData(newRow, false);
            // tabulator.current.replaceData(tabulator.current.getData());
            // props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
            tabulator.current.setFilter(tabulator.current.getFilters());
            $("#new_psline_"+costKey+PS_MAPPING.INSERT).parent().addClass("tabulator-editing");
        }, 300)
        setStackIsChanged(true);
        onChangeDiscard(true);
        setRedDot(true);
        updateRedDotState(data);
        toggleSaveButton();
    }

    // function checkExistingName(name, data, costKey, oldValue) {
    //     for(var e in data) {
    //         if (data[e] && data[e][_name]){
    //             if((data[e][_returnName] && data[e][_returnName].toLowerCase() === name.toLowerCase().replace(/[^a-zA-Z0-9_]/g, '') && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED)) {
    //                 return -1;
    //             } else if (data[e].name.toLowerCase().replace(/ /g, "") === name.toLowerCase().replace(/ /g, "") && data[e][_costKey] !== costKey && data[e][ROW_STATUS.FIELD] !== ROW_STATUS.VALUES.DELETED) {
    //                 return 1;
    //             } else {
    //                 if (data[e][_children]) {
    //                     var res = checkExistingName(name, data[e][_children], costKey, oldValue);
    //                     if (res !== 1 && res !== -1) {
    //                         continue;
    //                     } else {
    //                         return res;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    function hideDropDown() {
        UIkit.dropdown($('.in-dropdown-container.uk-dropdown')).hide();
    }

    function moveButtonFormatter(cell,params) {
        let dotsButtonContainer = document.createElement("div");
        let dotsButton = getTableIconButton(["fa-lg", "far", "fa-plus"], ["uk-button-icon"]);
        
        dotsButtonContainer.classList.add("uk-inline", "moveLineFirstLevel");
        dotsButtonContainer.style.display = "none";
        dotsButtonContainer.appendChild(dotsButton);
        dotsButton.addEventListener("click", function(ev){
            props.moveLines();
        });
        return dotsButtonContainer;
    }

    function addNewRowTitleFormatter(cell, params) {
        let buttons = document.createElement("div");
        let dropdownButton = document.createElement("div");
        let dropdownContainer = document.createElement("div");
        dropdownButton.classList.add("uk-button-icon");
        dropdownContainer.classList.add("in-dropdown-container", "uk-position-fixed", "width-150");
        dropdownContainer.setAttribute("uk-dropdown", "mode: click");
        // three dotted button
        let dotsButtonContainer = document.createElement("div");
        let dotsButton = getTableIconButton(["far", "fa-plus-circle", "fa-lg"], ["uk-button-icon"]);
        dotsButtonContainer.classList.add("uk-inline", "addGroupButton");

        let editContainer = getTableButton(
            "Standard Group",
            [],
            ["justify-content-start", "uk-button-icon"],
            [],
            "",
        ); 
        editContainer.style.width = "100%";
        editContainer.id = "standard-line";
        editContainer.addEventListener("click", function(ev){
            hideDropDown();
            onAddParentRow(costtype.standard);
        });

        let calculatedLine = getTableButton(
            "Calculated Line",
            [],
            ["justify-content-start", "uk-button-icon"],
            [],
            "",
        ); 
        calculatedLine.style.width = "100%";

        calculatedLine.id = "calculated-line";
        calculatedLine.addEventListener("click", function(ev){
            hideDropDown();
            onAddParentRow(costtype.calculated);
        });

        dropdownContainer.appendChild(editContainer);
        dropdownContainer.appendChild(calculatedLine);
        dotsButtonContainer.appendChild(dotsButton);
        dotsButtonContainer.appendChild(dropdownContainer);
        buttons.append(dotsButtonContainer);
        buttons.append(moveButtonFormatter(cell, params));
        return buttons;
        // return dotsButtonContainer;
    }


    function returnMenuDropDown(cell, rowData, type, callback) {
        var parentDiv = document.createElement("div");
        parentDiv.classList.add("uk-inline");
        parentDiv.id = "add-child-row-" + rowData[_costKey];

        var icon = document.createElement("i");
        var iconParent = document.createElement("button");
        var dropDownDiv = document.createElement("div");
        iconParent.classList.add("uk-button-icon", "transparent-bg");
        dropDownDiv.classList.add("in-dropdown-container");
        dropDownDiv.id = "in-dropdown-container-" + rowData[_costKey];

        if (rowData[_costType] === costtype.attribute) {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:top-right; offset: 5');
        } else {
            dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:bottom-left; offset: 5');
        }

        var optionChildDiv = document.createElement("div");

        if (type === "add") {
            if (!checkedItemsRef.current || (checkedItemsRef.current && checkedItemsRef.current.length === 0)) {
                iconParent.appendChild(icon);
                parentDiv.appendChild(iconParent);
                icon.classList.add("far", "fa-plus-circle", "fa-lg");
                var spanDiv = document.createElement("span");
                spanDiv.id = "child-standard-line";
                spanDiv.classList.add("uk-button-icon")
                spanDiv.textContent = "Add Standard Group";
                spanDiv.onclick = () => {
                    callback();
                };
                optionChildDiv.append(spanDiv);
            }

            dropDownDiv.append(optionChildDiv);
        }
        parentDiv.append(dropDownDiv);

        return parentDiv;
    }

    function onAddChild(data, costKey, row, level) {
        let children = [];
        var mappedLines = mappedLines;
        let psFields = linearizeHierarchy(tabulator.current.getData(),_children);//copyObjectValues(this.state.profitStackFields);
        let newStackCalcLines = psFields.filter(e=>e.returnName.endsWith("_ms") || e.costtype === costtype.calculated);
        let publishedData = linearizeHierarchy(props.getPublishedStackTabulator(),_children);
        let finalData = newStackCalcLines.concat(publishedData);
        var newCostKey = generateUniqueCostKey(finalData || []);
        var newId = generateUniqueIdDB(finalData || []);
        let newRow = {};
        newRow = {
            [_name] : "",
            [_id]: newId.toString(),
            ["id"]: newId.toString(),
            [_costKey]: newCostKey+PS_MAPPING.INSERT,
            [_parentCostKey]: costKey,
            [VECTOR_MAPPING.ROW_STATUS_VALUES.NEW]: "1",
            level: level+1,
            column_level: level +1,
            [_returnName]: "",
            [ROW_STATUS.FIELD]:
            ROW_STATUS.VALUES.NEW,
            [_costType]: costtype.standard ,   //copy parent's costtype
            [_isExpandable]: true,
            [showExpandFlag]: true,
            is_expandable:true,
        };
        let tempParentRowData = row.getData();
        //Add new row to children array
        if(!tempParentRowData.children){
            tempParentRowData.children = [];
        }
        children = copyObjectValues(tempParentRowData.children);
        
        children.push(newRow);

        //Update data table row with new children array
        row.update({[showExpandFlag]:true,is_column_expandable:true,is_expandable:true,children:children});
        expandedRowsCostkeys.current.push(tempParentRowData[_costKey]);
        props.setExpandedRowsCostkeys(id,expandedRowsCostkeys.current);
        tabulator.current.replaceData(tabulator.current.getData());
        props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
        setStackIsChanged(true);
        onChangeDiscard(true);
        setRedDot(true);
        updateRedDotState(tabulator.current.getData());
        toggleSaveButton();
        row.treeExpand();
    }

    const isMoved = (row) => {
        let data = linearizeHierarchy(copyObjectValues(getNewStackData()) , _children);
        if (data && data.filter(e => e[_returnName] === row[_returnName]).length > 0) {
            return true;
        }
        let newData = linearizeHierarchy([row], _children);
        for (var elt in newData) {
            if (data && data.filter(e => e[_returnName] === newData[elt][_returnName]).length > 0) {
                return true;
            }
            /** block of code commented since we are linearzing so no need for recursion */
            // if (row && row !== null && row[_children]) {
            //     return hasMovedChildren(row[_children]) === 1 ? true : false;
            // }
        }
        return false;
    }


    const hasMovedChildren = (result) => {
        for (var row in result) {
            if (result[row].isMoved) {
                return 1;
            } else if (result[row][_children]) {
                if (hasMovedChildren(result[row][_children]) !== 1) {
                    continue;
                }else {
                    return 1;
                }
            }
        }
    }

    // const isRedDot = (data) => {
    //     if (!data.name || (!data[_children] && data[_isExpandable]) || (data[_children] && data[_children].length === 0)) {
    //         return true;
    //     } 
    //     if (data && data !== null && data[_children] && data.children.length !== 0 ) {
    //         let dot =  hasRedDot(data[_children]) === 1 ? true : false;
    //         return dot;
    //     }
    //     return false;
    // };

/**
 * Returns true if the row has atleast 1 child moved
 * @param {*} data 
 * @returns 
 */

    const hasMoved = (data) => {
        if (data && data !== null && data[_children]) {
            return hasOneMovedChild(data[_children]) === 1 ? true : false;
        }
        return false;
    }

    const hasOneMovedChild = (result) => {
        for (var row in result) {
            if (!result[row][_children] && !result[row].isMoved) {
                return 1;
            } else if (result[row][_children]) {
                if (hasOneMovedChild(result[row][_children]) !== 1) {
                    continue;
                }else {
                    return 1;
                }
            }
        }
    }

    function getColumnFormatter(colName) {
        var columnFormatter = "";
        switch (colName) {
            case MANAGE_STACKS.FIELDS.CHECKBOX:
                columnFormatter = function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let rowNumber = cell.getRow().getPosition();
                    if (rowData[MANAGE_STACKS.FIELDS.COST_TYPE] === costtype.calculated || (props.id=== 'publishedStack' &&  (/*rowData.isMoved || */isMoved(rowData) || rowData.isApplicableForMove))) {
                        return "";
                    }
                    // var div = document.createElement('div');
                    var checkbox = createCheckbox();
                    checkbox.name = 'chosenEntity';
                    checkbox.id = 'checkbox_icon_' + rowNumber;
                    checkbox.classList.add('chosenEntity');
                    checkbox.checked = cell.getRow().getData().checked;
                    checkbox.indeterminate = cell.getRow().getData().indeterminate;
                    checkbox.onchange =(e)=> {
                        props.onCheckBoxChecked(e,cell);
                        $('#publishedStack .tabulator-tableHolder').scrollTop(globaltop)
                    };
                    // div.append(checkbox);
                    return checkbox;
                }

                break;
            case MANAGE_STACKS.FIELDS.NAME:
                columnFormatter = function(cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let div = document.createElement("div");
                    let divParent = document.createElement("div");
                    let p = document.createElement("p");
                    let em = document.createElement("em");
                    let blueDot = document.createElement("span");
                    let yellowDotDiv = document.createElement("div");
                    let linkIcon = document.createElement("i");
                    let isMovedRow = isMoved(rowData);
                    blueDot.setAttribute("uk-tooltip", ([costtype.attribute].includes(rowData[_costType]) ? lang.manage_stacks.attribute_bluedot_tooltip : lang.manage_stacks.bluedot_tooltip));
                    em.textContent = ([costtype.attribute, costtype.calculated].includes(rowData[_costType]) ? rowData[_costType].charAt(0).toUpperCase() + rowData[_costType].slice(1) : "");
                    em.classList.add("text-grey", "font-weight-normal", "uk-margin-xsmall-left");
                    em.style.padding = "0 " + convertPxToViewport(1);
                    let span = document.createElement("span");
                    span.classList.add("uk-text-overflow-ellipsis", "psl-title-name", "uk-display-inline-block")
                    p.classList.add("uk-flex-inline", "uk-cursor-pointer");
                    span.textContent = cell.getValue() + " ";
                    if(rowData[MANAGE_STACKS.FIELDS.COST_TYPE] === costtype.calculated){
                        p.classList.add("uk-text-bold")
                    }
                    let el = document.createElement("i");
                    //p.innerHTML = cell.getValue();
                    if(!(rowData[_costType] === costtype.standard && !rowData.formulaInFact) && rowData[_costType] !== costtype.calculated){
                        p.setAttribute("uk-tooltip",cell.getValue() && rowData[MANAGE_STACKS.FIELDS.DESCRIPTION] !== ""?
                        "title: Name: " +cell.getValue()+" <br> Description: "+rowData[MANAGE_STACKS.FIELDS.DESCRIPTION]+";pos:bottom" : "title: Name: " +cell.getValue()+ "<br> No Description");
                    }
                    if ((/*rowData.isMoved || */isMovedRow) && props.id=== 'publishedStack') {
                        p.classList.add("underline-onhover","disabled");
                    }
                    if(((hasMoved(rowData)) || (!rowData[_children] && !isMovedRow)) && props.id == "publishedStack" && rowData[MANAGE_STACKS.FIELDS.COST_TYPE] !== costtype.calculated){
                        blueDot.classList.add("column-blue-dot");
                    } else {
                        blueDot.classList.remove("column-blue-dot");
                    }
                    p.classList.add("underline-onhover");
                    span.setAttribute("id","new_line_"+rowData[_costKey]);
                    p.appendChild(span);
                    p.appendChild(el);
                    p.appendChild(em);
                    $(p).css("cursor", "pointer");
                    if (props.id === "publishedStack" && rowData.isMoved && (![costtype.standard, costtype.calculated].includes(rowData[_costType]) || (rowData[_costType]=== costtype.standard && ! rowData.children))){
                        // || (rowData[_costType]=== costtype.standardGroup && ! rowData.children) ---> if a standard line have hidden childrens  
                        linkIcon.classList.add("link-icon", "fa-lg", "fal", "fa-link", "d-inline", "uk-margin-small-right");
                        linkIcon.classList.add("uk-button-icon", "dots-button", "transparent-bg");
                        linkIcon.setAttribute("uk-tooltip", lang.profit_stack_link_filter_tooltip);
                        linkIcon.onclick = (e) =>{
                            props.filterData(rowData);
                        }
                    }
                    div.appendChild(p);
                    div.appendChild(linkIcon);
                    div.appendChild(blueDot);
                    if (!rowData[_name] && rowData[VECTOR_MAPPING.ROW_STATUS_VALUES.NEW] && rowData[VECTOR_MAPPING.ROW_STATUS_VALUES.NEW] === "1" && props.id==="newStack") {
                        var label = document.createElement('label');
                        label.textContent = lang.ps_mapping.enter_name;
                        label.classList.add("red", "uk-padding-remove-left");
                        var redDotDiv = document.createElement("div");
                        redDotDiv.classList.add("column-red-dot-psl-stacks");
                        redDotDiv.setAttribute("uk-tooltip", lang.manage_stacks_not_configured);
                        divParent.classList.add("uk-display-inline-flex");
                        divParent.setAttribute("id","new_psline_"+rowData[_costKey]);
                        divParent.appendChild(label);
                        divParent.appendChild(redDotDiv);
                        return divParent;
                    }

                    if (props.id === "newStack" &&  rowData[_costType] === costtype.standard) {
                        var redDotDiv = document.createElement("div");
                        if (isRedDot(rowData)) {
                            redDotDiv.classList.add("column-red-dot-psl-stacks");
                            redDotDiv.setAttribute("uk-tooltip", lang.manage_stacks_not_configured);
                            div.appendChild(redDotDiv);
                        }else{
                            redDotDiv.classList.remove("column-red-dot-psl-stacks");
                        }
                        if (rowData[_isExpandable] === false) {
                            redDotDiv.classList.remove("column-red-dot-psl-stacks");
                        }
                    }
                    if (props.id === "newStack" && rowData[_costType] === "calculated") {
                        var redDotDiv = document.createElement("div")
                        if (!rowData.formula || !rowData.name) {
                            redDotDiv.classList.add("column-red-dot-psl-stacks");
                            redDotDiv.setAttribute("uk-tooltip", lang.manage_stacks_not_configured);
                            div.appendChild(redDotDiv);
                        }else{
                            redDotDiv.classList.remove("column-red-dot-psl-stacks");
                        }
                    }
                    if (props.id === "newStack" && addYellowDotToParent(rowData)) {
                        yellowDotDiv.classList.add("column-yellow-dot-psl-stacks");
                        yellowDotDiv.setAttribute("uk-tooltip", lang.manage_stacks_line_not_found);
                        div.appendChild(yellowDotDiv);
                    }
                    return div;
                }
                break;
            case PS_MAPPING.FIELDS.HANDLE_ROW:
                columnFormatter = function (cell) {
                    let rowData = cell.getRow().getData();
                    let filteredLineInPublished = linearisedPublishedData.current.filter(e=>e.returnName === rowData[_returnName]);
                    filteredLineInPublished = filteredLineInPublished? filteredLineInPublished[0]:[];
                    if ([PSL_RETURN_NAMES.GROSS_PROFIT, PSL_RETURN_NAMES.NET_PROFIT].includes(rowData[_returnName])) {
                        cell.getElement().style.pointerEvents = "none";     //disable drag
                    } else {
                        var div = document.createElement("div");
                        // if (_this.props.checked_combinations && _this.props.checked_combinations.length > 0 && !rowData.children && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.calculated && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.attribute) {
                        if ((rowData.children || (rowData.costtype === costtype.standard && (rowData.children || rowData[showExpandFlag] || (filteredLineInPublished && filteredLineInPublished.hasChildren) || rowData.returnName.endsWith("_ms"))) || rowData.costKey.endsWith(PS_MAPPING.INSERT)) && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.calculated && rowData[PS_MAPPING.FIELDS.COSTTYPE] !== costtype.attribute) {
                            let i = getTableIconButton(["fa-lg", "far", "fa-plus"], ["uk-button-icon", "moveLine"])
                            i.style.display = checkedItemsRef.current && checkedItemsRef.current.filter(e=>e.costtype==="attribute").length >0? "none": checkedItemsRef.current && checkedItemsRef.current.length > 0 ? "block" : "none";
                            i.title = "Move to";
                            i.onclick = (e) => { 
                                props.moveLines(cell, true, rowData);
                            }
                            div.appendChild(i);
                        }

                        let i2 = document.createElement("i");
                        i2.classList.add("fa-lg", "fas", "fa-grip-vertical", "uk-grab", "moveRowIcon");
                        i2.id = "handle_row_"+rowData[_costKey];
                        i2.style.display  = "none";
                        div.appendChild(i2);
                        return div;
                    }
                } 
            break;
            case PS_MAPPING.FIELDS.ADD:
            columnFormatter = function (cell) {
                let rowData = cell.getRow().getData();
                let filteredLineInPublished = linearisedPublishedData.current.filter(e=>e.returnName === rowData[_returnName]);
                filteredLineInPublished = filteredLineInPublished? filteredLineInPublished[0]:[];
                var div = document.createElement("div");
                if((rowData.children || (rowData.costtype === costtype.standard && (rowData.children || rowData[showExpandFlag] || (filteredLineInPublished && filteredLineInPublished.hasChildren) || rowData.returnName.endsWith("_ms"))) || rowData.costKey.endsWith(PS_MAPPING.INSERT)) 
                    && rowData[_costType] !== costtype.calculated
                    && (!checkedItemsRef.current || (checkedItemsRef.current && checkedItemsRef.current.length === 0))){
                    let addDiv = returnMenuDropDown(cell, rowData, "add", function () {
                        onAddChild(rowData, rowData[_costKey], cell.getRow(), rowData["level"]);
                    });
                    addDiv.classList.add("dots-button");
                    div.id = "add_button_"+rowData[_costKey];
                    div.style.display  = "none";
                    div.appendChild(addDiv);
                }
                return div;
            }
            break;
            case PS_MAPPING.FIELDS.ACTION:
                columnFormatter = function(cell) {
                    let rowData = cell.getRow().getData();
                    let rowNumber = cell.getRow().getPosition();
                    // actions three dotted dropdown button container
                    var dropdownContainer = document.createElement("div");
                    dropdownContainer.id = "dropDown_"+rowNumber;
                    dropdownContainer.setAttribute("uk-dropdown", "mode: click");
    
                    // three dotted button
                    var dotsButtonContainer = document.createElement("div");
                    var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "dots-button"]);
                    dotsButtonContainer.classList.add("uk-inline");
                    
                    if(rowData[_costType] === costtype.calculated){// if it's a calculated line add the setup
                        // edit stack button
                        var editIconDiv = getTableButton(
                            lang.manage_stacks.edit_stack,
                            [],
                            ["justify-content-start", "uk-button-icon"],
                            ["fal", "fa-edit", "uk-margin-small-right"],
                            "left",
                            lang.manage_stacks.edit_stack
                        ); 

                        editIconDiv.onclick = (e) => { setupCalcLine(rowData) }
                        dropdownContainer.appendChild(editIconDiv);
                    }
                    // delete stack button
                    var deleteIconDiv = getTableButton(
                        lang.manage_stacks.remove_stack,
                        [],
                        ["justify-content-start", "uk-button-icon"],
                        ["fal", "fa-trash-alt", "uk-margin-small-right"],
                        "left",
                        lang.manage_stacks.remove_stack
                    );

                    deleteIconDiv.onclick = (e) => {startDeleteRow(cell)}
                    dropdownContainer.appendChild(deleteIconDiv);
                    
                    dotsButtonContainer.appendChild(dotsButton);
                    dotsButtonContainer.appendChild(dropdownContainer);
                    return dotsButtonContainer;
                };
                break;
            case MANAGE_STACKS.FIELDS.PLAN_TERM:
                columnFormatter = function (cell, formatterParams) {
                    let rowData = cell.getRow().getData();

                    let p = document.createElement("p");
                    p.innerHTML = cell.getValue() === undefined ? "" : cell.getValue();
                    p.style.marginLeft = "calc(" + _indentationPX + "px * -1 * " + rowData["level"];

                    let div = document.createElement("div");
                    div.appendChild(p);
                    return div;
                }
                break;
        }
        return columnFormatter;
    }

    const invalidFormulaDialogContent = () => {
        return (
            <h4>{lang.formula.invalid_formula}</h4>
        )
    }

    const deleteRowDialogContent = () => {
        let row = selectedPSLData;
        if(row.length === 0) { return; }
        let usingLines = isRowUsedInCalculated(row);

        if (usingLines.length) {
            let lines = "";
            let addedLinesNb = 0;
            usingLines.forEach((usedLine) => {
                usedLine.childName.forEach((line) => {
                    let exist = lines.indexOf(line);
                    if(exist > -1) {
                        lines += "";
                    }
                    else{
                        lines += line + ", ";
                        addedLinesNb ++;
                    }
                });
            });
            lines = lines.slice(0, -2);//remove the last ", "
            let msg = addedLinesNb > 1 ? lang.used_ps_no_delete_1_calc.replace("is","are"): lang.used_ps_no_delete_1_calc ;

            return (
                <div>
                    <h4>{`${lines} ${msg}`}</h4>
                    <ul>
                        {usingLines.map((line) => <li key={line.pslName}><h4 className="uk-margin-left">{"- " + line.pslName}</h4></li>)}
                    </ul>
                    <h4>{lang.used_ps_no_delete_2}</h4>
                </div>
            )
        }
    }

    const dialogActionsButtons = (btnClick) => {
        return  ( 
            <Button 
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={btnClick}
            />
        )
    }

    const handleOpenDeletePSLRowDialog = (isOpen, row) => {
        setSelectedPSLData(row);
        setOpenDeletePSLRowDialog(isOpen);
    }

    function startDeleteRow (cell) {
        let row = cell.getRow().getData();
        let usingLines = isRowUsedInCalculated(row);
        if (usingLines.length) {
            handleOpenDeletePSLRowDialog(true, row);
            return;
        }
        cell.getRow().delete();
        deleteRow(cell);
        tabulator.current.setData(tabulator.current.getData()).then(()=>{
            props.setDeletedCellFromChild(cell);
        });
        profitStackFieldsRef.current = tabulator.current.getData();
        updateRedDotState(tabulator.current.getData())
        toggleSaveButton();
        props.reExpandCollapsedRows(undefined,tabulator.current,"newStack")
    }

    function isRowUsedInCalculated(row) {
        let deletedCK = row[_costKey];// parent row costkey
        let childsCostKeys = getChildCostKeys(deletedCK.replace("Insert", ""), tabulator.current.getData()) // childs costkey
        childsCostKeys.push(parseInt(deletedCK));
        let calculatedMappedLines = tabulator.current.getData().filter(line => line[_costType] === costtype.calculated);
        let usingLines = [];
        calculatedMappedLines.forEach(line => {
            let formula = typeof line[_mapFormula] === "string" ? tryParse(line[_mapFormula]) :line[_mapFormula];
            let usedCostkeys = [];
            let isUsed = formula ? childsCostKeys.some(element => {
                if(formula.filter(item => Number(item[CALCULATED_COLUMNS.FIELDS.COLUMN_FIELDS.VALUE]) === element && item.type !== "control").length > 0){
                    usedCostkeys.push(element);
                }
            }): undefined;
            if (usedCostkeys.length>0) {
                let names = [];
                usedCostkeys.forEach((costKey) => {
                    names.push(getAttributeFromCostKey(tabulator.current.getData(), costKey, "name",""));
                });
                usingLines.push({pslName:line[_name], childName:names});
            }
        })
        return usingLines;
    }

    function expandCollapseAll() {
        tabulator.current.options.dataTreeStartExpanded = ! isAllExpanded;
        tabulator.current.replaceData(tabulator.current.getData());
       if(isAllExpanded) {
        expandedRowsCostkeys.current = linearizeHierarchy(tabulator.current.getData(),_children).filter(e => (e.children || (e.children && e.children.length > 0))).map(e=>e[_costKey]);
       }  else {
        expandedRowsCostkeys.current = []
       }
       props.setExpandedRowsCostkeys(id,expandedRowsCostkeys.current);
       setIsAllExpanded(!isAllExpanded);
       isAllExpandedRef.current=!isAllExpanded;
    }


    function onTabulatorRenderComplete() {

    }

    function closeSidePanel(callback) {
        if(isChecking.current){// don't close sidepanel when we are checking if the calculated line formula is valid
            return;
        }
        setOpenSidePanel(false);
        setWarning("");
        if(typeof callback === "function"){
            callback();
        }
        // setStackIsChanged(true);
        // onChangeDiscard(true)
        // updateRedDotState(tabulator.current.getData());
        // toggleSaveButton();
    }
    
    /**
     * setup a calculated line (open sidepanel and fill saved fields)
     * @param {*} rowData 
     */
    function setupCalcLine(rowData){
        setCostKey(rowData[_costKey]);
        tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME] = rowData[PS_MAPPING.FIELDS.NAME];
        tempCalculatedLineConf.current[PS_MAPPING.FIELDS.DESCRIPTION] = rowData[PS_MAPPING.FIELDS.DESCRIPTION];
        tempCalculatedLineConf.current[PS_MAPPING.FIELDS.MAP_FORMULA] = rowData[PS_MAPPING.FIELDS.MAP_FORMULA];
        if(tempCalculatedLineConf.current.formula){
            calculatedLineConf.current[rowData[_costKey]] = tempCalculatedLineConf.current;
        }
        setOpenSidePanel(true);

    }

    function launchToast() {
      $("#toastManageStacksCol").addClass("show");
      setTimeout(function(){
          $('[id^="toastManageStacksCol"]').removeClass("show");
      }, 4000);
    }

    /**
     * on submit, check if the formula will fail
     */
    function validateCalculatedFormula (){
        if(!tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME]){
            setWarning(lang.manage_access.name_empty);
            return -1;
        }else if(!calcPSLineRef.current.state.pslFormula.formula || !calcPSLineRef.current.state.pslFormula.formula.length || !calcPSLineRef.current.formulaDRef.state.isFormulaValid){
            setOpenInvalidFormulaDialog(true);
            return -1;
        }
        let nameExists = checkExistingName(tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME], tabulator.current.getData(),costKey);
        if (nameExists === -1 || (nameExists === 1 && name !== "")){        
            setWarning(lang.name_used);
            return -1;
        }
        isChecking.current = true;
        var query = {
            action: "validateCalculatedFormula",
            stack: JSON.stringify(tabulator.current.getData()),
            configuration: JSON.stringify({formula:calcPSLineRef.current.state.pslFormula.formula}),
            scenario_id: props.scenario
        }
        let onThenCallback = (data) => {
            if(data.isValid) {
                saveCalculatedLineConf();
                isChecking.current = false
                closeSidePanel();
                launchToast();
            } else{
                isChecking.current = false
                setOpenValidationWarning(true);
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "validateCalculatedFormula",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_COLUMNS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback
        }
        fetchAPI(fetchOptions);
    }

    /**
     * when changing name, description or formula of a calculatd line
     * @param {*} e 
     * @param {*} key 
     */
    function updateCalculatedLineConf(e, key){
        if(key === PS_MAPPING.FIELDS.NAME){
            setWarning("");
        }
        let lineObj = tempCalculatedLineConf.current;
        lineObj[key]= $(e.currentTarget).val();
        tempCalculatedLineConf.current= lineObj;
        setStackIsChanged(true);
        onChangeDiscard(true)
        updateRedDotState(tabulator.current.getData());
        toggleSaveButton();
    }

    /**
     * when saving the calculated line configuration (on submit)
     */
    function saveCalculatedLineConf(){
        if(!tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME]){
            setWarning(lang.manage_access.name_empty);
            return -1;
        }else if(calcPSLineRef.current !== null && (!calcPSLineRef?.current?.state?.pslFormula?.formula || !calcPSLineRef?.current?.state?.pslFormula?.formula?.length || !calcPSLineRef?.current?.formulaDRef?.state?.isFormulaValid)){
            setOpenInvalidFormulaDialog(true)
            return -1;
        }
        let nameExists = checkExistingName(tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME], tabulator.current.getData(),costKey);
        if (nameExists === -1 || (nameExists === 1 && name !== "")){        
            setWarning(lang.name_used);
            return -1;
        }
        setWarning("");
        calculatedLineConf.current[costKey] = tempCalculatedLineConf.current;
        let data = tabulator.current.getData();
        let rowData = data.filter(e=>e.costKey === costKey)[0]; // a calculated line is always a parent, no need to search on childs
        rowData[_name] = tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME];
        rowData[_returnName] = tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME].toLowerCase().replace(/[^a-zA-Z0-9_]/g,'').replace(/ /g,'')+PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX;
        rowData[PS_MAPPING.FIELDS.DESCRIPTION] = tempCalculatedLineConf.current[PS_MAPPING.FIELDS.DESCRIPTION];
        rowData[PS_MAPPING.FIELDS.MAP_FORMULA] =  calcPSLineRef.current.state.pslFormula.formula || tempCalculatedLineConf.current.formula;

        if (rowData) {
            tabulator.current.updateRow(costKey,rowData);
            profitStackFieldsRef.current = tabulator.current.getData();
            tabulator.current.replaceData(data);// to recall the columnformatter and apply changes
            // updateRedDotState(tabulator.current.getData());
            props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
            // toggleSaveButton();
        }
        updateRedDotState(tabulator.current.getData());
        setStackIsChanged(true);
        onChangeDiscard(true)
        toggleSaveButton();
    }

    /**
     * reset the formula in the setup of the calculated line
     */
    function resetFormulaChanges(){
        if(calculatedLineConf.current[costKey]){
            calcPSLineRef.current.setState({pslFormula:calculatedLineConf.current[costKey]})
        }
    }

    function clearFilter(){
        props.setHasFilter(false);
        tabulator.current.clearFilter();
    }

    function cancelRenamingLine(){
        triggerEdit(costKey);
    }

    function closeValidationWarning(){
        setOpenValidationWarning(false);
    }

    /**
     * after user clicks to rename his line the row is being updated 
     * @param {*} data 
     * @param {*} level 
     */
    function renameLine(data, level=1){
        for(let e in data) {
            if(data[e]){
                if(data[e][_costKey] === costKey){
                    data[e][_name]= newName;
                    data[e][_returnName] = newName.toLowerCase().replace(/[^a-zA-Z0-9_]/g,"").replace(/ /g,'')+PS_MAPPING.FIELDS.CREATED_GROUP_SUFFIX;
                    let lineObj = tempCalculatedLineConf.current;
                    lineObj[PS_MAPPING.FIELDS.NAME]= newName;
                    tempCalculatedLineConf.current= lineObj;
                    calculatedLineConf.current[data[e][_costKey]] = lineObj;
                    var tempData = updateChildAttr(tabulator.current.getData(), data[e][_costKey], [ROW_STATUS.FIELD], ROW_STATUS.VALUES.EDITED);
                    tempData = updateChildAttr(tempData, data[e][_costKey], [_name], newName);
                    profitStackFieldsRef.current = tempData;
                    if (tempData) {
                        tabulator.current.updateRow(data[e][_costKey],data[e]);
                        tabulator.current.replaceData(tabulator.current.getData());
                        props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
                    }
                    setStackIsChanged(true);
                    onChangeDiscard(true)
                    updateRedDotState(tabulator.current.getData());
                    toggleSaveButton();
                }
            }
            if(data[e][_children]){
                renameLine(data[e][_children], level+1);
            }
        }
        if (level === 1) {
            tabulator.current.setData(data);
            setOpenWarning(false);
            setWarning("");
            props.reExpandCollapsedRows(undefined,tabulator.current,"newStack");
        }
    }

    /**
     * trigger edit cell on name if user chooses not to rename the row 
     * @param {*} costKey 
     * @param {*} rows 
     */
    function triggerEdit(costKey, rows){
        var allRows = rows || tabulator.current.getRows();
        allRows.forEach(function (row) {
            if(row.getData()[_costKey] === costKey) {
                let cell = row.getCell(PS_MAPPING.FIELDS.NAME);
                cell.getData()[PS_MAPPING.FIELDS.NAME] = oldName;
                cell.edit();
                setOpenWarning(false);
                setWarning("");
            }else{
                if(row.getTreeChildren() && row.getTreeChildren().length > 0) {
                    triggerEdit(costKey, row.getTreeChildren());
                }
            }
        })
    }

    const warningValidationModal = () => {
        return (
            <div className='pi-warning-background uk-border-rounded uk-padding-xsmall margin-right-left'>
                <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    {lang.manage_columns.text.invalid_formula_warning}
                </DialogContentText>
            </div>
        )
    }

    const warningModal = () => {
        return (
            <div className='uk-border-rounded ' >
                <h5 className="uk-text-xlarge">{warning}</h5>
            </div>
        )
    }

    const warningModalValidationActions = () =>{
        return(
            <Button 
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close" 
                className={"uk-padding-small-right"}
                onBtnClick={() => {setOpenValidationWarning(false)}}
            />
        )
    }

    const warnignModalActions = () =>{
        return(
        <>
            <Button 
                label={lang.modal.buttons.rename}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className={"uk-padding-small-right"}
                onBtnClick={()=>renameLine(tabulator.current.getData())}
            /> 
            <Button 
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className="uk-padding-small-right"
                onBtnClick={cancelRenamingLine}
            />
        </>
        )
    }

    function body() {
        return(
            <div>
                <div className="uk-margin-xmedium-bottom">
                    <label htmlFor="Name" className="uk-text-xmedium">{lang.manage_access.NAME}<span className="input_required_text">{"(Required)"}</span></label>
                    <FormComponent tag="input" id={PS_MAPPING.FIELDS.NAME} className="uk-input" placeholder={lang.psl_placeholders.calculated_line_name}
                        onChange={(e)=>{updateCalculatedLineConf(e, PS_MAPPING.FIELDS.NAME)}} value={calculatedLineConf.current && calculatedLineConf.current[costKey] ? calculatedLineConf.current[costKey][PS_MAPPING.FIELDS.NAME] : ""}
                    />
                    {warning !== "" ? <div id="calc-col-warn" className="fs-12 red italic">{warning}</div> : ""}
                </div>
                <div className="uk-margin-xmedium-bottom">
                    <label htmlFor="Description" className="uk-text-xmedium">{lang.manage_access.DESCRIPTION}<span className="input_required_text_disabled">{"(Optional)"}</span></label>
                    <FormComponent tag="textarea" id={PS_MAPPING.DESCRIPTION} className="uk-textarea" placeholder={lang.psl_placeholders.calculated_line_description}
                        onChange={(e)=>{updateCalculatedLineConf(e, PS_MAPPING.FIELDS.DESCRIPTION)}} value={calculatedLineConf.current && calculatedLineConf.current[costKey] ? calculatedLineConf.current[costKey][PS_MAPPING.FIELDS.DESCRIPTION] : ""}
                    />
                </div>
                <div className="uk-margin-xmedium-bottom">
                    <CalculatedProfitStackLine ref={calcPSLineRef} line = {calculatedLineConf.current? calculatedLineConf.current[costKey]:line} 
                        profitStackLineColumns={tabulator.current.getData().filter(e=>e[_costKey] !== costKey && e[_costType] !== costtype.attribute && (e[_costType] !== costtype.calculated || (e[_costType] === costtype.calculated && e.formula) ))} 
                        isStacks={true} resetFormulaChanges={resetFormulaChanges} 
                    />
                </div>
            </div>
        );
    }

    let buttons = [];
    buttons.push(
        {button:lang.modal.buttons.submit, isSubmit: true, isDisabled :false},
        {button:lang.modal.buttons.cancel, isSubmit: false, isDisabled :false, onClick:closeSidePanel}
    );

    let backdrop = "";
    if (openSidePanel) {
        backdrop = <Backdrop close={closeSidePanel} />;
    }
    return (
        <div className="pi-box-shadow manage-stack-container uk-border">
            <div id={"toastManageStacksCol"} className="toast toast-success">
              <div id="desc">
                <i className={"fa-lg fas uk-margin-small-right " + "fa-check-circle greenText"} aria-hidden="true"></i>
                {lang.manage_columns.text.calculated_line_saved_successfully}
              </div>
            </div>
            <Modal 
                id={"invalid-formula-dialog"}
                openDialog={openInvalidFormulaDialog}
                dialogActions={() => dialogActionsButtons(() => setOpenInvalidFormulaDialog(false))}
                bodyContent={invalidFormulaDialogContent}
                closeClick={() => setOpenInvalidFormulaDialog(false)}
                size={DIALOG_SIZE.SMALL}
            />
            <Modal 
                id={"delete-psl-row-dialog"}
                openDialog={openDeletePSLRowDialog}
                dialogActions={() => dialogActionsButtons(() => handleOpenDeletePSLRowDialog(false, []))}
                bodyContent={deleteRowDialogContent}
                closeClick={() => handleOpenDeletePSLRowDialog(false, [])}
                size={DIALOG_SIZE.LARGE}
            />
            {openSidePanel?
                <SidePanelNew body={body()} largeDisplay={true} onSubmit={validateCalculatedFormula} buttons={buttons} show={openSidePanel}
                close={closeSidePanel} title={tempCalculatedLineConf.current[PS_MAPPING.FIELDS.NAME] + " Setup - Calculated Line"} />
                :""
            }
            {backdrop}
            <Modal 
                id={"stacks-renaming-warning-dialog"}
                openDialog={openWarning}
                dialogActions={warnignModalActions}
                bodyContent={warningModal}
                closeClick={cancelRenamingLine}
                size={DIALOG_SIZE.LARGE}
            />
            <Modal 
                id={"stacks-validation-warning-dialog"}
                openDialog={openValidationWarning}
                dialogActions={warningModalValidationActions}
                bodyContent={warningValidationModal}
                closeClick={closeValidationWarning}
                size={DIALOG_SIZE.LARGE}
            />
            <div className="published-stack-header">
                <div className="uk-flex-inline uk-flex-bottom">
                    <span id={"stack_title_" + id} className="uk-text-bold uk-text-xmedium">{title}</span>
                    {isAllExpanded ?
                        <Button 
                            id="Collapse_PS"  
                            label={"Collapse all"}
                            title={"Collapse all"}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            leftIcon={<i className={"fal fa-minus-square fa-lg"} aria-hidden="true" />}
                            className="uk-display-none uk-margin-left"
                            aria-hidden="true"
                            onBtnClick={() => { expandCollapseAll()}}
                        />
                        :
                        <Button 
                            id="Expand_PS"
                            label={"Expand all"}
                            title={"Expand all"}
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            className="uk-margin-left"
                            leftIcon={<i className={"fal fa-plus-square fa-lg"} aria-hidden="true" />}
                            aria-hidden="true" 
                            onBtnClick={() => { expandCollapseAll()}}
                        /> 
                    }
                    {props.hasFilter?
                        <a className="text-link uk-margin-default-left" href="#" uk-tooltip={lang.profit_stack_link_moved_filter_tooltip} onClick={() => {clearFilter()}}>{lang.ui_filter.titles.clear_filter}</a>
                        :""
                    }
                </div>
            </div>
            <div id={id} forwardRef="mainTable" />
        </div>
    );
})
export default StackTabulator;