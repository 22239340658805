import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import "./entityList.css";

export default function NewButton(props) {
  return (
    <Stack direction={props.direction ? props.direction : "row"} spacing={2}>
      <Button
        uk-tooltip={props.isDisabled ? props.tooltipTitle : "title:"}
        onClick={(event) => {
          if(props.apply){
            props.apply(props.isRow, event);
          }
        }}
        disabled={props.isDisabled}
        className={props.className}
        variant= {props.variant ? props.variant : "contained"}
        disableElevation
        disableRipple
        sx={props.sx}
        uk-toggle={props.isFilterBy || props.isWidgetFilter ? props.ukToggle : undefined}
      >
        {!props.rightIcon && props.icon}
        {props.text}
        {props.rightIcon && props.icon}
        {/* rightIcon props is to know the position of the icon if it's after or before the text */}
      </Button>
    </Stack>
  );
}
